import httpClient from '../httpClient';

const END_POINT = '/api/sessions';

const createSession = async (session) => await httpClient.post(END_POINT, session)
const getSessions = async () => await httpClient.get(`${END_POINT}`)
const getSessionsState = async () => await httpClient.get(`${END_POINT}/state`)
const getSession = async (sessionId) => await httpClient.get(`${END_POINT}/${sessionId}`)
const editSession = async (sessionId, session) => await httpClient.patch(`${END_POINT}/${sessionId}`, session)
const updateSessionState = async (sessionId, payload) => await httpClient.patch(`${END_POINT}/update-status/${sessionId}`, payload)
const deleteSession = async (sessionId) => await httpClient.delete(`${END_POINT}/${sessionId}`)
const getSessionsUsers = async (sessionId) => await httpClient.get(`${END_POINT}/user/${sessionId}`)
const editSessionFeedback = async (sessionUserId, session) => await httpClient.patch(`${END_POINT}/feedback/${sessionUserId}`, session)
const editSessionUsers = async (sessionId, users) => await httpClient.patch(`${END_POINT}/users/${sessionId}`, users)
const getAllUsers = async (sessionId) => await httpClient.get(`${END_POINT}/allUsers/${sessionId}`)

export {
  createSession,
  getSessions,
  getSessionsState,
  getSession,
  editSession,
  deleteSession,
  getSessionsUsers,
  editSessionFeedback,
  editSessionUsers,
  updateSessionState,
  getAllUsers
}