<template>
    <div class="d-flex flex-column justify-content-between vh-100">
      <div>
        <div v-if="loading" class="overlay">
            <div v-if="loading" class="overflow-loading">
              <rd-loading />
            </div>
          </div>
        <rd-navbar :title="$t('Background')" />
        <div class="container background-form my-3 p-3">
          <PageTitle
            :title="$t('Status Rezero')"
            :description="$t('Status Rezero')"
            :parentBread="$t('System Administration')"
            :childBread="$t('Settings')"
            :grandChildBread="$t('Status Rezero')"
          />
          <div class="row px-3 justify-content-between">
          <!-- Search bar -->
          <div class="col-12 col-lg-4">
            <rd-input
              class="w-100"
              :placeholder="$t('Search user by first name, last name or username')" 
              type="text"
              @input="(e) => this.search = e.target.value"
            />
          </div>
        </div>

        <div v-if="userLoading">
          <rd-loading />
        </div>

        <div v-else>
          <div
            v-if="filteredUsers.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No users Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>
          <div v-else class="row p-3">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover table-custom-general" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col">
                        <!-- v-model="allCategoriesSelected"
                        :checked2="allSubcategories.length === subcategoriesToAdd.length"
                        :value="parseInt(allCategoriesSelected)"
                        :id="parseInt(allCategoriesSelected)" -->
                        <rd-input
                          :checked2="allChecked"
                          :value="allChecked"
                          @change="checkAll"
                          type="checkbox"
                        />
                      </th>
                      <th >{{ $t('Users') }}</th>
                      <th >{{ $t('Email (table)') }}</th>
                      <th >{{ $t('Role (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-start">
                    <tr
                      v-for="(user, index) in dataTemp" :key="index"
                      class="hoverable"
                    >
                      <td>
                        <rd-input
                          v-model="user.check"
                          :value="user.check"
                          :checked2="user.check"
                          @change="checkUser(user)"
                          type="checkbox"
                        />
                      </td>
                      <td>{{ user.name }} </td>
                      <td>{{ user.email}}</td>
                      <td>{{ user.roleName }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <rd-paginator :items="filteredUsers" @page-change="handlePageChange" />
            <div class="col-12 justify-content-end text-end">
              <div class="row justify-content-end text-end">
                <div class="col-12 col-lg-6 text-end">
                  <button-component primaryOutline @handleClick="deselectAll">
                    {{ $t('Dismiss') }}
                  </button-component>
                  <button-component
                    primary
                    @handleClick="openConfirmationModal"
                  >
                    {{ $t('Save') }}
                  </button-component>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>

    <rd-confirmation-modal
      v-if="showModalProp"
      modalTitle="Restart File Visibility"
      confirmButton="Restart"
      dismissButton="Dismiss"
      bodyTitleText="Are you sure to restart file visibility"
      confirmCheckText="Delete anyway"
      @confirmAction="handleSave()"
      @dismissAction="showModalProp = false"
    />
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import { getAllUsers } from '@/services/user/user.api.js'
import RdConfirmationModal from '../../components/rd-components/rd-confirmation-modal.vue';
import RdLoading from '../../components/rd-components/rd-loading.vue';
import RdInput from '../../components/rd-components/rd-input.vue';
import RdPaginator from '@/components/rd-components/rd-paginator.vue';
import PageTitle from '../../components/rd-components/page-title.vue';
import ButtonComponent from '../../components/button-component.vue';
import { restartStatusRezero } from '../../services/interactions/interactions.api';

export default {
  components: {
    RdFooter,
    RdNavbar,
    RdConfirmationModal,
    RdLoading,
    RdInput,
    RdPaginator,
    PageTitle,
    ButtonComponent
  },
  data() {
    return {
      loading: false,
      allChecked: false,
      users: [],
      userLoading: true,
      showModalProp: false,
      userToDelete: {},
      search: '',
      dataTemp: [],
      tableSorting: {
        clickFirstName: false,
        clickLastName: false,
        clickUsername: false,
        clickRole: false,
      },
      thElm: null,
      startOffset: null,
      lastWidth: 0
    }
  },
  computed: {
    filteredUsers() {
      return this.users.filter( user => 
        (user.userFirstname && user.userFirstname.toLowerCase().includes(this.search.trim().toLowerCase())) || 
        (user.userLastname && user.userLastname.toLowerCase().includes(this.search.trim().toLowerCase())) ||
        user.userName.toLowerCase().includes(this.search.trim().toLowerCase())
      )
    },
    usersSelected() {
      const users = this.users.filter(user => user.check);
      return users.length ? users : [];
    },
  },
  async mounted() {
    if(this.$store.state.permissions.length == 1) {
      this.$store.commit('setShowBackButton', false)
    }
    await this.setUsers()
  },
  
  methods: {
    async restartRezero(items) {
      try {
        const {data} = await restartStatusRezero(items);
        return data;
      } catch (error) {
        console.log({error});
      }
    },
    deselectAll() {
      this.allChecked = false;
      this.users.forEach((user) => {
        user.check = this.allChecked;
      });
    },
    openConfirmationModal() {
      this.showModalProp = true;
    },
    checkAll() {
      this.allChecked = !this.allChecked;
      this.users.forEach((user) => {
        user.check = this.allChecked;
      });
    },
    checkUser(item) {
      const selectedUser = this.users.find(({userId}) => userId === item.userId);
      selectedUser.check = !selectedUser.check;
    },
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '20%'
      })
    },
    sortTable(param, key) {
      this.tableSorting[param] = !this.tableSorting[param]
      if(!this.tableSorting[param]) {
        this.users.reverse();
        return; 
      }
      if(key === 'roleId') {
        this.users.sort((a, b) => a[`role`]['roleName'].localeCompare(b[`role`]['roleName']))
        return;
      }
      this.users.sort((a, b) => a[`${key}`].localeCompare(b[`${key}`])) 
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    showModal(e, user){
      this.showModalProp = true
      this.userToDelete = user
    },
    dismissAction() {
      this.userToDelete = {}
      this.showModalProp = false
    },
    async handleSave(){
      try {
        this.loading = true;
        const users = this.usersSelected.map(({userId}) => {
          return userId;
        });
        const {statusCode} = await this.restartRezero({users});

        if(statusCode === 200) {
          this.$toast.open({
            message: 'Status Rezero successfully',
            type: 'success'
          });
          this.showModalProp = false;
          this.loading = false;
        }

      } catch (error) {
        console.log(error)
        this.$toast.open({
            message: 'Error while restarting Status Rezero',
            type: 'error'
          });
      } finally {
        this.loading = false;
      }
    },
    async setUsers() {
      try {
        this.userLoading = true
        const {data} = await getAllUsers()
        this.users = data.map((item) => {
          const user = item;
          user.name = user.userFirstname && user.userLastname ? `${user.userFirstname} ${user.userLastname}` : '-';
          user.email = user.userEmail || '-';
          user.roleName = user.role?.roleName || '-';
          user.check = false;
          return user;
        });
      } catch (error) {
        console.log(error)
      } finally {
        this.userLoading = false
        await this.$nextTick(() => {
          const elements = document.querySelectorAll("table th");
          elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
          })
          document.addEventListener("mousemove", (e) => {
            if (this.thElm) {
              this.lastWidth  = this.thElm.offsetWidth;
              this.thElm.style.width = this.startOffset + e.pageX + "px";
                document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                  if((internalIndex % 5) == Number(this.thElm.id)) {
                    td.style.width = this.startOffset + e.pageX + "px";
                  } else {
                    td.style.width = td.offsetWidth + "px";
                  }
                })
                this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
            }
          });

            document.addEventListener("mouseup", () => {
              this.thElm = undefined;
            });
        });
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.hoverable:hover {
  cursor: pointer;
}
.hoverable:hover {
  cursor: pointer;
}
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.overlay {
  position: fixed; /* Changed from absolute to fixed */
  top: 0;
  left: 0;
  width: 100vw; /* Cover the entire viewport width */
  height: 100vh; /* Cover the entire viewport height */
  background-color: rgba(0, 0, 0, 0.262); /* Semi-transparent background */
  color: white;
  z-index: 9999; /* Ensure it's on top but below the loader */
  border-radius: 0; /* Removed border-radius to cover the full screen */
}

.overflow-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000; /* Ensure it's above the overlay */
}
</style>