<template lang="">
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Categories (nav)')" />
      <div class="container background-form my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Edit Subcategory') }}</h2>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Subcategory name')" 
              :label="$t('Enter the subcategory name')"
              required
              :value="subcategoryName"
              @input="(e) => this.subcategoryName = e.target.value"
            />
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="select"
              :placeholder="$t('Select a new category')" 
              :placeholderToTag="$t('Add this category')"
              :label="$t('Enter the category related')"
              :items="categories"
              :initialValue="categorySelected"
              @handleAddTagging="(category) => categorySelected = category"
            />
          </div>
        </div>

        <div class="mt-3 row justify-content-end">
          <div class="col-12 col-md-6 col-lg-6 text-end">
            <button-component primaryOutline @handleClick="goBack">
              {{ $t('Dismiss') }}
            </button-component>
            <button-component :disabled="disableSaveButton" primary @handleClick="editSubcategory">
              {{ $t('Edit Subcategory (btn)') }}
            </button-component>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import router from '@/router'
import { getAllCategory } from '@/services/categories/categories.api.js'
import { getSubcategoryById, editSubcategoryById } from '@/services/subcategories/subcategories.api.js'
import buttonComponent from '../../components/button-component.vue';

export default {
  data() {
    return {
      subcategoryName: '',
      categories: [],
      categorySelected: {},
      disableSaveButton: false,
    }
  },
  async mounted() {
    await this.setCategories()
    await this.setSubcategoryData()
    this.$watch(
      () => {
        return [this.subcategoryName]
      },
      ([subcategoryName]) => {
        if (subcategoryName === '') {
          this.disableSaveButton = true
        } else {
          this.disableSaveButton = false
        }
      } 
    );
  },
  computed: {
    subcategoryId() {
      return this.$route.params.id
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    buttonComponent,
  },
  methods: {
    async setSubcategoryData() {
      try {
        const { data } = await getSubcategoryById(this.subcategoryId)
        this.subcategoryName = data[0].subCategoryName
        this.categorySelected = this.categories.find(el => el.value == data[0].categoryId)
      } catch (error) {
        console.log()
      }
    },
    async setCategories() {
      try {
        const res = await getAllCategory('evenIfNoSubcategories')
        this.categories = res.data.map(category => ({ value: category.categoryId, name: category.categoryName }));
      } catch (error) {
        console.log(error)
      }
    },
    async editSubcategory() {
      const subcategory = {
        categoryId: parseInt(this.categorySelected.value),
        subCategoryName: this.subcategoryName
      }
      try {
        await editSubcategoryById(this.subcategoryId, subcategory)
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Subcategory edited.',
          type: 'success',
        });
        router.back()
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Could not edit this subcategory.',
          type: 'error',
        });
        console.log(error)
      }
    },
    goBack() {
      router.back()
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.labelText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: grey;
  float: left;
  border-radius: 4px;
}
</style>