<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Exam (nav)')" />
      <div class="container background-form rounded-3 my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Edit Exam') }}</h2>
          </div>
        </div>
        <div class="container">
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                :placeholder="$t('Exam name')" 
                type="text"
                :label="$t('Enter the exam name')"
                required
                :value="examName"
                @input="(e) => examName = e.target.value"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                :placeholder="$t('Exam description')" 
                type="text"
                :label="$t('Enter the exam description')"
                required
                :value="examDescription"
                @input="(e) => examDescription = e.target.value"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="number"
                :placeholder="$t('Exam duration')" 
                :label="$t('Exam duration in minutes (if blank the exam duration is infinity)')"
                :value="examDuration"
                @input="(e) => examDuration = e.target.value"
              />
              <div
                v-if="!!examDuration && Number(examDuration) <= 0"
                class="text-start text-danger"
              >
                <small>{{ $t('Do not enter negative numbers') }} &nbsp;</small>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="number"
                :placeholder="$t('Exam attempts')" 
                :label="$t('Exam attempts (if blank makes the exam attempts unlimited)')"
                :value="examAttempts"
                @input="(e) => examAttempts = e.target.value"
              />
              <div
                v-if="!!examAttempts && Number(examAttempts) <= 0"
                class="text-start text-danger"
              >
                <small>{{ $t('Do not enter negative numbers') }} &nbsp;</small>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                :placeholder="$t('Exam minimum percentage')" 
                type="number"
                :label="$t('Enter the exam mimimum percentage')"
                :value="examMinimumScore"
                required
                @input="(e) => examMinimumScore = e.target.value"
              />
              <div
                v-if="!!examMinimumScore && Number(examMinimumScore) < 0"
                class="text-start text-danger"
              >
                <small>{{ $t('Do not enter negative numbers') }} &nbsp;</small>
              </div>
              <div
                v-if="!!examMinimumScore && Number(examMinimumScore) > 100"
                class="text-start text-danger"
              >
                <small>{{ $t('Do not enter numbers above 100') }} &nbsp;</small>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Exam language')" 
                :label="$t('Enter the exam language')"
                :value="examLanguage"
                @input="(e) => examLanguage = e.target.value"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :placeholder="$t('Choose a category')"
                :placeholderToTag="$t('Add this category')" 
                :label="$t('Enter exam category')"
                :items="categories"
                :initialValue="examCategory"
                required
                @handleAddTagging="handleCategorySelection"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :placeholder="$t('Choose a subcategory')" 
                :placeholderToTag="$t('Add this subcategory')" 
                :label="$t('Enter the exam subcategory')"
                :items="subcategories"
                :disbled="!subcategories.length"
                :initialValue="examSubcategory"
                required
                @handleAddTagging="(e) => this.examSubcategory = e"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <div class="form-check d-flex">
                <rd-input
                  :label="$t('Generates certificate')"
                  type="checkbox"
                  v-model="examGeneratesCertificate"
                  :checked2="examGeneratesCertificate"
                  id="certificateLabel"
                  @change="(e) => examGeneratesCertificate = e.target.checked"
                />
              </div>
            </div>
          </div>

          <div class="row mb-4" v-show="examGeneratesCertificate">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :placeholder="$t('Choose a template for certificates')" 
                :placeholderToTag="$t('Add this template')" 
                :label="$t('Enter the exam template')"
                :items="templates"
                :disbled="!templates.length"
                :initialValue="templateId"
                @handleAddTagging="handleTemplateSelection"
              />
              <div
                v-if="!!templateId"
                contentEditable="false"
                class="m-0 p-0 quill-overflow-custom"
              >
                <div class="m-o p-0" v-html="templateId.template"></div>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <div class="form-check d-flex">
                <!-- class="form-check-input me-2" -->
                <rd-input :label="$t('Allows empty answers')" type="checkbox" v-model="examAllowsEmpty" :checked2="examAllowsEmpty" id="allowEmptyLabel" @change="changeExamAllowsEmpty"/>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <div class="form-check d-flex">
                <rd-input :label="$t('Is available?')" type="checkbox" v-model="examIsAvailable" :checked2="examIsAvailable" id="isAvailableLabel" @change="changeExamIsAvailable"/>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <div class="form-check d-flex">
                <rd-input :label="$t('Review Answer?')" type="checkbox" v-model="examReviewAnswer" :checked2="examReviewAnswer" id="reviewAnswerLabel" @change="changeExamReviewAnswer"/>
              </div>
            </div>
          </div>

          <div class="row mb-4 align-items-center">
            <div class="col-10 col-sm-10 col-md-5">
              <rd-input
                ref="questions-input"
                type="file"
                :propagation="false"
                :label="$t('Choose a questions file')"
                :accept="'.csv'"
                required
                @input="handleQuestionsUploaded($event)"
              />
            </div>
            <div class="col-2 col-sm-2 col-md-1">
              <button-component
                class="btn-light text-primary"
                :title="$t('Download a question template')"
                @handleClick="downloadQuestions(questions)"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Download Template" type="button" class="bi bi-arrow-down-circle-fill"></i>
              </button-component>
            </div>
          </div>
          <div class="row mb-4 align-items-center">
            <div class="col-10 col-sm-10 col-md-5">
              <rd-input
                ref="questions-input"
                type="file_image"
                :propagation="false"
                :label="$t('Choose some images')"
                :multiple="true"
                :showError="showFileError"
                :errorMsg="errorMessage"
                :disable="!examQuestions"
                @input.prevent="handleFileUploaded($event)"
              />
              <div v-if="imageValidation.length" class="row mb-4 ">
                <div
                  class="d-flex"
                  v-for="(image, index) in imageValidation"
                  :key="index"
                  :class="{
                    'text-success': image[0] !== null && image[1] !== null,
                    'text-danger': image[0] === null || image[1] === null
                  }"
                > 
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Upload image Status" type="button" :class="`bi ${image[0] !== null && image[1] !== null ? 'bi-check-circle-fill': 'bi-exclamation-circle-fill'}`" />
                  {{ `${image[0] == null? 'Unexpected' : 'Expected'} ${image[0] ?? image[1]}` }}
                </div>
              </div>
            </div>
          </div>

          <div
            :class="{
              'w-50': windowWidth > 768,
              'w-100': windowWidth <= 768
            }"
          >
            <quill-editor
              v-model:value="editor.content"
              :options="editor.options"
            />
          </div>

          <div class="my-3 row justify-content-end">
            <div class="col-12 col-md-6 col-lg-3 text-end">
              <button-component primaryOutline @click="$router.back()">
                {{ $t('Dismiss') }}
              </button-component>
              <button-component
                :disabled="disableSaveButton"
                primary
                @handleClick="handleEditExam"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Exam" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Edit Exam') }}
              </button-component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import router from '@/router'
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import { updateExamById, getExamById } from '@/services/exams/exam.api.js'
import { decodeFromBase64, encodeToBase64 } from '../../utils/utils';
import { mapState } from 'vuex';
import { getAllCategory } from '@/services/categories/categories.api.js'
import { convertToCSV, convertToQuestionsFormat } from '../../utils/convertFormat';
import { downloadCSV } from '../../utils/handleFile';
import ButtonComponent from '../../components/button-component.vue';
import { getAllTemplates } from '@/services/templates/templates.api.js'

export default {
  data() {
    return {
      examName: null,
      examDescription: null,
      examDuration: null,
      examAttempts: null,
      examMinimumScore: null,
      examLanguage: null,
      examGeneratesCertificate: false,
      examCertificateTemplate: null,
      examAllowsEmpty: false,
      examIsAvailable: true,
      examReviewAnswer: false,
      examQuestions: null,
      disableSaveButton: true,
      showFileError: false,
      errorMessage: 'Images doesnt match csv',
      filesArray: [],
      editor: {
        content: '',
        options: {
          placeholder: this.$t('Type something...'),
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: ['Sans Serif', 'serif', 'monospace', 'roboto'] }],
              [{ align: [] }],
              ['clean'],
              ['link']
            ]
            // other moudle options here
            // otherMoudle: {}
          },
        },
        disabled: false,
      },
      categories: [],
      subcategories: [],
      questions: null,
      imagesExpected: [],
      imageValidation: [],
      templates: [],
      templateId: null,
    }
  },
  async created() {
    await this.setCategories();
    await this.setTemplates();
    await this.getExamData();
  },
  mounted() {
    this.$watch(
      () => {
        return [this.examName, this.examDescription, this.examDuration, this.examAttempts, this.examMinimumScore, this.showFileError]
      },
      ([examName, examDescription, examDuration, examAttempts, examMinimumScore, showFileError]) => {
        if (
          !examName
          || !examDescription
          || (!!examDuration && Number(examDuration) <= 0)
          || (!!examAttempts && Number(examAttempts) <= 0)
          || (examMinimumScore == null)
          || (examMinimumScore == '')
          || Number(examMinimumScore) < 0
          || Number(examMinimumScore) > 100
          || showFileError
        ) {
          this.disableSaveButton = true;
        } else {
          this.disableSaveButton = false;
        }
      }
    );
  },
  watch: {
    examCategory: {
      handler(n) {
        if (!n.value) this.disableSaveButton = false;
        else this.disableSaveButton = true;
      },
      deep: true
    },
    examSubcategory: {
      handler(n) {
        if (!n.value)this.disableSaveButton = false;
        else this.disableSaveButton = true;
      },
      deep: true
    },
  },
  computed: {
    ...mapState(['windowWidth']),
    user(){
      return this.$store.getters.getUser;
    },
  },
  methods: {
    handleTemplateSelection({
      code,name,template
    }) {
      if (this.templateId && this.templateId.code === code) return
      this.templateId = { code, name, template }
    },
    changeExamAllowsEmpty(e) {
      this.examAllowsEmpty = e.target.checked
    },
    changeExamIsAvailable(e) {
      this.examIsAvailable = e.target.checked
    },
    changeExamReviewAnswer(e) {
      this.examReviewAnswer = e.target.checked
    },
    async setTemplates() {
      try {
        const { data } = await getAllTemplates();
        this.templates = data.map(template => ({ name: template.name, code: template.id, template: template.template }))
      } catch (error) {
        console.log(error)
      }
    },
    calcImagesCsv() {
      const newText = this.examQuestions.trim()
      const lines = newText.split(/\r?\n/);
      const headers = lines[0].split('|');
      const dataArray = [];
      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split('|');
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentLine[j];
        }
        dataArray.push(obj);
      }
      const images = []
      dataArray.forEach((data) => {
        if(data.image) {
          images.push(data.image)
        }
      })
      this.imagesExpected = images
    },
    async handleQuestionsUploaded(e) {
      const file = e.target.files[0];
      this.examQuestions = await file?.text();
      if(file) {
        this.calcImagesCsv();
        this.handleValidImages();
      }
    },
    handleFileUploaded(event) {
      this.filesArray = []
      const files = event.target.files;
      if (files.length > 0) {
        Array.from(files).forEach(file => {
          this.filesArray.push(file)
        });
      }
      this.handleValidImages();
    },
    handleValidImages() {
      this.imageValidation = JSON.parse(JSON.stringify([]))
      if(this.filesArray.length && this.examQuestions) {
        const images = this.imagesExpected
        
        const fileNames = []
        this.filesArray.forEach((file) => {
          fileNames.push(file.name)
        })
        images.sort();
        fileNames.sort();
        let newFileNames = fileNames;
        if(images.length == fileNames.length) {
          const listsAreDifferent = !images.every((value, index) => value === fileNames[index]);
          for (let index = 0; index < images.length; index++) {
            const image = images[index];
            let validate = false;
            let indexFile = null;
            for (let index2 = 0; index2 < newFileNames.length; index2++) {
              const fileName = newFileNames[index2];
              if(fileName == image) {
                validate = true;
                indexFile = index2
              }
            }
            if(validate) {
              this.imageValidation.push([
                image, newFileNames[indexFile]
              ])
              newFileNames.splice(indexFile, 1);
            } else {
              this.imageValidation.push([
                image, null
              ])
            }
          }
          for (let index2 = 0; index2 < newFileNames.length; index2++) {
            const fileName = newFileNames[index2];
            this.imageValidation.push([
                null, fileName
              ])
          }
          this.showFileError = listsAreDifferent;
          this.imageValidation.sort((a, b) => {
              if (a[0] !== null && b[0] !== null) {
                  return a[0].localeCompare(b[0]);
              }
              else if (a[1] === null && b[1] === null) {
                  return a[0].localeCompare(b[0]);
              }
              else if (a[0] === null || b[0] === null) {
                  return a[0] === null ? 1 : -1;
              }
          });
          return;
        }
        for (let index = 0; index < images.length; index++) {
          const image = images[index];
          let validate = false;
          let indexFile = null;
          for (let index2 = 0; index2 < newFileNames.length; index2++) {
            const fileName = newFileNames[index2];
            if(fileName == image) {
              validate = true;
              indexFile = index2
            }
          }
          if(validate) {
            this.imageValidation.push([
              image, newFileNames[indexFile]
            ])
            newFileNames.splice(indexFile, 1);
          } else {
            this.imageValidation.push([
              image, null
            ])
          }
        }
        for (let index2 = 0; index2 < newFileNames.length; index2++) {
          const fileName = newFileNames[index2];
          this.imageValidation.push([
              null, fileName
            ])
        }
        this.imageValidation.sort((a, b) => {
            if (a[0] !== null && b[0] !== null) {
                return a[0].localeCompare(b[0]);
            }
            else if (a[1] === null && b[1] === null) {
                return a[0].localeCompare(b[0]);
            }
            else if (a[0] === null || b[0] === null) {
                return a[0] === null ? 1 : -1;
            }
        });
        this.showFileError = true;
      }
    },
    async downloadQuestions(payload) {
      try {
        const data = convertToQuestionsFormat(payload);
        const csv = await convertToCSV({ data });
        const filename = `${this.examName}_questions`
        downloadCSV({ data: csv, filename });
      } catch (error) {
        console.log(error);
      }
    },
    async getExamData() {
      try {
        const { data } = await getExamById(this.$route.params.id);
        const { exam, category: examCategory, subcategory: examSubcategory, questions } = data;
        this.questions = questions;
        this.examName = exam.examName;
        this.examDescription = exam.examDescription;
        this.examDuration = exam.examDuration;
        this.examAttempts = exam.examAttempts;
        this.examGeneratesCertificate = exam.examHasCertificate;
        this.examAllowsEmpty = exam.examAllowsEmpty;
        this.examMinimumScore = exam.minimumScore;
        this.examIsAvailable = exam.isAvailable;
        this.examLanguage = exam.examLanguage;
        this.examReviewAnswer = exam.reviewAnswer;
        this.templateId = exam.templateId ? this.templates.find(template => template.code == exam.templateId) : null;
        this.examCategory = Object.values(examCategory).length > 0 ? examCategory : null;
        const category = this.categories.find(({value}) => parseInt(value) === parseInt(examCategory.value))
        if (category) {
          this.subcategories = category.subcategories.map(({ subCategoryId, subCategoryName }) => (
            { value: subCategoryId, name: subCategoryName }
          ));
        }
        this.examSubcategory = Object.values(examSubcategory).length > 0 ? examSubcategory : null;
        const html = await decodeFromBase64(exam.examInstuctions);
        this.editor.content = JSON.parse(html);
      } catch (error) {
        console.log(error);
      }
    },
    handleCategorySelection(c) {
      this.examCategory = c
      const category = this.categories.find(({value}) => parseInt(value) === parseInt(c.value))
      if (category) {
        this.subcategories = category.subcategories.map(({ subCategoryId, subCategoryName }) => (
          { value: subCategoryId, name: subCategoryName }
        ));
      }
    },
    async setCategories() {
      try {
        const { data } = await getAllCategory()
        this.categories = data.map(category => (
          { 
            value: category.categoryId, 
            name: category.categoryName, 
            subcategories: category.subcategories 
          }
        ));
      } catch (error) {
        console.log(error)
      }
    },
    async handleEditExam() {
      try {
        const questions = this.examQuestions;
        if (
          (this.examDuration && Number(this.examDuration) <= 0)
          || (this.examAttempts && Number(this.examAttempts) <= 0)
          || Number(this.examMinimumScore) < 0
          || Number(this.examMinimumScore) > 100
        ) {
          // TODO: add message
          return;
        }
        const payload = {
          exam: {
            examName: this.examName,
            examDescription: this.examDescription,
            examDuration: this.examDuration ? Number(this.examDuration) : null,
            examAttempts: this.examAttempts ? Number(this.examAttempts) : null,
            examHasCertificate: this.examGeneratesCertificate,
            examAllowsEmpty: this.examAllowsEmpty,
            examInstuctions: this.editor.content ? encodeToBase64(this.editor.content) : null,
            examSubcategory: this.examSubcategory.value,
            minimumScore: Number(this.examMinimumScore),
            isAvailable: this.examIsAvailable,
            reviewAnswer: this.examReviewAnswer,
            examLanguage: this.examLanguage,
            templateId: this.examGeneratesCertificate && this.templateId ? this.templateId.code : null,
          },
          questions
        };
        let exam = new FormData();
        exam.append('payload', JSON.stringify(payload));
        this.filesArray.forEach((file) => {
          exam.append('files', file);
        })

        await updateExamById(this.$route.params.id, exam);
        
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Exam updated.',
          type: 'success',
        });
        router.back()
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Exam was not updated.',
          type: 'error',
        });
        console.log(error)
      }
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    ButtonComponent,
  }
}
</script>
<style>
.text-left {
  text-align: left !important;
}
.ql-picker-item[data-value="roboto"]::before {
  content: 'Roboto' !important;
}
span.ql-picker-label[data-value="roboto"]::before {
  content: 'Roboto' !important;
}

@media screen and (max-width: 768px) {
  .quill-overflow-custom {
    overflow-x: auto;
  }
}
</style>