<template>
  <div class="d-flex flex-column justify-content-between vh-100 unselectable">
    <div>
      <rd-navbar />
      <div class="container my-3 p-3">
        <div v-if="loading">
          <rd-loading /> 
        </div>

        <div v-else class="p-3">
          <div class="row">
            <div class="col-12">
              <h2 class="mb-3 text-start">{{ examTitle }}</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <pie-chart-component
                :data="[
                  {
                    category: $t('Pending'),
                    value: statsCount[ATTEMPT_STATUS.PENDING],
                    sliceSettings: {
                      fill: am5.color(0xb8b8b8),
                      stroke: am5.color(0xb8b8b8),
                    }
                  }, {
                    category: $t('In Progress'),
                    value: statsCount[ATTEMPT_STATUS.IN_PROGRESS],
                    sliceSettings: {
                      fill: am5.color(0xffdd99),
                      stroke: am5.color(0xffdd99),
                    }
                  }, {
                    category: $t('Failed'),
                    value: statsCount[ATTEMPT_STATUS.FAILED],
                    sliceSettings: {
                      fill: am5.color(0xedabab),
                      stroke: am5.color(0xedabab),
                    }
                  }, {
                    category: $t('Approved'),
                    value: statsCount[ATTEMPT_STATUS.APPROVED],
                    sliceSettings: {
                      fill: am5.color(0xacd778),
                      stroke: am5.color(0xacd778),
                    }
                  }
                ]"
                showLabels
                :hasInnerRadius="false"
                height="300px"
              />
            </div>
            <div class="col-6 d-flex flex-column justify-content-evenly">
              <div class="row ">
                <div class="col justify-content-center d-flex">
                  <div class="card text-center w-50" id="custom-card">
                    <div class="card-body" style="background: #759FD3;">
                      <h5 class="card-title">{{ $t('Assigned') }}</h5>
                      <p class="card-text">{{ statsList.length }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card text-center">
                    <div class="card-body bg-pending">
                      <h5 class="card-title">{{ $t('Pending') }}</h5>
                      <p class="card-text">{{ statsCount[ATTEMPT_STATUS.PENDING] ?? 0 }}{{ calcPercentage(statsCount[ATTEMPT_STATUS.PENDING] ?? 0) }}</p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card text-center">
                    <div class="card-body bg-inprogress">
                      <h5 class="card-title">{{ $t('In Progress') }}</h5>
                      <p class="card-text">{{ statsCount[ATTEMPT_STATUS.IN_PROGRESS] ?? 0 }}{{ calcPercentage(statsCount[ATTEMPT_STATUS.IN_PROGRESS] ?? 0) }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card text-center">
                    <div class="card-body bg-failed">
                      <h5 class="card-title">{{ $t('Failed') }}</h5>
                      <p class="card-text">{{ statsCount[ATTEMPT_STATUS.FAILED] ?? 0 }}{{ calcPercentage(statsCount[ATTEMPT_STATUS.FAILED] ?? 0) }}</p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card text-center">
                    <div class="card-body bg-approved">
                      <h5 class="card-title">{{ $t('Approved') }}</h5>
                      <p class="card-text">{{ statsCount[ATTEMPT_STATUS.APPROVED] ?? 0 }}{{ calcPercentage(statsCount[ATTEMPT_STATUS.APPROVED] ?? 0) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-start">
            <div class="col-12">
              <h3 class="mt-5">{{ $t('Detailed exam statistics') }}</h3>
            </div>
            <div class="col-12">
              {{ $t('Minimum passing score') }}: {{ examMinimumScore }}
            </div>
            <div class="col-12">
              {{ $t('Number of attempts') }}: {{ examMaxAttempts ? examMaxAttempts : $t('Unlimited') }}
            </div>
          </div>
          <div class="row p-3 justify-content-between">
            <div
              class="col-12 col-lg-4"
            >
              <rd-input
                class="w-100"
                :placeholder="$t('Search user by username')" 
                type="text"
                @input="(e) => this.search = e.target.value"
              />
            </div>

            <div class="col-12 col-lg-4 text-end">
              <button-component
                primaryOutline
                @handleClick="downloadExamStats"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Download Stats" type="button" class="bi bi-arrow-down-circle-fill"></i> {{ $t('Download Stats') }}
              </button-component>
            </div>
  
          </div>
          <div
            v-if="filteredStats.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No stats Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>
          <div v-else class="row mt-3 d-flex justify-content-start">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover table-custom-general" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr class="text-center">
                      <th style="width: 18%;" @click="sortTable('stats', 'userName')" scope="col">{{ $t('Users (table)') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.stats ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" /> </th>
                      <th style="width: 18%;" @click="sortTable('pending', 'PENDING')" scope="col">{{ $t('Pending (table)') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.pending ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" /> </th>
                      <th style="width: 18%;" @click="sortTable('inProgress', 'IN_PROGRESS')" scope="col">{{ $t('In Progress (table)') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.inProgress ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" /> </th>
                      <th style="width: 18%;" @click="sortTable('failed', 'FAILED')" scope="col">{{ $t('Failed (table)') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.failed ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" /> </th>
                      <th style="width: 18%;" @click="sortTable('approved', 'APPROVED')" scope="col">{{ $t('Approved (table)') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.approved ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" /> </th>
                      <th style="width: 10%;" @click="sortTable('score', 'score')" scope="col">{{ $t('Score (table)') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="Sort Table" type="button" :class="tableSorting.score ? 'bi bi-arrow-up-short' : 'bi bi-arrow-down-short'" /> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(attempt, index) in dataTemp" :key="index"
                      class="text-center hoverable"
                      :class="{ 'fill-ods': index % 2 !== 0 }"
                    >
                      <td style="word-break: break-all; vertical-align: middle;" scope="row" :header="$t('Users (table)')" class="word-wrap" ><div class="custom-table-text no-padding">{{ attempt.userName }}</div></td>
                      <td style="word-break: break-all; vertical-align: middle;" scope="row" :header="$t('Pending (table)')" >
                        <i data-bs-toggle="tooltip" data-bs-placement="right" title="Attempt Status" type="button" :class="attempt.status === ATTEMPT_STATUS.PENDING ? 'bi bi-check-circle' : 'bi bi-circle'"></i>
                      </td>
                      <td style="word-break: break-all; vertical-align: middle;" scope="row" :header="$t('In Progress (table)')" >
                        <i data-bs-toggle="tooltip" data-bs-placement="right" title="Attempt Status" type="button" :class="attempt.status === ATTEMPT_STATUS.IN_PROGRESS ? 'bi bi-check-circle' : 'bi bi-circle'"></i>
                      </td>
                      <td style="word-break: break-all; vertical-align: middle;" scope="row" :header="$t('Failed (table)')" >
                        <i data-bs-toggle="tooltip" data-bs-placement="right" title="Attempt Status" type="button" :class="attempt.status === ATTEMPT_STATUS.FAILED ? 'bi bi-check-circle' : 'bi bi-circle'"></i>
                      </td>
                      <td style="word-break: break-all; vertical-align: middle;" scope="row" :header="$t('Approved (table)')" >
                        <i data-bs-toggle="tooltip" data-bs-placement="right" title="Attempt Status" type="button" :class="attempt.status === ATTEMPT_STATUS.APPROVED ? 'bi bi-check-circle' : 'bi bi-circle'"></i>
                      </td>
                      <td style="word-break: break-all; vertical-align: middle;" scope="row" :header="$t('Score (table)')" >{{ attempt.score ? attempt.score : '-' }} / {{ questionsNumber }}</td>
                    </tr>
                  </tbody>
                </table>
                <rd-paginator :forceRender="false" :items="filteredStats" @page-change="handlePageChange($event, index)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import RdFooter from '@/components/rd-components/rd-footer.vue';
import RdNavbar from '@/components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import RdLoading from '@/components/rd-components/rd-loading.vue';
import { getStats, getExamById } from '@/services/exams/exam.api.js';
import { convertToCSV } from '@/utils/convertFormat';
import { downloadCSV } from '@/utils/handleFile';
import PieChartComponent from '@/components/amCharts/pie-chart-component.vue';
import { ATTEMPT_STATUS } from '@/utils/constants';
import RdPaginator from '@/components/rd-components/rd-paginator.vue'
import * as am5 from '@amcharts/amcharts5';
import ButtonComponent from '../../components/button-component.vue';

export default {
  data() {
    return {
      stats: {},
      loading: true,
      ATTEMPT_STATUS,
      examTitle: null,
      examMaxAttempts: null,
      examMinimumScore: null,
      questionsNumber: 0,
      search: '',
      am5,
      tableSorting: {
        stats: false,
        pending: false,
        inProgress: false,
        failed: false,
        approved: false,
        score: false,
      },
      dataTemp: [],
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdLoading,
    RdInput,
    PieChartComponent,
    RdPaginator,
    ButtonComponent,
  },
  async mounted() {
    await this.getExamStats();
    await this.getExamInfo();
  },
  methods: {
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    calcPercentage(value) {
      const sum = Object.values(this.statsCount).reduce((acc, current) => acc + current, 0)
      const percentage = Math.round(((value * 100) / sum) * Math.pow(10, 1)) / Math.pow(10, 1) ;
      return isNaN(percentage) ? '(0.00%)' : `(${percentage.toFixed(2)}%)` ;
    },
    sortTable(param, key) {
      this.tableSorting[param] = !this.tableSorting[param]
      if(!this.tableSorting[param]) {
        this.dataTemp.reverse();
        return; 
      }
      if(Object.keys(this.ATTEMPT_STATUS).includes(key)) {
        this.dataTemp.sort((a, b) => {
          if (a.status === this.ATTEMPT_STATUS[key] && b.status !== this.ATTEMPT_STATUS[key]) {
            return -1;
          } else if (a.status !== this.ATTEMPT_STATUS[key] && b.status === this.ATTEMPT_STATUS[key]) {
            return 1;
          } else {
            return a.status.localeCompare(b.status);
          }
        }) 
        return;
      }
      if(key === 'score') {
        this.dataTemp.sort((a, b) => a.score - b.score) 
        return;
      }
      this.dataTemp.sort((a, b) => a[`${key}`].localeCompare(b[`${key}`])) 
    },
    async getExamInfo() {
      try {
        const { data } = await getExamById(this.examId);
        this.examTitle = data.exam.examName;
        this.examMaxAttempts = data.exam.examAttempts;
        this.examMinimumScore = Math.round(Number(data.exam.minimumScore)) + '%';
        this.questionsNumber = data.questions.length;
      } catch (error) {
        console.log(error);
      }
    },
    async downloadExamStats() {
      try {
        const temp = JSON.parse(JSON.stringify(this.filteredStats));
        temp.forEach(element => {
          element.score = (Number(element.score / this.questionsNumber) * 100).toFixed(2) + '%'
        });
        const csv = await convertToCSV({ data: temp });
        downloadCSV({ data: csv, filename: `${this.examTitle}_stats`});
      } catch (error) {
        console.log(error);
      }
    },
    async getExamStats() {
      this.loading = true;
      try {
        const { data } = await getStats(this.examId);
        const defaultStructure = {
          score: null,
          approved: null,
          examAttempts: null,
          attemptsCount: null
        };
        this.stats = [
          ...data.examAttemps.map(user => ({ ...defaultStructure, ...user })),
          ...data.remainingUsers.map(user => ({ ...defaultStructure, ...user }))
        ];

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    examId() {
      return this.$route.params.id;
    },
    statsList() {
      return this.stats.map(user => {
        let status;
        if (user.approved) status = ATTEMPT_STATUS.APPROVED;
        else if (user.score === null) status = ATTEMPT_STATUS.PENDING;
        else if (!user.approved && user.attemptsCount < user.examAttempts) status = ATTEMPT_STATUS.IN_PROGRESS;
        else status = ATTEMPT_STATUS.FAILED;

        return {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          phone: user.phone,
          userName: user.userName,
          score: user.score,
          minimunScore: Math.round(Number(user.minimunScore)) + '%',
          status,
          attempt_count: user.attemptsCount ?? 0
        }
      });       
    },
    statsCount() {
      return this.statsList.reduce((acc, curr) => {
        acc[curr.status] = (acc[curr.status] || 0) + 1;
        return acc;
      }, {});
    },
    filteredStats() {
      return this.statsList.filter(
          stat => stat.userName.toLowerCase().includes(this.search.trim().toLowerCase())
        )
    },
  }
}
</script>
<style scoped>
.bg-pending {
  background-color: #b8b8b8;
}

.bg-inprogress {
  background-color: #ffdd99;
}

.bg-approved {
  background-color: #acd778;
}

.bg-failed {
  background-color: #edabab;
}
@media (max-width: 768px) {
  #custom-card {
    width: 100% !important;
  }
}

.card-title {
  font-size: 14px;
  font-weight: bold;
}
</style>