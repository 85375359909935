<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Main')" />
      <div class="container">
        <hr>
        <div class="row">
          <div class="my-2 col-12 fw-bold text-left">
              {{ 
                assignmentsForToday
                  ? assignmentsForToday.assignments.length > 0 && assignmentsForToday.assignments.length === 1
                    ? $t('You have one assignment for today')
                    : $t('You have assignments for today')
                  : $t(`You don't have assignments for today`)
              }}
          </div>
        </div>
        <hr>


        <!-- Search filters -->
        <div class="row my-3">
          <div class="col-12 col-lg-4">
            <rd-input
              class="w-100"
              :label="$t('Search an assignment')"
              :placeholder="$t('Search assignments by')"
              type="text"
              clearIcon
              @clearIcon="clearSearch"
              @input="searchBar($event)"
            />
          </div>
        </div>
        
        <div
          v-if="assingmentsLoading"
          class="mt-5 d-flex justify-content-center"
        >
          <rd-loading
            class="position-absolute"
            :width="6"
            :height="6"
          />
        </div>

        <div
          v-if="!assingmentsLoading"
        >
          <div 
            v-if="assignmentsFromDB.length !== 0"
            class="row my-2"
          >
            <div class="col-12 fs-3 fw-bold mx-2 text-primary text-left">
              {{ $t('Active Assignments') }}
            </div>
          </div>

          <div 
            v-if="filteredAssigments.length === 0"
            class="row justify-content-center" 
          >
            <div class="col-12 text-center mt-4">
              <img src="@/assets/imgs/exclamation-triangle-fill.svg" height="150">
            </div>
            <div class="col-12 fs-3 fw-bold text-center">
              {{ $t('No assignments available') }}
            </div>
          </div>

          <div 
            v-else-if="!showSearchResults"
            class="row justify-content-center"
          >
            <div class="accordion accordion-custom" id="accordionParent">
              <div class="accordion-item"
                v-for="(item, index) in filteredAssigments" :key="index"
              >
                <h2 class="accordion-header" :id="`heading-${index}`">
                  <button 
                    class="accordion-button accordion-button-custom" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    :data-bs-target="`#collapse-${index}`" 
                    aria-expanded="true"
                    :aria-controls="`collapse-${index}`"
                  >
                    <span >
                      {{ item.assignmentsDate ? calcDay(item.assignmentsDate) : '' }} {{ item.assignmentsDate ? calcDate(item.assignmentsDate) : '' }} 
                      <span class="text-capitalize" :class="[calcColor(item.assignments)]"> <i class="bi bi-dot" :class="[calcColor(item.assignments)]"></i> {{ calcText(item.assignments) }} </span>
                      <br>
                      <span class="mt-3">{{$t('There')}} {{item.assignments.length > 1? $t('are') : $t('is')}} {{ item.assignments.length }} {{ item.assignments.length > 1? $t('assignments') : $t('assignment') }}</span>
                    </span>
                  </button>
                </h2>
                <div 
                  :id="`collapse-${index}`" 
                  class="accordion-collapse show"
                  :aria-labelledby="`heading-${index}`" 
                  data-bs-parent="#accordionParent"
                  v-for="(assignment, indexAssignments) in item.assignments" :key="indexAssignments"
                >
                  <div class="accordion-body">

                    <div 
                      class="accordion col-12" 
                      id="x-rol-accordions"
                      @click="redirect(assignment)"
                    >
                      <button 
                        class="accordion-button custom-accordion" 
                        type="button" 
                      >
                        <span >
                          <i class='bi' :class="[`${calcColorUnique(assignment)}-clock`, `bi-${calcIcon(assignment)}`]"></i> {{ `${assignment.sites[0].site}` }} - {{`${assignment.sites[0].checkIn ? assignment.sites[0].checkIn : ''}`}} - {{ `${assignment.description ? assignment.description : ''}` }}
                        </span>
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        <div
          v-else-if="showSearchResults"
        >
          <div class="accordion accordion-custom">
            <div class="accordion-item"
              v-for="(item, index) in filteredAssigments" :key="index"
            >
              <h2 class="accordion-header" :id="`heading-${index}`">
                <button 
                  class="accordion-button accordion-button-custom" 
                  type="button" 
                  data-bs-toggle="collapse" 
                  :data-bs-target="`#collapse-${index}`" 
                  aria-expanded="true"
                  :aria-controls="`collapse-${index}`"
                >
                  <span >
                    {{ item.assignmentsDate ? calcDay(item.assignmentsDate) : '' }} {{ item.assignmentsDate ? calcDate(item.assignmentsDate) : '' }} <span :class="[calcColor(item.assignments)]"> <i class="bi bi-dot" :class="[calcColor(item.assignments)]"></i> {{ calcText(item.assignments) }} </span>
                    <br>
                    <span class="mt-3">{{$t('There')}} {{item.assignments.length > 1? $t('are') : $t('is')}} {{ item.assignments.length }} {{ item.assignments.length > 1? $t('assignments') : $t('assignment') }}</span>
                  </span>
                </button>
              </h2>
              <div 
                :id="`collapse-${index}`" 
                class="accordion-collapse show"
                :aria-labelledby="`heading-${index}`" 
                data-bs-parent="#accordionParent"
                v-for="(assignment, indexAssignments) in item.assignments" :key="indexAssignments"
              >
                <div class="accordion-body">

                  <div 
                    class="accordion col-12" 
                    id="x-rol-accordions"
                    @click="redirect(assignment)"
                  >
                    <button 
                      class="accordion-button custom-accordion" 
                      type="button" 
                    >
                      <span >
                        <i class='bi' :class="[`${calcColorUnique(assignment)}-clock`, `bi-${calcIcon(assignment)}`]"></i> {{ `${assignment.sites[0].site}` }} - {{`${assignment.sites[0].checkIn ? assignment.sites[0].checkIn : ''}`}} - {{ `${assignment.description ? assignment.description : ''}` }}
                      </span>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getMyAssignments } from '../../services/assignments/assignment.api'

import { convertDateFormat2 } from '../../utils/convertFormat'
import RdLoading from '../../components/rd-components/rd-loading.vue'
import rdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdInput from '../../components/rd-components/rd-input.vue'

export default {
  components: {   rdFooter, RdNavbar, RdInput, RdLoading },
  data() {
    return {
      assignmentsFromDB: [],
      filteredAssigments: [],
      assignmentsWithoutDateFormat: [],
      showSearchResults: false,
      currentServerDate: null,
      days: [
        this.$t('Sun'),
        this.$t('Mon'),
        this.$t('Tue'),
        this.$t('Wed'),
        this.$t('Thur'),
        this.$t('Fri'),
        this.$t('Sat')
      ],
      months: [
        this.$t('Jan'),
        this.$t('Feb'),
        this.$t('Mar'),
        this.$t('Apr'),
        this.$t('May'),
        this.$t('Jun'),
        this.$t('Jul'),
        this.$t('Aug'),
        this.$t('Sep'),
        this.$t('Oct'),
        this.$t('Nov'),
        this.$t('Dec'),
      ],
      assingmentsLoading: true
    }
  },
  async mounted() {
    if(this.$store.state.permissions.length == 1) {
      this.$store.commit('setShowBackButton', false)
    }
    await this.getAllUserAssignments()
    this.filteredAssigments = this.assignmentsFromDB
    // this.filteredAssigments.push(this.assignmentsFromDB[0])

  },
  computed: {
    ...mapState(['user']),
    assignmentsForToday(){
      return this.assignmentsFromDB.find(assignment => 
        assignment.assignmentsDate === this.currentServerDate)
    }
  },
  methods: {
    redirect(assignment) {
        this.$router.push({path: `/admin/current-assignments/${assignment.id}`});
    },
    calcIcon(item) { 
      const date_in_progress = item.date_in_progress;
      const date_arrived = item.date_arrived;
      const date_finished = item.date_finished;
      if(item.accepted == false ) {
        return 'x';
      }
      if (date_in_progress == null && date_arrived == null && date_finished == null) {
        return 'clock';
      } else if (date_in_progress == null || date_arrived == null || date_finished == null) {
        if(!date_finished) {
          const new_date_arrived = new Date(date_arrived);
          const new_date_in_progress = new Date(date_in_progress);
          if(new_date_arrived > new_date_in_progress) {
            return 'circle';
          }else {
            return 'slash-circle';
          }
        }else {
          if(date_arrived) {
            const new_date_arrived = new Date(date_arrived);
            const new_date_finished = new Date(date_finished);
            if(new_date_finished > new_date_arrived) {
              return 'check-circle';
            } else {
              return 'circle';
            }
          } else {
            const new_date_in_progress = new Date(date_in_progress);
            const new_date_finished = new Date(date_finished);
            if(new_date_finished > new_date_in_progress) {
              return 'check-circle';
            } else {
              return 'slash-circle';
            }
          }
        }
      } else {
        const new_date_arrived = new Date(date_arrived);
        const new_date_finished = new Date(date_finished);
        const new_date_in_progress = new Date(date_in_progress);
        if( new_date_finished > new_date_in_progress && new_date_finished > new_date_arrived) {
          return 'check-circle';
        } else {
          if(new_date_arrived > new_date_in_progress) {
            return 'circle';
          }else {
            return 'slash-circle';
          }
        }
      }
    },
    calcDay(date) {
      const newDate = new Date(date);
      return this.days[newDate.getDay()];
    },
    calcDate(date) {
      const dateParsed = date.split('/');
      const newDate = new Date(date);
      return `${this.months[newDate.getMonth()]}/${dateParsed[1]}/${dateParsed[2]}`
    },
    calcText(item) {
      let finished = 0;
      let pending = 0;
      let others = 0;
      for (let index = 0; index < item.length; index++) {
        const element = item[index];
        const date_in_progress = element.date_in_progress;
        const date_arrived = element.date_arrived;
        const date_finished = element.date_finished;
        if (date_in_progress == null && date_arrived == null && date_finished == null) {
          pending += 1;
        } else if (date_in_progress == null || date_arrived == null || date_finished == null) {
          if(!date_finished) {
            others += 1;
          }else {
            if(date_arrived) {
              const new_date_arrived = new Date(date_arrived);
              const new_date_finished = new Date(date_finished);
              if(new_date_finished > new_date_arrived) {
                finished += 1;
              } else {
                others += 1;
              }
            } else {
              const new_date_in_progress = new Date(date_in_progress);
              const new_date_finished = new Date(date_finished);
              if(new_date_finished > new_date_in_progress) {
                finished += 1;
              } else {
                others += 1;
              }
            }
          }
        } else {
          const new_date_arrived = new Date(date_arrived);
          const new_date_finished = new Date(date_finished);
          const new_date_in_progress = new Date(date_in_progress);
          if( new_date_finished > new_date_in_progress && new_date_finished > new_date_arrived) {
            finished += 1;
          } else {
            others += 1;
          }
        }
      }
      if(pending === item.length) {
        return 'pending';
      }
      else if(finished === item.length) {
        return 'finished';
      }
      else if(others > 0) {
        return 'in-progress';
      } else {
        return 'in-progress';
      }
    },
    calcColor(item) {
      let finished = 0;
      let pending = 0;
      let others = 0;
      for (let index = 0; index < item.length; index++) {
        const element = item[index];
        const date_in_progress = element.date_in_progress;
        const date_arrived = element.date_arrived;
        const date_finished = element.date_finished;
        if (date_in_progress == null && date_arrived == null && date_finished == null) {
          pending += 1;
        } else if (date_in_progress == null || date_arrived == null || date_finished == null) {
          if(!date_finished) {
            others += 1;
          }else {
            if(date_arrived) {
              const new_date_arrived = new Date(date_arrived);
              const new_date_finished = new Date(date_finished);
              if(new_date_finished > new_date_arrived) {
                finished += 1;
              } else {
                others += 1;
              }
            } else {
              const new_date_in_progress = new Date(date_in_progress);
              const new_date_finished = new Date(date_finished);
              if(new_date_finished > new_date_in_progress) {
                finished += 1;
              } else {
                others += 1;
              }
            }
          }
        } else {
          const new_date_arrived = new Date(date_arrived);
          const new_date_finished = new Date(date_finished);
          const new_date_in_progress = new Date(date_in_progress);
          if( new_date_finished > new_date_in_progress && new_date_finished > new_date_arrived) {
            finished += 1;
          } else {
            others += 1;
          }
        }
      }
      if(pending === item.length) {
        return 'pending';
      }
      else if(finished === item.length) {
        return 'finished';
      }
      else if(others > 0) {
        return 'in-progress';
      } else {
        return 'in-progress';
      }
    },
    calcColorUnique(item) {
      const date_in_progress = item.date_in_progress;
      const date_arrived = item.date_arrived;
      const date_finished = item.date_finished;
      if(item.accepted == false ) {
        return 'x';
      }
      if (date_in_progress == null && date_arrived == null && date_finished == null) {
        return 'pending';
      } else if (date_in_progress == null || date_arrived == null || date_finished == null) {
        if(!date_finished) {
          const new_date_arrived = new Date(date_arrived);
          const new_date_in_progress = new Date(date_in_progress);
          if (new_date_arrived > new_date_in_progress) {
              return 'arrived';
          } else {
             return 'in-progress';
          }
        }else {
          if (date_arrived) {
              const new_date_arrived = new Date(date_arrived);
              const new_date_finished = new Date(date_finished);
              if (new_date_finished > new_date_arrived) {
                  return 'finished';
              } else {
                  return 'arrived';
              }
          } else {
              const new_date_in_progress = new Date(date_in_progress);
              const new_date_finished = new Date(date_finished);
              if (new_date_finished > new_date_in_progress) {
                  return 'finished';
              } else {
                  return 'in-progress';
              }
          }
        }
      } else {
        const new_date_arrived = new Date(date_arrived);
        const new_date_finished = new Date(date_finished);
        const new_date_in_progress = new Date(date_in_progress);
        if (new_date_finished > new_date_in_progress && new_date_finished > new_date_arrived) {
            return 'finished';
        } else {
            if (new_date_arrived > new_date_in_progress) {
                return 'arrived';
            } else {
                return 'in-progress';
            }
        }
      }
    },
    async getAllUserAssignments() {
      try {
        this.assingmentsLoading = true
        const {data} = await getMyAssignments()
        
        this.currentServerDate = data.date
        this.assignmentsWithoutDateFormat = data.assignments

        // get assignment dates
        const assignmentsDates = [...new Set(data.assignments.map(element => element.activityDate))]
        // create object assignments => dates
        const assignmentsByDate = assignmentsDates.map(element => {
          const assignments = data.assignments.filter(assignm => assignm.activityDate === element)
          let assignmentsByHour = assignments
          
          if (assignments.length > 1) {
            assignmentsByHour = assignments.sort((a, b) => {
              const [hourOne, minutesOne] = a.sites[0].checkIn ? a.sites[0].checkIn.split(":") : ['00', '00']
              const [hourTwo, minutesTwo] = b.sites[0].checkIn ? b.sites[0].checkIn.split(":") : ['00', '00']
              return hourOne - hourTwo || minutesOne - minutesTwo
            })
          }

          return {
            assignmentsDate: element,
            assignments: assignmentsByHour
          }
        })


        // assign result
        this.assignmentsFromDB = assignmentsByDate.sort((a, b) => { 
          return convertDateFormat2(b.assignmentsDate) - convertDateFormat2(a.assignmentsDate); 
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.assingmentsLoading = false
      }
    },
    searchBar(event) {
      // searching by activity date, hour and code
      const filter = event.target.value.toLowerCase()

      if (filter.length === 0) {
        this.filteredAssigments = this.assignmentsFromDB
        this.showSearchResults = false
        return
      }
      
      const filteredAssigmentsTemp = this.assignmentsWithoutDateFormat.filter(
        element => {
          return (
            element.activityDate.toLowerCase().startsWith(filter) 
            //|| element.sites[0].checkIn.toLowerCase().startsWith(filter) 
            || element.sites[0].code.toLowerCase().startsWith(filter)
          )
        }
      )
      const assignmentsDates = [...new Set(filteredAssigmentsTemp.map(element => element.activityDate))]

      const assignmentsByDate = assignmentsDates.map(element => {
        const assignments = filteredAssigmentsTemp.filter(assignm => assignm.activityDate === element)
        let assignmentsByHour = assignments
        
        if (assignments.length > 1) {
          assignmentsByHour = assignments.sort((a, b) => {
            const [hourOne, minutesOne] = a.sites[0].checkIn ? a.sites[0].checkIn.split(":") : ['00', '00']
            const [hourTwo, minutesTwo] = b.sites[0].checkIn ? b.sites[0].checkIn.split(":") : ['00', '00']
            return hourOne - hourTwo || minutesOne - minutesTwo
          })
        }
        return {
          assignmentsDate: element,
          assignments: assignmentsByHour
        }
      })
      this.filteredAssigments = assignmentsByDate.sort((a, b) => { 
        return convertDateFormat2(b.assignmentsDate) - convertDateFormat2(a.assignmentsDate); 
      })
      
      this.showSearchResults = true
    },
    clearSearch() {
      this.filteredAssigments = this.assignmentsFromDB
      this.showSearchResults = false
    },
    
  },
  
}
</script>

<style lang="scss" >
    .accordion-custom {
      padding: 0 1rem 0 !important;
      .accordion-button-custom {
        background: #ffffff !important;
        color: #000000 !important;
        padding: 0.8rem 0 !important;
        span {
          font-weight: 700 !important;
          font-size: 1rem !important;
          i::before {
            font-size: 4rem !important;
          }
          i::before {
            width: 2.2rem !important;
            height: 0 !important;
            line-height: 0 !important;
            position: relative !important;
            top: 1.2rem !important;
          }
          span:nth-child(2) {
            font-size: 1rem !important;
            display: inline-block !important;
          }
          span:nth-child(3) {
            font-size: 0.7rem !important;          
            color: #7b7b7b !important;
            display: block !important;
            font-weight: 700 !important; 
          }
        }
      }
      .accordion-button-custom::after {
        display: none !important;
      } 
      .accordion-button-custom::before {
        margin: -0.8rem 0.5rem 0 !important;
        flex-shrink: 0 !important;
        width: var(--bs-accordion-btn-icon-width) !important;
        height: var(--bs-accordion-btn-icon-width) !important;
        content: "" !important;
        background-image: var(--bs-accordion-btn-icon) !important;
        background-repeat: no-repeat !important;
        background-size: var(--bs-accordion-btn-icon-width) !important;
        transition: var(--bs-accordion-btn-icon-transition) !important;
        transform: rotate(0.75turn) !important;
      }
      .accordion-button-custom:not(.collapsed)::before {
        transform: rotate(0turn) !important;
      }
    }
    .custom-accordion {
      font-size: 0.9rem !important;
      position: relative !important;
      background: #ffffff !important;
      color: #000000 !important;
      border: none !important;
      width: -webkit-fill-available !important;
      box-shadow: none !important;
      padding: 0 !important;
      background: none !important;
      
    }
    .custom-accordion:not(.collapsed)::after {        
      flex-shrink: 0 !important;
      width: var(--bs-accordion-btn-icon-width) !important;
      height: var(--bs-accordion-btn-icon-width) !important;
      content: "" !important;
      background-image: var(--bs-accordion-btn-icon) !important;
      background-repeat: no-repeat !important;
      background-size: var(--bs-accordion-btn-icon-width) !important;
      transition: var(--bs-accordion-btn-icon-transition) !important;
      transform: rotate(0.75turn) !important;
      color: #000000 !important;
      font-size: 0.9rem !important;
    }
    .accordion-body {
      border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color) !important;
    }
    .in-progress {
      color: #007bff!important;
    }
    .finished {
      color: #28a745!important;
    }
    .pending {
      color: #8d6a24 !important;
    }
    .x-clock {
      color: #f44336 !important;
    }
    .in-progress-clock {
      color: #007bff!important;
    }
    .finished-clock {
      color: #28a745!important;
    }
    .pending-clock {
      color: #ffc107!important;
    }
    .arrived-clock {
      color: grey !important;
    }
</style>