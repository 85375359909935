<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Settings (nav)')" />
      <div class="container background-form my-3 p-3">
        <PageTitle
          :title="$t('Settings')"
          :description="$t('Manage general website settings')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Settings')"
        />
        <div class="container mt-3 py-3">
          <div class="row g-4">

            <!-- 
              - Language
              - Background
              - Hire Settings / Status configuration
              - Assignment Settings
              - Roles
            -->

            <div class="col-12 col-lg-4" v-if="permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.LANGUAGES)  || user.isAdmin">
              <rd-admin-card
                :name="$t('Language')"
                :description="$t('Set main language for the application')"
                @route="goTo('/admin/settings/language')"
              />
            </div>
            <div class="col-12 col-lg-4" v-if=" permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.BACKGROUND) || user.isAdmin">
              <rd-admin-card
                :name="$t('Background')"
                :description="$t('Change background on login screen')"
                @route="goTo('/admin/settings/background')"
              />
            </div>
            <div
              class="col-12 col-lg-4"
              v-if="permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.SMART_HIRE_PARAMETERS) || user.isAdmin"
            >
              <rd-admin-card
                :name="$t('Hire Settings / Status configuration')"
                :description="$t('SmartHire Status Configuration')"
                @route="goTo('/admin/smarthire/status')"
              />
            </div>
            <div
              class="col-12 col-lg-4"
              v-if="permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.USER_RATING_SETTINGS) || user.isAdmin"
            >
              <rd-admin-card
                :name="$t('User Session Rating Settings')"
                :description="$t('Sessions Rating Configuration')"
                @route="goTo('/session/score-config')"
              />
            </div>
            <div
              class="col-12 col-lg-4"
              v-if="
                permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.ASSIGNMENTS_PARAMETERS)
                || user.isAdmin
              "
            >
              <rd-admin-card
                :name="$t('Assignments Settings')"
                :description="$t('Assignments settings configuration')"
                @route="goTo('/admin/assignments-settings')"
              />
            </div>

            <div
              class="col-12 col-lg-4"
              v-if="permissions.includes(APP_PERMISSIONS.ROLES.CRUD) || user.isAdmin"
            >
              <rd-admin-card
                :name="$t('Roles')"
                :description="$t('Roles Configuration')"
                @route="goTo('/admin/roles')"
              />
            </div>
            <div
              class="col-12 col-lg-4"
              v-if="permissions.includes(APP_PERMISSIONS.ROLES.CRUD) || user.isAdmin"
            >
              <rd-admin-card
                :name="$t('Status Rezero')"
                :description="$t('Status Rezero')"
                @route="goTo('/admin/settings/status-rezero')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>

<script>
import RdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdAdminCard from '@/components/rd-components/rd-admin-card.vue'
import router from '@/router'
import { mapState } from 'vuex'
import {APP_PERMISSIONS} from '@/utils/constants.js'
import PageTitle from '../../components/rd-components/page-title.vue'

export default {
  components: { 
    RdFooter, 
    RdNavbar, 
    RdAdminCard,
    PageTitle
  },
  data() {
    return {
      APP_PERMISSIONS
    }
  },
  created() {
    if(!this.user.isAdmin) {
      const permissionsFiltered = this.permissions.filter(permission => [
        APP_PERMISSIONS.SYSTEM_MANAGEMENT.LANGUAGES,
        APP_PERMISSIONS.SYSTEM_MANAGEMENT.BACKGROUND,
        APP_PERMISSIONS.SYSTEM_MANAGEMENT.SMART_HIRE_PARAMETERS,
        APP_PERMISSIONS.SYSTEM_MANAGEMENT.USER_RATING_SETTINGS,
        APP_PERMISSIONS.SYSTEM_MANAGEMENT.ASSIGNMENTS_PARAMETERS,
        APP_PERMISSIONS.ROLES.CRUD
      ].includes(permission));
      if(permissionsFiltered.length == 1) {
        switch (permissionsFiltered[0]) {
          case APP_PERMISSIONS.SYSTEM_MANAGEMENT.LANGUAGES:
            router.replace('/admin/settings/language');
            return;
          case APP_PERMISSIONS.SYSTEM_MANAGEMENT.BACKGROUND:
            router.replace('/admin/settings/background');
            return;
          case APP_PERMISSIONS.SYSTEM_MANAGEMENT.SMART_HIRE_PARAMETERS:
            router.replace('/admin/smarthire/status');
            return;
          case APP_PERMISSIONS.SYSTEM_MANAGEMENT.USER_RATING_SETTINGS:
            router.replace('/session/score-config');
            return;
          case APP_PERMISSIONS.SYSTEM_MANAGEMENT.ASSIGNMENTS_PARAMETERS:
            router.replace('/admin/assignments-settings');
            return;
          case APP_PERMISSIONS.ROLES.CRUD:
            // DO Nothing
            return;
        
          default:
            return;
        }
      }
    }
  },
  computed: {
    ...mapState(['user', 'permissions'])
  },
  methods: {
    goTo(route) {
      router.push(route)
    }
  },
}
</script>