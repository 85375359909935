import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import { userLogin, refreshToken, getUserById, revokeToken } from '@/services/user/user.api';
import { getAllLanguage } from '@/services/languages/language.api';
import {
  roles as rolesPermission, 
  interactionTypes as getInteractionTypes 
} from '@/utils/constants.js';
import { jsonParse, bufferToString } from '@/utils/convertFormat.js';
import { parseJwt } from '../utils/jwt';
import { createInteraction } from '@/services/interactions/interactions.api.js'
import router from '../router';

const vuexPersistStorage = new VuexPersist({
  key: 'vuex_repodocs',
  storage: window.sessionStorage
});

export default Vuex.createStore({
  plugins: [vuexPersistStorage.plugin],
  state: {
    user: {},
    token: '',
    loggedByToken: false,
    defaultLanguage: 'EN',
    locales: [],
    currentLanguage: '',
    sessionExpired: false,
    invalidSession: false,
    statusLogin: false,
    playingVideo: {},
    windowWidth: 576,
    settings: {
      id: null,
      title: null,
      background: null,
      logo: null
    },
    isMobile: false,
    isInternalMobile: false,
    stateLoad: false,
    showModal: false,
    modalType: '',
    modalProps: {}, 
    modalContent: null,
    notification: {
      show: false,
      title: '',
      message: '',
      type: '',
      closeButton: true,
      timeout: 3000,
    },
    keycloakLogoutURL: '',
    permissions: [],
    showBackButton: true
  },
  mutations: {
    setShowBackButton(state, value) {
      state.showBackButton = value;
    },
    setModalType(state, payload) {
      state.modalType = payload.type;
      state.modalProps = payload.props || {};
    },
    setModalContent(state, value) {
      state.modalContent = value;
    },
    setShowModal(state, value) {
      state.showModal = value;
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setLoggedByToken(state, payload) {
      state.loggedByToken = payload;
    },
    setLocales(state, locales) {
      state.locales = locales;
    },
    setCurrentLanguage(state, { currentLanguage }) {
      state.currentLanguage = currentLanguage;
    },
    removeLanguage(state, { languageAbbreviation }) {
      state.locales.languages = state.locales.languages.filter((language) => (language['abbreviation'] !== languageAbbreviation));
    },
    setSessionExpired(state, value) {
      state.sessionExpired = value;
    },
    setInvalidSession(state, value) {
      state.invalidSession = value;
    },
    setStatusLogin(state, value) {
      state.statusLogin = value;
    },
    setPlayingVideo(state, video) {
      state.playingVideo = video
    },
    clearPlayingVideo(state) {
      state.playingVideo = {}
    },
    setWindowWidth(state, width){
      // < 576 none
      // >= 576 small
      // >= 768 medium
      // >= 992 large
      // >= 1200 extra large
      // >= 1400 extra extra large
      state.windowWidth = width
    },
    setSettings(state, settings) {
      state.settings = settings
    },
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile
    },
    setInternalMobile(state, isInternalMobile) {
      state.isInternalMobile = isInternalMobile
    },
    setStateLoad(state, value) {
      state.stateLoad = value;
    },
    setNotification(state, payload) {
      state.notification = payload;
    },
    setKeycloakLogoutURL(state, url) {
      state.keycloakLogoutURL = url
    },
  },
  getters: {
    getStatusLogin: state => {
      return state.statusLogin;
    },
    getSessionExpired: state => {
      return state.sessionExpired;
    },
    getInvalidSession: state => {
      return state.invalidSession;
    },
    getUser: state => {
      return state.user;
    },
    getToken: state => {
      return state.token;
    },
    getDefaultLanguage: state => {
      return state.defaultLanguage;
    },
    getCurrentLanguage: state => {
      return state.currentLanguage;
    },
    getLocales: state => {
      return state.locales;
    },
    getPlayingVideo: state => {
      return state.playingVideo;
    },
    getKeycloakLogoutURL: state => {
      return state.keycloakLogoutURL;
    }
  },
  actions: {
    dispatchNotification({commit}, {
      show = true,
      title = 'Notification',
      message = 'Please check',
      type = 'success',
      closeButton = true,
      timeout = 3000,
    }) {
      commit('setNotification', { show, title, message, type, closeButton, timeout });
    },
    dispatchFileView({commit}, fileInfo) {
      const {data, file, file: { fileName, fileOriginalName }, shouldStreamDirectly, isExamPreview} = fileInfo;
      const modalTitle = () => {
        if (fileName) return fileName
        if (fileOriginalName) return fileOriginalName
        return ''
      }

      if(isExamPreview) {
        commit('setModalContent', JSON.stringify(file))
        commit('setModalType', {type: 'RDExamResults', props: { title: modalTitle() } }); 
        commit('setShowModal', true)
        return;
      }
      if(shouldStreamDirectly) {
        commit('setModalContent', file.uuid)
        commit('setModalType', {type: 'rd-video-viewer', props: { title: modalTitle() } }); 
        commit('setShowModal', true)
        return;
      }
      const {type} = data;
      if(!data || !type) {
        commit('setModalType', {type: 'rd-not-valid-viewer', props: { title: modalTitle() } }); 
        commit('setShowModal', true)
        return;
      }
      const fileResponse = file?.fromExam ? file.image : window.URL.createObjectURL(data);
      commit('setModalContent', fileResponse)
      switch (true) {
        case type.includes('image'):
          commit('setModalType', {type: 'rd-image-viewer', props: { title: modalTitle() } }); 
          break;
        case type.includes('pdf'):
          commit('setModalType', {type: 'rd-pdf-viewer', props: { title: modalTitle() } });
          break;
        case type.includes('mp4'):
          commit('setModalContent', file.uuid)
          commit('setModalType', {type: 'rd-video-viewer', props: { title: modalTitle() } }); 
          break;
        default:
          commit('setModalType', {type: 'rd-not-valid-viewer', props: { title: modalTitle() } }); 
          break;
      }
      commit('setShowModal', true)
    },
    handleSettings({commit}, settings) {
      commit('setSettings', settings);
    },
    handleKeycloakLogoutURL({commit}, url) {
      commit('setKeycloakLogoutURL', url);
    },
    async login({ commit }, credentials) {
      let {data: { token, user, profiles, permissions }} = await userLogin(credentials);
      const { isAvailable } = user
      if (!isAvailable) return { isAvailable };
      delete user.userPassword
      let isAdmin = parseInt(user.roleId) === rolesPermission.ADMIN
      let isUploader = parseInt(user.roleId) === rolesPermission.UPLOADER
      let isViewer = parseInt(user.roleId) === rolesPermission.VIEWER
      let isViewDownloader = parseInt(user.roleId) === rolesPermission.VIEWER_DOWNLOADER
      let isTrainer = parseInt(user.roleId) === rolesPermission.TRAINER
      let interactionTypes = await getInteractionTypes();
      await createInteraction({
        userId: user.userId,
        interactionTypeId: interactionTypes['LOGIN']
      });
      commit('setToken', token);
      commit('setPermissions', permissions);
      commit('setUser', {
        ...user,
        profiles,
        isAdmin,
        isUploader,
        isViewer,
        isViewDownloader,
        isTrainer
      });
      commit('setStatusLogin', true);
      return { isAvailable };
    },
    async loginByToken({commit}, { mainAuthToken, refreshToken }) {
      try {
        let { profiles, roleId, id, name, permissions } = parseJwt(mainAuthToken)
        let isAdmin = parseInt(roleId) === rolesPermission.ADMIN
        let isUploader = parseInt(roleId) === rolesPermission.UPLOADER
        let isViewer = parseInt(roleId) === rolesPermission.VIEWER
        let isViewDownloader = parseInt(roleId) === rolesPermission.VIEWER_DOWNLOADER
        let isTrainer = parseInt(roleId) === rolesPermission.TRAINER
        let interactionTypes = await getInteractionTypes();
        await createInteraction({
          userId: Number(id),
          interactionTypeId: interactionTypes['LOGIN']
        });
        commit('setToken', mainAuthToken);
        commit('setPermissions', permissions);
        const { data } = await getUserById(id)
        const { user: { isAvailable } } = data
        if (!isAvailable) return { isAvailable }
        delete data.user.userPassword
        commit('setUser', {
          ...data.user,
          userName: name,
          profiles,
          isAdmin,
          isUploader,
          isViewer,
          isViewDownloader,
          isTrainer,
          refreshToken
        });
        commit('setStatusLogin', true)
        commit('setLoggedByToken', true);
        return { isAvailable };
      } catch (error) {
        return { isAvailable: false }
      }
    },
    async getI18nData({ commit, getters }, i18n) {
      try {
        let { data } = await getAllLanguage();
        let languages = [];
        data.forEach(language => {
          languages.push({ name: language['languageName'], abbreviation: language['languageAbbreviation'], messages: jsonParse(bufferToString(language['languageResourceFile']['data'])) });
          i18n.setLocaleMessage(language['languageAbbreviation'], jsonParse(bufferToString(language['languageResourceFile']['data'])));
        });
        const defaultLanguage = getters.getDefaultLanguage;
        const currentLanguage = getters.getCurrentLanguage;
        const foundCurrentLanguageInLocales = languages.find(element => element['abbreviation'] === currentLanguage);

        if (foundCurrentLanguageInLocales !== undefined) {
          commit('setCurrentLanguage', { currentLanguage: currentLanguage });
          i18n.locale = currentLanguage;
        } else {
          if(languages.length) {
            commit('setCurrentLanguage', { currentLanguage: languages[0].abbreviation });
            i18n.locale = languages[0].abbreviation;
          } else {
            commit('setCurrentLanguage', { currentLanguage: defaultLanguage });
            i18n.locale = defaultLanguage;
          }
        }
        commit('setLocales', { languages });
      } catch (error) {
        console.log(error); 
      }
    },
    async refreshTokenSession({ commit }) {
      try {
        let { data } = await refreshToken();
        commit('setToken', data.token);
      } catch (error) {
        if(error.response && error.response.status == 401) {
          commit('setToken', '');
        }
        
      }
    },
    async goToKeycloakLoginPage({ getters }) {
      let url = getters['getKeycloakLogoutURL'];
      window.location.replace(url)
    },
    async logout({ commit, getters, state }) {
      let user = getters['getUser'];
      let url = getters['getKeycloakLogoutURL'];
      let interactionTypes = await getInteractionTypes();
      await createInteraction({
        userId: user.userId,
        interactionTypeId: interactionTypes['LOGOUT']
      });
      if (user.refreshToken) {
        await revokeToken(user.refreshToken);
      }
      commit('setStatusLogin', false);
      commit('setUser', {});
      commit('setToken', '');
      commit('setKeycloakLogoutURL', '');
      window.sessionStorage.removeItem('vuex_repodocs');
      window.sessionStorage.clear();
      window.localStorage.clear();
      if (state.loggedByToken) {
        commit('setLoggedByToken', false);
        window.location.replace(url);
      } else {
        router.push('/login');
      }
    }
  },
});
