<template>
    <div class="w-100">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6 col-lg-9">
                <pie-chart-component :data="[
                    {
                        category: $t('Correct'),
                        value: data.last.score,
                        color: 0x4caf50 // Green color for correct answers
                    }, {
                        category: $t('Incorrect'),
                        value: (data.questionsCount - data.last.score),
                        color: 0x7a7a7a // Dark green color for incorrect answers
                    }
                ]" :label="`${data.last.score} / ${data.questionsCount}`" height="300px" />
            </div>
            <div class="col-12 col-md-3 col-lg-3 p-4">
                <div class="row fs-4 fw-bold">
                    {{ $t('Exam Summary') }}:
                </div>
                <div class="row justify-content-start">
                    <p class="text-start m-0 p-0">
                        <span class="fw-bold">{{ $t('Correct answers') }}:</span> {{ data.last.score }}
                    </p>
                </div>
                <div class="row justify-content-start">
                    <p class="text-start m-0 p-0">
                        <span class="fw-bold">{{ $t('Minimum Score: ') }}:</span> {{ percentage }}%
                    </p>
                </div>
                <div class="row justify-content-start">
                    <p class="text-start m-0 p-0">
                        <span class="fw-bold">{{ $t('Questions not answered') }}: </span> {{ answersNotAnswered }}
                    </p>
                </div>
                <div class="row justify-content-start">
                    <p class="text-start m-0 p-0">
                        <span class="fw-bold">{{ $t('Exam Status') }}:</span> {{ data.last.approved ? $t('Passed') :
                            $t('Failed') }}
                    </p>
                </div>
                <div class="row justify-content-start">
                    <p class="text-start m-0 p-0">
                        <span class="fw-bold">{{ $t('Exam Attempt') }}:</span> {{ data.attemps }}
                    </p>
                </div>
                <div @click="downloadTemplate" v-if="data.last.approved && data.last.exam_has_certificate"
                    class="row justify-content-start">
                    <button-component class="btn-link text-start text-primary m-0 p-0">
                        {{ $t('Download Certificate') }}
                    </button-component>
                </div>
                <div v-if="data.last.approved && data.last.exam_has_certificate" class="hide-for-download">
                    <rd-template-creator ref="template-certificate"></rd-template-creator>
                </div>
            </div>
        </div>
        <div class="row justify-content-start">
            <div class="col-12 col-md-10 col-lg-10">
                <p class="fs-3 fw-bold">
                    {{ data.last.approved
                        ? $t('Congratulations on passing your exam! Well done!')
                        : $t(`Hey, I know the exam didn't go as you expected. Cheer up, keep going, you can do it!`)
                    }}
                </p>
            </div>
        </div>
        <div class="row justify-content-start">
            <div class="col-12 col-md-10 col-lg-10">
                <p class="fs-3">
                    {{ $t('Score') }}: {{ ((Number(data.last.score) / Number(data.questionsCount)).toFixed(2) *
                        100).toFixed(1) }} %
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import PieChartComponent from '../amCharts/pie-chart-component.vue';
import RdTemplateCreator from '../../components/rd-components/rd-template-creator.vue';
import { mapState } from 'vuex';
import {
    getTemplate,
} from '@/services/templates/templates.api.js'
export default {
    name: 'RDExamResults',
    components: {
        PieChartComponent,
        RdTemplateCreator
    },
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    computed: {
        ...mapState(['user']),
        percentage() {
            return (Number(this.data.minimum_score) / 100).toFixed(2) * 100;
        },
        answersNotAnswered() {
            return this.data.questionsAnswered.filter((answer) => answer.answer == '').length
        }
    },
    data() {
        return {

        };
    },
    methods: {
        async getTemplateHTML({ templateId }) {
            try {
                const { data } = await getTemplate(templateId);
                const { template } = data;
                this.$refs['template-certificate'].setHtml(template)
            } catch (error) {
                console.log(error);
            }
        },
        async downloadTemplate() {
            try {
                const templateId = this.data.last.template_id
                await this.getTemplateHTML({ templateId })
                const examName = this.data.last.exam_name
                const { userFirstname, userLastname, userName } = this.user
                const userToSent = userFirstname && userLastname ? `${userFirstname} ${userLastname}` : userName;
                const certificateName = `certificate of ${examName} for ${userToSent}`

                this.$refs['template-certificate'].test(userToSent, examName, certificateName);
            } catch (error) {
                console.log(error)
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.hide-for-download {
    position: absolute;
    top: -30000;
    left: -30000;
    width: 0;
    height: 0;
    opacity: 0;
    z-index: var(--bs-behidden-layer-z-index);
}
</style>