<template>
  <button
    v-bind="$attrs"
    :disabled="disabled"
    :class="[
      'btn btn-sm m-1',
      calcPaddingY,
      calcPaddingX,
      calcBtnStyle,
      extraClasess,
    ]"
    :title="title"
    :type="type"
    :style="customStyle"
    ref="button"
    @click="handleClick($event)"
  >
    <span class="fw-bolder text-custom">
      <slot>{{ text }}</slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'ButtonComponent',
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: 'click me!'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    primaryOutline: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    },
    px: {
      type: [String, Number], 
      default: '2'
    },
    py: {
      type: [String, Number], 
      default: '2'
    },
    customClass: {
      type: [String, Array],
      default: ''
    },
    ref: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'button'
    },
    customStyle: {
      type: [String, Object],
      default: ''
    },
  },
  computed: {
    calcBtnStyle() {
      const { primary, secondary, primaryOutline, danger } = this
      if (primary) return 'btn-primary'
      if (danger) return 'btn-danger'
      if (secondary) return 'btn-secondary'
      if (primaryOutline) return 'btn-outline-primary border border-1 border-primary'
      return ''
    },
    calcPaddingX() {
      const { px } = this
      return 'px-' + px
    },
    calcPaddingY() {
      const { py } = this
      return 'py-' + py
    },
    extraClasess() {
      const { customClass } = this
      return Array.isArray(customClass) ? customClass.join(' ') : customClass
    }
  },
  methods: {
    handleClick(event) {
      event.stopPropagation();
      this.$emit('handleClick', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  font-family: Roboto;
}

.btn-outline-primary {
  background-color: #FFFFFF;
}

.btn-outline-primary:hover {
  background-color: 'primary';
}

.text-custom {
  font-weight: 900;
}
</style>