import { createApp } from 'vue';
import DisableAutocomplete from 'vue-disable-autocomplete';
import Vuex from 'vuex';
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n';
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap';
import '../scss/custom.scss'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { quillEditor, Quill } from 'vue3-quill'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
const fonts = Quill.import('formats/font');
fonts.whitelist.push('roboto');
import ImageResize from 'quill-image-resize-vue';
import VueGoogleMaps from '@fawmi/vue-google-maps'

Quill.register(fonts, true);
const CustomModule = function(quill, options) {
  this.quill = quill;
  this.options = options;
  const customButton = document.createElement('button');
  customButton.innerHTML = '<i class="bi bi-image-fill"></i>';
  customButton.title = 'Insert background image';
  customButton.id = 'quill-custom-button-upload-background';
  const toolbar = quill.getModule('toolbar');
  toolbar.container.appendChild(customButton);
};

const CustomImageUpload = function(quill, options) {
  this.quill = quill;
  this.options = options;
  const toolbar = quill.getModule('toolbar');
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.style.display = 'none';

  input.addEventListener('change', () => {
    const file = input.files[0];
    if (file && /^image\//.test(file.type)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const range = quill.getSelection();
        quill.insertEmbed(range.index, 'image', e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Solo se permiten imágenes.');
    }
    input.value = '';
  });

  const customButton = document.createElement('button');
  customButton.innerHTML = '<i class="bi bi-images"></i>';
  customButton.title = 'Insert images';
  customButton.addEventListener('click', () => {
    input.click();
  });

  toolbar.container.appendChild(customButton);
};

// Registra tu módulo personalizado
Quill.register('modules/customModule', CustomModule);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/customImageUpload', CustomImageUpload);

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
const app = createApp(App);
app.config.silent = true;
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  },
})
app.use(VueToast, {
  position: store.state.windowWidth > 960 ? 'top-right' : 'top',
  duration: 3000,
  dismissible	: true,
  queue: false,
  pauseOnHover: true
})
app.use(quillEditor);
app.use(VueSweetalert2);
app.use(i18n);
app.use(router);
app.use(store);
app.use(Vuex);
app.use(DisableAutocomplete);
app.mount('#app')