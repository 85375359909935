import httpClient from "../httpClient";

const END_POINT = '/api/assignement'

const generateAssignment = (body) => httpClient.post(`${END_POINT}/generate`, body)
const generateAssignmentV2 = (body) => httpClient.post(`${END_POINT}/generatev2`, body)
const getAllAssignments = () => httpClient.get(`${END_POINT}`)
const getMyAssignments = () => httpClient.get(`${END_POINT}/myAssignments`)
const getMyAssignment = (id) => httpClient.get(`${END_POINT}/myAssignment/${id}`)
const getActiveAssignments = () => httpClient.get(`${END_POINT}/actives`)
const getAssignmentById = (assignementId) => httpClient.get(`${END_POINT}/${assignementId}`)
const updateAssignmentById = (assignementId, dataToUpdate) => httpClient.put(`${END_POINT}/${assignementId}`, dataToUpdate)
const assignmentAvailabilityByDate = ( dataToUpdate) => httpClient.put(`${END_POINT}/availability`, dataToUpdate)
const massiveLoadForAssignments = (csvAssignement) => httpClient.post(`${END_POINT}/import`, csvAssignement)
const updateStateAssignment = (request) =>  httpClient.post(`${END_POINT}/update-status-task-user`, request)
const aproveDeclineAssignment = (request) =>  httpClient.post(`${END_POINT}/accept-decline`, request)
const deleteAssignments = async (payload) => httpClient.post(`${END_POINT}/delete-assignments`, payload)
const clearTransactionalData = async () => await httpClient.post(`${END_POINT}/clear-transactional-data`)
const preprocessAssignment = (body) => httpClient.post(`${END_POINT}/preprocess`, body)
const massiveTeamAssignments = (body) => httpClient.post(`${END_POINT}/massiveTeamAssignments`, body)

export {
  generateAssignment,
  generateAssignmentV2,
  getAllAssignments,
  getMyAssignments,
  getActiveAssignments,
  getAssignmentById,
  updateAssignmentById,
  massiveLoadForAssignments,
  assignmentAvailabilityByDate,
  updateStateAssignment,
  getMyAssignment,
  aproveDeclineAssignment,
  deleteAssignments,
  clearTransactionalData,
  preprocessAssignment,
  massiveTeamAssignments,
}