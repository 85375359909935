<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Template (nav)')" />
      <div class="container background-form rounded-3 my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Template Creation') }}</h2>
          </div>
        </div>
        <div class="row justify-content-between p-3">
          <!-- Search bar -->
          <div class="col-12 col-lg-3">
            <rd-input
              class="w-100"
              :placeholder="$t('Search template by name')" 
              type="text"
              @input="(e) => this.search = e.target.value"
            />
          </div>

          <!-- buttons -->
          <div class="col-12 col-lg-6 text-end" v-if="user.isAdmin">
            <button-component
              primary
              @handleClick="goToAddTemplate"
            >
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Template" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('New Template') }}
            </button-component>
          </div>
        </div>

        <div v-if="templatesLoading">
          <rd-loading /> 
        </div>

        <div v-else>
          <div
            v-if="filteredTemplates.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No templates Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <div v-else class="row p-3 d-flex justify-content-start">

            <data-table-component
              :columns="[
                { label: $t('Name (table)'), field: 'name', sortable: true, type: 'string', },
                { label: $t('Actions (table)'), field: 'actions', hAlign: 'center', headerHAlign: 'center', },
              ]"
              :rows="filteredTemplates"
              paginator
              resizeable
            >
              <template #actions="{ row }">
                <div class="container">
                  <div class="row text-center">
                    <div class="col p-0 m-0">

                      <button 
                        v-if="user.isAdmin"
                        class="btn btn-label"
                        @click="goToEditTemplate($event, row.id)"
                      >
                        <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Template" type="button" class="bi bi-pencil-square"></i>
                      </button>

                    </div>
                  </div>
                </div>
              </template>
            </data-table-component>

          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
    <rd-confirmation-modal
      v-if="showModalProp"
      modalTitle="Delete template permanently?"
      confirmButton="Delete"
      dismissButton="Dismiss"
      @confirmAction="deleteFile(templateToDelete)"
      @dismissAction="dismissAction"
    />
  </div>
</template>
<script>
import rdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import router from '@/router'
import { getAllTemplates } from '@/services/templates/templates.api.js'
import RdConfirmationModal from '@/components/rd-components/rd-confirmation-modal.vue'
import RdLoading from '@/components/rd-components/rd-loading.vue'
import RdInput from '@/components/rd-components/rd-input.vue'
import ButtonComponent from '../../components/button-component.vue'
import DataTableComponent from '../../components/tables/data-table-component.vue'

export default {
  data() {
    return {
      templates: [],
      templatesLoading: true,
      showModalProp: false,
      templateToDelete: {},
      search: '',
      hasSpinner: true,
      dataTemp: [],
      counter: 0,
      thElm: null,
      startOffset: null,
      lastWidth: 0,
      columns: [
        { label: this.$t('Name (table)'), field: 'name', sortable: true, type: 'string', },
        { label: this.$t('Actions (table)'), field: 'actions', hAlign: 'center', headerHAlign: 'center', },
      ]
    }
  },
  components: { 
    rdFooter, 
    RdNavbar,
    RdConfirmationModal,
    RdLoading,
    RdInput,
    ButtonComponent,
    DataTableComponent,
  },
  computed: {
    filteredTemplates() {
      return this.templates.filter(
          template => template.name.toLowerCase().includes(this.search.trim().toLowerCase())
        )
    },
    user(){
      return this.$store.getters.getUser;
    },
  },
  async mounted() {
    try {
      await this.setTemplates();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '50%'
      })
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    showModal(e, template){
      e.stopPropagation()
      this.showModalProp = true
      this.templateToDelete = template
    },
    dismissAction() {
      this.templateToDelete = {}
      this.showModalProp = false
    },
    async deleteFile({ id }) {
      try {
        console.log(id)
        await this.setTemplates()
      } catch (error) {
        console.log(error)
      } finally {
        this.showModalProp = false
        this.templateToDelete = {}
      }
    },
    isExternal(path) {
      return path === null || path === ''
    },
    async setTemplates() {
      try {
        this.templatesLoading = true
        const { data } = await getAllTemplates();
        this.templates = data
      } catch (error) {
        console.log(error)
      } finally {
        this.templatesLoading = false
        await this.$nextTick(() => {
           const elements = document.querySelectorAll("table th");
           elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
           })
           document.addEventListener("mousemove", (e) => {
             if (this.thElm) {
               this.lastWidth  = this.thElm.offsetWidth;
               this.thElm.style.width = this.startOffset + e.pageX + "px";
                document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                  if((internalIndex % 2) == Number(this.thElm.id)) {
                    td.style.width = this.startOffset + e.pageX + "px";
                  } else {
                    td.style.width = td.offsetWidth + "px";
                  }
                })
                this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
             }
           });

            document.addEventListener("mouseup", () => {
              this.thElm = undefined;
            });
        });
      }
    },
    goToAddTemplate() {
      router.push(`/admin/template/add/`)
    },
    goToEditTemplate(e, uuid){
      e.stopPropagation()
      router.push(`/admin/template/edit/${uuid}`)
    }
  },
  
}
</script>
<style lang="scss" scoped>
.hoverable:hover {
  cursor: pointer;
}
.hoverable:hover {
  cursor: pointer;
}
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>