<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Profiles (nav)')" />
      <div class="container background-form my-3 p-3">
        <PageTitle
          :title="$t('Profiles')"
          :description="$t('Control user profiles')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Profiles')"
        />
        <div class="row px-3 justify-content-between">
          <!-- Search bar -->
          <div
            class="col-12 col-lg-4"
          >
            <rd-input
              class="w-100"
              :placeholder="$t('Search profile by name')" 
              type="text"
              @input="(e) => this.search = e.target.value"
            />
          </div>

          <div class="col-12 col-lg-4 text-end">
            <button-component
              primary
              @handleClick="goToAddNewProfile"
            >
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Profile" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Profile (btn)') }}
            </button-component>
          </div>
        </div>

        <div v-if="profilesLoading">
          <rd-loading />
        </div>

        <div v-else>

          <div
            v-if="filteredProfiles.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No profiles Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>
          
          <div v-else class="row p-3 d-flex justify-content-center">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover table-custom-general" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col">{{ $t('Profile (table)') }}</th>
                      <th scope="col">{{ $t('Description (table)') }}</th>
                      <th scope="col" class="text-center">{{ $t('Actions (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-start">
                    <tr
                      v-for="(profile, index) in dataTemp" :key="index"
                      class="hoverable"
                      :class="{ 'fill-ods': index % 2 !== 0 }"
                    >
                      <td style="width: 33%; word-break: break-all;" :header="$t('Profile (table)')" scope="row"><div class="custom-table-text">{{ profile.profileName }}</div></td>
                      <td style="width: 33%; word-break: break-all;" :header="$t('Description (table)')" ><div class="custom-table-text">{{ profile.profileDescription }}</div></td>
                      <td style="width: 33%; word-break: break-all;" :header="$t('Actions (table)')" class="centered">
                        <button-component
                          class="btn btn-label" 
                          @handleClick="goToEditProfile(profile.profileId)"
                        >
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Profile" type="button" class="bi bi-pencil-square"></i>
                        </button-component>
                        <button-component
                          class="btn btn-label" 
                          @handleClick="showModal(profile)"
                        >
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="Delete Profile" type="button" class='bi bi-trash-fill'></i>
                        </button-component>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <rd-paginator :items="filteredProfiles" @page-change="handlePageChange" />
          </div>

        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>

    <rd-confirmation-modal
      v-if="showModalProp"
      modalTitle="Delete profile permanently?"
      confirmButton="Delete"
      dismissButton="Dismiss"
      @confirmAction="deleteProfile(profileToDelete)"
      @dismissAction="dismissAction"
    />
  </div>
</template>

<script>
import rdFooter from '../../components/rd-components/rd-footer.vue'
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import router from '@/router'
import { getAllProfiles, deleteProfileById } from '@/services/profiles/profile.api.js'
import RdLoading from '../../components/rd-components/rd-loading.vue'
import RdConfirmationModal from '../../components/rd-components/rd-confirmation-modal.vue'
import RdInput from '../../components/rd-components/rd-input.vue'
import RdPaginator from '@/components/rd-components/rd-paginator.vue'
import PageTitle from '../../components/rd-components/page-title.vue'
import ButtonComponent from '../../components/button-component.vue'

export default {
  data() {
    return {
      profiles: [],
      profileToDelete: {},
      showModalProp: false,
      profilesLoading: true,
      search: '',
      thElm: null,
      startOffset: null,
      lastWidth: 0,
      dataTemp: []
    }
  },
  async mounted() {
    if(this.$store.state.permissions.length == 1) {
      this.$store.commit('setShowBackButton', false)
    }
    await this.setProfiles()
  },
  computed: {
    filteredProfiles() {
      return this.profiles.filter(
        profile => profile.profileName.toLowerCase().includes(this.search.trim().toLowerCase())
      )
    }
  },
  components: {
    rdFooter,
    RdNavbar,
    RdLoading,
    RdConfirmationModal,
    RdInput,
    RdPaginator,
    PageTitle,
    ButtonComponent
  },
  methods: {
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '33%'
      })
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    
    async setProfiles() {
      try {
        this.profilesLoading = true
        const {data} = await getAllProfiles()
        this.profiles = data
      } catch (error) {
        console.log(error)
      } finally {
        this.profilesLoading = false
        await this.$nextTick(() => {
           const elements = document.querySelectorAll("table th");
           elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
           })
           document.addEventListener("mousemove", (e) => {
             if (this.thElm) {
               this.lastWidth  = this.thElm.offsetWidth;
               this.thElm.style.width = this.startOffset + e.pageX + "px";
                document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                  if((internalIndex % 3) == Number(this.thElm.id)) {
                    td.style.width = this.startOffset + e.pageX + "px";
                  } else {
                    td.style.width = td.offsetWidth + "px";
                  }
                })
                this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
             }
           });

            document.addEventListener("mouseup", () => {
              this.thElm = undefined;
            });
        });
      }
    },
    goToAddNewProfile() {
      router.push(`/admin/profiles/add`)
    },
    goToEditProfile(id) {
      router.push(`/admin/profiles/edit/${id}`)
    },
    showModal(profile){
      this.showModalProp = true
      this.profileToDelete = profile
    },
    dismissAction() {
      this.profileToDelete = {}
      this.showModalProp = false
    },
    async deleteProfile({profileId}) {
      try {
        await deleteProfileById(profileId)
        await this.setProfiles()
      } catch (error) {
        console.log(error)
      } finally {
        this.showModalProp = false
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.hoverable:hover {
  cursor: pointer;
}
.hoverable:hover {
  cursor: pointer;
}
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>