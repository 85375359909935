import { getInteractionTypes } from "@/services/interactions/interactions.api";

// checks for in MM/DD/YYYY format
const DATE_FORMAT_REGEX = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])\/\d{4}$/;

const HOUR_FORMAT_REGEX = /^([01]\d|2[0-3]):[0-5]\d$/;

// check for valid URL
const linkRegExp = /^(ftp|http|https):\/\/[^ "]+$/

const profiles = {
  ADMIN: '1'
};

const roles = {
  ADMIN: 1,
  UPLOADER: 2,
  VIEWER: 3,
  VIEWER_DOWNLOADER: 4,
  TRAINER: 5,
  WORKER: 6,
  PROVIDER: 7
}

const ENTITIES_TO_IMPORT = {
  USERS: {
    value: 0,
    label: "Users",
  },
  CATEGORIES: {
    value: 1,
    label: "Categories",
  },
  SUB_CATEGORIES: {
    value: 2,
    label: "Sub Categories",
  },
  SESSIONS: {
    value: 3,
    label: "Sessions",
  },
  REGIONS: {
    value: 4,
    label: "Regions",
  },
  TEAMS: {
    value: 5,
    label: "Teams",
  },
  SITES: {
    value: 6,
    label: "Sites",
  },
  SITE_RESOURCES: {
    value: 7,
    label: "Site Resources",
  },
};

const ATTEMPT_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  FAILED: 'failed',
  IN_PROGRESS: 'in_progress'
};

const interactionTypes = async () => {
  try {
    let types = {};
    let { data } = await getInteractionTypes();
    data.forEach(({ interactionTypeName, interactionTypeId }) => {
      types[interactionTypeName.toUpperCase()] = interactionTypeId;
    });
    return types;
  } catch (error) {
    console.log(error);
  }
};

const notAllowedFileExtensions = [
  ".exe",
  ".pif",
  ".application",
  ".gadget",
  ".msi",
  ".hta",
  ".htaccess",
  ".jar",
  ".bat",
  ".cmd",
  ".js",
  ".jse",
  ".svg",
  ".reg",
  ".vbs",
  ".vb",
  ".html",
  ".ddl",
  ".tmp",
  ".py",
  ".com",
  ".odt",
  ".ods",
];

const QUESTIONS_TEMPLATE = `
  ID|question|answer|valid|image|multiple
  Q1|¿Pregunta 1?|OP1|1|image.png|true
  Q1|¿Pregunta 1?|OP2|1||
  Q1|¿Pregunta 1?|OP3|1||
  Q1|¿Pregunta 1?|OP4|0||
  Q2|¿Pregunta 2?|OP1|0|image2.png|true
  Q2||OP2|1||
  Q2||OP3|1||
  Q2||OP4|1||
  Q3|¿Pregunta 3?|OP1|0|image3.png|true
  Q3||OP2|1||
  Q3||OP3|1||
  Q3||OP4|0||
  Q4|¿Pregunta 4?|OP1|0||false
  Q4||OP2|0||
  Q4||OP3|0||
  Q4||OP4|1||
  Q5|¿Pregunta 5?|OP1|0||false
  Q5||OP2|0||
  Q5||OP3|0||
  Q5||OP4|1||
  Q6|¿Pregunta 6?|OP1|0||false
  Q6||OP2|0||
  Q6||OP3|0||
  Q6||OP4|1||
`

const APP_PERMISSIONS = {
  SYSTEM_MANAGEMENT: {
    LANGUAGES: 1, //
    BACKGROUND: 2, //
    ASSIGNMENTS_PARAMETERS: 3,
    SMART_HIRE_PARAMETERS: 4,
    USER_RATING_SETTINGS: 39
  },
  USER_MANAGEMENT: {
    CRUD: 5, //
    HISTORICAL_ROLES: 6,
    EDIT_USER: 40
  },
  ROLES: { //
    CRUD: 7
  },
  PROFILES: { //
    CRUD: 8
  },
  FILES_MANAGEMENT: {
    FILE_UPLOADER: 9, //
    CATEGORIES: 32,
    FILE_VISUALIZATION: 33
  },
  EXAM_MANAGEMENT: {
    EXAM_VISUALIZATION: 10, //
    CRUD: 11 //
  },
  SITE_MANAGEMENT: {
    CRUD: 16,
  },
  SESSION_MANAGEMENT: {
    CRUD: 18,
    DELETE: 41
  },
  ASSIGNMENTS: {
    VISUALIZATION: 19,
    EDIT_ASSIGNMENTS: 20,
    EXECUTE_ASSIGNMENTS: 21,
    DELETE_ASSIGNMENTS: 22,
    ACTIVE_ASSIGNMENTS: 23,
    GET_REPORT: 24,
    PROMOTE_DEMOTE: 37,
    ENABLE_ASSIGNMENTS: 38,
    MAPS: 42
  },
  POLL_WORKER_MANAGEMENT: { //
    CRUD: 17
  },
  BULK_UPLOAD: { //
    USERS: 25,
    CATEGORIES: 26,
    SUB_CATEGORIES: 27,
    SESSIONS: 28,
    REGIONS: 29,
    TEAMS: 30,
    SITES: 31,
    SITE_RESOURCES: 31
  },
  FIELD_WORK_ASSIGNMENTS: {
    WORKER: 36
  }
}
const TEAM_STATUS_COLOR = {
  Empty: {
    color: 'text-danger',
    icon: 'circle'
  },
  Partial: {
    color: 'text-warning',
    icon: 'circle-half'
  },
  Completed: {
    color: 'text-success',
    icon: 'circle-fill'          
  },
  Overcrowding: {
    color: 'text-primary',
    icon: 'dash-circle',
  },
}

export {
  profiles,
  roles,
  interactionTypes,
  notAllowedFileExtensions,
  ENTITIES_TO_IMPORT,
  QUESTIONS_TEMPLATE,
  ATTEMPT_STATUS,
  DATE_FORMAT_REGEX,
  APP_PERMISSIONS,
  HOUR_FORMAT_REGEX,
  TEAM_STATUS_COLOR,
  linkRegExp,
};