import { encodeToBase64 } from '../../utils/utils';
import httpClient from '../httpClient';

const END_POINT = '/api/users';
const createUser = (user) => httpClient.post(END_POINT, user);
const createUserFromCode = (code) => httpClient.post(`${END_POINT}/authByCode`, { code }, {
  headers: {
    'message': encodeToBase64('Login')
  }
});
const userLogin = (credentials) => httpClient.post(`${END_POINT}/login`, credentials, {
  headers: {
    'user': encodeToBase64(credentials.username),
    'message': encodeToBase64('Login')
  }
});
const getUserById = (userId) => httpClient.get(`${END_POINT}/${userId}`);
const getTrainers = () => httpClient.get(`${END_POINT}/trainers`);
const getAllLeadOperators = () => httpClient.get(`${END_POINT}/lead-operators`);
const getAllUsers = () => httpClient.get(END_POINT);
const getKeycloakLogoutURL = () => httpClient.get('/api/keycloak-logout-url');
const getShoulShowChat = () => httpClient.get('/api/should-show-chat');
const editUserById = (userId, user) => httpClient.patch(`${END_POINT}/${userId}`, user);
const userBanned = ({ id, payload }) => httpClient.patch(`${END_POINT}/banned/${id}`, payload);
const toogleUserAvailability = (userId, { isAvailable }) => httpClient.patch(`${END_POINT}/${userId}/availability`, { isAvailable });
const changeUserFunction = (userId) => httpClient.patch(`${END_POINT}/function/${userId}`);
const deleteUserById = (userId) => httpClient.delete(`${END_POINT}/${userId}`);
const refreshToken = () => httpClient.get(`${END_POINT}/refreshToken`);
const revokeToken = (refreshToken) => httpClient.post(`${END_POINT}/logout`, { refreshToken }, {
  headers: {
    'message': encodeToBase64('Logout')
  }
});
const getNotBannedUsers = () => httpClient.get(`${END_POINT}/not-banned`)
const getAllUsersLeadOperator = (assignmentId) => httpClient.get(`${END_POINT}/lead-operator/${assignmentId}`)
const getUserSessionsWithExams = ({ userId }) => httpClient.get(`${END_POINT}/sessions/${userId}`)
const getUserAssignmentsWithStatus = ({ userId }) => httpClient.get(`${END_POINT}/sessions/assignments/${userId}`)
const addGeneralFeedback = ({ userId, feedback }) => httpClient.post(`${END_POINT}/feedback/${userId}`, { feedback })
const smartHireReport = () => httpClient.get(`${END_POINT}/hireReport`)
const smartFieldReport = () => httpClient.get(`${END_POINT}/smartFieldReport`)
const getUserLocationByUserName = ({ userName }) => httpClient.get(`${END_POINT}/location/${userName}`)

export {
  userBanned,
  smartFieldReport,
  smartHireReport,
  createUser,
  userLogin,
  getUserById,
  getAllUsers,
  editUserById,
  deleteUserById,
  refreshToken,
  createUserFromCode,
  revokeToken,
  getTrainers,
  getKeycloakLogoutURL,
  getShoulShowChat,
  getNotBannedUsers,
  getAllUsersLeadOperator,
  getUserSessionsWithExams,
  addGeneralFeedback,
  changeUserFunction,
  getUserAssignmentsWithStatus,
  toogleUserAvailability,
  getUserLocationByUserName,
  getAllLeadOperators
}