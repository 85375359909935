<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Sites (nav)')" />
      <div class="container rounded-3 background-form my-3 p-3 text-left">
        <div class="row my-2">
          <h4 class="col-12 text-capitalize">
            {{ $t('Add Site') }}
          </h4>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <rd-input
              class="my-2"
              :label="$t('Enter the site name')"
              :placeholder="$t('Name')"
              type="text"
              :initialValue="site.name"
              :value="site.name"
              @input="(e) => this.site.name = e.target.value"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <rd-input
              class="my-2"
              :label="$t('Enter the site code')"
              :placeholder="$t('Code')"
              type="text"
              :initialValue="site.code"
              :value="site.code"
              @input="(e) => this.site.code = e.target.value"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <rd-input
              class="my-2"
              :label="$t('Enter the site address')"
              :placeholder="$t('Address')"
              type="text"
              :initialValue="site.address"
              :value="site.address"
              @input="(e) => this.site.address = e.target.value"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <rd-input
              class="my-2"
              :label="$t('Enter the site latitude')"
              :placeholder="$t('Latitude')"
              type="text"
              :initialValue="site.latitude"
              :value="site.latitude"
              @input="(e) => this.site.latitude = e.target.value"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <rd-input
              class="my-2"
              :label="$t('Enter the site longitude')"
              :placeholder="$t('Longitude')"
              type="text"
              :initialValue="site.longitude"
              :value="site.longitude"
              @input="(e) => this.site.longitude = e.target.value"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <rd-input
              class="my-2"
              :label="$t('Enter the site room')"
              :placeholder="$t('Room')"
              type="text"
              :initialValue="site.room"
              :value="site.room"
              @input="(e) => this.site.room = e.target.value"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <rd-input
              type="calendar"
              class="my-2"
              :previousDateAvailable="true"
              :label="$t('Enter the site activity date')"
              :placeholder="$t('Activity date')"
              :initialValue="site.activity_date"
              :value="site.activity_date"
              @input="({ date }) => this.site.activity_date = date"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <rd-input
              type="text"
              class="my-2"
              :label="$t('Enter the site check in')"
              :placeholder="$t('Check in')"
              :initialValue="site.check_in"
              :value="site.check_in"
              :showError="site.check_in.length >= 4 && !checkInMatchesFormat"
              :errorMsg="$t('Field does not match format. e.g. 08:00 or 18:30')"
              @input="(e) => this.site.check_in = e.target.value"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6 my-2">
            <rd-input
              type="select"
              :placeholder="$t('Add')"
              :placeholderToTag="$t('Add team to this site')"
              :initialValue="site.team"
              :value="site.team"
              :label="$t('Select a team')"
              :items="teams"
              :multiple="false"
              name="name"
              @handleAddTagging="updateSiteTeam"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6 my-2">
            <rd-input
              type="select"
              :placeholder="$t('Add')"
              :placeholderToTag="$t('Add this region')"
              :initialValue="site.region"
              :value="site.region"
              :label="$t('Enter the site city')"
              :multiple="false"
              :deselectText="$t(`Can't remove this value`)"
              @handleAddTagging="updateSiteRegion"
              :items="regions"
              name="name"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6 my-2">
            <rd-input
              type="select"
              :placeholder="$t('Add')"
              :placeholderToTag="$t('Add this size')"
              :initialValue="site.size"
              :value="site.size"
              :label="$t('Enter the site size')"
              :multiple="false"
              :deselectText="$t(`Can't remove this value`)"
              @handleAddTagging="updateSiteSize"
              :items="sizes"
              name="size-select-name"
            />
          </div>
        </div>

        <!-- Buttons -->
        <div class="row justify-content-end mt-3">
          <div class="col-12 col-lg-6 text-end">
            <button-component
              @handleClick="goBack"
              primaryOutline
            >
              {{ $t('Dismiss') }}
            </button-component>
            <button-component
              :disabled="disableSaveButton"
              primary
              @handleClick="handleCreateSite"
            >
              {{ $t('Create site') }}
            </button-component>
          </div>
        </div>

      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import RdInput from '../../components/rd-components/rd-input.vue'
import { createSite } from '@/services/sites/site.api'
import { getRegions } from '@/services/region/region.api'
import { getAllTeams } from '@/services/team/team.api'
import { getAllSizes } from '@/services/sizes/sizes.api'
import { HOUR_FORMAT_REGEX, DATE_FORMAT_REGEX } from '../../utils/constants'
import rdFooter from '../../components/rd-components/rd-footer.vue'
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import ButtonComponent from '../../components/button-component.vue'


export default {
  data() {
    return {
      site: {
        code: null,
        name: null,
        address: null,
        latitude: null,
        longitude: null, 
        room: null,
        activity_date: '',
        check_in: '',
        team: { code: null, name: null },
        region: { code: null, name: null },
      },
      regions: [],
      teams: [],
      sizes: [],
    }
  },
  components: { 
    rdFooter, 
    RdNavbar,
    RdInput,
    ButtonComponent
  },
  async mounted() {
    await this.getRegionsData()
    await this.getTeamsData()
    await this.getSizesData()
  },
  computed: {
    activityDateMatchesFormat() {
      return (
        !this.site.activity_date
        || typeof this.site.activity_date !== 'object'
        && DATE_FORMAT_REGEX.test(this.site.activity_date)
      )
    },
    checkInMatchesFormat() {
      return (
        !this.site.check_in
        || typeof this.site.check_in !== 'object'
        && HOUR_FORMAT_REGEX.test(this.site.check_in)
      )
    },
    siteId() {
      return this.$route.params.id
    },
    disableSaveButton() {
      return (
        !this.site.code ||
        !this.site.name ||
        !this.site.address ||
        !this.site.longitude ||
        !this.site.latitude ||
        !this.site.room ||
        !this.site.region.code ||
        !this.site.activity_date  ||
        !this.site.activity_date.length > 7 ||
        !this.activityDateMatchesFormat
      )
    }
  },
  methods: {
    async getTeamsData() {
      try {
        const { data } = await getAllTeams()
        this.teams = data
          .filter(team => this.site.team.id !== team.id)
          .map(team => ({code: team.id, name: team.team}))
      } catch (error) {
        console.log(error)
      }
    },
    async getRegionsData() {
      try {
        const { data} = await getRegions()
        this.regions = data
          .filter(region => this.site.region.code !== region.id)
          .map(region => ({code: region.id, name: region.region}))
      } catch (error) {
        console.log(error)
      }
    },
    async getSizesData() {
      try {
        const { data } = await getAllSizes()
        this.sizes = data
          .map(size => ({ code: size.id, name: size.name }))
      } catch (error) {
        console.log(error)
      }
    },
    updateSiteTeam(team) {
      this.site.team = team
    },
    updateSiteRegion(region) {
      this.site.region = region
    },
    updateSiteSize(size) {
      this.site.size = size
    },
    goBack() {
      this.$router.back()
    },
    async handleCreateSite() {
      const site = {
        code: this.site.code,
        site: this.site.name,
        address: this.site.address,
        latitude: this.site.latitude,
        longitude: this.site.longitude,
        room: this.site.room,
        regionId: this.site.region.code,
        activityDate: this.site.activity_date,
        checkIn: this.site.check_in,
        teamId: this.site.team.code,
        operatorLimit: 3,
        leadLimit: 1,
        sizeId: this.site.size?.code
      }

      try {
        await createSite(site)
        this.$toast.open({
          message: 'Site Created Successfully',
          type: 'success'
        })
        this.$router.back()
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: 'An error occured',
          type: 'error'
        })
      }
    }
  },
}
</script>