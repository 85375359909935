<template>
  <div
    v-if="show" 
    class="alert alert-dismissible fade show shadow-sm alertPosition" 
    :class="[colorClass]"
    role="alert"
    @mouseenter="handleMouseEnters"
    @mouseleave="handleMouseLeaves"
  >
    <span class="me-2">
      <tooltip v-if="show" text="Status Notification">
        <i :class="[iconClass]"></i>
      </tooltip>
    </span>
    <template v-if="title">
      <strong class="mx-1">{{ title }}</strong>
      <span v-if="message">{{ message }}</span>
    </template>
    <tooltip v-if="show" text="Close Notification">
      <button 
        v-if="closeButton"
        class="btn-close"
        @click="handleHide"
      ></button>
    </tooltip>
  </div>
</template>
<script>
import tooltip from '../tooltip.vue';
export default {
  components: { tooltip },
  name: 'rd-notification',
  props: {
    showAlert: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'success'
    },
    closeButton: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      show: false,
      SUCCESS: 'success',
      ERROR: 'error',
      DANGER: 'danger',
      timeoutHandler: null
    }
  },
  watch: {
    showAlert(n) {
      this.show = n;
    }
  },
  mounted() {
    this.show = this.showAlert;
    this.timeoutHandler = setTimeout(() => {
      this.handleHide();
    }, this.timeout)
  },
  computed: {
    colorClass() {
      if (this.type.trim().toLowerCase() === this.ERROR) return 'alert-warning';
      if (this.type.trim().toLowerCase() === this.DANGER) return 'alert-danger';
      if (this.type.trim().toLowerCase() === this.SUCCESS) return 'alert-success';
      return 'alert-info'
    },
    iconClass() {
      if (this.type.trim().toLowerCase() === this.ERROR) return 'bi bi-exclamation-triangle-fill';
      if (this.type.trim().toLowerCase() === this.DANGER) return 'bi bi-dash-circle-fill';
      if (this.type.trim().toLowerCase() === this.SUCCESS) return 'bi bi-check-circle-fill';
      return 'bi bi-info';
    },
  },
  methods: {
    handleMouseEnters() {
      clearTimeout(this.timeoutHandler);
    },
    handleMouseLeaves() {
      this.timeoutHandler = setTimeout(() => {
        this.handleHide();
      }, this.timeout)
    },
    handleHide() {
      this.show = false;
      this.$store.dispatch('dispatchNotification', {
        show: false,
        timeout: 0,
      });
    }
  }
}
</script>
<style scoped>
.alertPosition {
  position: fixed;
  top: 25px;
  right: 15px;
  z-index: var(--top-layer-z-index);
}
</style>