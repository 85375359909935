<template>
  <div class="d-flex flex-column justify-content-between vh-100 unselectable">
    <div>
      <rd-navbar />
      <div class="container my-3 p-3">
        <div v-if="loading">
          <rd-loading /> 
        </div>

        <div v-else class="p-3">
          <div class="row">
            <div class="col-12">
              <h3 class="text-start">{{ $t('Exam stadistics') }}</h3>
            </div>
          </div>
          <div class="row p-3 justify-content-between">
            <div
              class="p-0 m-0 mb-2 col-12 col-lg-4"
            >
              <rd-input
                class="w-100"
                :placeholder="$t('Search exam by name')" 
                type="text"
                @input="(e) => this.search = e.target.value"
              />
            </div>

            <div class="col-12 col-lg-4 text-end">
              <button-component
                primaryOutline 
                @handleClick="downloadGeneralExamStats"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Download Stats" type="button" class="bi bi-arrow-down-circle-fill"></i> {{ $t('Download Stats') }}
              </button-component>
            </div>
          </div>
          <div
            v-if="filteredStats.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No stats Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>
          <div v-else class="row mt-3 d-flex justify-content-start">

            <data-table-component
              :columns="[
                { field: 'examName', label: $t('Exam'), sortable: true, type: 'string', },
                { field: 'assigned', label: $t('Assigned'), sortable: true, type: 'number', },
                { field: 'pending', label: $t('Pending'), sortable: true, type: 'number', },
                { field: 'in_progress', label: $t('In Progress'), sortable: true, type: 'number', },
                { field: 'failed', label: $t('Failed'), sortable: true, type: 'number', },
                { field: 'approved', label: $t('Approved'), sortable: true, type: 'number', },
                { field: 'partipationRate', label: $t('Participation Rate'), sortable: true, type: 'number', hAlign: 'center', headerHAlign: 'center' },
                { field: 'passingScore', label: $t('Passing Score'), sortable: true, type: 'number', hAlign: 'center', headerHAlign: 'center' },
                { field: 'passingRate', label: $t('Passing Rate'), sortable: true, type: 'number', hAlign: 'center', headerHAlign: 'center' },
              ]"
              :rows="filteredStats"
              paginator
              resizeable
            >
              <template #examName="{ row }">
                <span @click="goToExamStats(row.examId)" style="cursor: pointer;">
                  {{ row.examName }}
                </span>
              </template>
              <template #partipationRate="{ row }">
                {{ row.partipationRate }} %
              </template>
              <template #passingScore="{ row }">
                {{ row.passingScore }} %
              </template>
              <template #passingRate="{ row }">
                {{ row.passingRate }} %
              </template>
            </data-table-component>

          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import router from '@/router';
import RdFooter from '@/components/rd-components/rd-footer.vue';
import RdNavbar from '@/components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import RdLoading from '@/components/rd-components/rd-loading.vue';
import { getGeneralStats } from '@/services/exams/exam.api.js';
import { convertToCSV } from '@/utils/convertFormat';
import { downloadCSV } from '@/utils/handleFile';
import { ATTEMPT_STATUS } from '@/utils/constants';
import ButtonComponent from '@/components/button-component.vue';
import DataTableComponent from '@/components/tables/data-table-component.vue';

export default {
  data() {
    return {
      stats: null,
      loading: true,
      ATTEMPT_STATUS,
      examTitle: null,
      search: '',
      dataTemp: [],
      columns: [
        { field: 'examName', label: this.$t('Exam'), sortable: true, type: 'string', },
        { field: 'assigned', label: this.$t('Assigned'), sortable: true, type: 'number', },
        { field: 'pending', label: this.$t('Pending'), sortable: true, type: 'number', },
        { field: 'in_progress', label: this.$t('In Progress'), sortable: true, type: 'number', },
        { field: 'failed', label: this.$t('Failed'), sortable: true, type: 'number', },
        { field: 'approved', label: this.$t('Approved'), sortable: true, type: 'number', },
        { field: 'partipationRate', label: this.$t('Participation Rate'), sortable: true, type: 'number', hAlign: 'center', headerHAlign: 'center' },
        { field: 'passingScore', label: this.$t('Passing Score'), sortable: true, type: 'number', hAlign: 'center', headerHAlign: 'center' },
        { field: 'passingRate', label: this.$t('Passing Rate'), sortable: true, type: 'number', hAlign: 'center', headerHAlign: 'center' },
      ],
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdLoading,
    RdInput,
    ButtonComponent,
    DataTableComponent,
  },
  async mounted() {
    await this.getExamsStats();
  },
  methods: {
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    goToExamStats(id) {
      router.push(`/admin/exam/stats/${id}`);
    },
    async downloadGeneralExamStats() {
      try {
        const csv = await convertToCSV({ data: this.filteredStats });
        downloadCSV({ data: csv, filename: 'general_stats'});
      } catch (error) {
        console.log(error);
      }
    },
    async getExamsStats() {
      this.loading = true;
      try {
        const { data } = await getGeneralStats();
        this.stats = data;

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    filteredStats() {
      return this.stats.filter(
          exam => exam.examName.toLowerCase().includes(this.search.trim().toLowerCase())
        )
    },
  }
}
</script>