<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />
      <div class="container background-form rounded-3 my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Bulk Load Details') }}</h2>
          </div>
        </div>

        <div v-if="loading">
          <rd-loading />
        </div>
        <div v-else class="row p-3">
          <div v-if="filteredHistory.lenght === 0">
            <h2>{{ $t('No data found') }}</h2>
          </div>
          <div v-else style="overflow-x: auto; width: 100%; max-width: 100%;">
            <div class="w-100">
              <table class="table text-start table-hover" ref="table_resize">
                <thead class="table-primary" @contextmenu.prevent="resize">
                  <tr>
                    <th scope="col">{{ $t('Date') }}</th>
                    <th scope="col">{{ $t('Data') }}</th>
                    <th scope="col">{{ $t('Result') }}</th>
                  </tr>
                </thead>
                <tbody class="text-start">
                  <tr
                    v-for="({
                      data: bulkData, createdAt, result, 
                    }, index) in dataTemp" :key="index"
                    class="hoverable"
                    :class="{ 'fill-ods': index % 2 !== 0 }"
                  >
                    <td style="width: 33.33%; word-break: break-all;" :header="$t('Date')" scope="row">{{ createdAt }}</td>
                    <td style="width: 33.33%; word-break: break-all;" :header="$t('Data')" scope="row">{{ bulkData }}</td>
                    <td style="width: 33.33%; word-break: break-all;" :header="$t('Result')" scope="row">{{ result }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <rd-paginator :items="filteredHistory" @page-change="handlePageChange" />
        </div>

      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import RdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdLoading from '../../components/rd-components/rd-loading.vue'
import { getFileDetailsById } from '@/services/bulk/bulk.api'
import RdPaginator from '../../components/rd-components/rd-paginator.vue'
import { convertDateFormat } from '@/utils/convertFormat'

export default {
  components: {
    RdNavbar,
    RdFooter,
    RdLoading,
    RdPaginator,
  },
  data() {
    return {
      loading: true,
      detailsData: [],
      dataTemp: [],
      convertDateFormat,
    }
  },
  async mounted() {
    await this.getDetailsData();
  },
  computed: {
    fileId() {
      return this.$route.params.id
    },
    filteredHistory() {
      return this.detailsData
    }
  },
  methods: {
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    async getDetailsData() {
      try {
        this.loading = true
        const { data } = await getFileDetailsById({ id: this.fileId })
        this.detailsData = data.data.map(({ createdAt, ...rest }) => ({
          ...rest,
          createdAt: convertDateFormat(createdAt),
        }))
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        await this.$nextTick(() => {
          const elements = document.querySelectorAll("table th");
          elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
          })
          document.addEventListener("mousemove", (e) => {
            if (this.thElm) {
              this.lastWidth  = this.thElm.offsetWidth;
              this.thElm.style.width = this.startOffset + e.pageX + "px";
              document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                if((internalIndex % 3) == Number(this.thElm.id)) {
                  td.style.width = this.startOffset + e.pageX + "px";
                } else {
                  td.style.width = td.offsetWidth + "px";
                }
              })
              this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
            }
          });

          document.addEventListener("mouseup", () => {
            this.thElm = undefined;
          });
        });
      }
    },
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '33.33%'
      })
    },
  },
}
</script>
<style scoped>
  
</style>