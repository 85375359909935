<template lang="">
    <div class="d-flex flex-column justify-content-between vh-100">
      <div>
        <rd-navbar :title="$t('Sessions (nav)')" />
        <div class="container background-form my-3 p-3">
          <div class="row">
            <div class="col-12">
              <h2 class="ms-4 text-start">{{ $t('Edit Session') }}</h2>
            </div>
          </div>
          
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Session name')" 
                :label="$t('Enter session name')"
                required
                :value="code"
                @input="(e) => this.code = e.target.value"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Description')" 
                :label="$t('Enter the session description')"
                required
                :value="description"
                @input="(e) => this.description = e.target.value"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :allowEmpty="true"
                :deselectText="$t('Click to remove')"
                :placeholder="$t('Choose a trainer')"
                :placeholderToTag="$t('Add this trainer')" 
                :label="$t('Select Trainer')"
                :items="trainers"
                :initialValue="trainer"
                @handleAddTagging="handleTrainerSelected"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-12 col-md-6">
              <label
                class="required labelText"
              >
                {{ $t('Session date and time') }}
              </label>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-12 col-md-2">
              <input  type="date" v-model="date" class="form-control" id="datepicker" :min="minDate">
            </div>
            <div class="col-sm-12 col-md-2">
              <div class="form-check form-switch d-flex" style="gap: 1rem;">
                <input v-model="allDay" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                <label class="form-check-label" for="flexSwitchCheckDefault">{{ $t('All day') }}</label>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-2">
              <input :disabled="allDay" v-model="startTime" type="time" class="form-control primary" step="60" />
            </div>
            <div class="col-sm-12 col-md-2">
              <input :disabled="allDay" v-model="endTime" type="time" class="form-control primary" step="60" />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Choose a location')" 
                :label="$t('Select a Location')"
                
                :value="location"
                @input="(e) => this.location = e.target.value"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :allowEmpty="true"
                :deselectText="$t('Click to remove')"
                :placeholder="$t('Choose a Status')"
                :placeholderToTag="$t('Add a status')" 
                :label="$t('Session Status')"
                :items="status"
                :initialValue="state"
                required
                @handleAddTagging="handleStatusSelected"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :allowEmpty="true"
                :deselectText="$t('Click to remove')"
                :placeholder="$t('Choose a Exam')"
                :placeholderToTag="$t('Add a Exam')" 
                :label="$t('Session Exam')"
                :items="exams"
                :initialValue="examId"
                @handleAddTagging="handleExamSelected"
              />
            </div>
          </div>
  
          <div class="mt-3 row justify-content-end">
            <div class="col-12 col-md-6 col-lg-6 text-end">
              <button-component primaryOutline @handleClick="goBack">
                {{ $t('Dismiss') }}
              </button-component>
              <button-component :disabled="disableSaveButton" primary @handleClick="editSession">
                {{ $t('Edit Session (btn)') }}
              </button-component>
            </div>
          </div>
  
        </div>
      </div>
      <div>
        <rd-footer/>
      </div>
    </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import router from '@/router'
import { getSession, editSession, getSessionsState } from '@/services/sessions/sessions.api.js'
import { getTrainers }  from '@/services/user/user.api.js'
import { getAllExams } from '@/services/exams/exam.api.js'
import ButtonComponent from '../../components/button-component.vue';

export default {
  data() {
    return {
      code: '',
      description: '',
      disableSaveButton: true,
      trainer: null,
      state: null,
      date: '',
      startTime: '',
      minDate: '',
      allDay: false,
      endTime: '',
      location: '',
      trainers: [],
      status: [],
      exams: [],
      examId: null
    }
  },
  created() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    this.minDate = `${year}-${month}-${day}`;
  },
  computed: {
    sessionId() {
      return this.$route.params.id
    }
  },
  async mounted() {
    await this.setSessionData()
    this.$watch(
      () => {
        return [this.code, this.description, this.date, this.startTime, this.endTime, this.allDay, this.state]
      },
      ([code, description, date, startTime, endTime, allDay, state]) => {
        if (code === '' || description === '' || date === '' || ((startTime === '' || endTime === '') && !allDay) || !state) {
          this.disableSaveButton = true
        } else {
          this.disableSaveButton = false
        }
      } 
    );
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    ButtonComponent,
  },
  methods: {
    handleExamSelected(c) {
      this.examId = c;
    },
    handleStatusSelected(c) {
      this.state = c;
    },
    handleTrainerSelected(c) {
      this.trainer = c
    },
    async setSessionData() {
      try {
        const { data:trainers } = await getTrainers();
        const { data:sessionState } = await getSessionsState();
        const { data:exams } = await getAllExams();
        this.trainers = trainers.map(({ userLastname, userFirstname, userId }) => (
          { value: userId, name: `${userFirstname} ${userLastname}` }
        ));
        this.status = sessionState.map(({ id, state }) => (
          { value: id, name: state }
        ));
        this.exams = exams.map(({ examId, examName }) => (
          { value: examId, name: `${examName}` }
        ));
        const {data} = await getSession(this.sessionId)
        const {session} = data
        this.code = session.code
        this.description = session.description
        const filterdTrainer = this.trainers.filter(trainer => trainer.value == session.trainer)
        if(filterdTrainer.length) {
          this.trainer = filterdTrainer[0]
        }
        const filterdStatus = this.status.filter(statu => statu.value == session.state)
        if(filterdStatus.length) {
          this.state = filterdStatus[0]
        }
        const filteredExams = this.exams.filter(exam => exam.value == session.examId)
        if(filteredExams.length) {
          this.examId = filteredExams[0]
        }
        this.date = session.date.split('T')[0]
        this.allDay = session.allDay
        this.startTime = session.startTime
        this.endTime = session.endTime
        this.location = session.location
        if (this.code === '' || this.description === '' || this.date === '' || ((this.startTime === '' || this.endTime === '') && !this.allDay) || !this.state) {
          this.disableSaveButton = true
        } else {
          this.disableSaveButton = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    async editSession() {
      const initialDate = new Date(this.date);
      initialDate.setDate(initialDate.getDate() + 1);
      const result = initialDate.toISOString().split('T')[0];
      const session = {
        session: {
          "code": this.code,
          "date": result,
          "startTime": this.allDay? '00:00' : this.startTime,
          "endTime": this.allDay? '23:59' : this.endTime,
          "location": this.location,
          "description": this.description,
          "trainer": this.trainer? this.trainer.value: null,
          "state": this.state.value,
          "examId": this.examId? this.examId.value : null,
          "allDay": this.allDay
      }
      }
      try {
        await editSession(this.sessionId, session)
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Session edited',
          type: 'success',
        });
        router.back()
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Session was not edited.',
          type: 'error',
        });
        console.log(error)
      }
    },
    goBack() {
      router.back()
    }
  },
}
</script>
<style scoped>
  
</style>