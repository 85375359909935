<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Exam (nav)')" />
      <div class="container background-form rounded-3 my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Add Exam') }}</h2>
          </div>
        </div>
        <div class="container">

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                :placeholder="$t('Exam name')" 
                type="text"
                :label="$t('Enter the exam name')"
                required
                @input="(e) => examName = e.target.value"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Exam description')" 
                :label="$t('Enter the exam description')"
                required
                @input="(e) => examDescription = e.target.value"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="number"
                :placeholder="$t('Exam duration')" 
                :label="$t('Exam duration in minutes (if blank the exam duration is infinity)')"
                @input="(e) => examDuration = e.target.value"
              />
              <div
                v-if="!!examDuration && Number(examDuration) <= 0"
                class="text-start text-danger"
              >
                <small>{{ $t('Do not enter negative numbers') }} &nbsp;</small>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="number"
                :placeholder="$t('Exam attempts')" 
                :label="$t('Exam attempts (if blank makes the exam attempts unlimited)')"
                @input="(e) => examAttempts = e.target.value"
              />
              <div
                v-if="!!examAttempts && Number(examAttempts) <= 0"
                class="text-start text-danger"
              >
                <small>{{ $t('Do not enter negative numbers') }} &nbsp;</small>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="number"
                :placeholder="$t('Exam minimum percentage')" 
                :label="$t('Enter the exam mimimum percentage')"
                required
                :value="examMinimumScore"
                @input="(e) => examMinimumScore = e.target.value"
              />
              <div
                v-if="!!examMinimumScore && Number(examMinimumScore) < 0"
                class="text-start text-danger"
              >
                <small>{{ $t('Do not enter negative numbers') }} &nbsp;</small>
              </div>
              <div
                v-if="!!examMinimumScore && Number(examMinimumScore) > 100"
                class="text-start text-danger"
              >
                <small>{{ $t('Do not enter numbers above 100') }} &nbsp;</small>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Exam language')" 
                :label="$t('Enter the exam language')"
                @input="(e) => examLanguage = e.target.value"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :placeholder="$t('Choose a category')"
                :placeholderToTag="$t('Add this category')" 
                :label="$t('Enter exam category')"
                :items="categories"
                required
                @handleAddTagging="handleCategorySelection"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :placeholder="$t('Choose a subcategory')" 
                :placeholderToTag="$t('Add this subcategory')" 
                :label="$t('Enter the exam subcategory')"
                :items="subcategories"
                :disbled="!subcategories.length"
                required
                @handleAddTagging="(e) => this.examSubcategory = e.value"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <div class="form-check d-flex">
                <rd-input
                  :label="$t('Generates certificate')"
                  type="checkbox"
                  v-model="examGeneratesCertificate"
                  :checked2="examGeneratesCertificate"
                  id="certificateLabel"
                  @change="(e) => examGeneratesCertificate = e.target.checked"
                />
              </div>
            </div>
          </div>

          <div class="row mb-4" v-show="examGeneratesCertificate">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :placeholder="$t('Choose a template for certificates')" 
                :placeholderToTag="$t('Add this template')" 
                :label="$t('Enter the exam template')"
                :items="templates"
                :disbled="!templates.length"
                @handleAddTagging="handleTemplateSelection"
              />
              <div
                v-if="!!templateId"
                contentEditable="false"
                class="m-0 p-0 quill-overflow-custom"
              >
                <div class="m-o p-0" v-html="templateId.template"></div>
              </div>

            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <div class="form-check d-flex">
                <rd-input :label="$t('Allows empty answers')" type="checkbox" v-model="examAllowsEmpty" :checked2="examAllowsEmpty" id="allowEmptyLabel" @change="changeExamAllowsEmpty"/>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <div class="form-check d-flex">
                <rd-input :label="$t('Is available?')" type="checkbox" v-model="examIsAvailable" :checked2="examIsAvailable" id="isAvailableLabel" @change="changeExamIsAvailable"/>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <div class="form-check d-flex">
                <rd-input :label="$t('Review Answer?')" type="checkbox" v-model="examReviewAnswer" :checked2="examReviewAnswer" id="reviewAnswerLabel" @change="changeExamReviewAnswer"/>
              </div>
            </div>
          </div>

          <div class="row mb-4 align-items-center">
            <div class="col-10 col-sm-10 col-md-5">
              <rd-input
                ref="questions-input"
                type="file"
                :propagation="false"
                :label="$t('Choose a questions file')"
                :accept="'.csv'"
                required
                @input="handleQuestionsUploaded($event)"
              />
            </div>
            <div class="col-2 col-sm-2 col-md-1">
              <button-component
                class="btn-light text-primary"
                :title="$t('Download a question template')"
                @handleClick="downloadQuestions"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Download Template" type="button" class="bi bi-arrow-down-circle-fill"></i>
              </button-component>
            </div>
          </div>
          <!-- <div class="row mb-4 align-items-center">
            <div v-if="examQuestions" class="col-10 col-sm-10 col-md-5">
              <p>
                {{ $t('Images expected') }}
              </p>
              <p class="text-left" v-for="(image, index) in imagesExpected" :key="index">
                {{ image }}
              </p>
            </div>
          </div> -->
          <div class="row mb-4 align-items-center">
            <div class="col-10 col-sm-10 col-md-5">
              <rd-input
                ref="questions-input"
                type="file_image"
                :propagation="false"
                :label="$t('Choose some images')"
                :showError="showFileError"
                :errorMsg="errorMessage"
                :multiple="true"
                @input.prevent="handleFileUploaded($event)"
              />
              <div v-if="imageValidation.length" class="row mb-4 ">
                <div
                  class="d-flex"
                  v-for="(image, index) in imageValidation"
                  :key="index"
                  :class="{
                    'text-success': image[0] !== null && image[1] !== null,
                    'text-danger': image[0] === null || image[1] === null
                  }"
                > 
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Upload image Status" type="button" :class="`bi ${image[0] !== null && image[1] !== null ? 'bi-check-circle-fill': 'bi-exclamation-circle-fill'}`" />
                  {{ `${image[0] == null? 'Unexpected' : 'Expected'} ${image[0] ?? image[1]}` }}
                </div>
              </div>
            </div>
          </div>

          <div
            :class="{
              'w-50': windowWidth > 768,
              'w-100': windowWidth <= 768
            }"
          >
            <quill-editor
              v-model:value="editor.content"
              :options="editor.options"
            />
          </div>

          <div class="my-3 row justify-content-end">
            <div class="col-12 col-md-6 col-lg-3 text-end">
              <button-component primaryOutline @click="$router.back()">
                {{ $t('Dismiss') }}
              </button-component>
              <button-component
                :disabled="disableSaveButton"
                primary
                @handleClick="handleCreateExam"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Exam" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Exam') }}
              </button-component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import router from '@/router'
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import { createExam } from '@/services/exams/exam.api.js'
import { encodeToBase64 } from '../../utils/utils';
import { mapState } from 'vuex';
import { getAllCategory } from '@/services/categories/categories.api.js'
import { getAllTemplates } from '@/services/templates/templates.api.js'
import { downloadCSV } from '../../utils/handleFile';
import { QUESTIONS_TEMPLATE } from '../../utils/constants';
import ButtonComponent from '../../components/button-component.vue';

export default {
  data() {
    return {
      examName: null,
      examDescription: null,
      examDuration: null,
      examAttempts: null,
      examMinimumScore: 0,
      examLanguage: null,
      examGeneratesCertificate: false,
      examCertificateTemplate: null,
      examAllowsEmpty: false,
      examIsAvailable: true,
      examReviewAnswer: true,
      examQuestions: null,
      examCategory: null,
      examSubcategory: null,
      showFileError: false,
      errorMessage: 'Images doesnt match csv',
      filesArray: [],
      imagesExpected: [],
      editor: {
        content: '',
        options: {
          placeholder: this.$t('Type something...'),
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: ['Sans Serif', 'serif', 'monospace', 'roboto'] }],
              [{ align: [] }],
              ['clean'],
              ['link']
            ]
            // other moudle options here
            // otherMoudle: {}
          },
        },
        disabled: false,
      },
      categories: [],
      subcategories: [],
      imageValidation: [],
      templates: [],
      templateId: null
    }
  },
  async mounted() {
    await this.setCategories();
    await this.setTemplates();
  },
  computed: {
    ...mapState(['windowWidth']),
    user(){
      return this.$store.getters.getUser;
    },
    disableSaveButton() {
      return (
        !this.examName
        || !this.examDescription
        || !this.examQuestions
        || !this.examCategory
        || !this.examSubcategory
        || this.showFileError
        || (!!this.examDuration && Number(this.examDuration) <= 0)
        || (!!this.examAttempts && Number(this.examAttempts) <= 0)
        || (this.examMinimumScore == null)
        || (this.examMinimumScore == '')
        || Number(this.examMinimumScore) < 0
        || Number(this.examMinimumScore) > 100
      )
    }
  },
  methods: {
    handleTemplateSelection({
      name, code, template
    }) {
      if (this.templateId && this.templateId.code === code) return
      this.templateId = { code, name, template }
    },
    changeExamAllowsEmpty(e) {
      this.examAllowsEmpty = e.target.checked
    },
    changeExamIsAvailable(e) {
      this.examIsAvailable = e.target.checked
    },
    changeExamReviewAnswer(e) {
      this.examReviewAnswer = e.target.checked
    },
    async setTemplates() {
      try {
        const { data } = await getAllTemplates();
        this.templates = data.map(template => ({
          name: template.name,
          code: template.id,
          template: template.template
        }))
      } catch (error) {
        console.log(error)
      }
    },
    calcImagesCsv() {
      const newText = this.examQuestions.trim()
      const lines = newText.split(/\r?\n/);
      const headers = lines[0].split('|');
      const dataArray = [];
      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split('|');
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentLine[j];
        }
        dataArray.push(obj);
      }
      const images = []
      dataArray.forEach((data) => {
        if(data.image) {
          images.push(data.image)
        }
      })
      this.imagesExpected = images
    },
    handleValidImages() {
      this.imageValidation = JSON.parse(JSON.stringify([]))
      if(this.filesArray.length && this.examQuestions) {
        const images = this.imagesExpected
        
        const fileNames = []
        this.filesArray.forEach((file) => {
          fileNames.push(file.name)
        })
        images.sort();
        fileNames.sort();
        let newFileNames = fileNames;
        if(images.length == fileNames.length) {
          const listsAreDifferent = !images.every((value, index) => value === fileNames[index]);
          for (let index = 0; index < images.length; index++) {
            const image = images[index];
            let validate = false;
            let indexFile = null;
            for (let index2 = 0; index2 < newFileNames.length; index2++) {
              const fileName = newFileNames[index2];
              if(fileName == image) {
                validate = true;
                indexFile = index2
              }
            }
            if(validate) {
              this.imageValidation.push([
                image, newFileNames[indexFile]
              ])
              newFileNames.splice(indexFile, 1);
            } else {
              this.imageValidation.push([
                image, null
              ])
            }
          }
          for (let index2 = 0; index2 < newFileNames.length; index2++) {
            const fileName = newFileNames[index2];
            this.imageValidation.push([
                null, fileName
              ])
          }
          this.showFileError = listsAreDifferent;
          this.imageValidation.sort((a, b) => {
              if (a[0] !== null && b[0] !== null) {
                  return a[0].localeCompare(b[0]);
              }
              else if (a[1] === null && b[1] === null) {
                  return a[0].localeCompare(b[0]);
              }
              else if (a[0] === null || b[0] === null) {
                  return a[0] === null ? 1 : -1;
              }
          });
          return;
        }
        
        for (let index = 0; index < images.length; index++) {
          const image = images[index];
          let validate = false;
          let indexFile = null;
          for (let index2 = 0; index2 < newFileNames.length; index2++) {
            const fileName = newFileNames[index2];
            if(fileName == image) {
              validate = true;
              indexFile = index2
            }
          }
          if(validate) {
            this.imageValidation.push([
              image, newFileNames[indexFile]
            ])
            newFileNames.splice(indexFile, 1);
          } else {
            this.imageValidation.push([
              image, null
            ])
          }
        }
        for (let index2 = 0; index2 < newFileNames.length; index2++) {
          const fileName = newFileNames[index2];
          this.imageValidation.push([
              null, fileName
            ])
        }
        this.imageValidation.sort((a, b) => {
            if (a[0] !== null && b[0] !== null) {
                return a[0].localeCompare(b[0]);
            }
            else if (a[1] === null && b[1] === null) {
                return a[0].localeCompare(b[0]);
            }
            else if (a[0] === null || b[0] === null) {
                return a[0] === null ? 1 : -1;
            }
        });
        this.showFileError = true;
      }
    },
    async handleQuestionsUploaded(e) {
      const file = e.target.files[0];
      this.examQuestions = await file?.text();
      if(file) {
        this.calcImagesCsv();
        this.handleValidImages();
      }
    },
    handleFileUploaded(event) {
      this.filesArray = []
      const files = event.target.files;
      if (files.length > 0) {
        Array.from(files).forEach(file => {
          this.filesArray.push(file)
        });
      }
      this.handleValidImages();
    },
    async downloadQuestions() {
      try {
        downloadCSV({
          data: QUESTIONS_TEMPLATE,
          filename: 'questions_template',
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleCategorySelection(category) {
      this.examCategory = category.value
      const { subcategories } = this.categories.find(cat => parseInt(cat.value) === parseInt(category.value))
      this.subcategories = subcategories.map(({ subCategoryId, subCategoryName }) => (
        { value: subCategoryId, name: subCategoryName }
      ));
    },
    async setCategories() {
      try {
        const { data } = await getAllCategory()
        this.categories = data.map(category => (
          { 
            value: category.categoryId, 
            name: category.categoryName, 
            subcategories: category.subcategories 
          }
        ));
      } catch (error) {
        console.log(error)
      }
    },
    async handleCreateExam() {
      try {
        const questions = this.examQuestions;
        if (
          (!!this.examDuration && Number(this.examDuration) <= 0)
          || (!!this.examAttempts && Number(this.examAttempts) <= 0)
          || Number(this.examMinimumScore) < 0
          || Number(this.examMinimumScore) > 100
        ) {
          // TODO: add message
          return;
        }
        const payload = {
          exam: {
            examName: this.examName,
            examDescription: this.examDescription,
            examDuration: this.examDuration ? Number(this.examDuration) : null,
            examAttempts: this.examAttempts ? Number(this.examAttempts) : null,
            examHasCertificate: this.examGeneratesCertificate,
            examAllowsEmpty: this.examAllowsEmpty,
            examInstuctions: encodeToBase64(this.editor.content),
            examSubcategory: this.examSubcategory,
            minimumScore: Number(this.examMinimumScore),
            isAvailable: this.examIsAvailable,
            reviewAnswer: this.examReviewAnswer,
            examLanguage: this.examLanguage,
            templateId: this.templateId ? this.templateId.code : null,
          },
          questions
        };
        let exam = new FormData();
        exam.append('payload', JSON.stringify(payload));
        this.filesArray.forEach((file) => {
          exam.append('files', file);
        })
        await createExam(exam);
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Exam created.',
          type: 'success',
        });
        router.back();
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Exam was not created.',
          type: 'error',
        });
        console.log(error)
      }
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    ButtonComponent,
  }
}
</script>
<style>
.text-left {
  text-align: left !important;
}
.ql-picker-item[data-value="roboto"]::before {
  content: 'Roboto' !important;
}
span.ql-picker-label[data-value="roboto"]::before {
  content: 'Roboto' !important;
}

@media screen and (max-width: 768px) {
  .quill-overflow-custom {
    overflow-x: auto;
  }
}
</style>