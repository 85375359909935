<template>
  <div @click.self="hideModal($event)" class="modal-feedback rounded-3">
    <div class="embed-container p-3">
      <div class="w-100" :style="{ width: '50rem' }">
        <h2 class="text-start ms-2">
          <slot name="title">{{ $t('Title') }}</slot>
        </h2>
        <p class="text-start ms-2">
          <slot name="subtitle"></slot>
        </p>
        <div class="border rounded p-3">
          <template v-if="loading">
            <rd-loading /> 
          </template>
          <slot v-else name="body">
            <textarea
              v-model="textareaValue"
              resize
              class="form-control custom-textarea"
              :disabled="textareaDisabled"
              :placeholder="placeholder"
            ></textarea>
          </slot>
        </div>
      </div>
      <div class="d-flex w-100 mt-3" style="gap: 1rem;">
        <slot name="footer">
          <button-component
            :disabled="loading"
            primaryOutline
            px="4" py="2"
            @handleClick="(e) => hideModal(e)"
          >
            {{ $t('Cancel') }}
          </button-component>
          <button-component
            :disabled="loading"
            primary 
            px="4" py="2"
            @handleClick="handleSave"
          >
            {{ $t('Save') }}
          </button-component>
        </slot>
      </div>
      <i data-bs-toggle="tooltip" data-bs-placement="right" title="Close" type="button" @click.self="hideModal($event)" class="bi bi-x-circle close"></i>
    </div>
  </div>
</template>
<script>
import ButtonComponent from './button-component.vue'
import RdLoading from './rd-components/rd-loading.vue'

export default {
  name: 'basic-modal',
  components: {
    RdLoading,
    ButtonComponent,
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    textareaDisabled: {
      type: Boolean,
      default: false
    },
    initialTextareaVal: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      textareaValue: '',
    }
  },
  created() {
    this.textareaValue = this.initialTextareaVal
  },
  methods: {
    hideModal(e) {
      if (e.target !== e.currentTarget) return
      this.$emit('hide', e)
    },
    handleSave() {
      this.$emit('save', this.textareaValue)
    }
  },
}
</script>
<style lang="scss" scoped>
.closeModalIcon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.modal-feedback {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4); /* fondo negro con opacidad del 40% */
  z-index: var(--top-layer-z-index); /* asegura que el modal se superpone a otros elementos */
  display: flex;
  justify-content: center;
  align-items: center;
}
.embed-container {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  flex-flow: column;
}
.close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}
.custom-textarea {
  min-width: 500px;
  width: 100%;
  height: 150px;
  resize: none;
}
@media (max-width: 768px) {
  .close {
    font-size: 1rem;
  }
  .custom-textarea {
    min-width: auto;
  }
  .embed-container {
    width: 90%;
  }
}

</style>