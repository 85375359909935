import httpClient from "../httpClient";

const END_POINT = '/api/roles';

const getAllRoles = () => httpClient.get(END_POINT)
const getAllPermissions = () => httpClient.get(`${END_POINT}/permissions`)
const getRole = (id) => httpClient.get(`${END_POINT}/${id}`)
const createRole = (role) => httpClient.post(END_POINT, role);
const updateRole = (id, role) => httpClient.patch(`${END_POINT}/${id}`, role);
const deleteRole = (id) => httpClient.delete(`${END_POINT}/${id}`);

export {
  getAllRoles,
  getRole,
  createRole,
  updateRole,
  deleteRole,
  getAllPermissions
}