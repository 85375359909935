<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />
      <div class="container background-form my-3 p-3">
        <!-- <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Files Management') }}</h2>
          </div>
        </div> -->
        <PageTitle
          :title="$t('Files Management')"
          :description="$t('Manage files, categories and sub-categories')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Files Management')"
        />
        <div class="container mt-3 py-3">
          <div class="row g-4">

            <div class="col-12 col-lg-4" v-if="permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION) || user.isAdmin">
              <rd-admin-card
                :name="$t('Main Site')"
                :description="$t('Main page of the application')"
                @route="goTo('/files')"
              />
            </div>

            <div class="col-12 col-lg-4" v-if="permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.FILE_UPLOADER) || user.isAdmin">
              <rd-admin-card
                :name="$t('File Uploader')"
                :description="$t('Manage all related to files')"
                @route="goTo('/admin/files')"
              />
            </div>

            <div class="col-12 col-lg-4" v-if="permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES) || user.isAdmin">
              <rd-admin-card
                :name="$t('Categories')"
                :description="$t('Manage and create categories')"
                @route="goTo('/admin/categories')"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import RdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdAdminCard from '@/components/rd-components/rd-admin-card.vue'
import router from '@/router'
import { mapState } from 'vuex'
import {APP_PERMISSIONS} from '@/utils/constants.js'
import PageTitle from '../../components/rd-components/page-title.vue'

export default {
  components: { 
    RdFooter, 
    RdNavbar, 
    RdAdminCard,
    PageTitle
  },
  data() {
    return {
      APP_PERMISSIONS
    }
  },
  created() {
    if(!this.user.isAdmin) {
      const permissionsFiltered = this.permissions.filter(permission => [
        APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION,
        APP_PERMISSIONS.FILES_MANAGEMENT.FILE_UPLOADER,
        APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES
      ].includes(permission));
      if(permissionsFiltered.length == 1) {
        switch (permissionsFiltered[0]) {
          case APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION:
            router.replace('/files');
            return;
          case APP_PERMISSIONS.FILES_MANAGEMENT.FILE_UPLOADER:
            router.replace('/admin/files');
            return;
          case APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES:
            router.replace('/admin/categories');
            return;
          
        
          default:
            return;
        }
      }
    }
  },
  computed: {
    ...mapState(['user', 'permissions'])
  },
  methods: {
    goTo(route) {
      router.push(route)
    }
  },
}
</script>