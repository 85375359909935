import httpClient from '../httpClient';

const END_POINT = '/api/interactions';
const END_POINT_TYPES = '/api/interaction_types';

const getInteractionTypes = () => httpClient.get(END_POINT_TYPES);
const getInteractionsByUserId = (userId) => httpClient.get(`${END_POINT}/${userId}`);
const findValidInteractionsByUserId = (userId) => httpClient.get(`${END_POINT}/valid-interactions/${userId}`);
const createInteraction = (interactionData) => httpClient.post(END_POINT, interactionData);
const restartStatusRezero = (request) => httpClient.post(`${END_POINT}/restartStatusRezero`, request);

export {
  createInteraction,
  getInteractionTypes,
  getInteractionsByUserId,
  findValidInteractionsByUserId,
  restartStatusRezero,
}