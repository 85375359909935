<template>
  <table width="100%">
    <tr v-if="label">
      <td>
        <label
          class="required labelText"
        >
          {{ label }}
        </label>
      </td>
    </tr>
    <tr v-if="helpText">
      <td>
        <small class="help-text text-start mb-1">{{ helpText }}</small>
      </td>
    </tr>
    <tr>
      <td>
        <multiselect 
          v-model="value"
          :tag-placeholder="placeholderToTag" 
          :placeholder="placeholder" 
          :label="name" 
          track-by="code" 
          :options="optionsToSelect" 
          :multiple="multiple" 
          ref="internal-multiselect"
          :taggable="true" 
          class="shadow-sm"
          @tag="handleAddTag"
        ></multiselect>
      </td>
    </tr>
  </table>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  data() {
    return {
      value: [],
    }
  },
  watch: {
    initialValue(val) {
      this.value = val
    },
    value(val) {
      this.$emit('handleTagChange', val)
      // if (this.initialValue.code !== val.code) {
      // }
    }
  },
  components: {
    Multiselect
  },
  props: {
    initialValue: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    },
    optionsToSelect: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String, 
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    helpText: {
      type: String,
      default: ''
    },
    placeholderToTag: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleAddTag() {
      this.$emit('handleAddTagging', this.value)
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>

.multiselect__tags {
  color: grey;
  box-shadow: inset 0 0 0 1px grey !important;
  background-color: white;
  min-height: 40px !important;
}

.multiselect__tags.padd-r {
  padding-left: 20%;
}

.multiselect__element {
  background: #3b82ee;
  color: white;
  padding: 0;
  margin: 0;
}

.multiselect__option,
.multiselect__option--selected,
.multiselect__option--highlight {
  background: #3b82ee;
  color: white;
  opacity: .9;
}

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight::after {
  background: #1e71ee;

}

.multiselect__option::after, 
.multiselect__option--highlight {
  background: #3b82ee;
}

.multiselect__tag {
    background: #3b82ee
}
.multiselect__tag-icon::after {
  color: #fff;
}

.required::after {
  color: #6040b9;
  content: ' *';
  font-size: 16px;
}

.wrapper {
  width: 100%;
}

.labelText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: grey;
  float: left;
  border-radius: 4px;
}

</style>


