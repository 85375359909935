<template>
  <div class="container vh-100 d-flex align-items-center justify-content-center">
    <rd-loading />
  </div>
</template>

<script>
import RdLoading from '@/components/rd-components/rd-loading.vue';
import { createUserFromCode } from '@/services/user/user.api.js';
import { mapActions } from 'vuex';

export default {
  name: 'LoginWithToken',
  components: {
    RdLoading,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    ...mapActions([
      'loginByToken',
      'goToKeycloakLoginPage'
    ]),
    handleRouting() {
      // if(this.user.isAdmin || this.user.isUploader || this.user.isTrainer) {
      //   this.$router.push('/admin');
      // } else {
      //   this.$router.push('/files');
      // }
      // TODO: Calc route with permissions
      this.$router.push('/admin');
    },
  },
  async created() {
    try {
      let { code, authToken } = this.$route.query;
      if (!code && !authToken) throw new Error();
      let dataRes = null;
      if (authToken) {
        let { data } = await createUserFromCode(authToken);
        dataRes = data;
      } else {
        let { data } = await createUserFromCode(code);
        dataRes = data;
      }
      if (!dataRes?.main_auth_token) throw new Error();

      const { isAvailable } = await this.loginByToken({ mainAuthToken: dataRes.main_auth_token, refreshToken: dataRes.refresh_token });
      if (!isAvailable) {
        // go to keycloak logout url
        throw new Error('User not found');
      }
      this.handleRouting();
    } catch (_) {
      // go to keycloak logout url
      this.goToKeycloakLoginPage();
    }
  },
}
</script>