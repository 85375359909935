<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />
      <div class="container background-form rounded-3 my-3 p-3">
        <PageTitle
          :title="$t('SmartHire Status')"
          :description="$t('SmartHire Status Configuration')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Settings')"
          :grandChildBread="$t('SmartHire Status')"
        />
        <div class="row px-3 justify-content-between">
          <!-- Search bar -->
          <div class="col-12 col-lg-3">
            <rd-input
              class="w-100"
              :placeholder="$t('Search by name or description')" 
              type="text"
              @input="(e) => this.search = e.target.value"
            />
          </div>

          <!-- buttons -->
          <div class="col-12 col-lg-6 text-end">
            <button-component
              primary
              @handleClick="goToAddNewStatus"
            >
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Status" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Status') }}
            </button-component>
          </div>
        </div>

        <div v-if="screenLoading">
          <rd-loading /> 
        </div>
        <div v-else>
          <div
            v-if="status.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No status Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <div v-else class="row p-3 d-flex justify-content-start">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover table-custom-general" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col">{{ $t('Ban Flag (table)') }}</th>
                      <th scope="col">{{ $t('Status (table)') }}</th>
                      <th scope="col">{{ $t('Description (table)') }}</th>
                      <th scope="col">{{ $t('Enabled') }}</th>
                      <th scope="col" class="text-center">{{ $t('Actions (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(status, index) in statusDataTemp" :key="index"
                      class="text-start hoverable"
                      :class="{ 'fill-ods': index % 2 !== 0 }"
                    >
                      <td style="width: 10%; word-break: break-all;" scope="row" :header="$t('Icon (table)')" class="word-wrap centered">
                        <rd-input class="d-flex justify-content-center" type="checkbox" v-model="status.banUser" p0 :checked2="status.banUser" @change="calcStatusBan(status.id)" />
                      </td>
                      <td style="width: 30%; word-break: break-all;" scope="row" :header="$t('Status (table)')" class="word-wrap" > <div class="custom-table-text " style="top: 1.2rem; left: 0.5rem;">{{ status.statusName }}</div></td>
                      <td style="width: 20%; word-break: break-all;" scope="row" :header="$t('Description (table)')" > <div class="custom-table-text " style="top: 1.2rem; left: 0.5rem;">{{ status.description }}</div></td>
                      <td style="width: 10%; word-break: break-all;" scope="row" :header="$t('Enabled')" class="word-wrap centered"> 
                        <rd-input class="d-flex justify-content-center" type="checkbox" v-model="status.enabled" p0 :checked2="status.enabled" @change="calcStatusEnabled(status.id)" />
                      </td>
                      <td style="width: 30%; word-break: break-all;" :header="$t('Actions (table)')">
                        <div class="container">
                          <div class="row text-center">
                            <div class="col p-0 m-0">
                              <button-component
                                class="btn btn-label"
                                @handleClick="goToEditStatus($event, status.id)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Status" type="button" class="bi bi-pencil-square"></i>
                              </button-component>

                              <button-component
                                class="btn btn-label" 
                                @handleClick="showDeleteModal($event, status.id)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Delete Status" type="button" class='bi bi-trash-fill'></i>
                              </button-component>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            <rd-paginator :items="filteredStatus" @page-change="handlePageChange" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
  <rd-confirmation-modal
    v-if="showModalProp"
    modalTitle="Delete status permanently?"
    confirmButton="Delete"
    dismissButton="Dismiss"
    @confirmAction="deleteUser(statusToDelete)"
    @dismissAction="dismissAction"
  />
</template>
<script>
import RdConfirmationModal from '../../components/rd-components/rd-confirmation-modal.vue'
import rdFooter from '../../components/rd-components/rd-footer.vue'
import RdInput from '../../components/rd-components/rd-input.vue'
import RdLoading from '../../components/rd-components/rd-loading.vue'
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import RdPaginator from '../../components/rd-components/rd-paginator.vue'
import router from '../../router'
import { deleteSHStatus, getSHAvailableStatus, updateBanStatus, updateEnabledStatus } from '../../services/smarthire/smarthire.api'
import PageTitle from '../../components/rd-components/page-title.vue'
import ButtonComponent from '../../components/button-component.vue'

export default {
  components: { rdFooter, RdInput, RdNavbar, RdLoading, RdPaginator, RdConfirmationModal, PageTitle, ButtonComponent },
  data() {
    return {
      screenLoading: false,
      status: [],
      search: '',
      statusDataTemp: [],
      showModalProp: false,
      statusToDelete: null,
      radioModel: -1
    }
  },
  async mounted() {
    if(this.$store.state.permissions.length == 1) {
      this.$store.commit('setShowBackButton', false)
    }
    await this.getStatus()
  },
  computed: {
    filteredStatus() {
      return this.status.filter(({ statusName, description }) => (
        statusName.toLowerCase().includes(this.search.toLowerCase())
        || description && description.toLowerCase().includes(this.search.toLowerCase())
      ))
    },
  },
  methods: {
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td, index) => {
        if(index % 4 == 0) {
          td.style.width = '10%'
        } else {
          td.style.width = '30%'
        }
      })
    },
    async calcStatusBan(id) {
      try {
        const status = this.status.filter((status) => status.id == id);
        status[0].banUser = !status[0].banUser
        await updateBanStatus(id);
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Status updated.',
          type: 'success',
        })
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Failed!',
          message: 'Error updating Status.',
          type: 'Error',
        })
        console.log(error)
      }
      
    },
    async calcStatusEnabled(id) {
      try {
        const status = this.status.filter((status) => status.id == id);
        status[0].enabled = !status[0].enabled
        await updateEnabledStatus(id);
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Status updated.',
          type: 'success',
        })
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Failed!',
          message: 'Error updating Status.',
          type: 'Error',
        })
        console.log(error)
      }
      
    },
    async getStatus() {
      try {
        this.screenLoading = true
        const { data } = await getSHAvailableStatus();
        this.status = data
        const statusBanUser = this.status.filter((status) => {
          return status.banUser == true
        })
        if(statusBanUser.length) {
          this.radioModel = statusBanUser[0].id
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.screenLoading = false
        await this.$nextTick(() => {
          const elements = document.querySelectorAll("table th");
          elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
          })
          document.addEventListener("mousemove", (e) => {
            if (this.thElm) {
              this.lastWidth  = this.thElm.offsetWidth;
              this.thElm.style.width = this.startOffset + e.pageX + "px";
                document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                  if((internalIndex % 4) == Number(this.thElm.id)) {
                    td.style.width = this.startOffset + e.pageX + "px";
                  } else {
                    td.style.width = td.offsetWidth + "px";
                  }
                })
                this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
            }
          });

            document.addEventListener("mouseup", () => {
              this.thElm = undefined;
            });
        });
      }
    },
    dismissAction() {
      this.showModalProp = false
      this.statusToDelete = null
    },
    showDeleteModal(_, id) {
      this.showModalProp = true
      this.statusToDelete = id
    },
    async deleteUser(id) {
      try {
        await deleteSHStatus({ id })
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Status deleted.',
          type: 'success',
        })
        this.showModalProp = false
        this.statusToDelete = null
        await this.getStatus()
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Failed!',
          message: 'Error deleting Status.',
          type: 'Error',
        })
        console.log(error)
      }
    },
    handlePageChange(data) {
      this.statusDataTemp = data;
    },
    goToEditStatus(e, id) {
      e.preventDefault();
      router.push(`/admin/smarthire/status/edit/${id}`)
    },
    goToAddNewStatus() {
      router.push('/admin/smarthire/status/create');
    },
    showModal(e, id) {
      e.preventDefault();
      console.log(`delete status with ${id} flow`)
    },
  },
}
</script>
<style scoped>
.centered {
  text-align: center;
  vertical-align: middle;
}
</style>