<template>
  <div
    class="d-flex flex-column justify-content-between vh-100"
  >
    <div>
      <rd-navbar 
        :title="$t('Training Center (nav)')" 
        :showBackButton="false" 
      />
      <div v-if="windowWidth > 768" class="trainer-resources">
        <h3 class="text-start mb-0">{{ `${$t('Welcome')}, ${user.userFirstname} ${user.userLastname}` }}</h3>
      </div>

      <div 
        v-if="!landscapeScreen && windowWidth > 768"
        class="container custom-container-table my-3 py-3">
        <div class="mt-1 py-3">
          <div class="row g-4">
            <!-- Side menu -->
            <!-- Table -->
            <div class="col-lg-12 col-md-12 col-sm-12 h-100 ps-0 pt-2">
              <div v-if="filesLoading">
                <rd-loading />
              </div>
              <div v-else>

                <div
                  v-if="files.length === 0"
                  class="row p-3"
                >
                  <h4 
                  data-bs-toggle="tooltip" 
                  data-bs-placement="right" 
                  title="No files found"
                  type="button">
                    {{ $t('No data found') }} <i class="bi bi-exclamation-triangle"></i>
                  </h4>
                </div>
                <div v-else>
                  <div class="container d-flex mb-3">
                    <div class="col-2">
                      <pie-chart-component
                        :data="[
                          {
                            category: $t('Completed'),
                            value: calcGeneralCompletionPercent.completedFiles,
                          }, {
                            category: $t('Total'),
                            value: calcGeneralCompletionPercent.totalFiles,
                          }
                        ]"
                        :label="`${calcCompletionPercent(calcGeneralCompletionPercent.totalFiles, calcGeneralCompletionPercent.completedFiles)}%`"
                        height="300px"
                      />
                    </div>
                    <div class="col-10 mt-auto mb-auto">
                      <div class="row mb-3">
                        <div class="col-5">
                          <div class="d-flex justify-content-between">
                            <p class="m-0">
                              {{ $t('Lesson Completion') }}
                            </p>
                            <p class="m-0">
                              {{ calcCompletionPercent(filesCount, calcFilesSeenDownloaded.length) }}%
                            </p>
                          </div>
                          <div class="progress">
                            <div class="progress-bar bg-success" role="progressbar" :style="`width: ${calcCompletionPercent(filesCount, calcFilesSeenDownloaded.length)}%`" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5">
                          <div class="d-flex justify-content-between">
                            <p class="m-0">
                              {{ $t('Approved Exams') }}
                            </p>
                            <p class="m-0">
                              {{ examsApproved }} / {{ examsCount }}
                            </p>
                          </div>
                          <div class="progress">
                            <div class="progress-bar bg-success" role="progressbar" :style="`width: ${calcCompletionPercent(examsCount, examsApproved)}%`" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="container">
                    <div class="row justify-content-between">
                      <div class="col-12 col-md-6 col-lg-6">
                        <rd-input
                          type="text"
                          class="w-100"
                          :placeholder="$t('Search file by file name')" 
                          @input="(e) => search = e.target.value"
                        />
                      </div>
                      <div class="col-12 col-md-6 col-lg-6">
                        <div class="extra-content">
                          <button-component
                            class="mb-4"
                            primary
                            px="4"
                            py="2"
                            @handleClick="openHideAll">
                            {{ $t('Expand/Collapse All') }}
                          </button-component>
                          <h5>
                            <span>
                              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Categories completed" type="button" class="bi bi-check-circle text-success"></i> 
                              {{ calcCompletionPercent(filteredCategories.length, calcCategoriesCompleted) }}% {{ $t('Completed') }} ({{ calcCategoriesCompleted }}  / {{ filteredCategories.length }})
                            </span>
                          </h5>
                        </div>
                      </div>
                      <!-- <div class="col-12 col-md-6 col-lg-4">
                        <div v-if="!user.isAdmin" class="extra-content">
                          <h3>{{ $t('Exams') }}</h3>
                          <p>{{ $t('Total Exams') }} ({{ examsCount }}) <span> | <i class="bi bi-check-circle text-success"></i> {{ examsCount !== 0 ? Math.round((examsApproved / examsCount) * 100) : 0 }}% {{ $t('Approved') }} ({{ examsApproved }}  / {{ examsCount }})</span></p>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  
                  <div class="container mt-2">
                    <div v-for="(category, index) in filteredCategories" :key="index" class="accordion" :id="`accordionFlushExample-${category.categoryId}`">
                      <div  class="accordion-item">
                        <h2 class="accordion-header" :id="`flush-heading-${category.categoryId}`">
                          <button
                            class="accordion-button d-flex justify-content-between"
                            :class="{
                              'collapsed': !search,
                            }"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="`#flush-collapse-${category.categoryId}`"
                            aria-expanded="false"
                            :aria-controls="`flush-collapse-${category.categoryId}`"
                            :ref="`acordion-${category.categoryId}`"
                            @click="setSelectedCategory($event, category, index, `table_resize-${category.categoryId}`)"
                          >
                            <div class="flex-grow-1">{{ category.categoryName }}</div>
                            <div class="me-3">
                              <span>
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Files/Exams Completed" type="button" class="bi bi-check-circle text-success"></i>
                                {{ calcCompletionPercent(category.totalFiles, category.completedFiles) }}% {{ $t('Completed') }} ({{ category.completedFiles }} / {{ category.totalFiles }})
                              </span>
                            </div>
                          </button>
                        </h2>
                        <div
                          :id="`flush-collapse-${category.categoryId}`"
                          class="accordion-collapse collapse"
                          :class="{
                            'show': search,
                          }"
                          :aria-labelledby="`flush-heading-${category.categoryId}`"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body p-0">
                            <div v-if="!files.length">
                              <rd-loading />
                            </div>

                            <template v-else>
                              <div class="custom-sub">
                                <span
                                  class="chips"
                                  :class="[category.subcategorySelected === '' ? 'selected' : 'not-selected']"
                                  @click="setSelectedSubcategory(category, null, index)"
                                  v-if="category.subcategories.length > 1"
                                >
                                  {{ $t('All') }}
                                </span>
                                <span
                                  v-for="(sub, indexSubCategory) in category.subcategories" :key="indexSubCategory"
                                  class="chips"
                                  :class="[(Number(category.subcategorySelected) === Number(sub.subCategoryId) || category.subcategories.length == 1) ? 'selected' : 'not-selected']"
                                  @click="setSelectedSubcategory(category, sub, index)"
                                >
                                  {{ sub.subCategoryName }}
                                </span>
                              </div>
                              <div style="overflow-x: auto; width: 100%; max-width: 100%;">
                                <div class="w-100">
                                  <table class="table text-start table-hover table-custom-general" :ref="`table_resize-${category.categoryId}`">
                                    <thead id="table-headers" class="table-primary" @contextmenu.prevent="resize(`table_resize-${category.categoryId}`)">
                                      <tr>
                                        <th
                                          v-for="(header, i) of headers"
                                          :key="i"
                                          scope="col"
                                          :class="{ 'centered': (i == 0 || i == (headers.length -1)) }"
                                        >
                                          {{ $t(header) }}
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr
                                        v-for="(file, index_row) in copyCategory[index].files"
                                        :key="index_row"
                                        class="hoverable"
                                        @click="!file.loadingView && file.fileType !== 'exam' ? handleTimer($event, file) : {}"
                                        :class="{ 'fill-ods': index_row % 2 != 0 }"
                                      >

                                        <td style="width: 10%; word-break: break-all;" scope="row" class="centered col-min">
                                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="File Status" type="button" v-if="file.fileType !== 'exam'" :class="this.filesSeenDownloaded.includes(Number(file.fileId)) ? 'bi bi-check-circle text-success' : 'bi bi-circle'"></i>
                                          <!-- if exam is approved render a circle check else just a circle -->
                                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="Exam Status" type="button" v-if="file.fileType === 'exam'" :class="file.examApproved ? 'bi bi-check-circle text-success' : 'bi bi-circle'"></i>
                                        </td>
                                        <td style="width: 18%; word-break: break-all;" scope="row">
                                          <div class="custom-table-text">{{ file.fileName }}</div>
                                        </td>
                                        <td style="width: 18%; word-break: break-all;" scope="row">
                                          <div class="custom-table-text">{{ file.subcategory.subCategoryName }}</div>
                                        </td>
                                        <td style="width: 18%; word-break: break-all;" scope="row">
                                          <div class="custom-table-text">{{ file.fileLanguage }}</div>
                                        </td>
                                        <td style="width: 18%; word-break: break-all;" scope="row">
                                          <div class="custom-table-text">{{ file.fileType }}</div>
                                        </td>
                                        <td style="width: 18%; word-break: break-all;" scope="row">

                                          <div class="container p-0">
                                            
                                            <div class="d-flex justify-content-center">
                                              
                                              <div v-if="file.fileType !== 'exam'" class="">
                                                <button
                                                  class="
                                                    btn btn-label d-flex justify-content-center align-items-center position-relative
                                                  "
                                                  @click="!file.loadingView ? handleTimer($event, file) : {}"
                                                  data-bs-toggle="tooltip" data-bs-placement="right" title="See File" type="button"
                                                >
                                                  <rd-loading
                                                    v-if="file.loadingView"
                                                    class="position-absolute"
                                                    :width="1.8"
                                                    :height="1.8"
                                                  />
                                                  <i class="bi bi-eye-fill"></i>
                                                </button>
                                              </div>

                                              <div v-if=" file.fileType !== 'exam' && !isExternal(file.filePath)"  class="">
                                                <button
                                                  class="
                                                    btn btn-label d-flex justify-content-center align-items-center position-relative
                                                  "
                                                  
                                                  @click="!file.downloading ? handleFileDownload($event, file) : {}"
                                                >
                                                  <rd-loading
                                                    v-if="file.downloading"
                                                    class="position-absolute"
                                                    :width="1.8"
                                                    :height="1.8"
                                                  />
                                                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Download File" type="button" class="bi bi-download"></i>
                                                </button>
                                              </div>

                                              <div v-if="file.fileType === 'exam'"  class="d-flex">
                                                <button
                                                  class="
                                                    btn btn-label d-flex justify-content-center align-items-center position-relative
                                                  "
                                                  data-bs-toggle="tooltip" data-bs-placement="right" title="Go to exam attempts"
                                                  type="button"
                                                  @click="redirectExam(file)"
                                                >
                                                  <i class="bi bi-info-circle"></i>
                                                </button>
                                                <button
                                                  class="
                                                    btn btn-label d-flex justify-content-center align-items-center position-relative
                                                  "
                                                  data-bs-toggle="tooltip" data-bs-placement="right" title="Go to exam"
                                                  type="button"
                                                  @click="goToExam(file.fileId)"
                                                >
                                                  <img
                                                    src="/assets/lista.png"
                                                    style="width: 1rem;"
                                                    alt="go to exam"
                                                  >
                                                </button>
                                              </div>

                                            </div>

                                          </div>

                                        </td>
      
                                      </tr>
                                    </tbody>
                                  </table>
                                  <rd-paginator forceRender :items="category.files" @page-change="handlePageChange($event, index)" />
                                </div>
                              </div>
                            </template>

                          </div>
                        </div>
                      </div>
                      
                    </div>

                    <div v-if="filteredCategories.length === 0">
                      <p class="text-center">{{ $t('No results found') }}</p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="landscapeScreen || windowWidth <= 768"
        class="container"
      >
        <home-mobile />
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import RdFooter from '../components/rd-components/rd-footer.vue';
import RdNavbar from '../components/rd-components/rd-navbar.vue';
import { getAllCategoryWithFiles, getCategoryByProfile } from '@/services/categories/categories.api.js';
import { getFilesByUserId, getAllFiles } from '@/services/files/file.api.js';
import { fileView, fileDownload, externalLinkRedirect } from '@/utils/handleFile.js';
import { convertDateFormat } from '@/utils/convertFormat.js';
import { interactionTypes as getInteractionTypes } from '@/utils/constants.js';
import { createInteraction } from '@/services/interactions/interactions.api.js';
import { convertDateFormatFromString } from '../utils/convertFormat';
import RdLoading from '../components/rd-components/rd-loading.vue';
import { mapState } from 'vuex';
import HomeMobile from './mobile/Home-mobile.vue';
import RdPaginator from '@/components/rd-components/rd-paginator.vue'
import { findValidInteractionsByUserId } from '@/services/interactions/interactions.api.js'
import { userCanPerformExam } from '@/services/exams/exam.api';
import PieChartComponent from '@/components/amCharts/pie-chart-component.vue';
import ButtonComponent from '../components/button-component.vue';
import RdInput from '../components/rd-components/rd-input.vue';
import { debounce } from '../utils/utils';

export default {
  name: 'Home',
  data() {
    return {
      showFilter: true,
      convertDateFormat,
      categories: [],
      filteredSubCategories: [],
      filesFromDB: [],
      filters: {
        type: null,
        render: 'all',
        id: null
      },
      interactionTypes: {},
      tableSorting: {
        clickFileName: false,
        clickFileLanguage: false,
        clickCategoryName: false,
        clickSubCategoryName: false,
        clickFileType: false,
      },
      filesLoading: true,
      hasSpinner: true,
      headers: [
        'Status (table)',
        'File (table)',
        'Sub Category (table)',
        'Language (table)',
        'File Type (table)',
        'Actions (table)',
      ],
      counter: 0,
      timer: null,
      dataTemp: [],
      showFilterCategory: false,
      showFilterSubCategory: false,
      callback: null,
      categorySearch: '',
      selectedSubcategoryId: '',
      selectedCategory: null,
      filesSeenDownloaded: [],
      thElm: null,
      startOffset: null,
      lastWidth: 0,
      callbackResize: [],
      copyCategory: null,
      landscapeScreen: false,
      search: '',
      debouncedSearch: '',
    }
  },
  async mounted() {
    // Verificar la orientación inicial al cargar la página
    this.checkOrientation();
    // Agregar un event listener para detectar cambios en la orientación o del ancho de la pantalla
    window.addEventListener('orientationchange', this.checkOrientation);
    window.addEventListener('resize', this.checkOrientation);
    try {
      this.interactionTypes = await getInteractionTypes();
      await this.setFiles()
      await this.setCategories()
      const { data } = await findValidInteractionsByUserId(this.user.userId)
      this.filesSeenDownloaded = Array.from(data, value => Number(value.file_id))
      await this.$nextTick(() => { 
        const tables = Object.keys(this.$refs).filter(ref => ref.includes('table_resize'))
        tables.forEach((acc) => {
          const ref = this.$refs[acc]
          const thElements = ref[0].querySelectorAll("th");
          thElements.forEach((el) => {
            el.style.position = "relative";

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.thElm.parentNode.childNodes.forEach((node, index) => {
                if(!(index == 0 || index == (this.thElm.parentNode.childNodes.length - 1))) {
                  if(node == this.thElm) {
                    this.thElm.id = index - 1
                  }
                }
              })
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
          })
          this.callbackResize.push(((e) => {
            e.stopPropagation();
            if (this.thElm) {
                const elementParent = this.thElm.parentNode.parentNode.parentNode;
                this.lastWidth  = this.thElm.offsetWidth;
                this.thElm.style.width = this.startOffset + e.pageX + "px";
                const childNodes = this.thElm.parentNode.parentNode.parentNode.childNodes[1].childNodes
                childNodes.forEach((node, index) => {
                  if(!(index == 0 || index == (childNodes.length - 1))) {
                    node.childNodes.forEach((child, childIndex) => {
                      if(childIndex == Number(this.thElm.id)) {
                        child.style.width = this.startOffset + e.pageX + "px";
                      } else {
                        child.style.width = child.offsetWidth + "px";
                      }
                    })
                  }
                })
                elementParent.style.width = elementParent.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
            }
          }))
          window.addEventListener('mousemove', this.callbackResize[this.callbackResize.length - 1]);
        })
        document.addEventListener("mouseup", () => {
          this.thElm = undefined;
        });
      })
    } catch (error) {
      console.log(error);
    }
    this.callback = ((e) => {
      if (this.showFilterCategory && !this.$refs.modal_search_category?.contains(e.target)){
        this.showFilterCategory = false;
      }
      if (this.showFilterSubCategory && !this.$refs.modal_search_sub_category?.contains(e.target)){
        this.showFilterSubCategory = false;
      }
      // if (this.showSecondDiagnosticModal && !this.$refs.modal_second_diag?.contains(e.target) && !this.$refs.second_diag?.contains(e.target)){
      //   this.showSecondDiagnosticModal = false;
      // }
    });
    window.addEventListener('click', this.callback);
  },
  beforeUnmount(){
    window.removeEventListener('click', this.callback);
    this.callbackResize.forEach((call) => {

      window.removeEventListener('mousemove', call);
    })
  },
  components: {
    RdFooter,
    RdNavbar,
    RdLoading,
    HomeMobile,
    PieChartComponent,
    RdPaginator,
    ButtonComponent,
    RdInput,
  },
  watch: {
    search: debounce(function (newVal) {
      this.debouncedSearch = newVal
      this.openAll()
    }, 300),
    filteredCategories(new_data) {
      this.copyCategory = JSON.parse(JSON.stringify(new_data));
    },
  },
  computed: {
    files() {
      return this.filesFromDB
    },
    filteredCategories() {
      const categories = JSON.parse(JSON.stringify(this.categories));
      return (
        categories
          .filter(category => (
            category.files.some(file => file.fileName.toLowerCase().includes(this.debouncedSearch.toLowerCase()))
          ))
          .map(category => ({
            ...category,
            totalFiles: category.files.length,
            completedFiles: category.files.filter(file => {
              if (file.fileType === 'exam') {
                return !!file.last && Object.values(file.last).length
              }

              return this.filesSeenDownloaded.includes(Number(file.fileId))
            }).length,
            files: category.files
              .filter(file => 
                this.debouncedSearch !== '' 
                  ? file.fileName.toLowerCase().includes(this.debouncedSearch.toLowerCase())
                  : file
              )
              .filter(file => category.subcategorySelected ? Number(file.subCategoryId) == Number(category.subcategorySelected) : true)
              .sort((a, b) => a['fileName'].localeCompare(b['fileName']))
              .sort((a, b) => a.category.categoryName.localeCompare(b.category.categoryName)),
          }))
      )
    },
    calcGeneralCompletionPercent() {
      const result = this.filteredCategories.reduce((accumulator, currentValue) => {
        accumulator.totalFiles += currentValue.totalFiles;
        accumulator.completedFiles += currentValue.completedFiles;
        return accumulator;
      }, { totalFiles: 0, completedFiles: 0 });
      
      return result;
    },
    calcFilesSeenDownloaded() {
      const files = [];
      this.files.filter(f => f.fileType !== 'exam').forEach((file) => {
        if(this.filesSeenDownloaded.includes(Number(file.fileId))) {
          files.push(file)
        }
      })

      return files
    },
    calcCategoriesCompleted() {
      return this.filteredCategories.filter(category => category.totalFiles === category.completedFiles).length
    },
    filesCount() {
      return this.files.filter(f => f.fileType !== 'exam').length
    },
    examsApproved() {
      return this.files.filter(f => f.fileType === 'exam' && f.examApproved).length
    },
    examsCount() {
      return this.files.filter(f => f.fileType === 'exam').length
    },
    user(){
      return this.$store.getters.getUser;
    },
    ...mapState(['windowWidth'])
  },
  methods: {
    openAll() {
      const accordions = Object.keys(this.$refs).filter(ref => ref.includes('acordion'));
      accordions.forEach((acc) => {
        const ref = this.$refs[acc];
        if (ref && ref[0]) { // Verificación adicional para evitar errores
          if (ref[0].classList.contains("collapsed")) {
            ref[0].click();
          }
        }
      });
    },
    openHideAll() {
      const accordions = Object.keys(this.$refs).filter(ref => ref.includes('acordion'));
      const collapsed = [];
      const notCollapsed = [];
      
      accordions.forEach((acc) => {
        const ref = this.$refs[acc];
        if (ref && ref[0]) { // Verificación adicional para evitar errores
          if (ref[0].classList.contains("collapsed")) {
            collapsed.push(ref[0]);
          } else {
            notCollapsed.push(ref[0]);
          }
        }
      });

      if (notCollapsed.length > collapsed.length) {
        notCollapsed.forEach((ref) => {
          ref.click();
        });
      } else if (notCollapsed.length < collapsed.length) {
        collapsed.forEach((ref) => {
          ref.click();
        });
      } else {
        notCollapsed.forEach((ref) => {
          ref.click();
        });
      }
    },
    checkOrientation() {
      // Obtener el valor de la orientación actual
      const orientation = window.screen.orientation.angle;
      if (orientation === 90 || orientation === -90) {
        this.landscapeScreen = true;
      } else {
        this.landscapeScreen = false;
      }
    },
    redirectExam(file) {
      this.redirect(`/view/attempts/${file.fileId}`)
    },
    calcCompletionPercent(total, completed) {
      if (total === 0) return 0;
      const percent = Math.round((completed / total) * 100);

      return percent;
    },
    setSelectedSubcategory(category, sub, index) {
      if (!sub) {
        this.categories[index].subcategorySelected = '';
        return
      }
      this.categories[index].subcategorySelected = sub.subCategoryId;
    },
    async handlePageChange(data, index = 0) {
      this.copyCategory[index].files = data;
    },
    resize(ref) {
      this.$refs[ref][0].style.width = '100%'
      const childNodes = this.$refs[ref][0].childNodes[1].childNodes
      childNodes.forEach((node, index) => {
        if(!(index == 0 || index == (childNodes.length - 1))) {
          node.childNodes.forEach((child, childIndex) => {
            if(childIndex == 0) {
              child.style.width = '10%'
            } else {
              child.style.width = '18%'
            }
          })
        }
      })
      this.$refs[ref][0].childNodes[0].childNodes[0].childNodes.forEach((el) => {
        try {
          el.style.width = 'auto'
        } catch (e) {
          e
        }
      })
    },
    async setSelectedCategory(e, category) {
      e.stopPropagation();
      this.selectedCategory = category;
      this.selectedSubcategoryId = '';
    },
    async handleTimer(e, file) {
      e.stopPropagation();
      this.counter++;
      
      if (this.counter == 1) {
        this.timer = setTimeout(async () => {
          this.counter = 0;
          await this.handleFileView(e,file);
        }, 300);
        return;
      }
      clearTimeout(this.timer); 
      this.counter = 0;
    },
    sortTable(e, key) {
      e.stopPropagation();
      this.filesFromDB = JSON.parse(JSON.stringify(this.filesFromDB))
      if (key === 'fileName') {
        if (this.tableSorting.clickFileName ) {
          this.files.reverse()
          this.tableSorting.clickFileName  = false
          return
        }
        this.files.sort((a, b) => a[`${key}`].localeCompare(b[`${key}`])) 
        this.tableSorting.clickFileName  = true
        
        return
      }
      if (key === 'fileType') {
        if (this.tableSorting.clickFileType ) {
          this.files.reverse()
          this.tableSorting.clickFileType  = false
          return
        }
        this.files.sort((a, b) => a[`${key}`].localeCompare(b[`${key}`])) 
        this.tableSorting.clickFileType  = true
        return
      }

      if (key === 'fileLanguage') {
        if (this.tableSorting.clickFileLanguage ) {
          this.files.reverse()
          this.tableSorting.clickFileLanguage  = false
          return
        }
        this.files.sort((a, b) => a[`${key}`].localeCompare(b[`${key}`])) 
        this.tableSorting.clickFileLanguage  = true
        return
      }

      if (key === 'categoryName') {
        if (this.tableSorting.clickCategoryName ) {
          this.files.reverse()
          this.tableSorting.clickCategoryName  = false
          return
        }
        this.files.sort((a, b) => a.category[`${key}`].localeCompare(b.category[`${key}`])) 
        this.tableSorting.clickCategoryName  = true
        return
      }

      if (key === 'subCategoryName') {
        if (this.tableSorting.clickSubCategoryName ) {
          this.files.reverse()
          this.tableSorting.clickSubCategoryName  = false
          return
        }
        this.files.sort((a, b) => a.subcategory[`${key}`].localeCompare(b.subcategory[`${key}`])) 
        this.tableSorting.clickSubCategoryName  = true
        return
      }
    },
    isExternal(path) {
      return path === null || path === ''
    },
    async setFiles() {
      try {
        const {userId, isAdmin} = this.user
        
        this.filesLoading = true

        let formattedData = [];
        if (isAdmin) {
          const { data } = await getAllFiles({ exams: true });
          formattedData = data
        } else {
          const { data } = await getFilesByUserId({ userId, exams: true });
          formattedData = data
        }
        
        formattedData = formattedData
          .map(file => ({ ...file, downloading: false, loadingView: false }))
          .filter(file => !file.isTemp)
          .sort((a, b) => convertDateFormatFromString(b['fileLastUpdate']) - convertDateFormatFromString(a['fileLastUpdate']))


        this.filesFromDB = formattedData;

      } catch (error) {
        console.log(error)
      } finally {
        this.filesLoading = false
      }
    },
    async setCategories() {
      try {
        const {userId, isAdmin} = this.user

        let formattedCategories = []

        if (isAdmin) {
          const { data } = await getAllCategoryWithFiles();
          formattedCategories = data;
        } else {
          const { data } = await getCategoryByProfile(userId);
          formattedCategories = data;
        }

        const categoriesWithFiles = formattedCategories.map(c => ({
          ...c,
          files: this.files.filter(file => file.categoryId === c.categoryId),
          subcategorySelected: '',
        }))

        this.categories = categoriesWithFiles
      } catch (error) {
        console.log(error)
      }
    },
    goBack(){
      router.back();
    },
    redirect(route) {
      router.push(route);
    },
    async handleFileDownload(e, file) {
      e.stopPropagation()
      if(file.isTemp) return;
      this.hasSpinner = true;
      setTimeout(() => {
          if(this.hasSpinner) {
            this.$store.commit('setStateLoad', true)
          }
        }, 200);
      const store = this.$store.state;

      this.filesFromDB = this.filesFromDB.map(elem => { 
        if (parseInt(elem.fileId) === parseInt(file.fileId)) {
          file.downloading = true
        }
        return elem
      })

      try {
        if(!store.isMobile) {
          await fileDownload(file.fileId, file.fileName);
        } else {
          window.flutter_inappwebview.callHandler('comunicationname', JSON.stringify({
            token: store.token,
            id: file.fileId,
            name: file.fileName,
            route: `${process.env.VUE_APP_API_URL}/api/files/download/${file.fileId}`
          }));
        }
        this.hasSpinner = false;
        await createInteraction({
          userId: parseInt(this.user.userId),
          fileId: parseInt(file.fileId),
          interactionTypeId: this.interactionTypes['DOWNLOAD']
        });
        this.filesSeenDownloaded.push(Number(file.fileId))
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit('setStateLoad', false)
        this.filesFromDB = this.filesFromDB.map(elem => {
          if (elem.fileId === file.fileId) {
            file.downloading = false
          }
          return elem
        })
      }
    },

    async handleFileView(e, file) {
      e.stopPropagation();
      if(file.isTemp) return;
      this.hasSpinner = true;
      setTimeout(() => {
          if(this.hasSpinner) {
            this.$store.commit('setStateLoad', true)
          }
        }, 200);

      // if (file.filePath && file.filePath.endsWith('.mp4')) {
      //   this.$store.commit('setPlayingVideo', file)
      //   this.hasSpinner = false;
      //   return router.push(`/files/${file.uuid}`)
      // }

      this.filesFromDB = this.filesFromDB.map(elem => {
        if (elem.fileId === file.fileId) {
          file.loadingView = true
        }
        return elem
      })

      try {
        if(this.isExternal(file.filePath)) {
          externalLinkRedirect(file.fileExternalSource);
        } else {
          const data = await fileView(file.fileId);
          this.$store.dispatch('dispatchFileView', {data, file})
        }
        this.hasSpinner = false;
        if(this.isExternal(file.filePath) || (this.$store.state.modalType !== 'rd-not-valid-viewer' && !(this.$store.state.modalType === 'rd-pdf-viewer' && this.$store.state.windowWidth <= 768))) {
          await createInteraction({
            userId: parseInt(this.user.userId),
            fileId: parseInt(file.fileId),
            interactionTypeId: this.interactionTypes['VISUALIZE']
          });
          this.filesSeenDownloaded.push(Number(file.fileId))
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit('setStateLoad', false)
        this.filesFromDB = this.filesFromDB.map(elem => {
          if (elem.fileId === file.fileId) {
            file.loadingView = false
          }
          return elem
        })
      }
    },
    searchBySubcategory(subCategory) {
      this.filters = {
        type: 'subcategory',
        render: subCategory.subCategoryName,
        id: subCategory.subCategoryId
      }
      this.showFilterSubCategory = false;
    },
    resetFilters() {
      this.selectedSubcategoryId = '';
      this.categorySearch = '';
      this.selectedCategory = null;
      this.filteredSubCategories = []
      this.filters = {
        type: null,
        render: 'all',
        id: null
      }
    },
    async goToExam(examId) {
      try {
        const { userId } = this.$store.state.user;
        const { data } = await userCanPerformExam(examId, userId);
        const { allowed, message, approved } = data;
        if (!allowed) {
          if (message) {
            // notify the user that no more remainings are available
            this.$store.dispatch('dispatchNotification', {
              title: 'Notification',
              message: message,
              type: approved ? 'success' : 'error',
            })
          }
          return;
        }
        this.redirect(`/apply/exam/${examId}`)
      } catch (error) {
        console.log(error);
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.col-min {
  width: 1px;
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.container-custom {
  margin-left: unset;
  width: 100%;
  max-width: 100%;
}
.container-custom.container-custom {
  padding-left: 3rem;
  padding-right: 3rem;
}
.accordion-custom {
  padding: 0 1rem 0;
  .accordion-button-custom {
    background: #ffffff;
    color: #000000;
    padding: 0.8rem 0;
  }
  .custom-dot {
    width: 1rem;
    height: 1rem;
    background-color: #421cac;
    border-radius: 50%;
    position: relative;
    top: -1px;
  }
  .accordion-header {
    border-bottom: #000 solid 2px;
    display: flex;
    align-items: center;
  }
  
  .accordion-button-custom::after {
    display: none;
  } 
  .accordion-button-custom::before {
    margin: -0.2rem 0.5rem 0;
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
    transform: rotate(0.75turn);
  }
  .accordion-button-custom:not(.collapsed)::before {
    transform: rotate(0turn);
  }
}
.custom-sub {
  display: flex;
  gap: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.filter {
  font-weight: 900;
}

.hoverable:hover {
  cursor: pointer;
}
.custom-header {
  border: none;
  font-weight: 700 !important;
  font-size: 14px!important;
  color: #000000 !important;
}
.custom-background {
  background: #F3F3F4;
}
.custom-border-right {
  border-left: #DCDBDC solid 1px;
}
.custom-border {
  border-top: #DCDBDC solid 1px;
  border-bottom: #DCDBDC solid 1px;;
}
@media(min-width: 1400px) {
  .custom-container-table {
    max-width: 95%;
  }
}
.trainer-resources {
  background: #F3F3F3;
  padding: 1rem;
}
.trainer-resources h3 {
  font-weight: 700;
  font-size: 34px;
}
.filter {
  display: flex;
  justify-content: end;
  padding-right: 3rem;
  margin-top: 1rem;
  gap: 10px;
  font-weight: 700;
}
.filter .btn-filter {
  background: #cccccd;
  border: #509cfc solid 4px;
}
.filter-container {
  display: flex;
  flex-flow: column;
  margin-left: 2rem;
  margin-right: 2rem;
  width: calc(100% - 4rem);;
  border-radius: 5px;
  border: 1px solid #EAEAE9;
  /* filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5)); */
  margin-top: 1rem;
  padding: 20px 40px;
  align-items: flex-start;
}
.filter-container h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #363634;
}
.filter-container .filters {
  display: flex;
  gap: 1rem;
  align-items: end;
}
.filter-container .btn {
  height: fit-content;
  color: #472FA6;
  background: none;
  border-radius: none;
  font-weight: 700;
  font-size: 14px;
  margin-top: 0.5rem;
  padding-left: 0;
  border: none !important;
  padding-left: 6px;
}
.filter-container .btn:hover {
  border: none;
}
.filter-container .btn:focus-visible {
  outline: none;
  box-shadow: none;
  border: none;
}
.filter-container .btn:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
.filter-container .btn:active {
  outline: none;
  box-shadow: none;
  border: none;
  transition: none;
}
.filter-container .filters .filter-input-container {
  display: flex;
  flex-flow: column;
  align-items: start;
  gap: 0.5rem;
  position: relative;
}
.filter-container .filters .filter-input-container label {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #363634;
}
.filter-container .filters .filter-input-container .search  {
  background: #FFFFFF;
  border: 1px solid #353435;
  /* shadow_default */

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.filter-container .filters .filter-input-container .search-items  {
  position: absolute;
  top: 100%;
  z-index: var(--top-layer-z-index);
  background: #FFFFFF;
  border: 1px solid #353435;
  border-radius: 4px;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-flow: column;
  gap: 0.2rem;
  max-height: 100px;
  overflow-y: scroll;
}
.filter-container .filters .filter-input-container .search-items::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #efefef;
}

.filter-container .filters .filter-input-container .search-items::-webkit-scrollbar {
  width: 10px;
  background-color: #efefef;
}

.filter-container .filters .filter-input-container .search-items::-webkit-scrollbar-thumb {
  border-radius: 10px;
  max-height: 5px;
  background-color: #6c6d75;
}
.filter-container .filters .filter-input-container .search-items p  {
  text-align: left;
  text-indent: 1rem;
  margin: 0;
  cursor: pointer;
}
.filter-container .filters .filter-input-container .search-items p:hover  {
  background: #F3F3F4;
}
.filter-container .filters .filter-input-container .search-items p.p-selected  {
  background: #F3F3F4;
}
.filter-container .filters .filter-input-container .search i {
  margin-right: 1rem;
}
.filter-container .filters .filter-input-container .search input {
  text-indent: 10px;
  border: none;
}
.filter-container .filters .filter-input-container .search.inactive {
  background: #e8ecec !important;
}
.filter-container .filters .filter-input-container .search.inactive input {
  background: #e8ecec !important;
}
.filter-container .filters .filter-input-container .search input:focus{
  outline: none;
}
.extra-content {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}
.extra-content h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #363634;
}
.extra-content p {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #363634;
}
.extra-content p span {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #363634;
}

.custom-sub {
  display: flex;
  gap: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
.selected {
  color: #5e2dcc !important;
}
.not-selected {
  color: #787878;
}
.chips {
  display: flex;
  justify-content: center;
  min-width: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 1.5rem;
  background-color: #dcd3f4;
  border-radius: 1rem;
  cursor: pointer;

}
th {
  cursor: pointer;
}
</style>
