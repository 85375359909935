<template>
<div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Main')" />
      <div class="container py-2 my-2" v-if="assignment.length > 0">
        <div class="row">
            <div class="col-12 text-left" style="font-size: 0.8rem;">
                {{ $t('Active Assignments') }} > <span class="fw-bold">
                    {{ assignment[0].activityDate ? calcDay(assignment[0].activityDate) : '' }} {{ assignment[0].activityDate ? calcDate(assignment[0].activityDate) : '' }}
                </span>
            </div>
            <div class="accordion accordion-custom my-3 text-left" id="accordionParent">
                <div class="accordion-item p-3">
                    <span class="fw-bold">{{ `${assignment[0].sites[0].site}` }} - {{`${assignment[0].sites[0].checkIn ? assignment[0].sites[0].checkIn : ''}`}} - {{ `${assignment[0].description ? assignment[0].description : ''}` }}</span>
                    <br>
                    <p>
                        <i class='bi' :class="[`${colorClass}-clock`, `bi-${icon}`]"></i> <span class="text-capitalize" :class="[`${colorClass}-clock`]">{{ colorClass }}</span>
                    </p>
                    <hr>
                    <p> {{ $t(`Date: `) }} <span class="fw-bold">{{ assignment[0].activityDate ? calcDay(assignment[0].activityDate) : ''}} {{ assignment[0].activityDate ? calcDate(assignment[0].activityDate) : '' }}</span> </p>
                    <p> {{ $t(`Check-in time: `) }} <span class="fw-bold">{{ assignment[0].sites[0].checkIn ? assignment[0].sites[0].checkIn : '' }}</span></p>
                    <p> {{ $t(`Vote Center: `) }} <span class="fw-bold">{{ assignment[0].sites[0].site }}</span> </p>
                    <p> {{ $t(`Address: `) }} <span class="fw-bold">{{ assignment[0].sites[0].address }}</span> </p>
                    <p> {{ $t(`City: `) }} <span class="fw-bold">{{ assignment[0].sites[0].region.region ? assignment[0].sites[0].region.region : '' }}</span></p>
                    <p> {{ $t(`Room: `) }} <span class="fw-bold">{{ assignment[0].sites[0]['room'] ? assignment[0].sites[0]['room'] : '' }}</span></p>
                    <p> {{ $t(`Dispatcher: `) }} <span class="fw-bold">{{ ` ${assignment[0].team.dispatcher} - ${assignment[0].team.dispatcherPhone}` }}</span></p>
                    <div v-if="!!assignment[0].sites[0].sitesResources && assignment[0].sites[0].sitesResources.length" class="d-flex gap-2 my-4">
                        <div>
                            {{ $t('Resources: ') }}
                        </div>
                        <div class="d-flex flex-column">
                            <div v-for="({ id, name, path }) in assignment[0].sites[0].sitesResources" :key="id">
                                <a  class="btn btn-link text-decoration-none p-0 m-0" :href="path" target="_blank">
                                    {{ name }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex">
                        <span>
                            {{ $t('Team') }} {{
                              assignment[0].sites[0].statusTeam.status === 'Empty'
                              ? `(${$t('Empty')})`
                              : assignment[0].sites[0].statusTeam.status === 'Partial'
                              ? `(${$t('Partial')})`
                              : assignment[0].sites[0].statusTeam.status === 'Completed'
                              ? `(${ $t('Completed') })`
                              : ''
                            }}:
                        </span>
                        <div style="margin-left: 0.4rem; ">
                            <ul v-if="teamToRender.length > 0" class="list-group list-group-flush">
                                <li class="list-group-item pt-0 border-0" v-for="(user, index) in teamToRender" :key="index">
                                    <span class="fw-bold">
                                        <!-- TODO: Add check to function -->
                                        {{user.userFirstname}} {{user.userLastname}}
                                        {{
                                            user.function == 1
                                                ? $t('Lead') 
                                                : user.function == 2
                                                ? $t('Operator') 
                                                : ''
                                        }}
                                    </span>
                                    <br>
                                    <span class="fw-bold">
                                        {{ $t('Number') }}:
                                        {{user.phone}}
                                    </span>
                                </li>
                            </ul>

                            <div v-else>
                                {{ $t('No members yet') }}
                            </div>
                        </div>
                    </div>
                    <p v-if="assignment[0].sites[0].leadInfo1"> {{ `Additional information: ` }} <span class="fw-bold">{{ `${assignment[0].sites[0].leadInfo1}` }}</span></p>
                    <div v-if="assignment[0].accepted" class="buttons-custom justify-content-between justify-content-md-start">
                        <button @click="handleIcon('circle', 'Arrived')" class="custom-button-buttons btn btn-sm text-capitalize rounded border-2" :class="{
                              'btn-outline-primary': !icons.includes('circle'),
                              'btn-primary': icons.includes('circle'),
                            }">
                            {{ $t('Arrived') }}
                        </button>
                        <!-- <button @click="handleIcon('slash-circle', 'Started')" class="custom-button-buttons btn btn-sm text-capitalize rounded border-2" :class="{
                              'btn-outline-primary': !icons.includes('slash-circle'),
                              'btn-primary': icons.includes('slash-circle'),
                            }">
                            <i class="bi bi-slash-circle" /> {{ $t('Started') }}
                        </button> -->
                        <button @click="handleIcon('check-circle', 'Finished')" class="custom-button-buttons btn btn-sm text-capitalize rounded border-2" :class="{
                              'btn-outline-primary': !icons.includes('check-circle'),
                              'btn-primary': icons.includes('check-circle'),
                            }">
                             {{ $t('Finished') }}
                        </button>
                    </div>
                    <div v-else-if="assignment[0].accepted == null" class="buttons-custom justify-content-between justify-content-md-start">
                        <button @click="decline" class="custom1 btn text-capitalize rounded">
                                {{ $t('Decline') }}
                        </button>
                        <button @click="accept" class="custom2 btn text-capitalize rounded">
                                {{ $t('Accept') }}
                        </button>
                    </div>
                    <div v-if="colorClass == 'declined'">
                        <p> {{ `Reason for rejection: ` }} <span class="fw-bold">{{ assignment[0].observations }}</span> </p>
                    </div>
                </div>
            </div>
        </div>

    </div>

    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>

<script>
import {
    getMyAssignment,
    updateStateAssignment,
    aproveDeclineAssignment
} from '../../services/assignments/assignment.api'
import rdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import {APP_PERMISSIONS} from '@/utils/constants.js'

import {
    
    mapState
} from 'vuex'

export default {
    components: {rdFooter, RdNavbar},
    data() {
        return {
            linkRegExp: /^(ftp|http|https):\/\/[^ "]+$/,
            days: [
                this.$t('Sun'),
                this.$t('Mon'),
                this.$t('Tue'),
                this.$t('Wed'),
                this.$t('Thur'),
                this.$t('Fri'),
                this.$t('Sat')
            ],
            months: [
                this.$t('Jan'),
                this.$t('Feb'),
                this.$t('Mar'),
                this.$t('Apr'),
                this.$t('May'),
                this.$t('Jun'),
                this.$t('Jul'),
                this.$t('Aug'),
                this.$t('Sep'),
                this.$t('Oct'),
                this.$t('Nov'),
                this.$t('Dec'),
            ],
            assignment: [],
            render: false,
            colorClass: '',
            icon: '',
            icons: [],
            APP_PERMISSIONS
        }
    },
    async mounted() {
        const {
            data
        } = await getMyAssignment(this.$route.params.task)
        if (data) {
            this.render = true;
            this.assignment = data.assignments;
            this.colorClass = this.calcColorUnique(this.assignment[0])
            this.icon = this.calcIcon(this.assignment[0]);
        }

    },
    computed: {
        ...mapState(['user', 'permissions']),
        user() {
            return this.$store.state.user
        },
        teamToRender() {
            //TODO: Add check to function
            let result = []
            if (this.user.isAdmin) { // team to render when admins
                return this.assignment[0].team.users
            }
            if (this.user.function == 1) {
                return this.assignment[0].team.users.filter(() => {
                    return true
                })
            }
            if (this.user.function == 2) {
                return this.assignment[0].team.users.filter((user) => {
                    return user.function == 1 || user.userId == this.user.userId
                })
            }
            return result
        },
        assignmentHeadingId() {
            return `heading-${this.assignment.sites[0].site}-${this.assignment.sites[0].room}-${this.assignment.address}`.trim().replace(/[^a-z]/gi, '')
        },
        assignmentCollapseId() {
            return `collapse-${this.assignment.sites[0].site}-${this.assignment.sites[0].room}-${this.assignment.sites[0].address}`.trim().replace(/[^a-z]/gi, '')
        }
    },
    methods: {
        async accept() {
            await aproveDeclineAssignment({
                assignment_id: this.assignment[0].id,
                status: true,
            })
            this.assignment[0].accepted = true;
        },
        async decline() {
            this.$swal.fire({
                title: 'Decline Assignment',
                showCancelButton: true,
                confirmButtonText: 'Decline',
                cancelButtonText: 'Cancel',
                customClass: {
                    cancelButton: 'order-1 btn custom1 mx-2',
                    confirmButton: 'order-2 btn custom2'
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                buttonsStyling: false,
                showCloseButton: true,
                input: 'textarea',
                inputLabel: 'Observations',
            }).then(async (result) => {
                if (!result.value) {
                    this.$swal.fire({
                        title: 'Observations are mandatory',
                        showCloseButton: true,
                    });
                    return;
                }
                if(result.isConfirmed) {
                    await aproveDeclineAssignment({
                        assignment_id: this.assignment[0].id,
                        status: false,
                        observations: result.value
                    })
                    this.assignment[0].accepted = false;
                    this.icon = 'x';
                    this.colorClass = 'declined';
                    this.assignment[0].observations = result.value
                } 
            })
            
        },
        calcDay(date) {
            const newDate = new Date(date);
            return this.days[newDate.getDay()];
        },
        calcDate(date) {
            const dateParsed = date.split('/');
            const newDate = new Date(date);
            return `${this.months[newDate.getMonth()]}/${dateParsed[1]}/${dateParsed[2]}`
        },
        calcColorUnique(item) {
            if (item == undefined) return;
            const date_in_progress = item.date_in_progress;
            const date_arrived = item.date_arrived;
            const date_finished = item.date_finished;
            if(item.accepted == false ) {
                return 'declined';
            }
            if (date_in_progress == null && date_arrived == null && date_finished == null) {
                return 'pending';
            } else if (date_in_progress == null || date_arrived == null || date_finished == null) {
                if (!date_finished) {
                    const new_date_arrived = new Date(date_arrived);
                    const new_date_in_progress = new Date(date_in_progress);
                    if (new_date_arrived > new_date_in_progress) {
                        return 'arrived';
                    } else {
                       return 'in-progress';
                    }
                } else {
                    if (date_arrived) {
                        const new_date_arrived = new Date(date_arrived);
                        const new_date_finished = new Date(date_finished);
                        if (new_date_finished > new_date_arrived) {
                            return 'finished';
                        } else {
                            return 'arrived';
                        }
                    } else {
                        const new_date_in_progress = new Date(date_in_progress);
                        const new_date_finished = new Date(date_finished);
                        if (new_date_finished > new_date_in_progress) {
                            return 'finished';
                        } else {
                            return 'in-progress';
                        }
                    }
                }
            } else {
                const new_date_arrived = new Date(date_arrived);
                const new_date_finished = new Date(date_finished);
                const new_date_in_progress = new Date(date_in_progress);
                if (new_date_finished > new_date_in_progress && new_date_finished > new_date_arrived) {
                    return 'finished';
                } else {
                    if (new_date_arrived > new_date_in_progress) {
                        return 'arrived';
                    } else {
                        return 'in-progress';
                    }
                }
            }
        },
        calcIcon(item) {
            if (item == undefined) return;
            const date_in_progress = item.date_in_progress;
            const date_arrived = item.date_arrived;
            const date_finished = item.date_finished;
            if(item.accepted == false ) {
                return 'x';
            }
            if (date_in_progress == null && date_arrived == null && date_finished == null) {
                return 'clock';
            } else if (date_in_progress == null || date_arrived == null || date_finished == null) {
                if (!date_finished) {
                    if (!date_arrived) {
                        this.icons.push('slash-circle');
                        return 'slash-circle';
                    }
                    if (!date_in_progress) {
                        this.icons.push('circle');
                        return 'circle';
                    }
                    const new_date_arrived = new Date(date_arrived);
                    const new_date_in_progress = new Date(date_in_progress);
                    if (new_date_arrived > new_date_in_progress) {
                        this.icons.push('circle');
                        this.icons.push('slash-circle');
                        return 'circle';
                    } else {
                        this.icons.push('slash-circle');
                        this.icons.push('circle');
                        return 'slash-circle';
                    }
                } else {
                    if (date_arrived) {
                        const new_date_arrived = new Date(date_arrived);
                        const new_date_finished = new Date(date_finished);
                        if (new_date_finished > new_date_arrived) {
                            this.icons.push('check-circle');
                            this.icons.push('circle');
                            return 'check-circle';
                        } else {
                            this.icons.push('circle');
                            this.icons.push('check-circle');
                            return 'circle';
                        }
                    } else if(date_in_progress) {
                        const new_date_in_progress = new Date(date_in_progress);
                        const new_date_finished = new Date(date_finished);
                        if (new_date_finished > new_date_in_progress) {
                            this.icons.push('check-circle');
                            this.icons.push('slash-circle');
                            return 'check-circle';
                        } else {
                            this.icons.push('slash-circle');
                            this.icons.push('check-circle');
                            return 'slash-circle';
                        }
                    } else {
                        this.icons.push('check-circle');
                        return 'check-circle';
                    }
                }
            } else {
                const new_date_arrived = new Date(date_arrived);
                const new_date_finished = new Date(date_finished);
                const new_date_in_progress = new Date(date_in_progress);
                this.icons.push('check-circle');
                this.icons.push('circle');
                this.icons.push('slash-circle');
                if (new_date_finished > new_date_in_progress && new_date_finished > new_date_arrived) {
                    return 'check-circle';
                } else {
                    if (new_date_arrived > new_date_in_progress) {
                        return 'circle';
                    } else {
                        return 'slash-circle';
                    }
                }
            }
        },
        async handleIcon(icon, state) {
            const btnChecked = this.icons.includes(icon)
            this.$swal.fire({
                title: btnChecked ? `Are you sure you want to un-mark this as "${state}"?` : `Are you sure you want to mark this as "${state}"?`,
                showCancelButton: true,
                confirmButtonText: btnChecked ? 'Un-Mark' : `${state}`,
                denyButtonText: 'Dismiss',
                customClass: {
                    confirmButton: 'btn btn-primary mx-2',
                    cancelButton: 'btn btn-outline-primary'
                },
                buttonsStyling: false
            }).then( async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    if (btnChecked) {
                        let status = 0;
                        switch (icon) {
                            case 'check-circle':
                                status = 3;
                                this.assignment[0].date_finished = null;
                                break;
                            case 'circle':
                                status = 1;
                                this.assignment[0].date_arrived = null;
                                break;
                            case 'slash-circle':
                                status = 2;
                                this.assignment[0].date_in_progress = null;
                                break;

                            default:
                                break;
                        }

                        await updateStateAssignment({
                            current_status: status,
                            assignment_id: this.assignment[0].id,
                        })

                        const index = this.icons.indexOf(icon);
                        this.icons.splice(index, 1);
                    } else {
                        let status = 0;
                        switch (icon) {
                            case 'check-circle':
                                status = 3;
                                break;
                            case 'circle':
                                status = 1;
                                break;
                            case 'slash-circle':
                                status = 2;
                                break;

                            default:
                                break;
                        }
                        const {
                            data
                        } = await updateStateAssignment({
                            current_status: status,
                            assignment_id: this.assignment[0].id,
                        })
                        switch (icon) {
                            case 'check-circle':
                                status = 3;
                                this.assignment[0].date_finished = data.hora[0].hora;
                                break;
                            case 'circle':
                                status = 1;
                                this.assignment[0].date_arrived = data.hora[0].hora;
                                break;
                            case 'slash-circle':
                                status = 2;
                                this.assignment[0].date_in_progress = data.hora[0].hora;
                                break;

                            default:
                                break;
                        }
                        
                        this.icons.push(icon);
                    }
                    const date_in_progress = this.assignment[0].date_in_progress;
                    const date_arrived = this.assignment[0].date_arrived;
                    const date_finished = this.assignment[0].date_finished;
                    if (date_in_progress == null && date_arrived == null && date_finished == null) {
                        this.icon = 'clock';
                        this.colorClass = 'pending';
                    } else if (date_in_progress == null || date_arrived == null || date_finished == null) {
                        if (!date_finished) {
                            if(!date_in_progress) {
                                this.icon = 'circle';
                                this.colorClass = 'arrived'
                                return
                            }
                            const new_date_arrived = new Date(date_arrived);
                            const new_date_in_progress = new Date(date_in_progress);
                            if (new_date_arrived > new_date_in_progress) {
                                this.icon = 'circle';
                                this.colorClass = 'arrived'
                            } else {
                                this.icon = 'slash-circle';
                                this.colorClass = 'in-progress'
                            }
                        } else {
                            if (date_arrived) {
                                const new_date_arrived = new Date(date_arrived);
                                const new_date_finished = new Date(date_finished);
                                if (new_date_finished > new_date_arrived) {
                                    this.icon = 'check-circle';
                                    this.colorClass = 'finished';
                                } else {
                                    this.icon = 'circle';
                                    this.colorClass = 'arrived';
                                }
                            } else {
                                if(!date_in_progress) {
                                    this.icon = 'check-circle';
                                    this.colorClass = 'finished';
                                    return;
                                }
                                const new_date_in_progress = new Date(date_in_progress);
                                const new_date_finished = new Date(date_finished);
                                if (new_date_finished > new_date_in_progress) {
                                    this.icon = 'check-circle';
                                    this.colorClass = 'finished';
                                } else {
                                    this.icon = 'slash-circle';
                                    this.colorClass = 'in-progress';
                                }
                            }
                        }
                    } else {
                        const new_date_arrived = new Date(date_arrived);
                        const new_date_finished = new Date(date_finished);
                        const new_date_in_progress = new Date(date_in_progress);
                        if (new_date_finished > new_date_in_progress && new_date_finished > new_date_arrived) {
                            this.icon = 'check-circle';
                            this.colorClass = 'finished';
                        } else {
                            this.colorClass = 'in-progress';
                            if (new_date_arrived > new_date_in_progress) {
                                this.icon = 'circle';
                                this.colorClass = 'arrived';
                            } else {
                                this.icon = 'slash-circle';
                                this.colorClass = 'in-progress';
                            }
                        }
                    }
                } 
            })

        }
    },

}
</script>

<style lang="scss">
.accordion-custom {
    .accordion-item {
        strong {
            font-size: 0.8rem;
        }
    }
}

p {
    margin-bottom: 0.7rem;
}

.in-progress {
    color: #007bff !important;
}

.finished {
    color: #28a745 !important;
}

.pending {
    color: #8d6a24 !important;
}

.in-progress-clock {
    color: #007bff !important;
}
.arrived-clock {
    color: grey !important;
}

.finished-clock {
    color: #28a745 !important;
}

.pending-clock {
    color: #ffc107 !important;
}
.declined-clock {
    color: #f44336 !important;
}

.buttons-custom {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

    .custom-button-buttons {
        display: flex;
        height: fit-content;
        gap: 0.2rem;
    }
}
</style>

<style lang="scss">
/* Sweet alert styles */
.swal2-title {
    font-family: Roboto !important;
    font-weight: lighter;
}
.custom1 {
    --bs-btn-color: #4429BD !important;
    --bs-btn-border-color: #4429BD !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: #4429BD !important;
    --bs-btn-hover-border-color: #4429BD !important;
    --bs-btn-focus-shadow-rgb: 13,110,253 !important;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: #4429BD !important;
    --bs-btn-active-border-color: #4429BD !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: #4429BD !important;
    --bs-btn-disabled-bg: transparent !important;
    --bs-btn-disabled-border-color: #4429BD !important;
    --bs-gradient: none !important;
    font-weight: 600 !important;
    border-width: 3px !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}
.custom2 {
    --bs-btn-color: #fff !important;
    --bs-btn-bg: #4429BD !important;
    --bs-btn-border-color: #4429BD !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: #4429BD !important;
    --bs-btn-hover-border-color: #4429BD !important;
    --bs-btn-focus-shadow-rgb: 49,132,253 !important;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: #4429BD !important;
    --bs-btn-active-border-color: #4429BD !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: #fff !important;
    --bs-btn-disabled-bg: #4429BD !important;
    --bs-btn-disabled-border-color: #4429BD !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}
.swal2-textarea {
    resize: none;
    border-radius: 1rem;
}
.swal2-title {
    text-align: left;
}
.swal2-input-label {
    text-align: left;
    margin: 1em 2em 3px;
    justify-content: start;
}
</style>