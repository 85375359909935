<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />
      <div class="container background-form rounded-3 my-3 p-3">
        <PageTitle
          :title="$t('Language')"
          :description="$t('Set main language for the application')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Settings')"
          :grandChildBread="$t('Language')"
        />
        <div class="row px-3 justify-content-end align-items-center">
          <div class="col-12 col-lg-6 text-end">
            <button-component
              primary-outline
              @handleClick="downloadTemplate"
            >
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Download Template" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Download Template') }}
            </button-component>
            <button-component
              primary
              @handleClick="goToAddNewLanguage"
            >
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Language" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Language') }}
            </button-component>
          </div>
        </div>

        <div v-if="languagesLoading">
          <rd-loading />
        </div>

        <div v-else>

          <div
            v-if="languages.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No language Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>
          <div v-else class="row p-3 d-flex justify-content-start">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover table-custom-general" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col">{{ $t('Language (table)') }}</th>
                      <th scope="col" class="text-center">{{ $t('Actions (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-start">
                    <tr
                      v-for="(language, index) in languages" :key="index"
                      class="hoverable"
                      :class="{ 'fill-ods': index % 2 !== 0 }"
                    >
                      <td style="width: 50%; word-break: break-all;" :header="$t('Language (table)')" scope="row"><div class="custom-table-text">{{ language.languageName }} ({{language.languageAbbreviation}})</div></td>
                      <td style="width: 50%; word-break: break-all;" :header="$t('Actions (table)')" class="centered">
                        <button-component
                          class="btn btn-label" 
                          @handleClick="goToEditLanguage(language.languageId)"
                        >
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Language" type="button" class="bi bi-pencil-square"></i>
                        </button-component>
                        <button-component
                          v-if="languages.length > 1" 
                          class="btn btn-label" 
                          @handleClick="showModal($event, language)"
                        >
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="Delete Language" type="button" class='bi bi-trash-fill'></i>
                        </button-component>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>

    <rd-confirmation-modal
      v-if="showModalProp"
      :modalTitle="$t('Delete language permanently?')" 
      :confirmButton="$t('Delete')"
      :dismissButton="$t('Dismiss')"
      @confirmAction="deleteLanguage(languageToDelete)"
      @dismissAction="dismissAction"
    />
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue'
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import router from '@/router'
import { getAllLanguage, deleteLanguageById } from '@/services/languages/language.api.js'
import RdConfirmationModal from '../../components/rd-components/rd-confirmation-modal.vue'
import RdLoading from '../../components/rd-components/rd-loading.vue'
import i18nJSON from '../../../i18n_template.json';
import PageTitle from '../../components/rd-components/page-title.vue'
import ButtonComponent from '../../components/button-component.vue'

export default {
  data() {
    return {
      languages: [],
      languagesLoading: true,
      showModalProp: false,
      languageToDelete: {},
      thElm: null,
      startOffset: null,
      lastWidth: 0,
    }
  },
  async mounted() {
    if(this.$store.state.permissions.length == 1) {
      this.$store.commit('setShowBackButton', false)
    }
    await this.setLanguages()
  },
  components: {
    RdFooter,
    RdNavbar,
    RdConfirmationModal,
    RdLoading,
    PageTitle,
    ButtonComponent
  },
  methods: {
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '50%'
      })
    },
    async setLanguages() {
      try {
        this.languagesLoading = true
        const { data } = await getAllLanguage()
        this.languages = data
      } catch (error) {
        console.log(error)
      } finally {
        this.languagesLoading = false
        await this.$nextTick(() => {
           const elements = document.querySelectorAll("table th");
           elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
           })
           document.addEventListener("mousemove", (e) => {
             if (this.thElm) {
               this.lastWidth  = this.thElm.offsetWidth;
               this.thElm.style.width = this.startOffset + e.pageX + "px";
                document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                  if((internalIndex % 2) == Number(this.thElm.id)) {
                    td.style.width = this.startOffset + e.pageX + "px";
                  } else {
                    td.style.width = td.offsetWidth + "px";
                  }
                })
                this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
             }
           });

            document.addEventListener("mouseup", () => {
              this.thElm = undefined;
            });
        });
      }
    },
    goToAddNewLanguage(){
      router.push(`/admin/settings/language/add`)
    },
    async downloadTemplate() {
      try {
        if (Object.values(i18nJSON).length === 0) {
          this.$store.dispatch('dispatchNotification', {
            title: 'Error!',
            message: 'JSON file has no existing data, please consult with your administrator.',
            type: 'error',
          })
          return
        }
        const blob = new Blob([JSON.stringify(i18nJSON, null, 2)], { type: 'application/json' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'i18n.json')
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        console.log(error)
      }
    },
    goToEditLanguage(id){
      router.push(`/admin/settings/language/edit/${id}`)
    },
    showModal(e, language){
      this.showModalProp = true
      this.languageToDelete = language
    },
    dismissAction() {
      this.languageToDelete = {}
      this.showModalProp = false
    },
    async deleteLanguage({languageId}) {
      try {
        await deleteLanguageById(languageId)
        await this.setLanguages()
        this.$store.dispatch('getI18nData', this.$i18n);
      } catch (error) {
        console.log(error)
      } finally {
        this.showModalProp = false
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.hoverable:hover {
  cursor: pointer;
}
.hoverable:hover {
  cursor: pointer;
}
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>