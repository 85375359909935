<template>
  <div class="accordion-item p-0">
    <h2 
      :id="assignmentHeadingId"
      class="accordion-header" 
    >
      <button 
        class="accordion-button" 
        type="button" 
        data-bs-toggle="collapse" 
        :data-bs-target="`#${assignmentCollapseId}`" 
        :aria-controls="assignmentCollapseId"
        aria-expanded="true"
        ref="open-accordion-button"
      >
        <span class="fw-bold" style="width: 100%; display: flex; align-items: center;">
          <span class="fw-normal">
            {{ `${assignment.sites[0].site}` }}
            <span class="fw-bold">
              {{
                ` - ${assignment.sites[0].checkIn ? assignment.sites[0].checkIn : ''} - ${assignment.description ?
                  assignment.description : ''}`
              }}
            </span>
          </span>
          <span  class="mx-1" :class="teamStatus.color">
            <i data-bs-toggle="tooltip" data-bs-placement="right" :title="this.assignment.sites[0].statusTeam.status" type="button" :class="`bi bi-${teamStatus.icon}`"></i>
          </span>
        </span>
        <span v-if="permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.EDIT_ASSIGNMENTS) || user.isAdmin" class="mx-1" :class="assignmentStateTaskStatus">
          <i data-bs-toggle="tooltip" data-bs-placement="right" :title="assignmentStateTaskStatusName" :class="'bi bi-circle-fill'"></i>
        </span>
      </button>
    </h2>
    <div 
      ref="accordion-content" 
      class="accordion-collapse collapse"
      :id="assignmentCollapseId" 
      :aria-labelledby="assignmentHeadingId"
      :data-bs-parent="'#x-rol-accordions'"
    >
      <div 
      style="position: relative;"
        class="accordion-body p-0 px-1 mx-auto"
        :class="{
          'w-75': !mobileDisplay,
          'w-100': mobileDisplay,
        }"
      >
        <ul class="list-group my-3 shadow">
          <li class="list-group-item">
            <div class="container px-0">
              <div class="row border-bottom py-2">
                <div 
                  class="fw-bold"
                  :class="{
                    'col-6': mobileDisplay,
                    'col-3': !mobileDisplay
                  }"
                >
                  {{ $t('Assignment Date') }}
                </div>
                <div class="col border-start">
                  {{ assignment['activityDate'] ? assignment['activityDate'] : '' }}
                </div>
              </div>
              <div class="row border-bottom py-2">
                <div 
                  class="col fw-bold"
                  :class="{
                    'col-6': mobileDisplay,
                    'col-3': !mobileDisplay
                  }"
                >
                  {{ $t('Checking Time') }}
                </div>
                <div class="col border-start">
                  {{ assignment.sites[0]['checkIn'] ? assignment.sites[0]['checkIn'] : '' }}
                </div>
              </div>
              <div class="row border-bottom py-2">
                <div 
                  class="col fw-bold"
                  :class="{
                    'col-6': mobileDisplay,
                    'col-3': !mobileDisplay
                  }"
                >
                  {{ $t('Vote Center') }}
                </div>
                <div class="col border-start" >
                  {{ assignment.sites[0].site ? assignment.sites[0].site : '' }}
                </div>
              </div>
              <div class="row border-bottom py-2">
                <div 
                  class="col fw-bold"
                  :class="{
                    'col-6': mobileDisplay,
                    'col-3': !mobileDisplay
                  }"
                >
                  {{ $t('Address') }}
                </div>
                <div class="col border-start">
                  {{ assignment.sites[0]['address'] ? assignment.sites[0]['address'] : '' }}
                </div>
              </div>
              <div class="row border-bottom py-2">
                <div 
                  class="col fw-bold"
                  :class="{
                    'col-6': mobileDisplay,
                    'col-3': !mobileDisplay
                  }"
                >
                  {{ $t('City') }}
                </div>
                <div class="col border-start">
                  {{ assignment.sites[0].region.region ? assignment.sites[0].region.region : '' }}
                </div>
              </div>
              <div class="row border-bottom py-2">
                <div 
                  class="col fw-bold"
                  :class="{
                    'col-6': mobileDisplay,
                    'col-3': !mobileDisplay
                  }"
                >
                  {{ $t('Room') }}
                </div>
                <div class="col border-start">
                  {{ assignment.sites[0]['room'] ? assignment.sites[0]['room'] : '' }}
                </div>
              </div>
              <div class="row border-bottom py-2">
                <div 
                  class="col fw-bold"
                  :class="{
                    'col-6': mobileDisplay,
                    'col-3': !mobileDisplay
                  }"
                >
                  {{ $t('Dispatcher') }}
                </div>
                <div class="col border-start">
                  {{ 
                    `${assignment.team.dispatcher} - ${assignment.team.dispatcherPhone} `
                  }}
                </div>
              </div>
              <div
                v-if="!!assignment.sites[0].sitesResources && assignment.sites[0].sitesResources.length"
                class="row border-bottom py-2"
              >
                <div 
                  class="col fw-bold"
                  :class="{
                    'col-6': mobileDisplay,
                    'col-3': !mobileDisplay
                  }"
                >
                  {{ $t('Resources') }}
                </div>
                <div class="col border-start">
                  <div
                    v-for="({ id, path, name }) in assignment.sites[0].sitesResources" :key="id"
                  >
                    <a
                      class="btn btn-link text-decoration-none p-0 m-0"
                      :href="path"
                      target="_blank"
                    > 
                      {{ name }}
                    </a>
                  </div>
                </div>
              </div>
              <!-- <div class="row border-bottom py-2"> -->
              <div class="row py-2">
                <div 
                  class="col fw-bold"
                  :class="{
                    'col-6': mobileDisplay,
                    'col-3': !mobileDisplay
                  }"
                >
                
                  {{ $t('Team') }} <span class="fw-light">
                    {{
                      assignment.sites[0].statusTeam.status === 'Empty'
                      ? `(${$t('Empty')})`
                      : assignment.sites[0].statusTeam.status === 'Partial'
                      ? `(${$t('Partial')})`
                      : assignment.sites[0].statusTeam.status === 'Completed'
                      ? `(${ $t('Completed') })`
                      : `(${ $t('Overcrowding') })`
                    }}
                  </span>
                </div>
                <div class="col border-start">
                  
                  <ul 
                    v-if="teamToRender.length > 0"
                    class="list-group list-group-flush"
                  >
                    <li class="list-group-item fs-6"
                      v-for="(user, index) in teamToRender" :key="index"
                    >
                      {{user.userFirstname}} {{user.userLastname}} 
                      <span class="fw-bold">
                        ({{ 
                          user.function == 1 
                          ? $t('Lead') 
                          : user.function == 2
                          ? $t('Operator') 
                          : ''
                        }}) - {{ user.state_task.length == 0? 'Pending' : (user.state_task[0]['accepted'] ? 'Accepted' : 'Rejected') }}
                      </span> 
                      <br> 
                      {{ $t('Contact number') }}: 
                      <span class="fw-bold">
                        {{user.phone}}
                      </span>
                      <br>
                      <span v-if="user.state_task && user.state_task.length">
                        {{ `Reason for rejection: ` }} <span class="fw-bold">{{ user.state_task[0].observations }}</span> 
                      </span>
                    </li>
                  </ul>

                  <div v-else>
                    {{ $t('No members yet') }}
                  </div>
                  
                </div>
              </div>
              
            </div>
          </li>
        </ul>
        <!-- <div class="dropdown" style="position: absolute;right: 0.25rem;top: 0.25rem; ">
            <a  class="btn btn-link dropdown-toggle" role="button" id="link-elipsis" data-bs-toggle="dropdown" aria-expanded="false" style="color:black;">
                <i  class="bi bi-three-dots-vertical text-decoration-none"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-end custom-dropdown custom-dropdown-responsive" aria-labelledby="link-elipsis">
                <li>
                    <button @click="$router.push(`/admin/edit/assignment/${assignment.id}`)" class="btn btn-head text-decoration-none dropdown-item">
                        {{ $t('Edit') }}
                    </button>
                </li>
            </ul>
        </div> -->
        <div class="container mb-5"
            v-if="permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.EDIT_ASSIGNMENTS) || user.isAdmin"
          >
          <div class="row justify-content-end">
            <div class="col-12 col-lg-4 text-end">
              <button-component
                @handleClick="$router.push(`/admin/edit/assignment/${assignment.id}`)"
                primary
                px="4"
                py="2"
              >
                {{ $t('Edit') }}
              </button-component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import {  mapState } from 'vuex'
import { updateStateAssignment } from '../../services/assignments/assignment.api'
import { TEAM_STATUS_COLOR } from '../../utils/constants'
import {APP_PERMISSIONS} from '@/utils/constants.js'
import buttonComponent from '../../components/button-component.vue'
export default {
  components: { buttonComponent },
  props: {
    assignment: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      linkRegExp: /^(ftp|http|https):\/\/[^ "]+$/,
      currenState: -1,
      APP_PERMISSIONS
    }
  },
  mounted() {
   
    this.currenState = this.assignment.stateTask;
  },
  computed: {
    ...mapState(['user', 'mobileDisplay', 'permissions']),
    user() {
      return this.$store.state.user
    },
    teamToRender() {
      return this.assignment.team.users
    },
    assignmentHeadingId() {
      return `heading-${this.assignment.sites[0].site}-${this.assignment.sites[0].room}-${this.assignment.address}`.trim().replace(/[^a-z]/gi, '')
    },
    assignmentCollapseId() {
      return `collapse-${this.assignment.sites[0].site}-${this.assignment.sites[0].room}-${this.assignment.sites[0].address}`.trim().replace(/[^a-z]/gi, '')
    },
    teamStatus() {
      return TEAM_STATUS_COLOR[this.assignment.sites[0].statusTeam.status];
    },
    assignmentStateTaskStatus(){
      const {users} = this.assignment.team
      if(users.every((user) => user.state_task.length > 0 && user.state_task[0].accepted)) {
        return 'text-success';
      }
      if(users.every((user) => user.state_task.length == 0)) {
        return 'text-danger';
      }
      if(users.some((user) => user.state_task.length > 0 && !(user.state_task[0].accepted))) {
        return 'text-danger';
      }
      return 'text-warning';
    },
    assignmentStateTaskStatusName() {
      const {users} = this.assignment.team
      if(users.every((user) => user.state_task.length > 0 && user.state_task[0].accepted)) {
        return 'Completed';
      }
      if(users.every((user) => user.state_task.length == 0)) {
        return 'At least one declined';
      }
      if(users.some((user) => user.state_task.length > 0 && !(user.state_task[0].accepted))) {
        return 'At least one declined';
      }
      return 'In progress';
    }
  },
  methods: {
    async updateState(value) {
      await updateStateAssignment({
        assignment_id: this.assignment.id,
        current_status: value
      })
    }
  }
}
</script>
<style lang="scss" >
.buttons-status {
  display: flex;
  justify-content: space-between;
  button {
    border-radius: 0;
  }
  .separator {
    background: grey;
    width: 0.2rem;
  }
  #buttons {
    display: flex;
    gap: 1rem;
    .error {
      background: red;
      border: none;
    }
    .sucess {
      background: green;
      border: none;
    }
  }
}
.dropdown-toggle::after {
  display: none;
}
@media (max-width: 768px) { 
  .dropdown {
    top: 1px !important;
    right: 1px !important;
  }
}
  
</style>