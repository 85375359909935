<template>
  <div class="btn-group dropdown" ref="dropdown">
    <span 
      class="btn btn-link dropdown-toggle"
      type="button" :id="`dropdownMenu-${id}`"
      aria-expanded="false"
      @click="toggleDropdown"
    >
      <i class="bi bi-three-dots-vertical text-primary"></i>
    </span>
    <teleport to="body">
      <ul
        class="dropdown-menu dropdown-menu-end"
        :class="{ show: isDropdownVisible }"
        :aria-labelledby="`dropdownMenu-${id}`"
        :style="{ width: 'max-content', position: 'absolute', top: top + 'px', right: right + 'px' }"
      >
        <li
          v-for="({ label, icon, callback, show }, index) in items"
          :key="index"
          v-show="show"
        >
          <button
            @click="handleCallback($event, { callback })"
            class="btn btn-link text-decoration-none dropdown-item"
          >
            <i v-if="icon" :class="`bi ${icon}`"></i> {{ label }}
          </button>
        </li>
      </ul>
    </teleport>
  </div>
</template>

<script>
import EventBus from '@/events';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "OptionsMenuComponent",
  props: {
    contextTitle: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    const id = uuidv4();
    return {
      isDropdownVisible: false,
      id: id,
      top: 0,
      right: 0,
    };
  },
  methods: {
    handleCallback(event, { callback }) {
      event.stopPropagation();
      if (callback) callback();
      this.isDropdownVisible = false;
    },
    toggleDropdown(event) {
      this.isDropdownVisible = !this.isDropdownVisible;
      EventBus.emit('dropdown-open', this.id);

      const element = event?.target;
      const rect = element.getBoundingClientRect();
      const { top: topRect, bottom: bottomRect, right: rightRect } = rect;
      const { scrollY, scrollX, innerWidth } = window;

      const dropdownHeight = 30 * this.items.length; // Estimación de la altura del dropdown
      const absoluteTop = topRect + scrollY;
      const absoluteRight = innerWidth - (rightRect + scrollX);

      // Calcula la posición inicial (hacia abajo)
      const top = absoluteTop + 10;
      const right = absoluteRight + 10
      const fullHeight = document.documentElement.scrollHeight;

      // Ajusta la posición si el dropdown se sale de la ventana
      if ((top + bottomRect + dropdownHeight) > fullHeight) {
        this.top = top - dropdownHeight;
        this.right = right;
      } else {
        this.top = top;
        this.right = right;
      }
    },
    closeDropdown(id) {
      if (id !== this.id) {
        this.isDropdownVisible = false;
      }
    },
    handleClickOutside(event) {
      if (this.isDropdownVisible && !this.$refs.dropdown.contains(event.target)) {
        this.isDropdownVisible = false;
      }
    },
  },
  mounted() {
    EventBus.on('dropdown-open', this.closeDropdown);
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    EventBus.off('dropdown-open', this.closeDropdown);
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.dropdown .dropdown-toggle::after,
.dropup .dropdown-toggle::after,
.dropstart .dropdown-toggle::after,
.dropend .dropdown-toggle::after {
  content: "";
  display: none;
}
</style>