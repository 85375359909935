<template>
  <div class="mt-3 row">
    <div
      class="col-12 col-lg-6 col-md-6"
      style="height: 100%; z-index: var(--top-layer-z-index);"
    >
      <quill-editor
        ref="quill-editor"
        v-model:value="editor.content"
        :options="editor.options"
      />
    </div>
    <div
      ref="quill-preview"
      contentEditable="false"
      :style="{ height: `300px`, 'max-height': `300px` }"
      style="width: 600px; max-width: 600px; position: relative;"
      class="col-12 col-lg-6 col-md-6 mt-1 ms-3"
    >
      <div
        v-html="editor.content"
        style="position: absolute; top: 0; left: 0; width: 100%; overflow-y: hidden; z-index: var(--top-layer-z-index); display: grid;"
        class="ql-editor ql-custom"
      ></div>
    </div>
  </div>
</template>
<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';

export default {
  name: 'RdTemplateCreator',
  data() {
    return {
      editor: {
        content: `
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
        `,
        options: {
          placeholder: this.$t('Type something...'),
          modules: {
            imageResize: {
              modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
            },
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: ['Sans Serif', 'serif', 'monospace', 'roboto'] }],
              [{ align: [] }],
              ['clean'],
              ['link'], 
              [{
                'color': ['#F00', '#0F0', '#00F', '#000', '#FFF', 'color-picker']
              }]
            ],
            customImageUpload: true,
            customModule: true
          },
        },
        disabled: false,
      },
    }
  },
  async mounted() {
    var uploadBackgroundButton = document.getElementById("quill-custom-button-upload-background");
    uploadBackgroundButton.addEventListener('click', () => {
      var fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/png, image/jpeg, image/jpg, image/webp'; 
      fileInput.click(); 
      fileInput.addEventListener('change', (event) => {
        const file = event.target.files[0]; 
        if (file) {
          if (
            file.type === 'image/png'
            || file.type === 'image/jpeg'
            || file.type === 'image/jpg'
            || file.type === 'image/webp'
          ) {
            const reader = new FileReader();
            reader.onload = (e) => {
              const imageUrl = e.target.result;
              const element = this.$refs['quill-preview'];
              const existingImg = element.querySelector('img.custom-image-quill-background-template');
              if (existingImg) {
                existingImg.src = imageUrl;
              } else {
                const img = document.createElement('img');
                img.src = imageUrl;
                img.classList.add('custom-image-quill-background-template'); // Agrega la clase a la imagen recién creada
                img.style.width = '100%';
                img.style.height = '100%';
                img.style.position = 'absolute';
                img.style.top = '0';
                img.style.left = '0';
                element.appendChild(img);
              }
            };
            reader.readAsDataURL(file);
          } else {
            console.error('El archivo seleccionado no es válido. Selecciona una imagen PNG o JPEG.');
          }
        }
      });
    });
    
  },
  methods: {
    async setHtml(content) {
      const container = document.createElement('div');
      container.innerHTML = content;
      const element = this.$refs['quill-preview'];
      // Encontrar la imagen con la clase específica
      const backgroundImage = container.querySelector('img.custom-image-quill-background-template');
      if (backgroundImage) {
        const contentWithoutBg = content.replace(backgroundImage.outerHTML, '')
        this.editor.content = contentWithoutBg
        const img = document.createElement('img');
        img.src = backgroundImage.src;
        img.classList.add('custom-image-quill-background-template'); // Agrega la clase a la imagen recién creada
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        img.style.top = '0';
        img.style.left = '0';
        element.appendChild(img);
      } else {
        this.editor.content = content
      }
    },
    handleTextChange() {
      // let quillContent = this.$refs['quill-editor'].editor.innerHTML;
      // let paragraphCount = (quillContent.match(/<p>/g) || []).length;
      // if (paragraphCount > 16) {
      //   this.editor.disabled = true;
      // } else {
      //   this.editor.disabled = false;
      // }
      // if (paragraphCount > 16) {
      //   this.editor.content = quillContent.substring(0, quillContent.lastIndexOf("<p>"));
      // }
    },
    calcHeightQuillEditor() {
      let height = 0;
      if(this.$refs['quill-editor']) {
        const qlEditor = this.$refs['quill-editor'].editor.querySelector('.ql-editor');
        height = qlEditor.clientHeight
      }
      return height
    },
    test(name = 'John Doe', examName = 'Exam Name', certificateName = 'certificate') {
      const dataToReplace = {
        name,
        date: moment().format('MM/DD/YYYY'),
        examName
      }
      this.element = this.$refs['quill-preview'];
      const width = this.element.offsetWidth;
      const height = this.element.offsetHeight;
      const clonedElement = this.element.cloneNode(true);
      const divClonado = this.element.cloneNode(false);
      
      let innerHTML = clonedElement.innerHTML;
      for (const [key, value] of Object.entries(dataToReplace)) {
        innerHTML = innerHTML.replace(`$${key}`, value);
      }
      
      divClonado.innerHTML = innerHTML;

      divClonado.style.position = "absolute";
      divClonado.style.top = "-3000px";
      divClonado.style.left = "-3000px";
      document.body.appendChild(divClonado);
      html2canvas(divClonado).then(function(canvas) {
        const pdf = new jsPDF({
          orientation: (width > height) ? 'landscape' : 'portrait',
          unit: 'px',
          format: [width * 2, height * 2]
        });
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', 0, 0, width * 2, height * 2);
        pdf.save(`${certificateName}.pdf`);
        const padre = divClonado.parentNode;
        padre.removeChild(divClonado);
      });
    },
  }
}
</script>
<style lang="scss">
.ql-editor {
  font-family: Roboto;
}
.text-left {
  text-align: left !important;
}
.ql-picker-item[data-value="roboto"]::before {
  content: Roboto !important;
}
span.ql-picker-label[data-value="roboto"]::before {
  content: Roboto !important;
}
.ql-container {
  width: 600px;
  height: 300px;
  max-height: 300px;
  overflow-y: hidden;
}
.ql-toolbar {
  width: 600px;
}
.ql-editor {
  font-size: 13px !important;
}
.ql-editor {
  p {
    image {
      width: 30px !important;
    }
  }
}
</style>
