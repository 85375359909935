<template>
  <div class="unselectable">
    <p class="remaining-time">{{ $t('Remaining time:') }} {{ minsComputed }}:{{ secsComputed }}</p>
  </div>
</template>

<script>
export default {
  props: {
    minutes: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      mins: null,
      secs: 59,
      interval: null,
      defaultMins: 10,
    };
  },
  computed: {
    minsComputed() {
      if (!this.mins) return '00';
      if (this.mins < 10) return '0' + this.mins.toString();
      return this.mins.toString();
    },
    secsComputed() {
      if (!this.secs) return '00';
      if (this.secs < 10) return '0' + this.secs.toString();
      return this.secs.toString();
    },
  },
  methods: {
    startCountdown() {
      this.interval = setInterval(() => {
        if (this.mins === 0 && this.secs === 0) {
          this.$emit('over');
          this.mins = null;
          this.secs = null;
          clearInterval(this.interval);
          return;
        }
        if (this.secs === 0) {
          this.mins = this.mins - 1;
          this.secs = 59;
          return;
        }
        this.secs = this.secs - 1;
      }, 1000);
    }
  },
  mounted() {
    if (this.minutes && this.minutes > 0) this.mins = this.minutes - 1;
    else this.mins = this.defaultMins - 1;
    this.startCountdown();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  }
};
</script>

<style scoped>
.remaining-time {
  font-size: calc(14px + .25vw);
  font-weight: 300;
  color: #3d3d3d;
}
</style>