<template lang="">
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Users (nav)')" />
      <div class="container background-form my-3 p-3">
        <div class="row">
          <div class="col-12 col-md-6 d-flex justify-content-between align-items-center">
            <h2 class="ms-4 text-start">{{ $t('Edit User') }}</h2>
            <div class="form-check form-switch">
              <label class="form-check-label">
                <input
                  v-model="isAvailable"
                  class="form-check-input"
                  type="checkbox"
                  @change="toggleAvailability"
                >
                {{ isAvailable ? $t('Enabled') : $t('Disabled') }}
              </label>
            </div>
          </div>
        </div>

        <form
          @submit.prevent=""
          autocomplete="off"
          class="container"
        >
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Username')" 
                :label="$t('Enter the username')"
                required
                :value="userName"
                @input="(e) => this.userName = e.target.value"
              />
            </div>
          </div>
          
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6 text-start">
              <rd-input
                type="password"
                :placeholder="$t('Password')" 
                :label="$t('Enter user password')"
                required
                showPassIcon
                :value="userPassword"
                @input="(e) => this.userPassword = e.target.value"
                :errorMsg="$t('Password insecure')"
                :showError="showError"
                ref="password"
              />
              <div
                v-if="userPassword.length > 0" 
                class="m-0 mt-2"
              >
                <div
                  :class="{
                    'text-success': passwordIsLongEnough,
                    'text-danger': !passwordIsLongEnough
                  }"
                > 
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Password Status" type="button" :class="`bi ${passwordIsLongEnough ? 'bi-check-circle-fill': 'bi-exclamation-circle-fill'}`" />
                  {{ $t('Minimum password length is 8 characters') }}
                </div>
                <div
                  :class="{
                    'text-success': passwordContainsCapitalLetters,
                    'text-danger': !passwordContainsCapitalLetters
                  }"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Password Status" type="button" :class="`bi ${passwordContainsCapitalLetters ? 'bi-check-circle-fill': 'bi-exclamation-circle-fill'}`" />
                  {{ $t('Require at least one uppercase letter from Latin alphabet (A-Z)') }}
                </div>
                <div
                  :class="{
                    'text-success': passwordContainsLowerCase,
                    'text-danger': !passwordContainsLowerCase
                  }"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Password Status" type="button" :class="`bi ${passwordContainsLowerCase ? 'bi-check-circle-fill': 'bi-exclamation-circle-fill'}`" />
                  {{ $t('Require at least one lowercase letter from Latin alphabet (a-z)') }}
                </div>
                <div
                  :class="{
                    'text-success': passwordContainsNumber,
                    'text-danger': !passwordContainsNumber
                  }"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Password Status" type="button" :class="`bi ${passwordContainsNumber ? 'bi-check-circle-fill': 'bi-exclamation-circle-fill'}`" />
                  {{ $t('Require at least one number') }}
                </div>
                <div
                  :class="{
                    'text-success': passwordContainsEspecialChar,
                    'text-danger': !passwordContainsEspecialChar
                  }"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Password Status" type="button" :class="`bi ${passwordContainsEspecialChar ? 'bi-check-circle-fill': 'bi-exclamation-circle-fill'}`" />
                  {{ $t('Require at least one non-alphanumeric / special character') }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                placeholder="Email" 
                type="email"
                :label="$t('Enter the email')"
                :value="userEmail"
                @input="(e) => this.userEmail = e.target.value"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('First name')"
                :label="$t('Enter user first name')"
                :value="userFirstname"
                @input="(e) => this.userFirstname = e.target.value"
              />
            </div>
          </div>
          
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Last name')" 
                :label="$t('Enter user last name')"
                :value="userLastname"
                @input="(e) => this.userLastname = e.target.value"
              />
            </div>
          </div>  
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Phone')" 
                :label="$t('Enter phone number')"
                :value="phone"
                @input="(e) => this.phone = e.target.value"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Latitude')" 
                :label="$t('Enter user latitude')"
                :value="latitude"
                @input="(e) => this.latitude = e.target.value"
              />
            </div>
          </div>    
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Longitude')" 
                :label="$t('Enter user longitude')"
                :value="longitude"
                @input="(e) => this.longitude = e.target.value"
              />
            </div>
          </div>    
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Address')" 
                :label="$t('Enter user address')"
                :value="address"
                @input="(e) => this.address = e.target.value"
              />
            </div>
          </div>    
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Provider')" 
                :label="$t('Enter user provider')"
                :value="provider"
                @input="(e) => this.provider = e.target.value"
              />
            </div>
          </div>    
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Session')" 
                :label="$t('Enter user session')"
                :value="session"
                @input="(e) => this.session = e.target.value"
              />
            </div>
          </div>    
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('City')" 
                :label="$t('Enter user city')"
                :value="city"
                @input="(e) => this.city = e.target.value"
              />
            </div>
          </div>    
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Zip Code')" 
                :label="$t('Enter user zip code')"
                :value="zip_code"
                @input="(e) => this.zip_code = e.target.value"
              />
            </div>
          </div>    
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Building House')" 
                :label="$t('Enter user building house')"
                :value="building_house"
                @input="(e) => this.building_house = e.target.value"
              />
            </div>
          </div>  
          
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-multiselect
                ref="multiselect-profiles"
                :initialValue="profilesSelected"
                :placeholderToTag="$t('Add this profile')"
                :placeholder="$t('Add profiles')"
                :label="$t('Enter the profiles related')"
                name="name"
                :optionsToSelect="profiles"
                @handleAddTagging="addTag"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-multiselect 
                ref="multiselect-roles"
                :initialValue="rolesSelected"
                :placeholderToTag="$t('Add this role')"
                :placeholder="$t('Add a role')"
                name="name"
                :label="$t('Enter the roles related')"
                :multiple="false"
                :optionsToSelect="roles"
                @handleAddTagging="addRoleTag"
              />
            </div>
          </div>

          <div class="mt-3 row justify-content-end">
            <div class="col-12 col-md-6 col-lg-6 text-end">
              <button-component primaryOutline @handleClick="goBack">
                {{ $t('Dismiss') }}
              </button-component>
              <button-component type="submit" :disabled="disableSaveButton" primary @handleClick="editUser">
                {{ $t('Edit User (btn)') }}
              </button-component>
            </div>
          </div>
        </form>
    </div>
    </div>
    <div v-if="isEditing" class="overlay">
      <div class="d-flex justify-content-center align-items-center w-100 h-100">
        <rd-loading />
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdInput from '../../components/rd-components/rd-input.vue';
import RdMultiselect from '@/components/rd-components/rd-multiselect.vue';
import router from '@/router'
import { getAllProfiles } from '@/services/profiles/profile.api.js'
import { getAllRoles } from '@/services/roles/role.api.js'
import { getUserById, editUserById, toogleUserAvailability } from '@/services/user/user.api.js'
import buttonComponent from '../../components/button-component.vue';
import RdLoading from '../../components/rd-components/rd-loading.vue';

export default {
  data() {
    return {
      profilesSelected: [],
      profiles: [],
      rolesSelected: [],
      roles: [],
      userEmail: '',
      userFirstname: '',
      userLastname: '',
      phone: '',
      userName: '',
      userPassword: '',
      disableSaveButton: false,
      showError: false,
      latitude: null,
      longitude: null,
      address: null,
      provider: null,
      session: null,
      city: null,
      zip_code: null,
      building_house: null,
      isAvailable: null,
      isEditing: false
    }
  },
  async mounted() {
    await this.setRoles()
    await this.setUserData()
    await this.setProfiles()
    this.$watch(
      () => {
        return [this.userName, this.$refs['multiselect-profiles'].value.length, this.$refs['multiselect-roles'].value, this.validPassword, this.userPassword]
      },
      ([userName, profilesSelected, role, validPassword, userPassword]) => {
        if (userName === '' || profilesSelected === 0 || Object.keys(role).length === 0 || (userPassword.length > 0 && !validPassword)) {
          this.disableSaveButton = true
        } else {
          this.disableSaveButton = false
        }
      }
    );
  },
  computed: {
    userId() {
      return this.$route.params.id
    },
    passwordIsLongEnough() {
      return this.userPassword && this.userPassword.length > 7
    },
    passwordContainsCapitalLetters() {
      return this.userPassword && /[A-Z]/.test(this.userPassword)
    },
    passwordContainsLowerCase() {
      return this.userPassword && /[a-z]/.test(this.userPassword)
    },
    passwordContainsNumber() {
      return this.userPassword && /\d/.test(this.userPassword)
    },
    passwordContainsEspecialChar() {
      return this.userPassword && /([!@#$%^&*()_+-]+)/.test(this.userPassword)
    },
    validPassword() {
      return (
        this.passwordIsLongEnough &&
        this.passwordContainsCapitalLetters &&
        this.passwordContainsLowerCase &&
        this.passwordContainsNumber &&
        this.passwordContainsEspecialChar
      )
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    RdMultiselect,
    buttonComponent,
    RdLoading,
  },
  methods: {
    async toggleAvailability(e) {
      const { checked } = e.target
      try {
        await toogleUserAvailability(this.userId, { isAvailable: checked })
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'User availability changed.',
          type: 'success',
        });
        this.isAvailable = checked
      } catch {
        setTimeout(() => this.isAvailable = !checked, 300);
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'User availability not changed.',
          type: 'error',
        });
      }
    },
    async setUserData() {
      let userId = this.$route.params.id
      try {
        const { data } = await getUserById(userId)
        this.userEmail = data.user.userEmail
        this.userFirstname = data.user.userFirstname
        this.userLastname = data.user.userLastname
        this.phone = data.user.phone
        this.userName = data.user.userName
        this.profilesSelected = data.userProfiles.map(item => ({ name: item.profileName, code: item.profileId }))
        this.rolesSelected = this.roles.find(role => parseInt(role.code) === parseInt(data.user.roleId))
        this.latitude = data.user.latitude;
        this.longitude = data.user.longitude;
        this.address = data.user.address;
        this.provider = data.user.provider;
        this.session = data.user.session;
        this.city = data.user.city;
        this.zip_code = data.user.zipCode;
        this.building_house = data.user.buildingHouse;
        this.isAvailable = data.user.isAvailable
      } catch (error) {
        console.log(error)
      }
    },
    async setProfiles() {
      try {
        const res = await getAllProfiles()
        this.profiles = res.data.map(item => ({ name: item.profileName, code: item.profileId }))
      } catch (error) {
        console.log(error)
      }
    },
    async setRoles() {
      try {
        const { data } = await getAllRoles()
        this.roles = data.map(role => ({ name: role.roleName, code: role.roleId }))
      } catch (error) {
        console.log(error)
      }
    },
    async editUser() {
      let profiles = this.$refs['multiselect-profiles'].value.map(item => parseInt(item.code));
      let role = this.$refs['multiselect-roles'].value.code;
      this.showError = false;
      const user = {
        userEmail: this.userEmail,
        userFirstname: this.userFirstname,
        userLastname: this.userLastname,
        phone: this.phone,
        userName: this.userName,
        profiles,
        roleId: role,
        latitude: this.latitude,
        longitude: this.longitude,
        address: this.address,
        provider: this.provider,
        session: this.session,
        city: this.city,
        zipCode: this.zip_code,
        buildingHouse: this.building_house,
      }
      if (this.userPassword.length > 0) user.userPassword = this.userPassword;
      this.isEditing = true;
      try {
        await editUserById(this.userId, user);
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'User edited.',
          type: 'success',
        });
        router.back()
      } catch (error) {
        // Validate if error has a response and the status is a 400
        if (error.response && error.response.status === 400) {
          // Then check if the response has data, error and message
          if (error.response.data && error.response.data.error && error.response.data.error.message) {
            // If the message of the error includes password this means we have a trouble in password
            // Then we show the error message
            if (error.response.data.error.message.toLowerCase().includes('password')) {
              this.showError = true;
              this.userPassword = ''
            }
          }
        }
        if (error.response && error.response.status === 406) {
          this.$store.dispatch('dispatchNotification', {
            title: 'Error!',
            message: 'User not exist on keycloak',
            type: 'error',
          });
          router.back();
          return;
        }
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Could not edit this user.',
          type: 'error',
        });
        console.log(error)
      } finally {
        this.isEditing = false;
      }
    },
    goBack() {
      router.back()
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.profiles.push(tag)
      this.profilesSelected.push(tag)
    },
    addRoleTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.roles.push(tag)
      this.rolesSelected.push(tag)
    }
  },
}
</script>

<style scoped>
.labelText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: grey;
  float: left;
  border-radius: 4px;
}

.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.09);
  color: white;
  z-index: 999999;
  border-radius: .5rem;
}
</style>