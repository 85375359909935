<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />
      <div class="container background-form my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Edit Rating') }}</h2>
          </div>
        </div>

        <form 
          @submit.prevent="" 
          autocomplete="off" 
          class="container"
        >
          <div class="row mb-2">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Rating')" 
                :label="$t('Enter the rating')"
                :value="name"
                required
                @input="(e) => this.name = e.target.value"
              />
            </div>
          </div>


          <div class="mt-3 row justify-content-end">
            <div class="col-12 col-lg-6 col-md-6 text-end">
              <button-component primaryOutline @handleClick="goBack">
                {{ $t('Dismiss') }}
              </button-component>
              <button-component type="submit" primary @handleClick="handleCreateStatus">
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Status" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Edit Rating (btn)') }}
              </button-component>
            </div>
          </div>

        </form>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import RdInput from '../../components/rd-components/rd-input.vue';
import rdFooter from "../../components/rd-components/rd-footer.vue"
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import router from "../../router";
import { getRating, editRating } from '../../services/sessions/sessions-score.api'
import ButtonComponent from '../../components/button-component.vue';

export default {
  components: {
    rdFooter,
    RdNavbar,
    RdInput,
    ButtonComponent
  },
  data() {
    return {
      name: null,
      description: null,
    }
  },
  async mounted() {
    await this.getCurrentRating();
  },
  computed: {
    statusId() {
      return this.$route.params.id
    },
  },
  methods: {
    async getCurrentRating() {
      try {
        const { data } = await getRating(this.statusId)
        const { score } = data
        this.name = score
      } catch (error) {
        console.log(error)
      }
    },
    async handleCreateStatus() {
      try {
        
        await editRating(this.statusId, {
          score: this.name
        })
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Rating edited.',
          type: 'success',
        });
        router.back()
      } catch (error) {
        console.log(error)
        this.$store.dispatch('dispatchNotification', {
          title: 'Failed!',
          message: 'Error editing Rating.',
          type: 'error',
        });
      }
    },
    goBack() {
      router.back()
    },
  }
}
</script>