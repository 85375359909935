<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Template (nav)')" />
      <div class="container background-form rounded-3 my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Add Template') }}</h2>
          </div>
        </div>
        <div class="container">

          <div class="row mb-4">
            <div class="col-11 col-sm-11 col-md-5">
              <rd-input
                :placeholder="$t('Template name')" 
                type="text"
                :label="$t('Enter the template name')"
                required
                @input="(e) => templateName = e.target.value"
              />
            </div>
            <div class="col-1 d-flex align-items-center justify-content-center">
              <Tooltip 
                :text="`
                  <div class='text-start'>
                    <p class='m-0 p-0'>$name: ${tooltipMessage.name}</p>
                    <p class='m-0 p-0'>$date: ${tooltipMessage.date}</p>
                    <p class='m-0 p-0'>$examName: ${tooltipMessage.examName}</p>
                  </div>
                `"
                placement="bottom"
              >
                <i class="bi fs-5 bi-info-circle-fill"></i>
              </Tooltip>
            </div>
          </div>

          <rd-template-creator ref="template-creator"></rd-template-creator>

          <div class="my-3 row justify-content-end">
            <div class="col-12 col-lg-6 col-md-6 text-end">
              <button-component primaryOutline @handleClick="$router.back()">
                {{ $t('Dismiss') }}
              </button-component>
              <button-component
                primary
                @handleClick="downloadPreview"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Download Preview" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Download Preview') }}
              </button-component>
              <button-component
                :disabled="disableSaveButton"
                primary
                @handleClick="handleCreateTemplate"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Template" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Template') }}
              </button-component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import router from '@/router'
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdTemplateCreator from '../../components/rd-components/rd-template-creator.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import { mapState } from 'vuex';
import { createTemplate } from '@/services/templates/templates.api.js'
import ButtonComponent from '../../components/button-component.vue';
import Tooltip from '../../components/tooltip.vue';

export default {
  data() {
    return {
      templateName: null,
      tooltipMessage: {
        name: this.$t('Name of the user'),
        date: this.$t('Date of the download'),
        examName: this.$t('Name of the exam'),
      }
    }
  },
  computed: {
    ...mapState(['windowWidth']),
    user(){
      return this.$store.getters.getUser;
    },
    disableSaveButton() {
      return (
        !this.templateName
      )
    }
  },
  methods: {
    downloadPreview() {
      this.$refs['template-creator'].test();
    },
    async handleCreateTemplate() {
      try {
        const template = this.$refs['template-creator'].$refs['quill-preview'].outerHTML;
        await createTemplate({
          templateName: this.templateName,
          template
        })
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Template created.',
          type: 'success',
        });
        router.back();
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Template was not created.',
          type: 'error',
        });
        console.log(error)
      }
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    RdTemplateCreator,
    ButtonComponent,
    Tooltip
  }
}
</script>
<style>
.text-left {
  text-align: left !important;
}
.ql-picker-item[data-value="roboto"]::before {
  content: 'Roboto' !important;
}
span.ql-picker-label[data-value="roboto"]::before {
  content: 'Roboto' !important;
}
</style>