<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />
      <div class="container background-form rounded-3 my-3 p-3">
        <PageTitle
          :title="$t('Bulk Loads History')"
          :description="$t('History of bulk load')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Bulk Loads History')"
        />
        <!-- <div class="row p-3 justify-content-between">
          <div class="p-3 m-0 mb-2 col-12 col-lg-6">
            <div class="row justify-content-end gap-1">
              <div class="p-0 m-0 mb-2 col-12 col-lg-5">
                <button
                  class="btn btn-primary w-100" 
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="New Bulk Load" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('New Bulk Load') }}
                </button>
              </div>
            </div>
          </div>
        </div> -->

        <div v-if="loading">
          <rd-loading />
        </div>
        <div v-else class="row p-3">
          <div v-if="filteredHistory.lenght === 0">
            <h2>{{ $t('No data found') }}</h2>
          </div>
          <template v-else>
            <div class="row mb-3">
              <div class="col-12 col-lg-4">
                <rd-input
                  class="w-100"
                  :placeholder="$t('Search file by date or name')" 
                  type="text"
                  @input="(e) => search = e.target.value"
                />
              </div>
            </div>
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover table-custom-general" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col">{{ $t('Date') }}</th>
                      <th scope="col">{{ $t('File') }}</th>
                      <th scope="col">{{ $t('Username') }}</th>
                      <th scope="col" class="text-center">{{ $t('Records') }}</th>
                      <th scope="col" class="text-center">{{ $t('Completion Percentage') }}</th>
                      <!-- <th scope="col" class="text-center">{{ $t('Inserts') }}</th> -->
                      <!-- <th scope="col" class="text-center">{{ $t('Errors') }}</th> -->
                      <!-- <th scope="col" class="text-center">{{ $t('Actions') }}</th> -->
                    </tr>
                  </thead>
                  <tbody class="text-start">
                    <tr
                      v-for="({
                        date, file, username, records, completion_percentage
                      }, index) in dataTemp" :key="index"
                      class="hoverable"
                      :class="{ 'fill-ods': index % 2 !== 0 }"
                    >
                      <td style="width: 20%; word-break: break-all;" class="custom-height" :header="$t('Date')" scope="row"><div class="custom-table-text">{{ date }}</div></td>
                      <td style="width: 20%; word-break: break-all;" class="custom-height" :header="$t('File')"><div class="custom-table-text">{{ file }}</div></td>
                      <td style="width: 20%; word-break: break-all;" class="custom-height" :header="$t('Username')"><div class="custom-table-text">{{ username }}</div></td>
                      <td style="width: 20%; word-break: break-all;"  :header="$t('Records')" class="centered custom-height"><div class="custom-table-text">{{ records }}</div></td>
                      <td style="width: 20%; word-break: break-all;" :header="$t('Completion Percentage')" class="centered custom-height"><div class="custom-table-text">{{ completion_percentage }}%</div></td>
                      <!-- <td style="width: 14.28%; word-break: break-all;" :header="$t('Inserts')" class="centered">{{ inserts }}</td> -->
                      <!-- <td style="width: 14.28%; word-break: break-all;" :header="$t('Errors')" class="centered">{{ errors }}</td> -->
                      <!-- <td style="width: 14.28%; word-break: break-all;" :header="$t('Actions')" class="centered"> -->
                        <!-- <button 
                          class="btn btn-label"
                          @click="goToBulkLoadDetails({ id })"
                        >
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="View Records" type="button" class="bi bi-eye-fill"></i>
                        </button>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <rd-paginator :items="filteredHistory" @page-change="handlePageChange" />
          </template>
        </div>

      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import RdFooter from '@/components/rd-components/rd-footer.vue'
import RdLoading from '@/components/rd-components/rd-loading.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdPaginator from '@/components/rd-components/rd-paginator.vue'
import router from '@/router'
import { bulkHistory } from '@/services/bulk/bulk.api'
import { convertDateFormat } from '@/utils/convertFormat'
import PageTitle from '@/components/rd-components/page-title.vue'
import { convertDateToNumbers } from '@/utils/convertFormat'
import RdInput from '@/components/rd-components/rd-input.vue'

export default {
  components: {
    RdNavbar,
    RdFooter,
    RdLoading,
    RdPaginator,
    PageTitle,
    RdInput
  },
  data() {
    return {
      loading: true,
      search: '',
      data: [],
      dataTemp: [],
      convertDateFormat,
    }
  },
  async mounted() {
    this.getHistoryData();
  },
  computed: {
    filteredHistory() {
      return this.data.filter(({ date, file }) => (
        date && date.toLowerCase().startsWith(this.search.toLowerCase())
        || file && file.toLowerCase().startsWith(this.search.toLowerCase())
      ))
    }
  },
  methods: {
    async getHistoryData() {
      try {
        this.loading = true
        const { data } = await bulkHistory()
        this.data = data.data.map(({ date, ...rest }) => ({
          ...rest,
          date: convertDateFormat(date),
        }))
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        await this.$nextTick(() => {
          const elements = document.querySelectorAll("table th");
          elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
          })
          document.addEventListener("mousemove", (e) => {
            if (this.thElm) {
              this.lastWidth  = this.thElm.offsetWidth;
              this.thElm.style.width = this.startOffset + e.pageX + "px";
              document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                if((internalIndex % 5) == Number(this.thElm.id)) {
                  td.style.width = this.startOffset + e.pageX + "px";
                } else {
                  td.style.width = td.offsetWidth + "px";
                }
              })
              this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
            }
          });

          document.addEventListener("mouseup", () => {
            this.thElm = undefined;
          });
        });
      }
    },
    async handlePageChange(data) {
      this.dataTemp = data
        .filter(({ date, file }) => (
          date && date.toLowerCase().startsWith(this.search.toLowerCase())
          || file && file.toLowerCase().startsWith(this.search.toLowerCase())
        ))
        .sort(({ date: a }, { date: b }) => (
          convertDateToNumbers(b) - convertDateToNumbers(a)
        ));
    },
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '20%'
      })
    },
    goToBulkLoadDetails({ id }) {
      router.push(`/admin/bulk-load-details/${id}`)
    }
  },
}
</script>
<style scoped>
.centered {
  text-align: center;
  vertical-align: middle;
}
.custom-height {
  height: 3rem;
}
</style>