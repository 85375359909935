<template>
  <div class="offcanvas w-max-content offcanvas-end" :id="id" aria-labelledby="offcanvasRightLabel" tabindex="-1">
    <div class="offcanvas-header d-flex justify-content-between align-items-center">
      <div class="row">
        <h4 class="col text-capitalize fw-bold text-center align-self-center">
          {{ $t('User Information')}}
        </h4>
      </div>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div v-if="loading">
        <rd-loading />
      </div>
      <div v-else class="container text-start">
        <div class="row d-flex">
          <div class="col-12 col-lg-4 col-md-6">
            <span class="fw-bold">{{ $t('Name') }}:</span> {{ fullName ? fullName : $t('Not provided') }}
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <span class="fw-bold">{{ $t('Email') }}:</span> {{ user.userEmail ? user.userEmail : $t('Not provided') }}
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <span class="fw-bold">{{ $t('Phone') }}:</span> {{ user.phone ? user.phone : $t('Not provided') }}
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <span class="fw-bold">{{ $t('City') }}:</span> {{ user.city ? user.city : $t('Not provided') }}
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <span class="fw-bold">{{ $t('Address') }}:</span> {{ user.address ? user.address : $t('Not provided') }}
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <span class="fw-bold">{{ $t('Zip Code') }}:</span> {{ user.zipCode ? user.zipCode : $t('Not provided') }}
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <span class="fw-bold">{{ $t('Building/House') }}:</span> {{ user.buildingHouse ? user.buildingHouse : $t('Not provided') }}
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <span class="fw-bold">{{ $t('Provider') }}:</span> {{ user.provider ? user.provider : $t('Not provided') }}
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <span class="fw-bold">{{ $t('Rol') }}:</span> {{ user.role.name }}
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <span class="fw-bold">{{ $t('Function') }}:</span> {{ user.functionData.function }}
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <span class="fw-bold">{{ $t('Status') }}:</span> {{ user.smarthireStatus.status }}
          </div>
          <div class="col-12 col-lg-4 col-md-6 parent-container">
            <span class="fw-bold">{{ $t('Ban Flag') }}:</span>
            <div class="checkbox-container">
              <rd-input
                type="checkbox"
                v-model="banFlagChecked"
                :id="banFlagCheckbox"
                :checked2="isBanned"
                @change="banningUser(user.userId, !isBanned, user.functionData.function)"
              />
            </div>
          </div>
        </div>
        <div class="row my-1">
          <div class="col-12 col-lg-4 col-md-6">
            <button-component primary class="text-end" @handleClick="showGeneralUserFeedback"> {{ $t('Feedback') }} </button-component>
            <button-component v-if="permissions.includes(APP_PERMISSIONS.USER_MANAGEMENT.EDIT_USER) || this.$store.state.user.isAdmin" primary class="text-end" @handleClick="showEditUser"> {{ $t('Edit User') }} </button-component>
          </div>
        </div>

        <div v-if="filteredExams.length > 0" class="row mb-2 d-flex justify-content-start">
          <div class="row">
            <h4 class="col text-capitalize fw-bold text-center">
              {{ $t('Exams Information')}}
            </h4>
          </div>

          <data-table-component
            :columns="[
              { label: $t('Name'), field: 'examName', sortable: true, type: 'string', },
              { label: $t('Status'), field: 'status' },
              { label: $t('Score'), field: 'score' },
              { label: $t('Attempts'), field: 'attempts' },
            ]"
            :rows="filteredExams"
            resizeable
            paginator
          >
            <template #status="{ row: { approved, attempts } }">
              <div>{{ +(attempts) !== 0 ? approved ? $t('Approved') : $t('Failed') : $t('Not attempted yet') }}</div>
            </template>

            <template #score="{ row: { lastScore, totalQuestions } }">
              <div>{{ lastScore || '-' }} / {{ totalQuestions }}</div>
            </template>

            <template #attempts="{ row: { examAttempts, attempts } }">
              <div>{{ attempts || '-' }} / {{ examAttempts || '-' }}</div>
            </template>
          </data-table-component>

        </div>

        <div v-if="filteredSessions.length > 0" class="row mb-2 d-flex justify-content-start">
          <div class="row">
            <h4 class="col text-capitalize fw-bold text-center">
              {{ $t('Sessions Information')}}
            </h4>
          </div>

          <data-table-component
            :columns="[
              { label: $t('Name'), field: 'code', sortable: true, type: 'string', },
              { label: $t('Session Trainer'), field: 'trainer' },
              { label: $t('Rating'), field: 'rating' },
              { label: $t('Date'), field: 'date' },
              { label: $t('Feedback'), field: 'feedback', hAlign: 'center', headerHAlign: 'center' },
            ]"
            :rows="filteredSessions"
            resizeable
            paginator
          >
            <template #name="{ row: { code } }">
              <div class="custom-table-text">{{ code }}</div>
            </template>
            <template #trainer="{ row: { trainer } }">
              <div class="custom-table-text">{{ trainer || '-' }}</div>
            </template>
            <template #rating="{ row: { ratingScore } }">
              <div class="custom-table-text">{{ ratingScore || '-' }} / {{ higherRating }}</div>
            </template>
            <template #date="{ row: { allDay, date } }">
              <div class="custom-table-text">{{ formatedDate(date) }} {{ allDay ? $t('All day') : '' }}</div>
            </template>
            <template #feedback="{ row: { feedback } }">
              <div class="container">
                <div class="row text-center">
                  <div class="col p-0 m-0">
                    <i 
                      :class="{
                        'text-primary': !!feedback,
                      }"
                      class="bi bi-eye-fill fs-5"
                      @click="showModalFeedback({ feedback })"
                      data-bs-toggle="tooltip" data-bs-placement="right" title="Feedback" type="button"
                    ></i>
                  </div>
                </div>
              </div>
            </template>
          </data-table-component>

        </div>

        <div v-if="filteredAssignments.length" class="row mb-2 d-flex justify-content-start">
          
          <div class="row">
            <h4 class="col text-capitalize fw-bold text-center">
              {{ $t('Assignment Information')}}
            </h4>
          </div>

          <data-table-component
            :columns="[
              { label: $t('Activity Date - VC Code'), field: 'name', sortable: true, type: 'string', },
              { label: $t('Status'), field: 'status', },
              { label: $t('Position'), field: 'function', },
            ]"
            :rows="filteredAssignments"
            resizeable
            paginator
          >
            <template #name="{ row }">
              <div class="container" style="visibility: hidden">
                <div class="row text-center">
                  <div class="col p-0 m-0">
                    <i 
                      :class="{
                        'text-primary': !!feedback,
                      }"
                      class="bi bi-eye-fill fs-5"
                      @click="showModalFeedback({ feedback })"
                      data-bs-toggle="tooltip" data-bs-placement="right" title="Feedback" type="button"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="custom-table-text" style="top: 0.6rem;">{{ row.activity_date }} - {{ row.code }}</div>
            </template>
          </data-table-component>

        </div>

        <!--modal to show user session feedback -->
        <basic-modal
          v-if="showFeedbackModal"
          :initialTextareaVal="feedbackSelected"
          :textareaDisabled="true"
          @hide="feedbackSelected = null; showFeedbackModal = false;"
        >
          <template #title>{{ $t('Feedback') }}</template>
          <template #footer>
            <button-component  primaryOutline @handleClick="showFeedbackModal = false; feedbackSelected = null;">{{ $t('Close') }}</button-component>
          </template>
        </basic-modal>

        <!-- modal to add general user feedback -->
        <basic-modal
          v-if="showGeneralFeedbackModal"
          :loading="addingFeedback"
          :textareaDisabled="addingFeedback"
          :initialTextareaVal="user.feedback"
          :placeholder="$t('Add feedback for this user')"
          @save="addGeneralFeedbackToUser"
          @hide="showGeneralFeedbackModal = false;"
        >
          <template #title>{{ $t('Feedback') }}</template>
        </basic-modal>

      </div>
    </div>
    
  </div>
</template>
<script>
import {
  addGeneralFeedback,
  getUserById,
  getUserSessionsWithExams,
  getUserAssignmentsWithStatus,
  userBanned,
} from '../../services/user/user.api';
import BasicModal from '../basic-modal.vue';
import ButtonComponent from '../button-component.vue';
import RdLoading from '../rd-components/rd-loading.vue'
import { formatedDate } from '../../utils/convertFormat';
import DataTableComponent from '../tables/data-table-component.vue';
import RdInput from '@/components/rd-components/rd-input.vue';
import router from '@/router';
import { getRatings } from '../../services/sessions/sessions-score.api';
import {APP_PERMISSIONS} from '@/utils/constants.js'
import { mapState } from 'vuex'

export default {
  name: "user-details-component",
  components: {
    RdLoading,
    BasicModal,
    ButtonComponent,
    DataTableComponent,
    RdInput,
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      banFlagChecked: false,
      APP_PERMISSIONS,
      loading: true,
      userRaw: null,
      exams: [],
      sessions: [],
      assignments: [],
      assignmentsDataTemp: [],
      examsDataTemp: [],
      sessionsDataTemp: [],
      feedbackSelected: null,
      showEditUserModal: false,
      showFeedbackModal: false,
      showGeneralFeedbackModal: false,
      userSelectedForFeedback: null,
      addingFeedback: false,
      formatedDate,
      higherRating: 0,
    }
  },
  watch: {
    userId: {
      handler: async function (newVal) {
        if (!newVal) {
          this.$emit('hide');
          return
        }
        await this.getUserData();
      },
    },
  },
  mounted() {
    // this.getAllRatings();
    this.setHigherRating();
  },
  computed: {
    ...mapState(['permissions']),
    fullName() {
      if (!this.user) return null
      if (!this.user.userFirstname && !this.user.userLastname) return null
      return `${this.user.userFirstname ? this.user.userFirstname : ''} ${this.user.userLastname ? this.user.userLastname : ''}`
    },
    user() {
      if (!this.userRaw) return {
        smarthireStatus: {},
        functionData: {},
        role: {}
      }
      return this.userRaw
    },
    isBanned() {
      return !this.user.isAvailable && !this.user.isAvailableNextAssignment && !this.user.function;
    },
    filteredExams() {
      return this.exams
    },
    filteredSessions() {
      return this.sessions
    },
    filteredAssignments() {
      return this.assignments
    }
  },
  methods: {
    async getAllRatings() {
      try {
        const {data} = await getRatings();
        return data;
      } catch (error) {
        console.error({error});
      }
    },
    async setHigherRating() {
      const ratings = await this.getAllRatings();
      const highest = ratings.reduce((max, current) => {
        return +current.score > +max.score ? current : max;
      }, ratings[0]);

      this.higherRating = +highest.score;
    },
    showEditUser() {
      document.body.classList.remove('offcanvas-open');
      // document.body.classList.remove('offcanvas w-max-content offcanvas-end');
      document.body.style.overflow = 'auto';
      router.push({
        name: 'Edit User',
        params: { id: this.user.userId }
      });
    },
    async banningUser(id, isBanned, userFunction) {
      try {
        this.loading = true;
        await userBanned({
          id: id,
          payload: {
            banned: isBanned,
            function: isBanned ? null : userFunction,
          }
        });
        await this.getUserInformation()
        await this.getUserExamsInformation()
        await this.getUserAssignments();
        this.$emit('user-updated');
        this.$toast.open({
          message: this.$t('User updated.'),
          type: 'success',
        })
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('User could not be updated.'),
          type: 'error',
        })
      } finally {
        this.loading = false
      }
    },
    async getUserData() {
      try {
        this.loading = true
        await this.getUserInformation()
        await this.getUserExamsInformation()
        await this.getUserAssignments();
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        // await this.$nextTick(() => {
        //   const elements = document.querySelectorAll("table th");
        //   elements.forEach((el, index) => {
        //     el.style.position = "relative";
        //     el.id = index
        //     var grip = document.createElement("div");
        //     grip.innerHTML = "&nbsp;";
        //     grip.style.borderRight = 'black solid 1px'
        //     grip.style.top = 0;
        //     grip.style.right = 0;
        //     grip.style.bottom = 0;
        //     grip.style.width = "5px";
        //     grip.style.position = "absolute";
        //     grip.style.cursor = "col-resize";
        //     // grip.addEventListener("mousedown", (e) => {
        //     //   this.thElm = el;
        //     //   this.startOffset = el.offsetWidth - e.pageX;
        //     //   this.lastWidth = 0
        //     // });
        //     el.appendChild(grip);
        //   })
        //   document.addEventListener("mousemove", (e) => {
        //     if (this.thElm) {
        //       this.lastWidth  = this.thElm.offsetWidth;
        //       this.thElm.style.width = this.startOffset + e.pageX + "px";
        //       document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
        //         if((internalIndex % 6) == Number(this.thElm.id)) {
        //           td.style.width = this.startOffset + e.pageX + "px";
        //         } else {
        //           td.style.width = td.offsetWidth + "px";
        //         }
        //       })
        //       this.$refs.exam_table_resize.style.width = this.$refs.exam_table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
        //     }
        //   });

        //   document.addEventListener("mouseup", () => {
        //     this.thElm = undefined;
        //   });
        // });
      }
    },
    async getUserInformation() {
      try {
        const { data } = await getUserById(this.userId)
        const { user } = data
        this.userRaw = user
      } catch (error) {
        console.log(error)
      }
    },
    async getUserExamsInformation() {
      try {
        const { data } = await getUserSessionsWithExams({ userId: this.userId })
        const { data: { exams, sessions } } = data
        this.exams = exams
        this.sessions = sessions
      } catch (error) {
        console.log(error)
      }
    },
    async getUserAssignments() {
      try {
        const { data } = await getUserAssignmentsWithStatus({ userId: this.userId })
        const { data: assignments } = data
        this.assignments = assignments
      } catch (error) {
        console.log(error)
      }
    },
    examTableResize() {
      this.$refs.exam_table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '  16.66%'
      })
    },
    hideModal(e) {
      if (e.target !== e.currentTarget) return
      this.$emit('hide')
    },
    showGeneralUserFeedback() {
      this.showGeneralFeedbackModal = true
    },
    async addGeneralFeedbackToUser(feedback) {
      try {
        this.addingFeedback = true
        await addGeneralFeedback({
          userId: this.userId,
          feedback: feedback ? feedback : null
        })
        this.showGeneralFeedbackModal = false
        await this.getUserData();
      } catch (error) {
        console.log(error)
      } finally {
        this.addingFeedback = false
      }
    },
    showModalFeedback({ feedback }) {
      if (!feedback) {
        this.$toast.open({
          message: this.$t('No feedback for this session yet.'),
          type: 'info',
          duration: 3000
        })
        return
      }
      this.feedbackSelected = feedback
      this.showFeedbackModal = true
    },
    handleExamPageChange(data) {
      this.examsDataTemp = data;
    },
    handleSessionPageChange(data) {
      this.sessionsDataTemp = data;
    },
    handleAssignmentPageChange(data) {
      this.assignmentsDataTemp = data;
    },
  },
}
</script>
<style lang="scss" scoped>
.fill-ods {
  background-color: #f5f5f5;
}

.backScreen {
  position: relative;
  z-index: var(--top-layer-z-index);
  background: rgb(0, 0, 0, 0.7);
  min-height: 100vh;
  min-width: 100vw;
}

.modalPosition {
  min-width: 60%;
  max-width: 100vw;
  min-height: 100%;
  overflow-y: scroll;
}

.closeModalIcon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.parent-container {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Space between the text and checkbox */
}

.checkbox-container {
  display: flex;
  align-items: center; /* Vertically center the label and checkbox */
}

.checkbox-label {
  margin-right: 0.5rem; /* Space between the label and checkbox */
  white-space: nowrap; /* Prevents wrapping of text */
}

</style>