<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Sessions (nav)')" />
      <div class="container background-form rounded-3 my-3 p-3">
        <PageTitle
          :title="$t('Session')"
          :description="$t('Sessions Configuration')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Session')"
        />
        <div class="row px-3 justify-content-between">
          <!-- Search bar -->
          <div class="col-12 col-lg-3">
            <rd-input
              :placeholder="$t('Search session by name')"
              type="text"
              @input="(e) => (this.search = e.target.value)"
            />
          </div>

          <!-- buttons -->
          <div class="col-12 col-lg-6 text-end">
            <button-component primary @handleClick="goToAddSession">
              <i
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Add Session"
                type="button"
                class="bi bi-plus-circle-fill"
              ></i>
              {{ $t("Add Session") }}
            </button-component>
          </div>
        </div>

        <div v-if="sessionsLoading">
          <rd-loading />
        </div>

        <div v-else>
          <div v-if="filteredSessions.length === 0" class="row p-3">
            <h2>
              {{ $t("No data found") }}
              <i
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="No sessions Found"
                type="button"
                class="bi bi-exclamation-triangle"
              ></i>
            </h2>
          </div>

          <div v-else class="row p-3 d-flex justify-content-start">
            <data-table-component
              :columns="[
                { label: $t('Session'), field: 'code', },
                { label: $t('Date'), field: 'sessionDate', },
                { label: $t('Trainer'), field: 'trainer', },
                { label: $t('Status'), field: 'state', type: 'dropdown', },
                { label: $t('Total Attendance'), field: 'sessionUsers', hAlign: 'center', headerHAlign: 'center', },
                { label: $t('Actions'), field: 'actions', type: 'dropdown', hAlign: 'center', headerHAlign: 'center', },
              ]"
              :rows="filteredSessions"
              resizeable
              paginator
            >
              <template #sessionDate="{ row }">
                <span v-if="!row.allDay" class="fw-normal">
                  {{ formatedDate(row.date) }}
                  {{ formatHour(row.startTime) }} to
                  {{ formatHour(row.endTime) }}</span
                >
                <span v-else class="fw-normal">
                  {{ formatedDate(row.date) }}
                  {{ $t("All Day") }}</span
                >
              </template>

              <template #state="{ row }">
                  <dropdown-menu
                    :items="status"
                    :selected="row.state"
                    @change="(value) => handleStatusSelected(value, row.id)"
                  />
              </template>

              <template #trainer="{ row }">
                {{
                  row.trainer
                    ? `${row.trainerInfo.userFirstname} ${row.trainerInfo.userLastname}`
                    : "No trainer found"
                }}
              </template>

              <template #actions="{ row }">
                <options-menu-component
                  :contextTitle="$t('Sessions options')"
                  :items="[
                    {
                      show: true,
                      label: $t('Edit Session'),
                      icon: 'bi-pencil-square',
                      callback: () => goToEditSession(row.id),
                    },
                    {
                      show: permissions.includes(APP_PERMISSIONS.SESSION_MANAGEMENT.DELETE),
                      label: $t('Delete Session'),
                      icon: 'bi-trash-fill',
                      callback: () => showModal(row),
                    },
                    {
                      show: true,
                      label: $t('Add session Users'),
                      icon: 'bi-person',
                      callback: () => goToAddUsers(row.id),
                    },
                    {
                      show: true,
                      label: $t('Session Feedback'),
                      icon: 'bi-chat-left-text',
                      callback: () => goToFeedbackSession(row.id),
                    },
                  ]"
                />
              </template>
            </data-table-component>

          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
    <rd-confirmation-modal
      v-if="showModalProp"
      modalTitle="Delete session permanently?"
      confirmButton="Delete"
      dismissButton="Dismiss"
      @confirmAction="deleteFile(sessionToDelete)"
      @dismissAction="dismissAction"
    />
  </div>
</template>
<script>
import rdFooter from "@/components/rd-components/rd-footer.vue";
import RdNavbar from "@/components/rd-components/rd-navbar.vue";
import router from "@/router";
import {
  getSessions,
  deleteSession,
  getSessionsState,
  updateSessionState,
} from "@/services/sessions/sessions.api.js";
import RdConfirmationModal from "@/components/rd-components/rd-confirmation-modal.vue";
import RdLoading from "@/components/rd-components/rd-loading.vue";
import RdInput from "@/components/rd-components/rd-input.vue";
import DropdownMenu from "../../components/dropdown-menu.vue";
import PageTitle from "../../components/rd-components/page-title.vue";
import OptionsMenuComponent from "../../components/options-menu-component.vue";
import buttonComponent from "../../components/button-component.vue";
import DataTableComponent from '../../components/tables/data-table-component.vue';
import {APP_PERMISSIONS} from '@/utils/constants.js'
import { mapState } from 'vuex';

export default {
  data() {
    return {
      sessions: [],
      sessionsLoading: true,
      showModalProp: false,
      sessionToDelete: {},
      search: "",
      status: [],
      APP_PERMISSIONS
    };
  },
  components: {
    rdFooter,
    RdNavbar,
    RdConfirmationModal,
    RdLoading,
    RdInput,
    DropdownMenu,
    PageTitle,
    OptionsMenuComponent,
    buttonComponent,
    DataTableComponent,
  },
  computed: {
    filteredSessions() {
      return this.sessions.filter((session) =>
        session.code.toLowerCase().includes(this.search.trim().toLowerCase())
      );
    },
    user() {
      return this.$store.getters.getUser;
    },
    ...mapState(['permissions'])
  },
  async mounted() {
    try {
      await this.setSession();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    formatHour(hour) {
      if (!hour) return "";
      let partsHour = hour.split(":");
      let hours = parseInt(partsHour[0]);
      let minutes = partsHour[1];

      let period = hours >= 12 ? "PM" : "AM";

      hours = hours > 12 ? hours - 12 : hours;
      hours = hours == 0 ? 12 : hours;

      let formatedHour = hours + ":" + minutes + " " + period;

      return formatedHour;
    },
    formatedDate(date) {
      if (!date) {
        return "";
      }
      return date.split("T")[0].replace(/-/g, "/");
    },
    async handleStatusSelected(value, id) {
      try {
        await updateSessionState(id, { state: value });
        this.$toast.open({
          message: this.$t("Session updated."),
          type: "success",
        })
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message: this.$t("Session could not be updated."),
          type: "error",
        })
      }
    },
    showModal(session) {
      this.showModalProp = true;
      this.sessionToDelete = session;
    },
    dismissAction() {
      this.sessionToDelete = {};
      this.showModalProp = false;
    },
    async deleteFile({ id }) {
      try {
        await deleteSession(id);
        await this.setSession();
      } catch (error) {
        console.log(error);
      } finally {
        this.showModalProp = false;
        this.sessionToDelete = {};
      }
    },
    async setSession() {
      try {
        this.sessionsLoading = true;
        const { data } = await getSessions();
        const { data: sessionState } = await getSessionsState();
        this.sessions = data;
        this.status = sessionState.map(({ id, state }) => ({
          value: id,
          label: state,
        }));
      } catch (error) {
        console.log(error);
      } finally {
        this.sessionsLoading = false;
      }
    },
    goToAddSession() {
      router.push(`/session/add`);
    },
    goToFeedbackSession(uuid) {
      router.push(`/session/feedback/${uuid}`);
    },
    goToAddUsers(uuid) {
      router.push(`/session/users/${uuid}`);
    },
    goToEditSession(uuid) {
      router.push(`/session/edit/${uuid}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.hoverable:hover {
  cursor: pointer;
}
.hoverable:hover {
  cursor: pointer;
}
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>