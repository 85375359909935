import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue'
import LoginScreen from '../views/login/login-screen.vue'
import LoginWithToken from '../views/login/login-with-token.vue'

// user routes
import UserManage from '../views/user/user-manage.vue'
import UserEdit from '../views/user/user-edit.vue'
import UserAdd from '../views/user/user-add.vue'
import UserProfile from '../views/user/user-profile.vue'
import UserManagement from '../views/user/user-management.vue'

// admin routes
import AdminView from '@/views/admin/admin-main.vue'
import CategoriesManage from '@/views/categories/categories-manage.vue'
import ProfilesManage from '@/views/profiles/profiles-manage.vue'
import FilesManage from '@/views/files/files-manage.vue'
import FilesManagement from '@/views/files/files-management.vue'

// exam routes
import ExamManage from '@/views/exam/exam-manage.vue'
import ExamAdd from '@/views/exam/exam-add.vue'
import ExamEdit from '@/views/exam/exam-edit.vue'
import ApplyExam from '@/views/exam/apply-exam.vue'
import ExamStats from '@/views/exam/exam-stats.vue'
import ExamGeneralStats from '@/views/exam/exam-general-stats.vue'
import ExamResults from '@/views/exam/exam-results.vue'
import ExamAttempts from '@/views/exam/exam-attempts.vue'

// general settings
import AdminGeneralSettings from '@/views/general-settings/admin-general-settings.vue'
import BackgroundSettings from '@/views/general-settings/background-settings.vue'
import StatusRezero from '@/views/general-settings/status-rezero.vue'

// files routes
import FileAdd from '@/views/files/files-add.vue'
import FileEdit from '@/views/files/files-edit.vue'
import FilesStream from '@/views/files/files-stream.vue'

// category routes
import CategoryAdd from '@/views/categories/category-add.vue'
import CategoryEdit from '@/views/categories/category-edit.vue'

// subcategory routes
import SubcategoryAdd from '@/views/subcategories/subcategories-add.vue'
import SubcategoryEdit from '@/views/subcategories/subcategories-edit.vue'
import SubcategoryAll from '@/views/subcategories/subcategories-all.vue'
import SubcategoryView from '@/views/subcategories/subcategory-view.vue'

// profile routes
import ProfileAdd from '@/views/profiles/profiles-add.vue'
import ProfileEdit from '@/views/profiles/profiles-edit.vue'

// languages routes
import LanguageManage from '@/views/languages/language-manage.vue'
import LanguageAdd from '@/views/languages/language-add.vue'
import LanguageEdit from '@/views/languages/language-edit.vue'

// mobile 
import FileDetail from '@/views/mobile/File-detail.vue'

// Session Routes
import SessionManage from '@/views/sessions/sessions-manage.vue'
import SessionScoreManage from '@/views/sessions/sessions-score-management.vue'
import SessionScoreAdd from '@/views/sessions/sessions-score-add.vue'
import SessionScoreEdit from '@/views/sessions/sessions-score-edit.vue'
import SessionEdit from '@/views/sessions/sessions-edit.vue'
import SessionAdd from '@/views/sessions/sessions-add.vue'
import SessionFeedback from '@/views/sessions/sessions-feedback.vue'
import SessionUser from '@/views/sessions/sessions-users.vue'

// SmartHire module
import SmarthireManagement from '@/views/smarthire/smarthire-management.vue'
import SmarthireStatusManagement from '@/views/smarthire/smarthire-status-management.vue'
import SmarthireStatusAdd from '@/views/smarthire/smarthire-status-add.vue'
import SmarthireStatusEdit from '@/views/smarthire/smarthire-status-edit.vue'

// Templates module
import TemplatesManagement from '@/views/templates/template-manage.vue'
import TemplatesAdd from '@/views/templates/template-add.vue'
import TemplatesEdit from '@/views/templates/template-edit.vue'

// assignments
import AssignmentsManagement from '@/views/assignments/assignments-management.vue'
import ActiveAssignments from '@/views/assignments/active-assignments.vue'
import EditAssignments from '@/views/assignments/Edit-assignment.vue'
import AdminViewAssignment from '@/views/assignments/Admin-View-Assignment.vue'
import CurrentActiveAssignment from '@/views/assignments/Assignment-detail.vue'
import AssignmentsSettings from '@/views/assignments/assignments-settings.vue'
import AssignmentMaps from '@/views/assignments/assignment-maps.vue'

// sites
import SitesManagement from '@/views/sites/sites-management.vue'
import SitesManage from '@/views/sites/sites-manage.vue'
import SitesResources from '@/views/sites/sites-resources.vue'
import SitesResourcesAdd from '@/views/sites/sites-resources-add.vue'
import SitesResourcesEdit from '@/views/sites/sites-resources-edit.vue'
import SitesEdit from '@/views/sites/sites-edit.vue'
import SitesAdd from '@/views/sites/sites-add.vue'

// teams
import TeamsManagement from '@/views/teams/teams-management.vue'

// Role module
import RolesManage from '@/views/roles/roles-manage.vue';
import RolesAdd from '@/views/roles/roles-add.vue'
import RolesEdit from '@/views/roles/roles-edit.vue'

// bulk load
import BulkLoadHistory from '@/views/bulk/bulk-load-history.vue'
import BulkLoadDetails from '@/views/bulk/bulk-load-details.vue'

import {APP_PERMISSIONS} from '@/utils/constants.js'

const publicRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginScreen
  },
  {
    path: '/login_with_token',
    name: 'LoginWithToken',
    component: LoginWithToken
  }
]

const privateRoutes = [
  {
    path: '/files',
    name: 'Home',
    component: Home,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION] }
  },
  {
    path: '/admin',
    name: 'Administration',
    component: AdminView,
    meta: { authorize: true, permissions: null }
  },
  {
    path: '/admin/files-management',
    name: 'Files Management',
    component: FilesManagement,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.FILE_UPLOADER, APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES, APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION] }
  },
  {
    path: '/admin/files',
    name: 'Files Manage',
    component: FilesManage,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.FILE_UPLOADER] }
  },
  {
    path: '/admin/file/add',
    name: 'Add File',
    component: FileAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.FILE_UPLOADER] }
  },
  {
    path: '/admin/file/edit/:uuid',
    name: 'Edit File',
    component: FileEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.FILE_UPLOADER] }
  },
  {
    path: '/admin/profiles',
    name: 'Profiles Managament',
    component: ProfilesManage,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.PROFILES.CRUD] }
  },
  {
    path: '/admin/profiles/add',
    name: 'Add Profile',
    component: ProfileAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.PROFILES.CRUD] }
  },
  {
    path: '/admin/profiles/edit/:id',
    name: 'Edit Profile',
    component: ProfileEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.PROFILES.CRUD] }
  },
  {
    path: '/admin/categories',
    name: 'Categories Managament',
    component: CategoriesManage,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES] }
  },
  {
    path: '/admin/categories/add',
    name: 'Add Category',
    component: CategoryAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES] }
  },
  {
    path: '/admin/categories/edit/:id',
    name: 'Edit Category',
    component: CategoryEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES] }
  },
  {
    path: '/admin/subcategories/add',
    name: 'Add Subategory',
    component: SubcategoryAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES] }
  },
  {
    path: '/admin/subcategories/edit/:id',
    name: 'Edit Subcategory',
    component: SubcategoryEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES] }
  },
  {
    path: '/admin/subcategories/all',
    name: 'All Subcategories',
    component: SubcategoryAll,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES] }
  },
  {
    path: '/admin/subcategories/view/:id',
    name: 'Subcategory View',
    component: SubcategoryView,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES] }
  },
  {
    path: '/admin/settings',
    name: 'General Settings',
    component: AdminGeneralSettings,
    meta: { authorize: true, permissions: [
      APP_PERMISSIONS.SYSTEM_MANAGEMENT.LANGUAGES, 
      APP_PERMISSIONS.SYSTEM_MANAGEMENT.BACKGROUND, 
      APP_PERMISSIONS.SYSTEM_MANAGEMENT.ASSIGNMENTS_PARAMETERS,
      APP_PERMISSIONS.SYSTEM_MANAGEMENT.SMART_HIRE_PARAMETERS,
      APP_PERMISSIONS.ROLES.CRUD,
      APP_PERMISSIONS.SESSION_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/settings/language',
    name: 'Language Settings',
    component: LanguageManage,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SYSTEM_MANAGEMENT.LANGUAGES] }
  },
  {
    path: '/admin/settings/language/add',
    name: 'Add Language',
    component: LanguageAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SYSTEM_MANAGEMENT.LANGUAGES] }
  },
  {
    path: '/admin/settings/language/edit/:id',
    name: 'Edit Language',
    component: LanguageEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SYSTEM_MANAGEMENT.LANGUAGES] }
  },
  {
    path: '/admin/settings/background',
    name: 'Background Settings',
    component: BackgroundSettings,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SYSTEM_MANAGEMENT.BACKGROUND] }
  },
  {
    path: '/admin/settings/status-rezero',
    name: 'Status Rezero',
    component: StatusRezero,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SYSTEM_MANAGEMENT.BACKGROUND] }
  },
  {
    path: '/admin/user-management',
    name: 'Users Managament',
    component: UserManagement,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.USER_MANAGEMENT.CRUD, APP_PERMISSIONS.USER_MANAGEMENT.EDIT_USER] }
  },
  {
    path: '/admin/user',
    name: 'Users Manage',
    component: UserManage,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.USER_MANAGEMENT.CRUD, APP_PERMISSIONS.USER_MANAGEMENT.EDIT_USER] }
  },
  {
    path: '/admin/user/add',
    name: 'Add User',
    component: UserAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.USER_MANAGEMENT.CRUD, APP_PERMISSIONS.USER_MANAGEMENT.EDIT_USER] }
  },
  {
    path: '/admin/user/edit/:id',
    name: 'Edit User',
    component: UserEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.USER_MANAGEMENT.CRUD, APP_PERMISSIONS.USER_MANAGEMENT.EDIT_USER] }
  },
  {
    path: '/admin/user/:id',
    name: 'User Profile',
    component: UserProfile,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.USER_MANAGEMENT.CRUD, APP_PERMISSIONS.USER_MANAGEMENT.EDIT_USER] }
  },
  {
    path: '/files/:uuid',
    name: 'Video Stream',
    component: FilesStream,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION] }
  },
  {
    path: '/file/detail/:uuid',
    name: 'File Details',
    component: FileDetail,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION] }
  },
  {
    path: '/session',
    name: 'Session Management',
    component: SessionManage,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SESSION_MANAGEMENT.CRUD] }
  },
  {
    path: '/session/score-config',
    name: 'Session Score Management',
    component: SessionScoreManage,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SYSTEM_MANAGEMENT.USER_RATING_SETTINGS] }
  },
  {
    path: '/session/score-config/create',
    name: 'Session Score Add',
    component: SessionScoreAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SYSTEM_MANAGEMENT.USER_RATING_SETTINGS] }
  },
  {
    path: '/session/score-config/edit/:id',
    name: 'Session Score Edit',
    component: SessionScoreEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SYSTEM_MANAGEMENT.USER_RATING_SETTINGS] }
  },
  {
    path: '/session/edit/:id',
    name: 'Session Edit',
    component: SessionEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SESSION_MANAGEMENT.CRUD] }
  },
  {
    path: '/session/feedback/:id',
    name: 'Session Feedback',
    component: SessionFeedback,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SESSION_MANAGEMENT.CRUD] }
  },
  {
    path: '/session/users/:id',
    name: 'Session Users',
    component: SessionUser,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SESSION_MANAGEMENT.CRUD] }
  },
  {
    path: '/session/add',
    name: 'Session Add',
    component: SessionAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SESSION_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/exam',
    name: 'Exam Management',
    component: ExamManage,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SESSION_MANAGEMENT.CRUD, APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/exam/add',
    name: 'Exam Add',
    component: ExamAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/exam/edit/:id',
    name: 'Exam Edit',
    component: ExamEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD] }
  },
  {
    path: '/apply/exam/:id',
    name: 'Apply Exam',
    component: ApplyExam,
    meta: {
      authorize: true,
      permissions: [
        APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION,
        APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD,
        APP_PERMISSIONS.SESSION_MANAGEMENT.CRUD
      ]
    }
  },
  {
    path: '/admin/exam/stats/:id',
    name: 'Exam Statistics',
    component: ExamStats,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD, APP_PERMISSIONS.SESSION_MANAGEMENT.CRUD] }
  },
  {
    path: '/view/exam/:id',
    name: 'Exam Results',
    component: ExamResults,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION, APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD] }
  },
  {
    path: '/view/attempts/:id',
    name: 'Exam Attemps',
    component: ExamAttempts,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION, APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/exam/stats',
    name: 'Exam General Statistics',
    component: ExamGeneralStats,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/smarthire',
    name: 'SmartHire Module',
    component: SmarthireManagement,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.POLL_WORKER_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/smarthire/status',
    name: 'SmartHire Status',
    component: SmarthireStatusManagement,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SYSTEM_MANAGEMENT.SMART_HIRE_PARAMETERS] }
  },
  {
    path: '/admin/smarthire/status/create',
    name: 'SmartHire Status Create',
    component: SmarthireStatusAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SYSTEM_MANAGEMENT.SMART_HIRE_PARAMETERS] }
  },
  {
    path: '/admin/smarthire/status/edit/:id',
    name: 'SmartHire Status Edit',
    component: SmarthireStatusEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SYSTEM_MANAGEMENT.SMART_HIRE_PARAMETERS] }
  },
  {
    path: '/admin/template/management',
    name: 'Templates Module',
    component: TemplatesManagement,
    meta: { authorize: true }
  },
  {
    path: '/admin/template/add',
    name: 'Templates Module Add',
    component: TemplatesAdd,
    meta: { authorize: true }
  },
  {
    path: '/admin/template/edit/:id',
    name: 'Templates Module Edit',
    component: TemplatesEdit,
    meta: { authorize: true }
  },
  {
    path: '/admin/roles',
    name: 'Roles Module',
    component: RolesManage,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.ROLES.CRUD] }
  },
  {
    path: '/admin/roles/add',
    name: 'Roles Module Add',
    component: RolesAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.ROLES.CRUD] }
  },
  {
    path: '/admin/roles/edit/:id',
    name: 'Roles Module Edit',
    component: RolesEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.ROLES.CRUD] }
  },
  {
    path: '/admin/assignments-management',
    name: 'Assignments Management',
    component: AssignmentsManagement,
    meta: {
      authorize: true,
      permissions: [
        APP_PERMISSIONS.ASSIGNMENTS.ENABLE_ASSIGNMENTS,
        APP_PERMISSIONS.ASSIGNMENTS.VISUALIZATION, 
        APP_PERMISSIONS.ASSIGNMENTS.EDIT_ASSIGNMENTS, 
        APP_PERMISSIONS.ASSIGNMENTS.DELETE_ASSIGNMENTS, 
        APP_PERMISSIONS.ASSIGNMENTS.ACTIVE_ASSIGNMENTS,
        APP_PERMISSIONS.FIELD_WORK_ASSIGNMENTS.WORKER
      ]
    }
  },
  {
    path: '/admin/assignments-settings',
    name: 'Assignments Settings',
    component: AssignmentsSettings,
    meta: {
      authorize: true,
      permissions: [
        APP_PERMISSIONS.SYSTEM_MANAGEMENT.ASSIGNMENTS_PARAMETERS
      ]
    }
  },
  {
    path: '/admin/assignments/maps',
    name: 'Maps',
    component: AssignmentMaps,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.ASSIGNMENTS.MAPS] }
  },
  {
    path: '/admin/active-assignments',
    name: 'Active assignments',
    component: AdminViewAssignment,
    meta: {
      authorize: true,
      permissions: [
        APP_PERMISSIONS.ASSIGNMENTS.ENABLE_ASSIGNMENTS,
        APP_PERMISSIONS.ASSIGNMENTS.VISUALIZATION,
        APP_PERMISSIONS.ASSIGNMENTS.EDIT_ASSIGNMENTS, 
        APP_PERMISSIONS.ASSIGNMENTS.DELETE_ASSIGNMENTS, 
        APP_PERMISSIONS.ASSIGNMENTS.ACTIVE_ASSIGNMENTS
    ]
    }
  },
  {
    path: '/admin/edit/assignment/:id',
    name: 'Edit Active assignments',
    component: EditAssignments,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.ASSIGNMENTS.EDIT_ASSIGNMENTS] }
  },
  {
    path: '/admin/current-assignments',
    name: 'Current assignments',
    component: ActiveAssignments,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FIELD_WORK_ASSIGNMENTS.WORKER] }
  },
  {
    path: '/admin/current-assignments/:task',
    name: 'Current Active assignments',
    component: CurrentActiveAssignment,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.FIELD_WORK_ASSIGNMENTS.WORKER] }
  },
  {
    path: '/admin/sites/edit/:id',
    name: 'Sites Edit',
    component: SitesEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SITE_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/sites/add',
    name: 'Sites add',
    component: SitesAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SITE_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/sites-management',
    name: 'Sites management',
    component: SitesManagement,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SITE_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/sites',
    name: 'Sites manage',
    component: SitesManage,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SITE_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/sites/resources/:id',
    name: 'Sites resources',
    component: SitesResources,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SITE_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/sites/resources/add/:id',
    name: 'Sites resources add',
    component: SitesResourcesAdd,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SITE_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/sites/resources/edit/:siteId/:id',
    name: 'Sites resources edit',
    component: SitesResourcesEdit,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SITE_MANAGEMENT.CRUD] }
  },
  {
    path: '/admin/teams-management',
    name: 'Teams management',
    component: TeamsManagement,
    meta: { authorize: true, permissions: [APP_PERMISSIONS.SYSTEM_MANAGEMENT.ASSIGNMENTS_PARAMETERS] }
  },
  {
    path: '/admin/bulk-load-history',
    name: 'Bulk Load History',
    component: BulkLoadHistory,
    meta: {
      authorize: true,
      permissions: [
        APP_PERMISSIONS.BULK_UPLOAD.USERS,
        APP_PERMISSIONS.BULK_UPLOAD.CATEGORIES,
        APP_PERMISSIONS.BULK_UPLOAD.SUB_CATEGORIES,
        APP_PERMISSIONS.BULK_UPLOAD.SESSIONS,
        APP_PERMISSIONS.BULK_UPLOAD.REGIONS,
        APP_PERMISSIONS.BULK_UPLOAD.TEAMS,
        APP_PERMISSIONS.BULK_UPLOAD.SITES,
      ]
    }
  },
  {
    path: '/admin/bulk-load-details/:id',
    name: 'Bulk Load Details',
    component: BulkLoadDetails,
    meta: {
      authorize: true,
      permissions: [
        APP_PERMISSIONS.BULK_UPLOAD.USERS,
        APP_PERMISSIONS.BULK_UPLOAD.CATEGORIES,
        APP_PERMISSIONS.BULK_UPLOAD.SUB_CATEGORIES,
        APP_PERMISSIONS.BULK_UPLOAD.SESSIONS,
        APP_PERMISSIONS.BULK_UPLOAD.REGIONS,
        APP_PERMISSIONS.BULK_UPLOAD.TEAMS,
      ],
    },
  }
];

const routes = publicRoutes.concat(privateRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta
  const user = store.getters.getUser
  store.commit('setShowBackButton', true)
  // enviar al /login cuando quiera ir al root
  if (to.path === '/') return next('/login')

  // si la ruta es publica, dejar pasar
  if(!authorize) return next();
  if((to.path === '/login') || (to.path === '/login_with_token')) {
    return next();
  }
  if(!store.state.statusLogin) {
    return next('/login');
  }
  if(user.isAdmin) {
    return next();
  }
  if(!to.meta.permissions) {
    // Calc route on permissions
    if(store.state.permissions.length == 1) {
      store.commit('setShowBackButton', false)
      switch (store.state.permissions[0]) {
        case APP_PERMISSIONS.SYSTEM_MANAGEMENT.LANGUAGES:
          return next('/admin/settings/language');
        case APP_PERMISSIONS.SYSTEM_MANAGEMENT.BACKGROUND:
          return next('/admin/settings/background');
        case APP_PERMISSIONS.SYSTEM_MANAGEMENT.SMART_HIRE_PARAMETERS:
          return next('/admin/smarthire/status');
        case APP_PERMISSIONS.USER_MANAGEMENT.CRUD:
          return next('/admin/user');
        case APP_PERMISSIONS.USER_MANAGEMENT.EDIT_USER:
          return next('/admin/user');
        case APP_PERMISSIONS.ROLES.CRUD:
          return next('/admin/roles');
        case APP_PERMISSIONS.PROFILES.CRUD:
          return next('/admin/profiles');
        case APP_PERMISSIONS.FILES_MANAGEMENT.FILE_UPLOADER:
          return next('/admin/files');
        case APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES:
          return next('/admin/categories');
        case APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION:
          return next('/files');
        case APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD:
          return next('/admin/exam');
        case APP_PERMISSIONS.SITE_MANAGEMENT.CRUD:
          return next('/admin/sites');
        case APP_PERMISSIONS.ASSIGNMENTS.ENABLE_ASSIGNMENTS:
          return next('/admin/active-assignments');
        case APP_PERMISSIONS.ASSIGNMENTS.VISUALIZATION:
          return next('/admin/active-assignments');
        case APP_PERMISSIONS.ASSIGNMENTS.EDIT_ASSIGNMENTS:
          return next('/admin/active-assignments');
        case APP_PERMISSIONS.ASSIGNMENTS.ACTIVE_ASSIGNMENTS:
          return next('/admin/active-assignments');
        case APP_PERMISSIONS.POLL_WORKER_MANAGEMENT.CRUD:
          return next('/admin/smarthire');
        case APP_PERMISSIONS.SYSTEM_MANAGEMENT.ASSIGNMENTS_PARAMETERS:
          return next('/admin/assignments-settings');
        case APP_PERMISSIONS.SYSTEM_MANAGEMENT.USER_RATING_SETTINGS:
          return next('/session/score-config');
        
        case APP_PERMISSIONS.FIELD_WORK_ASSIGNMENTS.WORKER:
          return next('/admin/current-assignments');
          
      
        default:
          return next();
      }
    }
    return next();
  }
  
  if(to.meta.permissions.some(permission => store.state.permissions.includes(permission))) {
    return next();
  }
  return next('/login');
  // const viewerAllowedRoutes = (
  //   to.path ==='/files' 
  //   || to.name === 'Video Stream'
  //   || to.name === 'File Details'
  //   || to.name === 'Apply Exam'
  //   || to.name === 'Exam Results'
  //   || to.name === 'Exam Attemps'
  // )
  // const trainerAllowedRoutes = (
  //   to.name === 'Session Management'
  //   || to.name === 'Session Edit'
  //   || to.name === 'Session Feedback'
  //   || to.name === 'Session Users'
  //   || to.name === 'Session Add'
  //   || to.name === 'Administration'
  //   || to.name === 'Exam Statistics'
  //   || to.name === 'Apply Exam'
  //   || to.name === 'Exam Management'
  // )
  
  // if (
  //   user.isAdmin 
  //   || user.isUploader 
  //   || ((user.isViewer || user.isViewDownloader) && viewerAllowedRoutes) 
  //   || ((user.isTrainer) && trainerAllowedRoutes)
  //   || (to.path === '/login')
  //   || (to.path === '/login_with_token')
  // ) {
  //   next();
  // } else {
  //   next('/login');
  // }
});

router.afterEach((to) => {
  if (to.path !== '/login' && to.path !== '/files' && to.path !== '/login_with_token') {
    store.dispatch('refreshTokenSession');
  }
});

export default router
