<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Settings (nav)')" />
      <div class="container background-form my-3 p-3">
        <PageTitle
          :title="$t('Sites Management')"
          :description="$t('Sites module')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Sites Management')"
        />
        <div class="container mt-3 py-3">
          <div class="row g-4">

            <div class="col-12 col-lg-4" v-if="permissions.includes(APP_PERMISSIONS.SITE_MANAGEMENT.CRUD) || user.isAdmin">
              <rd-admin-card
                :name="$t('Sites')"
                :description="$t('Go to edit sites')"
                @route="goTo('/admin/sites')"
              />
            </div>

            <div class="col-12 col-lg-4" v-if="user.isAdmin">
              <rd-admin-card
                :name="$t('Sites to teams')"
                :description="$t('Assign sites to teams')"
                @route="handleShowSitesToTeams"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>

    <!-- Sites to teams modal -->
    <div class="modal fade" id="sitesToTeamsModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

          <div v-if="executingSitesToTeams" class="overlay">
            <div v-if="executingSitesToTeams" class="overflow-loading">
              <rd-loading />
            </div>
          </div>

          <div class="modal-header pb-0 d-flex flex-column flex-md-row justify-content-between">
            <h5 class="modal-title text-start">
              {{ $t('Execute Site - Team Assignment') }} 
            </h5>
            <span class="align-middle">
              <rd-calendar @getCalendar="getSitesToTeamsCalendar" />
            </span>
          </div>
          <div class="modal-body text-uppercase">
            <p class="text-left">
              {{ $t('warning') }} 
              <br>
              {{ $t('No previous assignment along site with teams made before. Are you sure to continue?') }}
              <br>
              <rd-input
                class="mt-2"
                :label="$t('Enter the max radius for this site-team assignment')"
                :placeholder="$t('Radius')"
                type="number"
                :value="siteToTeamRadius"
                :initialValue="siteToTeamRadius"
                @clearIcon="(e) => this.siteToTeamRadius = e"
                @input="(e) => this.siteToTeamRadius = e.target.value"
              />
              <rd-input
                type="select"
                :placeholder="$t('Size agrupation for 1 site')"
                :placeholderToTag="$t('Add')" 
                :items="siteSizeOptions"
                @handleAddTagging="handleSizeSelection"
              />
            </p>
          </div>
          <div class="modal-footer">
            <button-component
              type="button"
              ref="hideSitesToTeamsModal"
              class="btn btn-outline-primary"
              @handleClick="hideSitesToTeamsModal"
              data-bs-dismiss="modal"
            >
              {{ $t('Dismiss') }}
            </button-component>
            <button-component
              class="position-relative"
              @handleClick="executeSitesToTeams"
              :disabled="executingSitesToTeams"
              primary
            >
              {{ $t('Confirm') }}
            </button-component>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import RdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdAdminCard from '@/components/rd-components/rd-admin-card.vue'
import router from '@/router'
import { mapState } from 'vuex'
import {APP_PERMISSIONS} from '@/utils/constants.js'
import PageTitle from '@/components/rd-components/page-title.vue'
import RdCalendar from '@/components/rd-components/rd-calendar.vue'
import RdLoading from '@/components/rd-components/rd-loading.vue'
import RdInput from '@/components/rd-components/rd-input.vue'
import ButtonComponent from '@/components/button-component.vue'
import { Modal } from 'bootstrap'
import { massiveTeamAssignments } from '@/services/assignments/assignment.api'
import { getAllSizes } from '@/services/sizes/sizes.api'

export default {
  components: { 
    RdFooter, 
    RdNavbar, 
    RdAdminCard,
    PageTitle,
    RdCalendar,
    RdLoading,
    RdInput,
    ButtonComponent
  },
  data() {
    return {
      APP_PERMISSIONS,
      executingSitesToTeams: false,
      siteToTeamCalendar: {
        date: '',
        formattedDate: ''
      },
      siteToTeamRadius: 15,
      siteSizeOptions: [],
    }
  },
  created() {
    if(!this.user.isAdmin) {
      const permissionsFiltered = this.permissions.filter(permission => [
        APP_PERMISSIONS.SITE_MANAGEMENT.CRUD
      ].includes(permission));
      if(permissionsFiltered.length == 1) {
        switch (permissionsFiltered[0]) {
          case APP_PERMISSIONS.SITE_MANAGEMENT.CRUD:
            router.replace('/admin/sites');
            return;
          default:
            return;
        }
      }
    }
  },
  async mounted() {
    await this.getSizesData();
  },
  computed: {
    ...mapState(['user', 'permissions'])
  },
  methods: {
    async getSizesData() {
      try {
        const { data } = await getAllSizes()
        this.siteSizeOptions = data.map(size => ({ name: size.name, value: size.id }));
      } catch (error) {
        console.log(error)
      }
    },
    goTo(route) {
      router.push(route)
    },
    handleShowSitesToTeams() {
      let sitesToTeamsModal = new Modal(document.getElementById('sitesToTeamsModal'))
      sitesToTeamsModal.show(sitesToTeamsModal)
    },
    getSitesToTeamsCalendar(calendar) {
      this.siteToTeamCalendar = calendar
    },
    handleSizeSelection(value) {
      const data = {
        name: value.name,
        value: value.value
      }
      this.siteSizeSelected = data
    },
    hideSitesToTeamsModal() {
      this.$refs.hideSitesToTeamsModal.$refs.button.click()
    },
    async executeSitesToTeams() {
      try {
        this.executingSitesToTeams = true
        const request = {
          date: this.siteToTeamCalendar.date,
          radius: this.siteToTeamRadius,
          size: this.siteSizeSelected?.value || null,
        }

        const {data} = await massiveTeamAssignments(request);
        if(data.statusCode=== 200){
          this.$store.dispatch('dispatchNotification', {
            title: 'Success!',
            message: data.message,
            type: 'success',
          });
          this.hideSitesToTeamsModal()
        }
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: error.message,
          type: 'error',
        });
      } finally {
        this.executingSitesToTeams = false
      }
    },
  },
}
</script>