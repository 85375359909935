<template lang="">
<div class="d-flex flex-column justify-content-between vh-100">
  <div>
    <rd-navbar :title="$t('Sessions (nav)')" />
    <div class="container background-form my-3 p-3">
      <div class="row">
        <div class="col-12">
          <h2 class="ms-4 text-start">{{ $t('Trainer Feedback') }}</h2>
        </div>
        <div class="col-12">
          <h2 class="ms-4 text-start fw-bolder">{{ $t('Description') }}: <span class="fw-normal"> {{description}}</span></h2>
        </div>
        <div class="col-12">
          <p class="ms-4 mb-0 text-start fw-bolder">{{ $t('Name') }}: <span class="fw-normal"> {{code}}</span></p>
        </div>
        <div class="col-12">
          <p class="ms-4 mb-0 text-start fw-bolder">{{ $t('Date & Time') }}:
            <span v-if="!allDay" class="fw-normal"> {{formatedDate(date)}} - {{formatHour(startTime)}} - {{formatHour(endTime)}}</span>
            <span v-else class="fw-normal"> {{formatedDate(date)}} - {{ $t('All Day') }}</span>
          </p>
        </div>
        <div class="col-12">
          <p class="ms-4 mb-0 text-start fw-bolder">{{ $t('Trainer') }}:
            <span class="fw-normal"> {{trainer}}</span>
          </p>
        </div>
        <div class="row px-3 justify-content-between">
          <div class="col-12 col-lg-4">
            <rd-input class="w-100" :placeholder="$t('Search file by file name')" type="text" @input="(e) => this.search = e.target.value" />
          </div>

          <div class="col-12 col-lg-4 text-end">
            <button-component v-if="isSelected" primaryOutline @handleClick="downloadFeedback">
              <i class="bi bi-arrow-down-circle-fill"></i> {{ $t('Download Feedback') }}
            </button-component>
            <Tooltip :text="$t('Please select at least one user')" v-else>
              <div class="tooltip-wrapper" data-bs-toggle="tooltip" data-bs-placement="right" title="Download Feedback">
                <button-component @handleClick="downloadFeedback" :disabled="!isSelected">
                  <i class="bi bi-arrow-down-circle-fill"></i> {{ $t('Download Feedback') }}
                </button-component>
              </div>
            </Tooltip>
          </div>
        </div>

        <div v-if="dataLoading">
          <rd-loading />
        </div>

        <div v-else>
          <div v-if="filteredUsers.length === 0" class="row p-3">
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No data Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <div v-else class="row p-3 d-flex justify-content-start">

            <data-table-component
              :columns="[
                { label: $t(' '), field: 'check', hAlign: 'center', headerHAlign: 'center', },
                { label: $t('Users (table)'), field: 'user', hAlign: 'center', headerHAlign: 'center', },
                { label: $t('Score (table)'), field: 'score', hAlign: 'center', headerHAlign: 'center',  },
                { label: $t('Rating (table)'), field: 'rating', type: 'dropdown', hAlign: 'center', headerHAlign: 'center', },
                { label: $t('Feedback (table)'), field: 'feedback', type: 'dropdown', hAlign: 'center', headerHAlign: 'center', },
              ]"
              :rows="filteredUsers"
              resizeable
              paginator
            >

              <template #checkHeader>
                <rd-input @change="handleSelectAll" :value="true" v-model="selectAll" :checked2="selectAll" type="checkbox"/> 
              </template>

              <template #check="{ row }">
                <!-- TODO: check the checkbox component -->
                <rd-input
                  :value="row.feedBackToDownload"
                  :checked2="row.feedBackToDownload"
                  type="checkbox"
                  @click="handleClickUser(row)"
                />
              </template>

              <template #user="{ row }">
                {{ `${row.user.userFirstname ?? ''} ${row.user.userLastname ?? ''}` }}
              </template>

              <template #score="{ row }">
                {{ row.examAttemp ? row.examAttemp.score : '-' }} / {{ questionCount }}
              </template>

              <template #rating="{ row }">
                <dropdown-menu
                  :key="row.sessionsUser.id"
                  :selected="row?.sessionsUser?.rating"
                  :items="options"
                  @change="(value) => handleChangeRating({ value, user: row })"
                />
              </template>

              <template #feedback="{ row }">
                <options-menu-component
                  :contextTitle="$t('Feedback')"
                  :items="[
                    {
                      show: true,
                      label: $t('Feedback (btn)'),
                      callback: () => showModalFeedback(row)
                    },
                  ]"
                />
              </template>
            </data-table-component>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" @click.self="hide" class="modal-feedback">
    <div class="embed-container p-3">
      <div class="w-100">
        <h2 class="text-start ms-2">{{ $t('Feedback') }}</h2>
        <p class="text-start ms-2">{{ $t('Observations') }}</p>
        <div class="border rounded p-3">
          <textarea v-model="feedback" resize class="form-control custom-textarea" :placeholder="$t('Feedback')"></textarea>
        </div>
      </div>
      <div class="d-flex w-100 mt-3" style="gap: 1rem;">
        <button-component
          primaryOutline
          class="w-100"
          @handleClick="hide"
        >
          {{ $t('Cancel') }}
        </button-component>
        <button-component
          :disabled="false"
          primary
          class="w-100" 
          @handleClick="handleSave"
        >
          {{ $t('Save') }}
        </button-component>
        <!-- <div class="col-6 col-lg-2">
          
        </div>
        <div class="col-6 col-lg-2">
        </div> -->
      </div>
      <i data-bs-toggle="tooltip" data-bs-placement="right" title="Close" type="button" @click.self="hide" class="bi bi-x-circle close"></i>
    </div>
    
  </div>
  <div>
    <rd-footer />
  </div>
</div>
</template>

<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import router from '@/router'
import RdInput from '@/components/rd-components/rd-input.vue'
import DropdownMenu from '@/components/dropdown-menu.vue'
import {
  getSession,
  getSessionsUsers,
  editSessionFeedback
} from '@/services/sessions/sessions.api.js'
import {
  convertToCSV
} from '@/utils/convertFormat';
import {
  downloadCSV
} from '@/utils/handleFile';
import { mapState } from 'vuex';
import optionsMenuComponent from '@/components/options-menu-component.vue';
import { getRatings } from '../../services/sessions/sessions-score.api'
import buttonComponent from '../../components/button-component.vue';
import DataTableComponent from '../../components/tables/data-table-component.vue';
import Tooltip from '../../components/tooltip.vue'

export default {
  data() {
    return {
      date: null,
      startTime: null,
      endTime: null,
      code: null,
      trainer: null,
      allDay: true,
      sessionUsers: [],
      dataLoading: true,
      selectAll: false,
      dataTemp: [],
      questionCount: '-',
      options: [],
      search: '',
      showModal: false,
      currentUser: null,
      feedback: null,
      description: '',
      user: null,
      display: "none",
      left: "",
      top: "",
      callback: null,
      closeDisplay: null,
      sessionsUser: null,
      topSelect: "",
      leftSelect: "",
      displaySelect: "none"
    }
  },

  computed: {
    ...mapState(['isInternalMobile']),
    sessionId() {
      return this.$route.params.id
    },
    filteredUsers() {
      return this.sessionUsers.filter(
        user => `${user.user.userFirstname ?? ''} ${user.user.userLastname ?? ''}`.toLowerCase().includes(this.search.trim().toLowerCase())
      )
    },
    isSelected() {
      const usersFilter = this.filteredUsers.filter(
        user => user.feedBackToDownload
      );
      return usersFilter.length;
    }
  },
  watch: {
    filteredUsers(current) {
      this.selectAll = current.every((value) => value.feedBackToDownload === true)
    }
  },
  async mounted() {
    await this.setSessionData()
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    optionsMenuComponent,
    DropdownMenu,
    buttonComponent,
    DataTableComponent,
    Tooltip,
  },
  methods: {
    async handleChangeRating({ user, value }) {
      try {
        await editSessionFeedback(user.sessionsUser.id, {
          feedback: user.sessionsUser.feedback,
          rating: value
        })
        this.$toast.open({
          message: this.$t('Saved'),
          type: 'success',
        })
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('The feedback was not saved'),
          type: 'error',
        })
      }
    },
    async handleSave() {
      try {
        await editSessionFeedback(this.currentUser.sessionsUser.id, {
          feedback: this.feedback,
          rating: this.currentUser.sessionsUser.rating
        })
        this.currentUser.sessionsUser.feedback = this.feedback
        this.showModal = false;
        this.feedback = null
        this.currentUser = null

        this.$toast.open({
          message: this.$t('Saved'),
          type: 'success',
        })
      } catch (error) {
        console.log(error)
        this.$toust.open({
          message: this.$t('The feedback was not saved'),
          type: 'error',
        })
      }
    },
    showModalFeedback(user) {
      this.currentUser = user
      this.showModal = true
      this.feedback = this.currentUser.sessionsUser.feedback
    },
    hide() {
      this.showModal = false;
      this.feedback = null
      this.currentUser = null
    },
    handleClickUser(user) {
      this.filteredUsers = this.filteredUsers.map((sessionUser) => {
        if (sessionUser.user.userName === user.user.userName) {
          sessionUser.feedBackToDownload = !sessionUser.feedBackToDownload
        }
        return sessionUser
      })
      this.selectAll = this.filteredUsers.every((value) => value.feedBackToDownload === true)
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    handleSelectAll() {
      this.filteredUsers.forEach((sessionUser) => {
        sessionUser.feedBackToDownload = !this.selectAll;
      })
      this.selectAll = !this.selectAll;
    },
    async downloadFeedback() {
      try {
        const usersFilter = this.filteredUsers.filter(
          user => user.feedBackToDownload
        );
        const usersToDownload = usersFilter.map((user) => {
          return {
            name: `${user.user.userFirstname ?? ''} ${user.user.userLastname ?? ''}`,
            score: `${user.examAttemp ? user.examAttemp.score : '-'} / ${this.questionCount}`,
            rating: user.sessionsUser.rating,
            feedback: user.sessionsUser.feedback
          }
        });
        const csv = await convertToCSV({ data: usersToDownload });
        downloadCSV({ data: csv, filename: 'feedback_stats' });
      } catch (error) {
        console.log(error);
      }
    },
    formatHour(hour) {
      if (!hour) return ''
      let partsHour = hour.split(":");
      let hours = parseInt(partsHour[0]);
      let minutes = partsHour[1];

      let period = (hours >= 12) ? "PM" : "AM";

      hours = (hours > 12) ? hours - 12 : hours;
      hours = (hours == 0) ? 12 : hours;

      let formatedHour = hours + ":" + minutes + " " + period;

      return formatedHour;
    },
    formatedDate(date) {
      if (!date) {
        return ''
      }
      return date.split('T')[0].replace(/-/g, "/")
    },
    async setSessionData() {
      this.dataLoading = true
      try {
        const {
          data
        } = await getSession(this.sessionId)
        const {
          data: sessionUsers
        } = await getSessionsUsers(this.sessionId)
        const {
          session,
          trainer,
          exam,
          questions
        } = data;
        this.questionCount = questions ? questions.length : '-'
        this.description = session.description
        sessionUsers.forEach((sessionUser) => {
          sessionUser.feedBackToDownload = false;
          if(exam) {
            const examAttemp = exam.find(element => element.userName === sessionUser.user.userName)
            if(examAttemp) {
              sessionUser.examAttemp = examAttemp
            }
          }
        })
        
        const {
          date,
          startTime,
          endTime,
          code,
          allDay
        } = session
        this.allDay = allDay
        this.date = date;
        this.startTime = startTime;
        this.endTime = endTime;
        this.code = code;
        this.trainer = `${trainer?.userFirstname ?? ''} ${trainer?.userLastname ?? ''}`
        this.sessionUsers = sessionUsers
        const scores = await getRatings();
        this.options = scores.data.map((score) => {
          return {
            label: score.score,
            value: score.id
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.dataLoading = false;
      }
    },
    goBack() {
      router.back()
    }
  },
}
</script>

<style scoped>
.centered {
  text-align: center;
  vertical-align: middle;
}
.modal-feedback {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4); /* fondo negro con opacidad del 40% */
  z-index: var(--top-layer-z-index); /* asegura que el modal se superpone a otros elementos */
  display: flex;
  justify-content: center;
  align-items: center;
}
.embed-container {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  flex-flow: column;
}
.close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}
.custom-textarea {
  min-width: 500px;
  width: 100%;
  height: 150px;
  resize: none;
}
@media (max-width: 768px) {
  .close {
    font-size: 1rem;
  }
  .custom-textarea {
    min-width: auto;
  }
  .embed-container {
    width: 90%;
  }
}

.tooltip-wrapper {
  display: inline-flex;
  align-items: center;
  /* Adjusts tooltip to button spacing */
  margin-right: 0;
  padding-right: 0;
}

.tooltip-wrapper .tooltip {
  /* Remove any additional margin */
  margin: 0;
  padding: 0;
}

</style>
