import httpClient from "../httpClient";

const END_POINT = '/api/site'
const getAllSites = async () => await httpClient.get(END_POINT)
const getSiteById = async (siteId) => await httpClient.get(`${END_POINT}/${siteId}`)
const getAllResourcesById = async (siteId) => await httpClient.get(`${END_POINT}/resources/${siteId}`)
const updateSiteById = async (siteId, site) => await httpClient.patch(`${END_POINT}/${siteId}`, site)
const massiveLoadForSites = async (csvFile) => await httpClient.post(`${END_POINT}/import`, csvFile)
const createSite = async (site) => await httpClient.post(END_POINT, site)

export {
  getAllSites,
  getSiteById,
  getAllResourcesById,
  updateSiteById,
  massiveLoadForSites,
  createSite,
}