<template>
  <div class="mb-2">

    <div class="calendar-container" @click="showCalendar = true;">
      <div style="width: fit-content; position: relative;">
        <input 
          v-model="fecha" 
          ref="input_container" 
          type="text" 
          :placeholder="$t('mes,día,año')" 
          readonly 
          class="px-4"
        />
        <i style="position: absolute; right: 10px; top: 2px" class="bi bi-calendar"></i>
      </div>
      <div class="calendar container" v-if="showCalendar" ref="calendar_container">
        <i
          @click="recalcDates(-1)" 
          style="position: absolute; left: 10px; z-index: var(--top-layer-z-index);" 
          class="bi bi-caret-left-fill" 
        ></i>
        <i
          @click="recalcDates(1)" 
          style="position: absolute; right: 10px;"
          class="bi bi-caret-right-fill"
        ></i>
        <div class="sep-calendar" ref="calendar" v-for="(date, index) in dates" :key="index">
          <h1>
            {{ months[date.month] }} <strong>{{ date.year }}</strong>
          </h1>
          <ul class="days">
            <li>dom</li>
            <li>lun</li>
            <li>mar</li>
            <li>mié</li>
            <li>jue</li>
            <li>vie</li>
            <li>sáb</li>
          </ul>
          <ul class="date">
            <li 
              v-for="(day, indexDay) in date.days" :key="indexDay" 
              @click="assignDate(date.year, date.month, day.date, day.ifFill, day.isPrevDay)" 
              :class="{ 
                'prev-month': day.ifFill,
                'prev-day': !this.previousDateAvailable ? day.isPrevDay : false
              }"
            >
              {{ day.date }}
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { DATE_FORMAT_REGEX } from '../../utils/constants';

export default {
  data() {
    return {
      showCalendar: false,
      callback: null,
      fecha: null,
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      day: new Date().getDate(),
      months: [
        this.$t('ENERO'),
        this.$t('FEBRERO'),
        this.$t('MARZO'),
        this.$t('ABRIL'),
        this.$t('MAYO'),
        this.$t('JUNIO'),
        this.$t('JULIO'),
        this.$t('AGOSTO'),
        this.$t('SEPTIEMBRE'),
        this.$t('OCTUBRE'),
        this.$t('NOVIEMBRE'),
        this.$t('DICIEMBRE'),
      ],
      dates: [],
    }
  },
  props: {
    initialValue: {
      type: String,
      default: '',
    },
    previousDateAvailable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    initialValue(val) {
      if (val && DATE_FORMAT_REGEX.test(val)) {
        const [initialMonth, initialDay, initialYear] = val.split('/');
        this.fecha = `${this.months[initialMonth - 1]}/${initialDay}/${initialYear}`;
      }
    }
  },
  mounted() {
    this.callback = ((e) => {
      if (this.showCalendar && (!this.$refs.calendar_container.contains(e.target) && !this.$refs.input_container.contains(e.target))) {
        this.showCalendar = false;
      }
    })
    window.addEventListener('click', this.callback);
    this.fecha = `${this.months[this.month]}/${this.day}/${this.year}`;
    this.dates = this.generateDates(this.month, this.year);
    this.month += 1;
    this.$emit('getCalendar', {
      formattedDate: this.fecha,
      date: `${(this.month < 10 ? '0' : '') + this.month}/${ (this.day < 10 ? '0' : '') + this.day}/${this.year}`,
    })
  },
  methods: {
    generateDates(month, year){
      let arr = [];
      const currentDate = new Date(new Date().getFullYear(), new Date().getMonth() , new Date().getDate());
      for (let indexMonth = 0; indexMonth < 1; indexMonth++) {
        if(month > 11) {
          month = 0;
          year += 1;
        }
        arr.push({
          year,
          month,
          days: []
        });
        const fillDays = this.calcFillDays(month, year);
        fillDays.reverse();
        for (let indexFillDays = 0; indexFillDays < fillDays.length; indexFillDays++) {
          arr[indexMonth].days.push({
            date: fillDays[indexFillDays],
            isPrevDay: false,
            ifFill: true,
          })   
        }
        
        const days = this.calcDaysInMonth((month + 1), year);
        for (let indexDays = 0; indexDays < days; indexDays++) {
          arr[indexMonth].days.push({
            date: (indexDays + 1),
            isPrevDay: currentDate > new Date(year, month , (indexDays + 1)),
            ifFill: false,
          })     
        }
        month += 1;

      }
      return arr;
    },
    calcDaysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
    calcFillDays(month, year) {
      const firstDay = new Date(year,   month, 1).getDay();
      if (firstDay === 0) { return []; }
      const days = this.calcDaysInMonth(month, year);
      const daysReturned = [];
      for (let index = 0; index < (firstDay); index++) {
        daysReturned.push(days - index);
      }
      return daysReturned;
    },
    assignDate(year, month, date, fill, prev) {
      if (!this.previousDateAvailable && (fill || prev)) {
        return;
      }

      this.year = year;
      this.month = month;
      this.day = date;
      this.fecha = `${this.months[this.month]}/${this.day}/${this.year}`
      this.month += 1;
      this.$emit('getCalendar', {
        formattedDate: this.fecha,
        date: `${(this.month < 10 ? '0' : '') + this.month}/${ (this.day < 10 ? '0' : '') + this.day}/${this.year}`,
        day: this.day,
        month: this.month,
        year: this.year
      })
      this.showCalendar = false;
    },
    recalcDates(value) {
      if (value === 1 ){
        let year = this.dates[this.dates.length - 1].year;
        let month = this.dates[this.dates.length - 1].month;
        month += 1;
        if(month > 11 ) {
          month = 0;
          year += 1;
        }
        this.dates = this.generateDates(month, year);
      }
      if (value === -1 ){
        let year = this.dates[0].year;
        let month = this.dates[0].month;
        let initialMonth = month;
        month -= 1;
        if(month < 0) {
          month = 11 - ( 1 - (initialMonth + 1) )
          year -= 1;
        }
        this.dates = this.generateDates(month, year);
      }
    },
  }
}
</script>
<style lang="scss">
.calendar-container {
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    input {
      border: 1px solid rgba(0, 0, 0, 0.175);
      border-radius: 0.375rem;
      padding-left: 1rem;
    }
    .calendar {
      width: auto;
      margin-top: 1rem;
      font-family: Roboto;
      position: absolute;
      z-index: var(--top-layer-z-index);
      background-color: #fafafa;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      border: 1px solid rgba(0, 0, 0, 0.175);
      border-radius: 0.375rem;
      padding-top: 1rem;
      h1 {
        color: #3cd0fb;
        font-size: 1rem;
        text-align: center;
      }
      strong {
        h1 {
          color: #2387c6;
        }
      }
      .days {
        background: #aaaaa9;
        color: white;
      }
      ul {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        list-style: none;
        gap: 0.5rem;
        .prev-month {
          color: transparent;
        }
        .prev-day {
          opacity: 0.5;
        }
      }
    }
  }
</style>