<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div v-if="permissions.length > 0 || user.isAdmin">
      <rd-navbar :showBackButton="false" />
      
      <div 
        v-if="
          !(
            permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION)
            || permissions.includes(APP_PERMISSIONS.FIELD_WORK_ASSIGNMENTS.WORKER)
          ) || user.isAdmin
        " 
        class="row no-gutters mx-0 header bg-light p-2 text-start"
      >
        <h2 class="col-12 align-self-center main-title p-0 m-0">
          {{ $t('System Administration') }}
        </h2>
      </div>
      <div style="margin-top: 0 !important; padding-top: 0 !important;" class="container container-custom p-2">
        <div v-if="permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.LANGUAGES)
          || permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.BACKGROUND)
          || permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.SMART_HIRE_PARAMETERS)
          || permissions.includes(APP_PERMISSIONS.ROLES.CRUD)
          || permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.ASSIGNMENTS_PARAMETERS)
          || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.USERS)
          || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.CATEGORIES)
          || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.SUB_CATEGORIES)
          || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.SESSIONS)
          || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.REGIONS)
          || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.TEAMS)
          || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.SITES)
          || permissions.includes(APP_PERMISSIONS.PROFILES.CRUD)
          || permissions.includes(APP_PERMISSIONS.USER_MANAGEMENT.CRUD)
          || permissions.includes(APP_PERMISSIONS.USER_MANAGEMENT.EDIT_USER)
          || permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.USER_RATING_SETTINGS)
          || user.isAdmin" class="accordion accordion-custom mt-4">
          <div class="accordion-item px-2" style="border: none;">
            <div class="d-flex align-items-center">
              <div class="custom-dot"></div>
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button accordion-button-custom" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  {{ $t('System Management') }}
                </button>
              </h2>
            </div>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#accordionExampleOne">
              <div class="accordion-body">
                <!-- 
                  - System Administration
                    - Language
                    - Background
                    - Hire Settings / Status configuration
                    - Assignment Settings
                    - Roles
                  - Bulk Load
                  - Profiles
                  - User Management
                -->
                <div class="row g-4">

                  <div v-if="
                    permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.LANGUAGES)
                    || permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.BACKGROUND)
                    || permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.SMART_HIRE_PARAMETERS)
                    || permissions.includes(APP_PERMISSIONS.ROLES.CRUD)
                    || permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.ASSIGNMENTS_PARAMETERS)
                    || permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.USER_RATING_SETTINGS)
                    || user.isAdmin
                  " class="col-12 col-lg-4">
                    <rd-admin-card :name="$t('System Administration')"
                      :description="$t('Manage general website settings')" @route="goTo('/admin/settings')" />
                  </div>

                  <div class="col-12 col-lg-4" v-if="
                    permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.USERS)
                    || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.CATEGORIES)
                    || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.SUB_CATEGORIES)
                    || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.SESSIONS)
                    || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.REGIONS)
                    || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.TEAMS)
                    || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.SITES)
                    || user.isAdmin
                  ">
                    <rd-admin-card :name="$t('Bulk Load')" :description="$t('Bulk load of data')"
                      @route="openModalBulkModal()" />
                  </div>
                  <div class="col-12 col-lg-4" v-if="
                    permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.USERS)
                    || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.CATEGORIES)
                    || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.SUB_CATEGORIES)
                    || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.SESSIONS)
                    || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.REGIONS)
                    || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.TEAMS)
                    || permissions.includes(APP_PERMISSIONS.BULK_UPLOAD.SITES)
                    || user.isAdmin
                  ">
                    <rd-admin-card :name="$t('Bulk Load History')" :description="$t('History of bulk load')"
                      @route="goTo('/admin/bulk-load-history')" />
                  </div>

                  <div class="col-12 col-lg-4"
                    v-if="permissions.includes(APP_PERMISSIONS.PROFILES.CRUD) || user.isAdmin">
                    <rd-admin-card :name="$t('Profiles')" :description="$t('Control user profiles')"
                      @route="goTo('/admin/profiles')" />
                  </div>

                  <div class="col-12 col-lg-4"
                    v-if="permissions.includes(APP_PERMISSIONS.USER_MANAGEMENT.CRUD) || permissions.includes(APP_PERMISSIONS.USER_MANAGEMENT.EDIT_USER) || user.isAdmin">
                    <rd-admin-card :name="$t('User Management')" :description="$t('Control user')"
                      @route="goTo('/admin/user-management')" />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="
          permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.FILE_UPLOADER)
          || permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION)
          || permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES)
          || permissions.includes(APP_PERMISSIONS.SESSION_MANAGEMENT.CRUD)
          || permissions.includes(APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD)
          || user.isAdmin
        " class="accordion accordion-custom mt-4">
          <div class="accordion-item px-2" style="border: none;">
            <div class="d-flex align-items-center">
              <div class="custom-dot"></div>
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button accordion-button-custom" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                  {{ $t('Training') }}
                </button>
              </h2>
            </div>
            <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo"
              data-bs-parent="#accordionExampleTwo">
              <div class="accordion-body">
                <!-- 
                  - File Management
                  - Exams Management
                  - Session Management
                -->
                <div class="row g-4">

                  <div v-if="
                    permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.FILE_UPLOADER)
                    || permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.FILE_VISUALIZATION)
                    || permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES)
                    || user.isAdmin
                  " class="col-12 col-lg-4">
                    <rd-admin-card :name="$t('File Management')"
                      :description="$t('Manage files, categories and sub-categories')"
                      @route="goTo('/admin/files-management')" />
                  </div>

                  <div v-if="
                    permissions.includes(APP_PERMISSIONS.SESSION_MANAGEMENT.CRUD)
                    || permissions.includes(APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD)
                    || user.isAdmin
                  " class="col-12 col-lg-4">
                    <rd-admin-card :name="$t('Exams Management')" :description="$t('Exam Creation')"
                      @route="goTo('/admin/exam')" />
                  </div>

                  <div class="col-12 col-lg-4" v-if="user.isAdmin">
                    <rd-admin-card :name="$t('Template Creation')" :description="$t('Exam Template Creation')"
                      @route="goTo('/admin/template/management')" />
                  </div>

                  <div v-if="permissions.includes(APP_PERMISSIONS.SESSION_MANAGEMENT.CRUD) || user.isAdmin"
                    class="col-12 col-lg-4">
                    <rd-admin-card :name="$t('Session Management')" :description="$t('Sessions Configuration')"
                      @route="goTo('/session')" />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="
          permissions.includes(APP_PERMISSIONS.POLL_WORKER_MANAGEMENT.CRUD)
          || permissions.includes(APP_PERMISSIONS.SITE_MANAGEMENT.CRUD)
          || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.VISUALIZATION)
          || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.EDIT_ASSIGNMENTS)
          || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.EXECUTE_ASSIGNMENTS)
          || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.DELETE_ASSIGNMENTS)
          || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.ACTIVE_ASSIGNMENTS)
          || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.GET_REPORT)
          || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.ENABLE_ASSIGNMENTS)
          || permissions.includes(APP_PERMISSIONS.FIELD_WORK_ASSIGNMENTS.WORKER)
          || user.isAdmin" class="accordion accordion-custom mt-4">
          <div class="accordion-item px-2" style="border: none;">
            <div class="d-flex align-items-center">
              <div class="custom-dot"></div>
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button accordion-button-custom" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                  {{ $t('Assignments') }}
                </button>
              </h2>
            </div>
            <div id="collapseThree" class="accordion-collapse collapse show" aria-labelledby="headingThree"
              data-bs-parent="#accordionExampleThree">
              <div class="accordion-body">
                <!-- 
                  - Hire (Poll worker Management)
                  - Site Management
                  - Team Management
                  - Assignments
                -->
                <div class="row g-4">

                  <div class="col-12 col-lg-4"
                    v-if="permissions.includes(APP_PERMISSIONS.POLL_WORKER_MANAGEMENT.CRUD) || user.isAdmin">
                    <rd-admin-card :name="$t('Poll worker Management')" :description="$t('SmartHire Module')"
                      @route="goTo('/admin/smarthire')" />
                  </div>

                  <div class="col-12 col-lg-4"
                    v-if="permissions.includes(APP_PERMISSIONS.SITE_MANAGEMENT.CRUD) || user.isAdmin">
                    <rd-admin-card :name="$t('Site Management')" :description="$t('Sites module')"
                      @route="goTo('/admin/sites-management')" />
                  </div>

                  <!-- Hide team management -->
                  <!-- <div class="col-12 col-lg-4" v-if="user.isAdmin">
                    <rd-admin-card :name="$t('Team Management')" :description="$t('Teams module')"
                      @route="goTo('/admin/teams-management')" />
                  </div> -->

                  <div v-if="
                    permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.VISUALIZATION)
                    || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.EDIT_ASSIGNMENTS)
                    || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.EXECUTE_ASSIGNMENTS)
                    || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.DELETE_ASSIGNMENTS)
                    || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.ACTIVE_ASSIGNMENTS)
                    || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.GET_REPORT)
                    || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.ENABLE_ASSIGNMENTS)
                    || permissions.includes(APP_PERMISSIONS.FIELD_WORK_ASSIGNMENTS.WORKER)
                    || user.isAdmin" class="col-12 col-lg-4">
                    <rd-admin-card :name="$t('Assignments')" :description="$t('Assignments module')"
                      @route="goTo('/admin/assignments-management')" />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-else style="flex-grow: 1;" class="d-flex flex-column">
      <rd-navbar :showBackButton="false" />
      <div class="row no-gutters mx-0 header bg-light p-2 text-start">
        <h2 class="col-12 align-self-center main-title p-0 m-0">
          {{ $t('System Administration') }}
        </h2>
      </div>
      <div style="flex-grow: 1; gap: 1rem; padding-left: 10%; padding-right: 10%;"
        class="d-flex justify-content-center align-items-center flex-column">
        <div style="width: 50; height: 20%;">
          <img src="/assets/search.png" style="width: 5rem;" alt="wrong answer">
        </div>
        Currently, you do not have any roles or permissions assigned. Please contact the system administrator and
        inform them of this situation.
        <br>
        We recommend that you log out the system until the administrator grants you the necessary access.
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
    <bulk-load v-if="showBulkLoadModal" @hide="() => showBulkLoadModal = false" />
  </div>
</template>

<script>
import RdAdminCard from '@/components/rd-components/rd-admin-card.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdFooter from '@/components/rd-components/rd-footer.vue'
import BulkLoad from '@/views/bulk/bulk-load.vue'
import {
  APP_PERMISSIONS
} from '@/utils/constants.js'
import router from '@/router'
import {
  mapState
} from 'vuex'

export default {
  components: {
    RdFooter,
    RdNavbar,
    RdAdminCard,
    BulkLoad,
  },
  data() {
    return {
      showBulkLoadModal: false,
      APP_PERMISSIONS,
      executingAssignment: false,
      vcCodeToDelete: null,
      calendarToDelete: null,
      loadingDeletion: false,
    }
  },
  computed: {
    ...mapState(['user', 'permissions'])
  },
  methods: {
    goTo(route) {
      router.push(route)
    },
    openModalBulkModal() {
      this.showBulkLoadModal = true;
    },
  },
}
</script>

<style lang="scss" scoped>
.main-title {
  padding-left: 12px !important;
  font-size: calc(24px + .25vw);
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #3d3d3d;
}

.container-custom {
  margin-left: unset;
  width: 100%;
  max-width: 100%;
}

.container-custom.container-custom {
  padding-left: 3rem;
  padding-right: 3rem;
}

.accordion-custom {
  padding: 0 1rem 0;

  .accordion-button-custom {
    font-size: calc(20px + .25vw);
    font-weight: 700;
    background: #ffffff;
    color: #3d3d3d !important;
    padding: 0.8rem 0;

    &:focus,
    &:active {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  .custom-dot {
    width: 1.5rem;
    height: 1.5rem;
    background-color: #421cac;
    border-radius: 50%;
    position: relative;
    top: -1px;
    left: -10px;
  }

  .accordion-header {
    border-bottom: black solid 2px;
    display: flex;
    align-items: center;
    flex: 1;
  }

  .accordion-button-custom::after {
    display: none;
  }

  .accordion-button-custom::before {
    margin: 0rem 0.5rem 0 !important;
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
    transform: rotate(0.75turn);
  }

  .accordion-button-custom:not(.collapsed)::before {
    transform: rotate(0turn);
  }
}

.accordion-body {
  padding: 2rem 4rem;
  border: none !important;
  box-shadow: none;
  outline: none;
}

@media screen and (max-width: 768px) {
  .accordion-body {
    padding: 1rem 1rem 1rem 3rem;
  }
}

.custom-sub {
  display: flex;
  gap: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.header {
  height: 96px;
  -webkit-box-shadow: inset 0 -1px 0 0 #e0e0e0;
  box-shadow: inset 0 -1px 0 0 #e0e0e0;
}
</style>
