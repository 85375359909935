import httpClient from '../httpClient';

const END_POINT = '/api/categories';

const createCategory = async (category) => await httpClient.post(END_POINT, category)
const getAllCategoryWithFiles = async () => await httpClient.get(`${END_POINT}`)
const getAllCategory = async (evenIfNoSubcategories = '') => await httpClient.get(`${END_POINT}/all?evenIfNoSubcategories=${evenIfNoSubcategories}`)
const getCategoryByProfile = async (userId, fileVisibility = '') => await httpClient.get(`${END_POINT}/profile/${userId}?file_visibility=${fileVisibility}`)
const getCategoriesByUploader = async (userId) => await httpClient.get(`${END_POINT}/uploader/${userId}`)
const getCategoryById = async (categoryId) => await httpClient.get(`${END_POINT}/${categoryId}?filter={"fields":{"categoryId": true,"categoryName": true, "categoryDescription": true}}`)
const editCategoryById = async (categoryId, category) => await httpClient.patch(`${END_POINT}/${categoryId}?where={"categoryId":${categoryId}}`, category)
const deleteCategoryById = async (categoryId) => await httpClient.delete(`${END_POINT}/${categoryId}`)
const getCategoryAndSubcategoryName = async (categoryId, subcategoryId) => await httpClient.get(`/categories/subcategories/${categoryId}/${subcategoryId}`)

export {
  createCategory,
  getAllCategoryWithFiles,
  getAllCategory,
  getCategoryByProfile,
  getCategoriesByUploader,
  getCategoryById,
  editCategoryById,
  deleteCategoryById,
  getCategoryAndSubcategoryName
}