<template>
  <div class="full-resolution-icon">
    <tooltip v-if="fullResolution" :text="$t('Fit')">
      <i class="bi bi-fullscreen" @click="fullResolutionToogle"></i>
    </tooltip>
    <tooltip v-else :text="$t('Full resolution')">
      <i class="bi bi-fullscreen-exit" @click="fullResolutionToogle"></i>
    </tooltip>
  </div>
  <div class="image-container" :class="{ 'overflow-auto': fullResolution }">
    <img :src="data" alt="image" :class="{ 'fit-image': !fullResolution }" />
  </div>
</template>

<script>
import tooltip from '../tooltip.vue';
export default {
  components: { tooltip },
  name: 'RdImageViewer',
  data() {
    return {
      fullResolution: true
    }
  },
  props: {
    data: {
      type: String,
      required: true
    },
  },
  methods: {
    fullResolutionToogle() {
      this.fullResolution = !this.fullResolution;
    }
  }
}
</script>

<style scoped>
.image-container {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  /* center el contenido del div sin afectar la imagen, sin usar flex, ni position absolute */
  place-content: center;
}
  
.overflow-auto {
  overflow: auto;
}

.image-container img {
  transition: all 0.3s ease;
}

.fit-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.full-resolution-icon {
  position: absolute;
  display: flex;
  top: 1.5rem;
  left: 1rem;
  z-index: var(--top-layer-z-index);
  display: flex;
  align-items: center;
}
  
.full-resolution-icon i {
  background: #00000B6D;
  padding: .3rem;
  border-radius: .3rem;
  font-size: 1.5rem;
  color: #F5F5F580;
  cursor: pointer;
}
</style>
