<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Site Resources (nav)')" />
      <div class="container background-form my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Add Resource') }}</h2>
          </div>
        </div>

        <form 
          @submit.prevent="" 
          autocomplete="off" 
          class="container"
        >
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Resource name')" 
                :label="$t('Enter the name')"
                required
                @input="(e) => this.name = e.target.value"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Resource Path')" 
                :label="$t('Enter the path')"
                required
                @input="(e) => this.path = e.target.value"
              />
            </div>
          </div>

          <div class="my-3 row justify-content-end">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <button class="btn btn-outline-primary me-3" @click="goBack">
                {{ $t('Dismiss') }}
              </button>
              <button type="submit" :disabled="disableSaveButton" class="btn btn-primary" @click="handleCreateResource">
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Resource" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Resource (btn)') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import router from '@/router'
import { createResource } from '../../services/sites-resources/sites-resources.api'

export default {
  data() {
    return {
      name: '',
      path: '',
      disableSaveButton: true,
      siteId: null
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput    
  },
  async mounted() {
    this.siteId = this.$route.params.id;
    this.$watch(
      () => {
        return [this.name, this.path]
      },
      ([name, path]) => {
        if (name == '' || path == '') {
          this.disableSaveButton = true
        } else {
          this.disableSaveButton = false
        }
      } 
    );
  },
  methods: {
    async handleCreateResource() {
      this.showError = false;
      try {
        const resource = {
          name: this.name,
          path: this.path
        }
        await createResource(resource, this.siteId);
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Resource created.',
          type: 'success',
        });
        router.back()
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Resource was not created.',
          type: 'error',
        });
        console.log(error);
      }
    },
    goBack() {
      router.back()
    },
  },
}
</script>
<style scoped>
.labelText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: grey;
  float: left;
  border-radius: 4px;
}
</style>