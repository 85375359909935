export const getRoleText = (roleId) => {
  if (parseInt(roleId) === 1) return 'Admin';
  if (parseInt(roleId) === 2) return 'Uploader';
  if (parseInt(roleId) === 3) return 'Viewer';
  if (parseInt(roleId) === 4) return 'Viewer and Downloader';
  return 'Trainer'
}

export const encodeToBase64 = (json) => {
  const jsonString = JSON.stringify(json);
  
  // Check if the string contains characters outside of Latin1 range
  const latin1Characters = /^[ -~]*$/;
  if (latin1Characters.test(jsonString)) {
    // Use btoa for Latin1 characters
    return btoa(jsonString);
  } else {
    // Use TextEncoder for characters outside of Latin1 range
    const encoder = new TextEncoder();
    const bytes = encoder.encode(jsonString);
    const base64 = btoa(String.fromCharCode(...bytes));
    return base64;
  }
}

export const decodeFromBase64 = (string) => {
  return new Promise((resolve, reject) => {
    try {
      const binaryString = window.atob(string);
      const utf8Bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        utf8Bytes[i] = binaryString.charCodeAt(i);
      }
      const decodedString = new TextDecoder('utf-8').decode(utf8Bytes);
      resolve(decodedString);
    } catch (error) {
      reject('');
    }
  });
};

export const getCustomTimestamp = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  return `${year}${month}${day}&${hours}hh${minutes}mm${seconds}ss`;
}

export const validateJSON = async (json) => {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
}

export function debounce (fn, delay = 300) {
  let timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    const args = arguments
    const that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}