<template>
    <div class="d-flex flex-column justify-content-between vh-100">
        <div>
            <rd-navbar :title="$t('Files (nav)')" />
            <div class="container background-form my-3 p-3">
                <div class="row">
                    <div class="col-12">
                        <h2 class="ms-4 text-start">{{ $t('Attempts') }}</h2>
                    </div>
                </div>

                <div v-if="attemptsLoading">
                    <rd-loading />
                </div>

                <div v-else>
                    <div v-if="attempts.attempts.length === 0" class="row p-3">
                        <h2>
                            {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right"
                                title="No attempts Found" type="button" class="bi bi-exclamation-triangle"></i>
                        </h2>
                    </div>

                    <div v-else class="row p-3 d-flex justify-content-start">
                        <div style="overflow-x: auto; width: 100%; max-width: 100%;">
                            <div class="w-100">
                                <table class="table text-start table-hover table-custom-general" ref="table_resize">
                                    <thead class="table-primary">
                                        <tr class="centered">
                                            <th scope="col">{{ $t('Attempt (table)') }}</th>
                                            <th scope="col">{{ $t('Score (table)') }}</th>
                                            <th scope="col">{{ $t('Completion Percentage (table)') }}</th>
                                            <th scope="col">{{ $t('Status (table)') }}</th>
                                            <th scope="col" class="text-center">{{ $t('Actions (table)') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(attempt, index) in attempts.attempts" :key="index"
                                            class="text-start hoverable" :class="{ 'fill-ods': index % 2 !== 0 }">
                                            <td style="width: 20%; word-break: break-all;" scope="row"
                                                :header="$t('Attempt (table)')" class="word-wrap centered">{{ index + 1
                                                }}</td>
                                            <td style="width: 20%; word-break: break-all;" class="centered" scope="row"
                                                :header="$t('Score (table)')">
                                                {{ attempt.score ?? 0 }}
                                            </td>
                                            <td style="width: 20%; word-break: break-all;" class="centered" scope="row"
                                                :header="$t('Completion Percentage (table)')">
                                                {{ attempt.completion_percent }}%
                                            </td>
                                            <td style="width: 20%; word-break: break-all;" class="centered" scope="row"
                                                :header="$t('Status (table)')">{{ attempt.approved ? $t('Approved') :
                                                    $t('Failed') }}</td>
                                            <td style="width: 20%; word-break: break-all;" class="centered"
                                                :header="$t('Actions (table)')">
                                                <div class="container">
                                                    <div class="row">

                                                        <div class="col p-0 m-0">
                                                            <button class="btn btn-label"
                                                                @click="showExam(attempt, index)">
                                                                <i data-bs-toggle="tooltip" data-bs-placement="right"
                                                                    title="Show Attempt" type="button"
                                                                    class="bi bi-info-circle"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <rd-footer />
        </div>
    </div>
</template>

<script>
import rdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdLoading from '../../components/rd-components/rd-loading.vue'
import {
    getAttempts
} from '@/services/exams/exam.api';
export default {
    data() {
        return {
            attempts: [],
            attemptsLoading: true,
            showModalProp: false
        }
    },
    components: {
        rdFooter,
        RdNavbar,
        RdLoading
    },
    computed: {

    },
    async mounted() {
        const {
            id: examId
        } = this.$route.params;
        const {
            userId
        } = this.$store.state.user;
        await this.getAttempts(examId, userId)
    },
    methods: {
        async getAttempts(examId, userId) {
            try {
                const { data } = await getAttempts(examId, userId)
                this.attempts = data
                this.attemptsLoading = false;
            } catch (error) {
                console.log(error)
                this.attemptsLoading = false;
                this.attempts = {
                    attempts: []
                }
            }
        },
        showExam(attemp, index) {
            let file = {
                last: {
                    score: attemp.score,
                    completion_percent: attemp.completion_percent,
                    approved: attemp.approved,
                    exam_has_certificate: attemp.exam_has_certificate,
                    template_id: attemp.template_id,
                    exam_name: attemp.exam_name
                },
                attemps: index + 1,
                questionsCount: this.attempts.questionsCount,
                questionsAnswered: this.attempts.answers.filter((answer) => answer.try_number == index + 1),
                minimum_score: attemp.minimum_score
            }
            this.$store.dispatch('dispatchFileView', { file, isExamPreview: true })
        }
    },

}
</script>

<style lang="scss" scoped>
.hoverable:hover {
    cursor: pointer;
}

.hoverable:hover {
    cursor: pointer;
}

.fill-ods {
    background-color: #f5f5f5;
}

thead {
    border-top: #e0e0e0 solid 1px;
    border-right: #e0e0e0 solid 1px;
    border-left: #e0e0e0 solid 1px;
}

tr {
    border-top: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
}

tbody {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
}

td {
    min-height: 50px;
}

th {
    min-height: 50px;
    height: 50px;
}

.table-primary {
    tr {
        th {
            vertical-align: middle;
        }
    }
}

.centered {
    text-align: center;
    vertical-align: middle;
}

.hoverable {
    td {
        vertical-align: middle;
    }
}

th {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

td {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
</style>
