import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  locale: 'en_US',
  fallbackLocale: 'en_US',
  messages: {},
  missingWarn: false,
  fallbackWarn: false,
  warnHtmlInMessage: false,
  fallbackFormat: false,
  onWarn: () => {
    return;
  }
});

export default i18n;