<template>
  <span
    ref="tooltipButton"
    type="button"
    class="tooltip-trigger"
    data-bs-toggle="tooltip"
    data-bs-html="true"
    :data-bs-placement="placement"
    :title="tooltipHtml"
  >
    <slot></slot>
  </span>
</template>

<script>
import { Tooltip } from 'bootstrap';

export default {
  props: {
    text: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      default: 'auto'
    }
  },
  computed: {
    tooltipHtml() {
      return this.text;
    }
  },
  data() {
    return {
      tooltipInstance: null
    };
  },
  mounted() {
    this.initializeTooltip();
  },
  beforeUnmount() {
    this.disposeTooltip();
  },
  methods: {
    initializeTooltip() {
      this.tooltipInstance = new Tooltip(this.$refs.tooltipButton, {
        title: this.text,
        placement: this.placement,
        html: true
      });
    },
    disposeTooltip() {
      if (this.tooltipInstance) {
        this.tooltipInstance.dispose();
        this.tooltipInstance = null;
      }
    }
  }
};
</script>

<style scoped>
span {
  all: unset;
}
.tooltip-trigger {
  cursor: pointer;
  z-index: var(--top-layer-z-index);
}
</style>
