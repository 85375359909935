<template>
  <div
    class="
      position-fixed 
      top-0 
      start-0 
      modalPosition
    "
  >

    <div 
      class="
        card 
        position-fixed 
        top-50
        start-50
        translate-middle
      "
      :class="{
        'w-25': windowWidth >= 992,
        'w-50': windowWidth >= 768 && windowWidth < 992,
        'w-75': windowWidth < 768,
      }"
    >

      <div class="card-header">
        {{ modalTitle }}
      </div>

      <div
        v-if="showBody"
        class="container text-start card-body"
      >
        <div class="row">
          <div class="col-12">
            {{ bodyTitleText }}
          </div>
        </div>

        <div class="row">
          <ol class="ms-3">
            <li class="col-12"
              v-for="(item, index) in items" :key="index"
            >
              {{ item.name }}
            </li>
          </ol>
        </div>

        <div class="row">
          <div class="col-12">
            <input v-model="checkToConfirm" type="checkbox">
            <span class="ms-1">
              {{ confirmCheckText }}
            </span>
          </div>
        </div>
      </div>

      <div 
        :class="{
          'card-body': !showBody,
          'card-footer': showBody
        }"
        class="container"
      >
        <div class="row">
          <div class="col-12 mb-1">
            <button-component
              primary-outline
              px="4" py="2"
              @handleClick="this.$emit('dismissAction')"
            >
              {{ dismissButton }}
            </button-component>
            <button-component
              :disabled="disabledConfirmBtn"
              primary
              px="4" py="2"
              @handleClick="this.$emit('confirmAction')"
            >
              {{ confirmButton }}
            </button-component>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>
<script>
import { mapState } from 'vuex'
import ButtonComponent from '../button-component.vue'
  export default {
  components: { ButtonComponent },
    data() {
      return {
        checkToConfirm: false 
      }
    },
    props: {
      name: {
        type: String,
        default: 'modal'
      },
      id: {
        type: String,
        default: 'modal'
      },
      modalTitle: {
        type: String,
        default: 'Are you sure to continue?'
      },
      confirmButton: {
        type: String,
        default: 'Confirm'
      },
      dismissButton: {
        type: String,
        default: 'Dismiss'
      },
      icon: {
        type: String,
        default: "<i class='bi bi-trash-fill'></i>"
      },
      show: {
        type: Boolean,
        default: false
      },
      showBody: {
        type: Boolean,
        default: false
      },
      bodyTitleText: {
        type: String,
        default: 'Files Related'
      },
      confirmCheckText: {
        type: String,
        default: 'Confirm'
      },
      items: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapState(['windowWidth']),
      disabledConfirmBtn() {
        return this.showBody && !this.checkToConfirm
      }
    }
  }
</script>
<style scoped>
.modalPosition {
  background: rgb(0, 0, 0, 0.7);
  min-height: 100vh; 
  min-width: 100vw;
  z-index: var(--top-layer-z-index);
}
</style>