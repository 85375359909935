import httpClient from "../httpClient";

const END_POINT = '/api/region'

const massiveLoadForRegion = (csvFile) => httpClient.post(`${END_POINT}/import`, csvFile)
const getRegions = () => httpClient.get(END_POINT)

export {
  massiveLoadForRegion,
  getRegions
}