<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Roles (nav)')" />
      <div class="container background-form rounded-3 my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Add Role') }}</h2>
          </div>
        </div>

        <form @submit.prevent="" autocomplete="off" class="container">
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input type="text" :placeholder="$t('Role name')" :label="$t('Enter the name')" required
                @input="(e) => this.name = e.target.value" />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input type="text" :placeholder="$t('Role Description')" :label="$t('Enter the description')"
                @input="(e) => this.description = e.target.value" />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input type="text" :placeholder="$t('Keycloak Name')" :label="$t('Enter the keycloak name')"
                @input="(e) => this.keycloak_role_name = e.target.value" />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <label class="labelText required">
                {{ $t('Link permissions to this role') }}
              </label>
              <table class="table text-start">
                <thead class="table-primary">
                  <tr>
                    <th scope="col">
                      <rd-input type="checkbox" v-model="allChecked" :checked2="allChecked"
                        @change="handleAllChecked" />
                      {{ $t('Modules (table)') }}
                    </th>
                    <th scope="col">{{ $t('Permissions (table)') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(currentModule, index) in allModule" :key="index">
                    <rd-row :category="currentModule" :currentSubcategories="modulesToAdd"
                      @addSubcategories="addPermissions" @cleanSubcategories="cleanPermissions" />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="mt-3 row justify-content-end">
            <div class="col-12 col-lg-6 col-md-6 text-end">
              <button-component primaryOutline @handleClick="goBack">
                {{ $t('Dismiss') }}
              </button-component>
              <button-component primary type="submit" :disabled="disableSaveButton" @handleClick="handleCreateRole">
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Role" type="button"
                  class="bi bi-plus-circle-fill"></i> {{ $t('Add Role (btn)') }}
              </button-component>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-if="isCreating" class="overlay">
      <div class="d-flex justify-content-center align-items-center w-100 h-100">
        <rd-loading />
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import router from '@/router'
import { createRole, getAllPermissions } from '@/services/roles/role.api.js'
import RdRow from '@/components/rd-components/rd-row.vue'
import ButtonComponent from '../../components/button-component.vue';
import RdLoading from '../../components/rd-components/rd-loading.vue';

export default {
  data() {
    return {
      name: '',
      description: '',
      disableSaveButton: true,
      allModule: [
      ],
      modulesToAdd: [],
      allModuleFlat: [],
      allChecked: false,
      keycloak_role_name: '',
      isCreating: false
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    RdRow,
    ButtonComponent,
    RdLoading
  },
  async mounted() {
    const { data } = await getAllPermissions();
    this.allModule = data.map((module) => {
      return {
        categoryId: module.id,
        categoryName: module.module,
        subcategories: module.permissions.map((permission) => {
          return {
            subCategoryName: permission.permissions,
            subCategoryId: permission.id,
          }
        })
      }
    });
    this.allModuleFlat = this.allModule.flatMap((module) => {
      return module.subcategories.map((subcategorie) => {
        return subcategorie.subCategoryId
      })
    })
    this.$watch(
      () => {
        return [this.name, this.modulesToAdd]
      },
      ([name, modulesToAdd]) => {
        if (name == '' || modulesToAdd.length == 0) {
          this.disableSaveButton = true
        } else {
          this.disableSaveButton = false
        }
      }
    );
  },
  methods: {
    handleAllChecked(e) {
      if (e.target.checked) {
        this.modulesToAdd = this.allModule.flatMap((module) => {
          return module.subcategories.map((subcategorie) => {
            return subcategorie.subCategoryId
          })
        })
        return;
      }
      this.modulesToAdd = []
    },
    cleanPermissions(subcategories) {
      for (const subcategory of subcategories) {
        this.modulesToAdd = this.modulesToAdd.filter(sub => sub !== subcategory)
      }
      this.allChecked = false
    },
    addPermissions({ subcategoryId }) {
      if (!this.modulesToAdd.includes(subcategoryId)) {
        this.modulesToAdd.push(subcategoryId)
        this.disableSaveButton = (this.modulesToAdd.length == 0 || this.name == '')
        this.allChecked = JSON.stringify(this.allModuleFlat.sort()) == JSON.stringify(this.modulesToAdd.sort())
        return
      }
      this.allChecked = false
      this.modulesToAdd = this.modulesToAdd.filter(sub => sub !== subcategoryId)
      this.disableSaveButton = (this.modulesToAdd.length == 0 || this.name == '')
    },
    async handleCreateRole() {
      this.showError = false;
      this.isCreating = true;
      try {
        const role = {
          roleName: this.name,
          roleDescription: this.description,
          keycloak_role_name: this.keycloak_role_name,
          modulesToAdd: this.modulesToAdd
        }
        await createRole(role);
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Role created.',
          type: 'success',
        });
        router.back()
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Role was not created.',
          type: 'error',
        });
        console.log(error);
      }
      finally {
        this.isCreating = false;
      }
    },
    goBack() {
      router.back()
    },
  },
}
</script>
<style scoped>
.labelText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: grey;
  float: left;
  border-radius: 4px;
}

.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.09);
  color: white;
  z-index: 999999;
  border-radius: .5rem;
}
</style>