<template>
  <div class="dropdown">
    <a class="btn btn-link dropdown-toggle" title="Change language" style="text-decoration:none; color: black;" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
      <i data-bs-toggle="tooltip" data-bs-placement="right" title="Choose Language" type="button" class="bi bi-translate mx-1"/>
    </a>

    <ul class="dropdown-menu dropdown-menu-end">
      <li
        v-for="(language, index) in localesItems" :key="index"
        @click="setLanguage(language)"
      >
        <span class="dropdown-item cursor-pointer" :class="{ 'active': language.abbreviation === currentLanguage }">
          {{ language.abbreviation }}
        </span>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  data() {
    return {
      items: [],
      languages: []
    }
  },
  mounted() {
    this.$watch(
      () => {
        return this.$store.state.locales
      },
      (locales) => {
        try {
          let items = [];
          locales.languages.forEach(language => {
            let item = {};
            item.name = language.name;
            item.abbreviation = language.abbreviation;
            items.push(item);
          });
          this.items = items;
          this.languages = locales.languages;
        } catch (error) {
          console.log(error); // eslint-disable-line no-console
        }
      }
    );
  },
  computed: {
    localesItems() {
      let items = [];
      try {
        this.$store.state.locales.languages.forEach(language => {
          let item = {};
          item.name = language.abbreviation;
          item.abbreviation = language.abbreviation;
          items.push(item);
        });
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
      }
      return items;
    },
    currentLanguage(){
      return this.$store.getters.getCurrentLanguage;
    }
  },
  methods: {
    setLanguage(language) {
      const found = this.localesItems.find(element => element.abbreviation === language.abbreviation)
      this.$i18n.locale = found.abbreviation;
      this.$store.commit('setCurrentLanguage', { currentLanguage: found.abbreviation });
      this.$store.dispatch('getI18nData', this.$i18n);
    },
  },
}
</script>
<style scoped>
select {
  border: none;
  outline: none;
}
.cursor-pointer {
  cursor: pointer;
}
.dropdown-toggle::after {
  display: none;
}
</style>