<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />
      <div class="container background-form rounded-3 my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Rating Settings') }}</h2>
          </div>
        </div>
        <div class="row px-3 justify-content-between">
          <!-- Search bar -->
          <div class="col-12 col-lg-3">
            <rd-input
              class="w-100"
              :placeholder="$t('Search by name or description')" 
              type="text"
              @input="(e) => this.search = e.target.value"
            />
          </div>

          <!-- buttons -->
          <div class="col-12 col-lg-6 text-end">
            <button-component
              primary
              @handleClick="goToAddNewRating"
            >
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Status" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Rating') }}
            </button-component>
          </div>

        </div>

        <div v-if="screenLoading">
          <rd-loading /> 
        </div>
        <div v-else>
          <div
            v-if="status.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No status Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <div v-else class="row p-3 d-flex justify-content-start">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover table-custom-general" ref="table_resize">
                  <thead class="table-primary">
                    <tr>
                      <th scope="col">{{ $t('Rating (table)') }}</th>
                      <th scope="col" class="text-center">{{ $t('Actions (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="({ id, score }, index) in statusDataTemp" :key="index"
                      class="text-start hoverable"
                      :class="{ 'fill-ods': index % 2 !== 0 }"
                    >
                      <td style="width: 30%; word-break: break-all;" scope="row" :header="$t('Status (table)')" class="word-wrap" >{{ score }}</td>
                      <td style="width: 30%; word-break: break-all;" :header="$t('Actions (table)')">
                        <div class="container">
                          <div class="row text-center">
                            <div class="col p-0 m-0">
                              <button-component
                                class="btn btn-label"
                                @handleClick="goToEditStatus($event, id)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Status" type="button" class="bi bi-pencil-square"></i>
                              </button-component>

                              <button-component
                                class="btn btn-label" 
                                @handleClick="showDeleteModal($event, id)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Delete Status" type="button" class='bi bi-trash-fill'></i>
                              </button-component>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            <rd-paginator :items="filteredStatus" @page-change="handlePageChange" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
  <rd-confirmation-modal
    v-if="showModalProp"
    modalTitle="Delete rating permanently?"
    confirmButton="Delete"
    dismissButton="Dismiss"
    @confirmAction="deleteRating(statusToDelete)"
    @dismissAction="dismissAction"
  />
</template>
<script>
import ButtonComponent from '../../components/button-component.vue'
import RdConfirmationModal from '../../components/rd-components/rd-confirmation-modal.vue'
import rdFooter from '../../components/rd-components/rd-footer.vue'
import RdInput from '../../components/rd-components/rd-input.vue'
import RdLoading from '../../components/rd-components/rd-loading.vue'
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import RdPaginator from '../../components/rd-components/rd-paginator.vue'
import router from '../../router'
import { getRatings, deleteRating, updateBanStatus } from '../../services/sessions/sessions-score.api'

export default {
  components: { rdFooter, RdInput, RdNavbar, RdLoading, RdPaginator, RdConfirmationModal, ButtonComponent },
  data() {
    return {
      screenLoading: false,
      status: [],
      search: '',
      statusDataTemp: [],
      showModalProp: false,
      statusToDelete: null,
      radioModel: -1
    }
  },
  async mounted() {
    if(this.$store.state.permissions.length == 1) {
      this.$store.commit('setShowBackButton', false)
    }
    await this.getScores()
  },
  computed: {
    filteredStatus() {
      return this.status.filter(({ score }) => (
        score.toLowerCase().includes(this.search.toLowerCase())
      ))
    },
  },
  methods: {
    async calcStatusBan(id) {
      try {
        await updateBanStatus(id);
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Status updated.',
          type: 'success',
        })
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Failed!',
          message: 'Error updating Status.',
          type: 'Error',
        })
        console.log(error)
      }
      
    },
    async getScores() {
      try {
        this.screenLoading = true
        const { data } = await getRatings();
        this.status = data
      } catch (error) {
        console.log(error)
      } finally {
        this.screenLoading = false
      }
    },
    dismissAction() {
      this.showModalProp = false
      this.statusToDelete = null
    },
    showDeleteModal(_, id) {
      this.showModalProp = true
      this.statusToDelete = id
    },
    async deleteRating(id) {
      try {
        await deleteRating(id)
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Rating deleted.',
          type: 'success',
        })
        this.showModalProp = false
        this.statusToDelete = null
        await this.getScores()
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Failed!',
          message: 'Error deleting Rating.',
          type: 'Error',
        })
        console.log(error)
      }
    },
    handlePageChange(data) {
      this.statusDataTemp = data;
    },
    goToEditStatus(e, id) {
      e.preventDefault();
      router.push(`/session/score-config/edit/${id}`)
    },
    goToAddNewRating() {
      router.push('/session/score-config/create');
    },
    showModal(e, id) {
      e.preventDefault();
      console.log(`delete status with ${id} flow`)
    },
  },
}
</script>
<style scoped>
.centered {
  text-align: center;
  vertical-align: middle;
}
</style>