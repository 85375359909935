import httpClient from '../httpClient';

const END_POINT = '/api/user-score';

const createRating = async (rating) => await httpClient.post(END_POINT, rating)
const getRatings = async () => await httpClient.get(`${END_POINT}`)
const getRating = async (ratingId) => await httpClient.get(`${END_POINT}/${ratingId}`)
const deleteRating = async (ratingId) => await httpClient.delete(`${END_POINT}/${ratingId}`)
const editRating = async (ratingId, rating) => await httpClient.patch(`${END_POINT}/${ratingId}`, rating)

export {
  createRating,
  getRatings,
  getRating,
  deleteRating,
  editRating,
}