import moment from 'moment';

const jsonParse = function(string) {
  let parse = {};
  try {
    parse = JSON.parse(string.toString(), function (key, value) {
      if ((value && typeof value === 'string') && (value.toLowerCase() === 'true')) {
        return true;
      } else if ((value && typeof value === 'string') && (value.toLowerCase() === 'false')) {
        return false;
      } else if ((value && typeof value === 'string') && (value.substr(0, 8) === 'function')) {
      // eslint-disable-next-line no-new-func
        return new Function(`return ${value}`)();
      } else {
        return value;
      }
    });
  } catch (error) {
    console.log(error);
  }

  return parse;
}

const bufferToString = function(content) {
  let data = [];
  try {
    for (var x = 0; x < content.length; x++) {
      data += String.fromCharCode(content[x])
    }
    data = decodeURIComponent(escape(data));
  } catch (error) {
    console.log(error);
  }

  return data;
}

const convertDateFormat = (date) => {
  return moment(date).format('DD/MM/YYYY HH:mm');
}
const convertDateFormat2 = (stringDate) => {
  let splittedDate = stringDate.split("/");
  let year = new Date().getFullYear();
  if (splittedDate.length == 3) {
    year = splittedDate[2];
  }
  let month = splittedDate[0] - 1;
  let day = splittedDate[1];

  return new Date(year, month, day);
}

const convertDateFormatFromString = (stringDate) => {
  const splittedDate = convertDateFormat(stringDate).split(' ');
  
  const [fullDate, hoursSecond] = splittedDate
  const [day, month, year] = fullDate.split('/')
  const [hour, minutes] = hoursSecond.split(':')

  return new Date(year, month, day, hour, minutes);
}

const convertToCSV = ({ data, separator = '|', firstRowReparator = null }) => {
  return new Promise((resolve) => {
    const header = Object.keys(data[0]).join(firstRowReparator? firstRowReparator : separator);
    const rows = data.map(obj => Object.values(obj).join(separator));
    resolve(`${header}\r\n${rows.join('\r\n')}`);
  })
}

const convertToQuestionsFormat = (questions) => (
  questions.flatMap((question, index) => 
    question.answers.map((answer, answerIndex) => ({
      ID: `Q${index + 1}`,
      question: question.questionText,
      answer: answer.text,
      valid: 0,
      image: answerIndex == 0? question.fileName : ''
    }))
  )
)

const convertDateToNumbers = (cadenaFecha) => {
  const [date, hh] = cadenaFecha.split(' ');
  const [day, month, year] = date.split('/').map(Number);
  const [hour, minute] = hh.split(':').map(Number);
  return new Date(year, month - 1, day, hour, minute);
};

const formatedDate = (date) => {
  if (!date) return "";
  return date.split("T")[0].replace(/-/g, "/");
}

export {
  jsonParse,
  bufferToString,
  convertDateFormat,
  convertDateFormat2,
  convertDateFormatFromString,
  convertToCSV,
  convertToQuestionsFormat,
  convertDateToNumbers,
  formatedDate,
};