<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />
      <div class="container background-form rounded-3 my-3 p-3">
        <PageTitle
          :title="$t('Sites')"
          :description="$t('Go to edit sites')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Sites Management')"
          :grandChildBread="$t('Sites Resources')"
        />
        <div class="row p-3 justify-content-between">
          <!-- Search bar -->
          <div
            class="p-0 m-0 mb-2 col-12 col-lg-3"
          >
            <rd-input
              class="w-100"
              :placeholder="$t('Search resource by name')" 
              type="text"
              @input="(e) => this.search = e.target.value"
            />
          </div>

          <!-- buttons -->
          <div class="p-3 m-0 mb-2 col-12 col-lg-6">
            <div class="row justify-content-end gap-1">
              <div class="p-0 m-0 mb-2 col-12 col-lg-5">
                <button
                  class="btn btn-primary w-100" 
                  @click="goToAddResource"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Resource" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('New Resource') }}
                </button>
              </div>
              
            </div>
          </div>
        </div>

        <div v-if="loading">
          <rd-loading />
        </div>

        <div v-else>

          <div
            v-if="filteredSiteResources.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No roles Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>
          <div v-else class="row p-3 d-flex justify-content-center">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover table-custom-general" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col">{{ $t('Name (table)') }}</th>
                      <th scope="col">{{ $t('Path (table)') }}</th>
                      <th scope="col" class="text-center">{{ $t('Actions (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-start">
                    <tr
                      v-for="({
                        id,
                        path,
                        name
                      }, index) in dataTemp" :key="id"
                      class="hoverable"
                      :class="{ 'fill-ods': index % 2 != 0 }"
                    >
                      <td style="width:33%; word-break: break-all;" :header="$t('Name (table)')" scope="row"><div class="custom-table-text">{{ name }}</div></td>
                      <td style="width:33%; word-break: break-all;" :header="$t('Path (table)')" scope="row"><div class="custom-table-text">{{ path }}</div></td>
                      <td style="width:33%; word-break: break-all;" :header="$t('Actions (table)')">
                        <div class="container">
                          <div class="row text-center">
                            <div class="col p-0 m-0">
                              <button-component 
                                class="btn btn-label" 
                                @click="goToEditSiteResource(id)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Resource" type="button" class="bi bi-pencil-square"></i>
                              </button-component>
                              <button-component 
                                class="btn btn-label" 
                                @click="showModal($event, {
                                  id,
                                  path,
                                  name
                                })"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Delete Resource" type="button" class='bi bi-trash-fill'></i>
                              </button-component>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <rd-paginator :items="filteredSiteResources" @page-change="handlePageChange" />
          </div>

        </div>

      </div>
    </div>
    <div>
      <rd-footer />
    </div>
    <rd-confirmation-modal
      v-if="showModalProp"
      modalTitle="Delete resource permanently?"
      confirmButton="Delete"
      dismissButton="Dismiss"
      @confirmAction="deleteFile(resourceToDelete)"
      @dismissAction="dismissAction"
    />
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdLoading from '../../components/rd-components/rd-loading.vue';
import rdNavbar from '../../components/rd-components/rd-navbar.vue';
import { getAllResourcesById } from '../../services/sites/site.api'
import { deleteResourceById } from '../../services/sites-resources/sites-resources.api'
import router from '@/router'
import RdPaginator from '../../components/rd-components/rd-paginator.vue';
import PageTitle from '../../components/rd-components/page-title.vue';
import RdConfirmationModal from '@/components/rd-components/rd-confirmation-modal.vue'
import RdInput from '@/components/rd-components/rd-input.vue'
import ButtonComponent from '../../components/button-component.vue';

export default {
  components: { rdNavbar, RdFooter, RdLoading, RdPaginator, PageTitle, RdConfirmationModal, RdInput, ButtonComponent },
  name: 'SiteResources',
  data() {
    return {
      loading: false,
      dataTemp: [],
      resources: [],
      siteId: null,
      showModalProp: false,
      resourceToDelete: {},
      search: ''
    }
  },
  async mounted() {
    this.siteId = this.$route.params.id;
    await this.getResources();
  },
  computed: {
    filteredSiteResources() {
      return this.resources.filter(
          resource => resource.name.toLowerCase().includes(this.search.trim().toLowerCase())
        )
    },
  },
  methods: {
    goToAddResource() {
      router.push(`/admin/sites/resources/add/${this.siteId}`)
    },
    async deleteFile({ id }) {
      try {
        await deleteResourceById(id)
        await this.getResources()
      } catch (error) {
        console.log(error)
      } finally {
        this.showModalProp = false
        this.examToDelete = {}
      }
    },
    showModal(e, resource){
      this.showModalProp = true
      this.resourceToDelete = resource
    },
    dismissAction() {
      this.resourceToDelete = {}
      this.showModalProp = false
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '33%'
      })
    },
    goToEditSiteResource(id) {
      router.push(`/admin/sites/resources/edit/${this.siteId}/${id}`)
    },
    async getResources() {
      try {
        this.loading = true
        const { data } = await getAllResourcesById(this.siteId)
        this.resources = data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        await this.$nextTick(() => {
          const elements = document.querySelectorAll("table th");
          elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
          })
          document.addEventListener("mousemove", (e) => {
            if (this.thElm) {
              this.lastWidth  = this.thElm.offsetWidth;
              this.thElm.style.width = this.startOffset + e.pageX + "px";
              document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                if((internalIndex % 3) == Number(this.thElm.id)) {
                  td.style.width = this.startOffset + e.pageX + "px";
                } else {
                  td.style.width = td.offsetWidth + "px";
                }
              })
              this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
            }
          });

          document.addEventListener("mouseup", () => {
            this.thElm = undefined;
          });
        });
      }
    }
  },
}
</script>