<template>
  <div
    class="chart"
    ref="chartdiv"
    :style="{
      'width': width,
      'height': height,
    }"
  ></div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
am5.addLicense('AM5C403235567');
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export default {
  name: 'pie-chart-component',
  props: {
    data: {
      type: Array,
      default: function() { return [] },
    },
    label: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '500px',
    },
    hasInnerRadius: {
      type: Boolean,
      default: true,
    },
    showLabels: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      root: null,
      chart: null,
    }
  },
  methods: {
    loadChart() {
      if (this.chart) {
        this.chart.series.each(series => {
          series.data.setAll(this.cleanData);
          series.slices.each((slice, index) => {
            if (this.cleanData[index].color) {
              slice.set("fill", am5.color(this.cleanData[index].color));
              slice.set("stroke", am5.color(this.cleanData[index].color));
            }
          })
          series.labels.each((label, index) => {
            if (this.showLabels) {
              label.set("text", this.cleanData[index].category + ": " + this.cleanData[index].value);
            }
          });
          series.children.each(label => {
            label.set("text", this.label);
          });
        });
      }
    }
  },
  mounted() {
    let root = am5.Root.new(this.$refs.chartdiv);
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        name: "Series",
        innerRadius: this.hasInnerRadius ? am5.percent(80) : null,
      })
    );

    // Create series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        categoryField: "category",
        valueField: "value",
        clickTarget: "none",
      })
    );

    series.data.setAll(this.cleanData);
    series.slices.template.setAll({
      templateField: "sliceSettings"
    });

    // Set up labels
    series.labels.template.setAll({
      text: "{category}: {value}", // Use the category and value fields as the label text
      visible: this.showLabels,
      radius: am5.percent(-30), // Position the labels above the slices
      inside: false,
      populateText: true
    });

    series.ticks.template.set("visible", false);
    series.slices.template.set("toggleKey", "none");

    series.children.push(am5.Label.new(root, {
      text: this.label,
      fontSize: 40,
      centerX: am5.percent(50),
      centerY: am5.percent(50)
    }));

    series.slices.each((slice, index) => {
      if (this.cleanData[index].color) {
        slice.set("fill", am5.color(this.cleanData[index].color));
        slice.set("stroke", am5.color(this.cleanData[index].color));
      }
      slice.remove('border')
    });

    this.chart = chart;
    this.root = root;
  },
  watch: {
    data: {
      handler: 'loadChart',
      deep: true
    }
  },
  computed: {
    cleanData() {
      return this.data.filter(d => +(d.value) > 0);
    },
  },
  beforeUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }
}
</script>
