<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Settings (nav)')" />
      <div class="container background-form my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Edit Language') }}</h2>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Language name')" 
              :label="$t('Enter the language name')"
              required
              :value="languageName"
              @input="(e) => this.languageName = e.target.value"
            />
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Language abbreviation')" 
              :label="$t('Enter the language abbreviation')"
              required
              :value="languageAbbreviation"
              @input="(e) => this.languageAbbreviation = e.target.value"
            />
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <table width="100%">
              <tr>
                <td>
                  <label class="labelText">
                    {{ $t('Language File') }}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <rd-input
                    type="file"
                    :label="$t('Choose a file')"
                    required
                    @input="handleFileSelect"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Buttons -->
        <div class="my-2 row justify-content-end">
          <div class="col-lg-6 col-12 text-end">
            <button-component
              primary-outline
              px="4" py="2"
              @handleClick="goBack"
              >
              {{ $t('Dismiss') }}
            </button-component>
            <button-component
              :disabled="disableSaveButton"
              primary
              px="4" py="2"
              @handleClick="editThisLanguage"
            >
              {{ $t('Edit Language (btn)') }}
            </button-component>
          </div>
        </div>

      </div>
    </div>
      <rd-footer />
    </div>
</template>
<script>
  import rdFooter from '../../components/rd-components/rd-footer.vue'
  import RdNavbar from '../../components/rd-components/rd-navbar.vue'
  import RdInput from '@/components/rd-components/rd-input.vue'
  import router from '@/router'
  import { getLanguageById, editLanguageById} from '@/services/languages/language.api.js'
  import { bufferToString } from '../../utils/convertFormat.js'
import { validateJSON } from '../../utils/utils'
import ButtonComponent from '../../components/button-component.vue'

  export default {
    data() {
      return {
        languageName: '',
        languageAbbreviation: '',
        languageResourceFile: null,
        disableSaveButton: false,
      }
    },
    components: {
      rdFooter,
      RdNavbar,
      RdInput,
      ButtonComponent,
    },
    async mounted() {
      await this.setLanguage()
    },
    computed: {
      thisFileId() {
        return this.$route.params.id
      }
    },
    methods: {
      async setLanguage() {
        try {
          const { data } = await getLanguageById(this.thisFileId)
          this.languageName = data.languageName
          this.languageAbbreviation = data.languageAbbreviation
          this.languageResourceFile = bufferToString(data.languageResourceFile.data)
        } catch (error) {
          console.log(error)
        }
      },
      goBack() {
        router.back()
      },
      async editThisLanguage() {
        try {
          const validMessages = await validateJSON(this.languageResourceFile)
          if (!validMessages) {
            this.$store.dispatch('dispatchNotification', {
              title: 'Error!',
              message: 'JSON file has errors, please fix and try again.',
              type: 'error',
            })
            return
          }

          const language = {
            languageName: this.languageName,
            languageAbbreviation: this.languageAbbreviation,
            languageResourceFile: this.languageResourceFile
          }
          
          await editLanguageById(this.thisFileId, language)
          this.$store.dispatch('dispatchNotification', {
            title: 'Success!',
            message: 'Language edited.',
            type: 'success',
          });
          this.$store.dispatch('getI18nData', this.$i18n);
          router.back()
        } catch (error) {
          this.$store.dispatch('dispatchNotification', {
            title: 'Error!',
            message: 'Could not edit this language.',
            type: 'error',
          });
        }
      },
      handleFileSelect(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = e => this.languageResourceFile = e.target.result;
        reader.readAsText(file);
      }
    },

  }
</script>