import httpClient from "../httpClient";

const END_POINT = '/api/sites-resources'
const deleteResourceById = async (id) => await httpClient.delete(`${END_POINT}/${id}`);
const createResource = async (resource, id) => await httpClient.post(`${END_POINT}/${id}`, resource);
const editResource = async (resource, id) => await httpClient.patch(`${END_POINT}/${id}`, resource);
const getResourceById = async (id) => await httpClient.get(`${END_POINT}/${id}`)

export {
  deleteResourceById,
  createResource,
  getResourceById,
  editResource
}