<template lang="">
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Profiles (nav)')" />
      <div class="container background-form my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t("Add Profile") }}</h2>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Profile name')"
              :label="$t('Enter the profile name')"
              required
              @input="(e) => (this.profileName = e.target.value)"
            />
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Profile description')"
              :label="$t('Enter the profile description')"
              required
              @input="(e) => (this.profileDescription = e.target.value)"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              type="text"
              :placeholder="$t('Keycloak Name')" 
              :label="$t('Enter the keycloak name')"
              @input="(e) => this.keycloak_profile_name = e.target.value"
            />
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <label class="labelText required">
              {{ $t("Link categories or subcategories to this profile") }}
            </label>
            <table class="table text-start">
              <thead class="table-primary">
                <tr>
                  <th scope="col">
                    <rd-input
                      type="checkbox"
                      v-model="allCategoriesSelected"
                      :checked2="allSubcategories.length === subcategoriesToAdd.length"
                      @change="addAllCategories"
                      :value="parseInt(allCategoriesSelected)"
                      :id="parseInt(allCategoriesSelected)"
                    />
                    {{ $t("Category (table)") }}
                  </th>
                  <th scope="col">{{ $t("Subcategory (table)") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(category, index) in categories" :key="index">
                  <rd-row
                    v-if="category.subcategories.length > 0"
                    :category="category"
                    :currentSubcategories="subcategoriesToAdd"
                    @addSubcategories="addSubcategories"
                    @cleanSubcategories="cleanSubcategories"
                  />
                </tr>
              </tbody>
            </table>
            
          </div>
        </div>

        <!-- Buttons -->
        <div class="my-3 row justify-content-end">
          <div class="col-12 col-md-6 col-lg-4 text-end">
            <button-component primaryOutline @handleClick="goBack">
              {{ $t("Dismiss") }}
            </button-component>
            <button-component
              :disabled="disableSaveButton"
              primary
              @handleClick="handleCreateProfile"
            >
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Profile" type="button" class="bi bi-plus-circle-fill"></i>
              {{ $t("Add Profile (btn)") }}
            </button-component>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isCreating" class="overlay">
      <div class="d-flex justify-content-center align-items-center w-100 h-100">
        <rd-loading />
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import RdFooter from "../../components/rd-components/rd-footer.vue";
import RdNavbar from "../../components/rd-components/rd-navbar.vue";
import RdInput from "@/components/rd-components/rd-input.vue";
import router from "@/router";
import RdRow from '@/components/rd-components/rd-row.vue'
import { getAllCategory } from "@/services/categories/categories.api.js";
import { createProfile } from '@/services/profiles/profile.api.js';
import buttonComponent from "../../components/button-component.vue";
import RdLoading from '../../components/rd-components/rd-loading.vue';

export default {
  data() {
    return {
      profileName: '',
      profileDescription: '',
      subcategoriesToAdd: [],
      categories: [],
      allCategoriesSelected: false,
      allSubcategories: [],
      keycloak_profile_name: '',
      isCreating: false
    };
  },
  async mounted() {
    await this.setCategories();
  },
  computed: {
    disableSaveButton() {
      return (
        this.profileName === '' ||
        this.profileDescription === ''
      )
    },
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    RdRow,
    buttonComponent,
    RdLoading
  },
  methods: {
    addSubcategories({ subcategoryId }) {
      if (!this.subcategoriesToAdd.includes(subcategoryId)) {
        this.subcategoriesToAdd.push(subcategoryId)
        return
      }
      this.subcategoriesToAdd = this.subcategoriesToAdd.filter(sub => sub !== subcategoryId)
    },
    cleanSubcategories(subcategories) {
      for (const subcategory of subcategories) {
        this.subcategoriesToAdd = this.subcategoriesToAdd.filter(sub => sub !== subcategory)
      }
    },
    addAllCategories(e) {
      if (!e.target.checked) {
        this.subcategoriesToAdd = []
        return;
      }
      this.subcategoriesToAdd = []
      for (const category of this.categories) {
        category.subcategories.forEach(sub => {
          if (parseInt(sub.subCategoryId)) this.subcategoriesToAdd.push(parseInt(sub.subCategoryId))
        })
      }
    },
    async setCategories() {
      try {
        const { data } = await getAllCategory();
        this.categories = data;
        for (const category of data) {
          const subcategories = category.subcategories.map(sub => sub.subCategoryId)
          this.allSubcategories.push(...subcategories)
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleCreateProfile() {
      this.isCreating = true;
      try {
        const formattedSubcategories = [
          ...new Set(this.subcategoriesToAdd),
        ];
        const profile = {
          profileName: this.profileName,
          profileDescription: this.profileDescription,
          subCategories: formattedSubcategories,
          keycloak_profile_name: this.keycloak_profile_name
        };
        await createProfile(profile);
        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Profile created.',
          type: 'success',
        });
        router.back()
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Profile was not created.',
          type: 'error',
        });
        console.log(error);
      }
      finally {
        this.isCreating = false;
      }
    },
    goBack() {
      router.back();
    },
  },
};
</script>
<style scoped>
.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.09);
  color: white;
  z-index: 999999;
  border-radius: .5rem;
}
</style>