<template>
  <div 
    class="
      d-flex
      justify-content-center
      align-items-center
      flex-column
      h-100
    "
  >
    <video
      width="500"
      controls
    >
      <source 
        :src="`/api/files/streaming/${data}?authToken=${authToken}`" 
        type="video/mp4"
      >
    </video>
  </div>
</template>
<script>
export default {
  name: 'RdPdfViewer',
  props: {
    data: {
      type: String,
      required: true
    },
  },
  computed: {
    authToken() {
      return this.$store.getters.getToken
    }
  },
  unmounted() {
    this.$store.commit('clearPlayingVideo')
  },
}
</script>
<style scoped>
video {
  width: 100%;
  height: 100%;
}
</style>
