<template>
  <div class="card hoverable minHeight h-100" @click="handleRouter">
    <div
      class="card-header text-start p-0"
      :style="{
        'margin': '0 0 12px 0',
        'font-weight': '700'
      }"
    >
      {{ name }}
    </div>
    <div class="card-body p-0 m-0">
      <p class="card-text text-start p-0 m-0">
        {{ description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleRouter() {
      this.$emit('route')
    }
  },
  
}
</script>
<style scoped>

.card {
  padding: 1.25rem;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  box-shadow: inset 0 0 0 1px #e0e0e0;
}

.card-header {
  background-color: transparent !important;
  border-bottom: none !important;
  font-size: calc(16px + .25vw);
  font-weight: 700;
}

.card-body {
  font-size: calc(10px + .25vw);
  font-weight: 400;
}

.hoverable:hover {
  cursor: pointer;
  box-shadow: 0 2px 6px 0 rgba(61, 61, 61, .3);
}

.minHeight {
  min-height: 120px;
}
</style>