import httpClient from '../httpClient';

const END_POINT = '/api/templates';

const createTemplate = async (template) => await httpClient.post(END_POINT, template);
const getAllTemplates = async () => await httpClient.get(END_POINT);
const getTemplate = async (id) => await httpClient.get(`${END_POINT}/${id}`);
const updateTemplateById = async ({ id, ...template }) => httpClient.patch(`${END_POINT}/${id}`, template);

export {
  createTemplate,
  getAllTemplates,
  getTemplate,
  updateTemplateById,
}