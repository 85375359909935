<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Background')" />
      <div class="container background-form my-3 p-3">
        <PageTitle
          :title="$t('Assignments Settings')"
          :description="$t('Assignments settings configuration')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Settings')"
          :grandChildBread="$t('Assignments Settings')"
        />
          
        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              class="mt-2"
              :label="$t('Enter the geolocation parameter percentage')"
              :placeholder="$t('Geolocation Percentage')"
              type="number"
              :value="assignments_geolocation"
              :initialValue="assignments_geolocation"
              @input="(e) => this.assignments_geolocation = e.target.value"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              class="mt-2"
              :label="$t('Enter the team rating parameter percentage')"
              :placeholder="$t('Team Rating Percentage')"
              type="number"
              :value="assignments_team_rating"
              :initialValue="assignments_team_rating"
              @input="(e) => this.assignments_team_rating = e.target.value"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              class="mt-2"
              :label="$t('Enter the last assignment accepted parameter percentage')"
              :placeholder="$t('Last Assignment Accepted Percentage')"
              type="number"
              :value="assignments_last_assignment"
              :initialValue="assignments_last_assignment"
              @input="(e) => this.assignments_last_assignment = e.target.value"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              class="mt-2"
              :label="$t('Enter the user has worked with lead parameter percentage')"
              :placeholder="$t('User Has Worked With Lead Percentage')"
              type="number"
              :value="assignments_has_worked"
              :initialValue="assignments_has_worked"
              @input="(e) => this.assignments_has_worked = e.target.value"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <rd-input
              class="mt-2"
              :label="$t('Enter the user assignments mileage filter')"
              :placeholder="$t('Assignments mileage')"
              type="number"
              :value="assignments_mileage"
              :initialValue="assignments_mileage"
              @input="(e) => this.assignments_mileage = e.target.value"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="form-check d-flex">
            <rd-input :label="$t('Use Assignments V1')" type="checkbox" v-model="use_v1" :checked2="use_v1" @change="changeUseV1"/>
          </div>
        </div>
        

        <!-- buttons -->
        <div class="row my-3 justify-content-end align-items-center">
          <div class="col-12 col-lg-3 text-end align-self-center">
            <button-component
              primary-outline
              @handleClick="goBack"
            >
              {{ $t('Dismiss') }}
            </button-component>
            <button-component
              primary
              @handleClick="handleSave"
            >
              {{ $t('Update') }}
            </button-component>
          </div>
        </div>

      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue'
import RdInput from '../../components/rd-components/rd-input.vue'
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import router from '@/router'
import { getAssignmentSettings , updateAssignmentSettings } from '../../services/settings/settings.api'
import PageTitle from '../../components/rd-components/page-title.vue'
import ButtonComponent from '../../components/button-component.vue'

export default {
  data() {
    return {
      assignments_geolocation: 50,
      assignments_team_rating: 0,
      assignments_last_assignment: 30,
      assignments_has_worked: 20,
      assignments_mileage: 10,
      use_v1: true
    }
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    PageTitle,
    ButtonComponent
  },
  async mounted() {
    if(this.$store.state.permissions.length == 1) {
      this.$store.commit('setShowBackButton', false)
    }
    const {data} = await getAssignmentSettings();
    const {
      assignments_geolocation,
      assignments_team_rating,
      assignments_last_assignment,
      assignments_has_worked,
      assignments_mileage,
      use_v1
    } = data;
    this.assignments_geolocation = assignments_geolocation
    this.assignments_team_rating = assignments_team_rating
    this.assignments_last_assignment = assignments_last_assignment
    this.assignments_has_worked = assignments_has_worked
    this.assignments_mileage = assignments_mileage
    this.use_v1 = use_v1
  },
  methods: {
    changeUseV1(e) {
      this.use_v1 = e.target.checked
    },
    async handleSave() {
      try {
        await updateAssignmentSettings({
          assignments_geolocation: this.assignments_geolocation,
          assignments_team_rating: this.assignments_team_rating,
          assignments_last_assignment: this.assignments_last_assignment,
          assignments_has_worked: this.assignments_has_worked,
          assignments_mileage: Math.floor(this.assignments_mileage),
          use_v1: this.use_v1
        })

        this.$store.dispatch('dispatchNotification', {
          title: 'Success!',
          message: 'Settings saved.',
          type: 'success',
        });
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'Something went wrong.',
          type: 'error',
        });
        console.log(error)
      }
    },
    goBack() {
      router.back()
    },
  }
}
</script>