<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Sites (nav)')" />
      <div class="container background-form my-3 p-3">
        <PageTitle
          :title="$t('Sites')"
          :description="$t('Go to edit sites')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Sites Management')"
        />
        <div class="row px-3 justify-content-between">
          <!-- Search bar -->
          <div
            class="col-12 col-lg-4"
          >
            <rd-input
              type="text"
              class="w-100"
              :placeholder="$t('Search site by name, vc code or team code')"
              @input="searchSites($event)"
            />
          </div>

          <div class="col-12 col-lg-6 text-end">
            <button-component
              primary 
              @handleClick="goToAddSite">
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Site" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Site (btn)') }}
            </button-component>
          </div>

        </div>

        <div v-if="sitesLoading">
          <rd-loading /> 
        </div>
      <!-- after loading -->
      <div v-if="!sitesLoading">
        <!-- No sites were found -->
        <div 
          v-if="filteredSites.length === 0"
          class="row justify-content-center" 
        >
          <div class="col-12 text-center mt-4"> 
            <img src="@/assets/imgs/exclamation-triangle-fill.svg" height="150">
          </div>
          <div class="col-12 fs-3 fw-bold text-center">
            {{ $t('No sites found') }}
          </div>
        </div>

        <!-- Sites found -->
        <template v-else>
          <div class="mt-4">
            <data-table-component
              :columns="[
                { label: $t('Site'), field: 'site', sortable: true, type: 'string' },
                { label: $t('VC Code'), field: 'code', sortable: true, type: 'string' },
                { label: $t('Activity date'), field: 'activityDate', sortable: true, type: 'date' },
                { label: $t('Team Code'), field: 'team' },
                { label: $t('Status'), field: 'status' },
                { label: $t('Actions'), field: 'actions', headerHAlign: 'center', hAlign: 'center' },
              ]"
              :rows="filteredSites"
              paginator
              resizeable
            >
              <template #team="{ row }">
                {{ row.activityDate }} - {{ row?.checkIn ?? $t('none') }}
              </template>
              <template #status="{ row }">
                <div class="custom-table-text"><span> {{ row.isAvailable ? $t('Active') : $t('Inactive') }} </span></div>
              </template>
              <template #actions="{ row }">
                <button-component @click="(e) => goToResources(e, row.id)" class="btn btn-label text-decoration-none">
                  <i class="bi bi-box-arrow-up-right"></i>
                </button-component>
                <button-component @click="() => goToEditSite(row.id)" class="btn btn-label text-decoration-none">
                  <i class="bi bi-pencil-square"></i>
                </button-component>
              </template>
            </data-table-component>
          </div>
        </template>
      </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import rdFooter from '../../components/rd-components/rd-footer.vue'
import RdInput from '../../components/rd-components/rd-input.vue'
import RdNavbar from '../../components/rd-components/rd-navbar.vue'
import RdLoading from '../../components/rd-components/rd-loading.vue'
import { getAllSites } from '../../services/sites/site.api'
import PageTitle from '../../components/rd-components/page-title.vue'
import ButtonComponent from '../../components/button-component.vue'
import DataTableComponent from '../../components/tables/data-table-component.vue'

export default {
  components: { rdFooter, RdInput, RdNavbar, RdLoading, PageTitle, ButtonComponent, DataTableComponent },
  data() {
    return {
      sites: [],
      showSites: [],
      sitesLoading: true,
      search: '',
      tableSorting: {
        clickSites: false,
        clickCode: false,
        clickActivityDate: false,
        clickTeamCode: false,
        clickStatus: false,
      },
      columns: [
        { label: this.$t('Site'), field: 'site', sortable: true, type: 'string' },
        { label: this.$t('VC Code'), field: 'code', sortable: true, type: 'string' },
        { label: this.$t('Activity date'), field: 'activityDate', sortable: true, type: 'date' },
        { label: this.$t('Team Code'), field: 'team' },
        { label: this.$t('Status'), field: 'status' },
        { label: this.$t('Actions'), field: 'actions', headerHAlign: 'center', hAlign: 'center' },
      ]
    }
  },
  async mounted() {
    if(this.$store.state.permissions.length == 1) {
      this.$store.commit('setShowBackButton', false)
    }
    await this.getSites()
  },
  computed: {
    filteredSites() {
      if(!this.sites) return []
      return this.sites.filter(site => (
        site.site.toLowerCase().includes(this.search) ||
        site.team.code.toLowerCase().includes(this.search) ||
        site.code.toLowerCase().includes(this.search)
      ))
    },
  },
  methods: {
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
    },
    sortTable(param, key) {
      this.tableSorting[param] = !this.tableSorting[param]

      if (key === 'code') {
        if (!this.tableSorting[param]) {
          this.sites.sort((a, b) => a[`${key}`] - b[`${key}`]) 
        } else {
          this.sites.sort((a, b) => b[`${key}`] - a[`${key}`]) 
        }
        return;
      }
      if (key === 'activityDate') {
        if (!this.tableSorting[param]) {
          this.sites.sort((a, b) => {
            // Parse activityDate strings into Date objects
            const dateA = new Date(a.activityDate);
            const dateB = new Date(b.activityDate);

            // Compare the dates
            if (dateA < dateB) {
              return -1; // 'a' should come before 'b'
            } else if (dateA > dateB) {
              return 1; // 'b' should come before 'a'
            } else {
              // If the dates are the same, compare the checkIn times
              const timeA = a.checkIn !== null ? a.checkIn.split(':').map(Number) : [0, 0];
              const timeB = b.checkIn !== null ? b.checkIn.split(':').map(Number) : [0, 0];

              if (timeA[0] < timeB[0]) {
                return -1; // 'a' should come before 'b' based on hours
              } else if (timeA[0] > timeB[0]) {
                return 1; // 'b' should come before 'a' based on hours
              } else {
                if (timeA[1] < timeB[1]) {
                  return -1; // 'a' should come before 'b' based on minutes
                } else if (timeA[1] > timeB[1]) {
                  return 1; // 'b' should come before 'a' based on minutes
                } else {
                  return 0; // no change in order
                }
              }
            }
          }) 
        } else {
          this.sites.sort((a, b) => {
            // Parse activityDate strings into Date objects
            const dateA = new Date(a.activityDate);
            const dateB = new Date(b.activityDate);

            // Compare the dates
            if (dateA < dateB) {
              return 1; // 'b' should come before 'a'
            } else if (dateA > dateB) {
              return -1; // 'a' should come before 'b'
            } else {
              // If the dates are the same, compare the checkIn times
              const timeA = a.checkIn ? a.checkIn.split(':').map(Number) : [0, 0];
              const timeB = b.checkIn ? b.checkIn.split(':').map(Number) : [0, 0];

              if (timeA[0] < timeB[0]) {
                return 1; // 'b' should come before 'a' based on hours
              } else if (timeA[0] > timeB[0]) {
                return -1; // 'a' should come before 'b' based on hours
              } else {
                if (timeA[1] < timeB[1]) {
                  return 1; // 'b' should come before 'a' based on minutes
                } else if (timeA[1] > timeB[1]) {
                  return -1; // 'a' should come before 'b based on minutes
                } else {
                  return 0; // no change in order
                }
              }
            }
          }) 
        }
        return;
      }
      if (key === 'team.code') {
        if (!this.tableSorting[param]) {
          this.sites.sort(
            (a, b) => ((a.team ? a.team.code : '')).localeCompare(
              (b.team ? b.team.code : '')
            )
        ) 
        } else {
          this.sites.sort(
            (a, b) => (b.team ? b.team.code : '').localeCompare(
              (a.team ? a.team.code : '')
            )
          ) 
        }
        return;
      }
      if (key === 'is_available') {
        if (!this.tableSorting[param]) {
          this.sites.sort((a, b) => {
            if (a[`${key}`] && !b[`${key}`]) return -1; // 'a' should come before 'b'
            if (!a[`${key}`] && b[`${key}`]) return 1; // 'b' should come before 'a'
            return 0; // no change in order
          })
        } else {
          this.sites.sort((a, b) => {
            if (a[`${key}`] && !b[`${key}`]) return 1; // 'b' should come before 'a'
            if (!a[`${key}`] && b[`${key}`]) return -1; // a' should come before 'b'
            return 0; // no change in order
          })
        }
        return;
      }

      if (!this.tableSorting[param]) {
        this.sites.reverse();
        return;
      }
      this.sites.sort((a, b) => a[`${key}`].localeCompare(b[`${key}`])) 
    },
    handlePageChange(data) {
      this.showSites = data;
    },
    async getSites() {
      try {
        this.sitesLoading = true
        const {data} = await getAllSites()
        this.sites = data
      } catch (error) {
        console.log(error)
      } finally {
        this.sitesLoading = false
        await this.$nextTick(() => {
          const elements = document.querySelectorAll("table th");
          elements.forEach((el) => {
            el.style.position = "relative";

            let grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });
            el.appendChild(grip);
          })
          document.addEventListener("mousemove", (e) => {
            if (this.thElm) {
              this.lastWidth  = this.thElm.offsetWidth;
              this.thElm.style.width = this.startOffset + e.pageX + "px";
              this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
            }
          });

          document.addEventListener("mouseup", () => {
            this.thElm = undefined;
          });
        });
      }
    },
    goToEditSite(id) {
      this.$router.push(`/admin/sites/edit/${id}`)
    },
    goToResources(event, id) {
      event.stopPropagation();
      this.$router.push(`/admin/sites/resources/${id}`)
    },
    goToAddSite() {
      this.$router.push(`/admin/sites/add`)
    },
    redirect(route) {
      this.$router.push(route)
    },
    searchSites(e) {
      this.search = e.target.value.toLowerCase()
    },
    clearSearch() {
      this.search = ''
    },
  },
}
</script>