<template>
  <div class="col-lg-9 col-md-9 col-sm-12 h-100 py-3">
    <div class="row">
      <h3 class="text-start mt-2">{{ $t('Welcome') }}, {{ this.user.userName }} </h3>
      <div class="col-12">
        <rd-input 
          type="text"
          class="w-100"
          :placeholder="$t('Search file by file name')" 
          @input="(e) => this.search = e.target.value"
        />
      </div>
      <div class="col-12 w-100">
        <div class="row p-1 mt-2">
          <div>
            <div class="d-flex justify-content-between">
              <p class="m-0 fs-6">
                {{ $t('Lesson Completion') }}
              </p>
              <p class="m-0">
                {{ calcCompletionPercent(filesCount, calcfilesSeenOrDownloaded.length) }}%
              </p>
            </div>
            <div class="progress">
              <div class="progress-bar bg-success" role="progressbar" :style="`width: ${calcCompletionPercent(filesCount, calcfilesSeenOrDownloaded.length)}%`" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row p-1 mt-2 mb-4">
          <div>
            <div class="d-flex justify-content-between">
              <p class="m-0 fs-6">
                {{ $t('Approved Exams') }}
              </p>
              <p class="m-0">
                {{ examsApproved }} / {{ examsCount }}
              </p>
            </div>
            <div class="progress">
              <div class="progress-bar bg-success" role="progressbar" :style="`width: ${calcCompletionPercent(examsCount, examsApproved)}%`" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="filesLoading">
      <rd-loading />
    </div>

    <div v-else>
      <div 
        v-if="filteredCategories.length === 0" 
        class="row p-3"
      >
        <h4>
          {{ $t('No data found') }} <i class="bi bi-exclamation-triangle"></i>
        </h4>
      </div>
      
      <div v-else class="accordion accordion-custom px-1" id="accordionParent">

        <div class="accordion-item"
          v-for="(category, index) in filteredCategories" :key="index"
        >
          <h2 class="accordion-header" :id="`heading-${index}`">
            <button 
              class="accordion-button accordion-button-custom" 
              :class="{
                'collapsed': this.search
              }"
              type="button" 
              data-bs-toggle="collapse" 
              :data-bs-target="`#collapse-${index}`" 
              aria-expanded="true"
              :aria-controls="`collapse-${index}`"
            >
              <div class="flex-grow-1">{{ category.categoryName }}</div>
              <div class="me-3">
                <span>
                  {{ calcCompletionPercent(category.totalFiles, category.completedFiles) }}% {{ $t('Completed') }} ({{ category.completedFiles }} / {{ category.totalFiles }})
                </span>
              </div>
            </button>
          </h2>
          <div 
            :id="`collapse-${index}`" 
            class="accordion-collapse collapse"
            :class="{
              'show': this.search
            }"
            :aria-labelledby="`heading-${index}`" 
            data-bs-parent="#accordionParent"
          >
            <div 
              class="accordion-body p-0"
            >
              <div class="custom-sub ">
                <span class="chips" @click="setSubcategorySelected(category.categoryId, Number(sub.subCategoryId))" :class="[(category.selected === Number(sub.subCategoryId) || category.subcategory.length == 1) ? 'selected' : 'not-selected']" v-for="(sub, indexSubCategory) in category.subcategory" :key="indexSubCategory" >
                  {{ sub.subCategoryName }}
                </span>
              </div>
              <div
                class="list-group list-group-flush text-start"
                v-for="(file, fileIndex) in category.files" :key="fileIndex"
              >
                <a 
                  class="list-group-item text-primary"
                  :class="{
                    'border border-top-0 border-start-0 border-end-0': fileIndex !== (category.files.length - 1) 
                  }" 
                  @click="file.fileType !== 'exam' ? goToFileDetail(file.uuid, file.isTemp) : goToExam(file)"
                >
                  <i v-if="file.fileType !== 'exam'" :class="`bi bi-box-arrow-right mx-1`"></i> <img v-else
                                                    src="/assets/lista.png"
                                                    class="custom-list-image"
                                                    alt="go to exam"
                                                  > {{ file.subcategory.subCategoryName }} - {{ file.fileName }} <i v-if="file.isTemp" class="bi bi-exclamation-triangle"></i>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import { mapState } from 'vuex'
import RdInput from '../../components/rd-components/rd-input.vue'
import rdLoading from '../../components/rd-components/rd-loading.vue'
import { getFilesByUserId, getAllFiles } from '@/services/files/file.api.js';
import { getAllCategoryWithFiles, getCategoryByProfile } from '@/services/categories/categories.api.js';
import { findValidInteractionsByUserId } from '@/services/interactions/interactions.api.js'
import { userCanPerformExam } from '@/services/exams/exam.api';
import router from '@/router';

export default {
  components: { rdLoading, RdInput },
  data() {
    return {
      filesFromDB: [],
      filesLoading: false,
      fileCategories: [],
      search: '',
      allCategories: [],
      filesSeenOrDownloaded: [],
      files: [],
    }
  },
  async mounted() {
    await this.setCategories();
    await this.setFiles()
    const { data } = await findValidInteractionsByUserId(this.user.userId)
    this.filesSeenOrDownloaded = Array.from(data, value => Number(value.file_id))
  },
  computed: {
    calcfilesSeenOrDownloaded() {
      const files = [];
      this.files.filter(f => f.fileType !== 'exam').forEach((file) => {
        if(this.filesSeenOrDownloaded.includes(Number(file.fileId))) {
          files.push(file)
        }
      })
      return files
    },
    filesCount() {
      return this.files.filter(f => f.fileType !== 'exam').length
    },
    examsApproved() {
      return this.files.filter(f => f.fileType === 'exam' && f.examApproved).length
    },
    examsCount() {
      return this.files.filter(f => f.fileType === 'exam').length
    },
    filteredFiles() {
      return this.filesFromDB
    },
    filteredCategories() {
      let categories = this.fileCategories.filter(category => category.files.some(file => file.fileName.toLowerCase().includes(this.search.toLowerCase())))
      categories = JSON.parse(JSON.stringify(categories))
      categories.forEach((category) => {
        if(!(category.selected === -1)) {
          category.files = category.files.filter(file => Number(file.subCategoryId) === category.selected);
        }
      })
      return categories.map(category => ({
          ...category,
          totalFiles: category.files.length,
          files: category.files.filter(file => file.fileName.toLowerCase().includes(this.search.toLowerCase())),
          completedFiles: category.files.filter(file => {
              if (file.fileType === 'exam') {
                return !!file.last && Object.values(file.last).length
              }

              return this.filesSeenOrDownloaded.includes(Number(file.fileId))
            }).length,
        })
      )
    },
    ...mapState(['user'])
  },
  methods: {
    calcCompletionPercent(total, completed) {
      if (total === 0) return 0;

      const percent = Math.round((completed / total) * 100);

      return percent;
    },
    setSubcategorySelected(categorySelected, selectedValue) {
      this.fileCategories.forEach((category) => {
        if(category.categoryId === categorySelected) {
          category.selected = selectedValue
        }
      })
    },
    async setCategories() {
      try {
        const {userId, isAdmin} = this.user

        let {data} = isAdmin 
          ? await getAllCategoryWithFiles()
          : await getCategoryByProfile(userId)

        this.allCategories = data
      } catch (error) {
        console.log(error)
      }
    },
    async setFiles() {
      try {
        const {userId, isAdmin} = this.user
        
        this.filesLoading = true

        let { data } = isAdmin
          ? await getAllFiles({ exams: true })
          : await getFilesByUserId({ userId, exams: true })
        data = data.filter(file => !file.isTemp)
        this.files = data;
        const categoriesTemp = [...new Set(data.map(file => file.category.categoryName))]
          .sort((a, b) => a.localeCompare(b))
          .sort((a, b) => {
            const first = !(isNaN(parseInt(a.split(' ')[0]))) ? parseInt(a.split(' ')[0]) : 10000000000
            const second = !(isNaN(parseInt(b.split(' ')[0]))) ? parseInt(b.split(' ')[0]) : 10000000000
            return first - second
          })
          .map(category => {
            const found = data.find(file => file.category.categoryName === category)
            return {
              categoryName: found.category.categoryName,
              categoryId: found.category.categoryId,
              files: []
            }
          })

        this.fileCategories = categoriesTemp.map(category => {
          category.files = data.filter(file => file.category.categoryName === category.categoryName)
          category.files
            .sort((a, b) => a.subcategory.subCategoryName.localeCompare(b.subcategory.subCategoryName))
            .sort((a, b) => {
              const second = !(isNaN(parseFloat(b.subcategory.subCategoryName.split(' ')[0]))) ? parseFloat(b.subcategory.subCategoryName.split(' ')[0]) * 1000 : 10000000000
              const first = !(isNaN(parseFloat(a.subcategory.subCategoryName.split(' ')[0]))) ? parseFloat(a.subcategory.subCategoryName.split(' ')[0]) * 1000 : 10000000000
              return first - second
            })
          return category
        })
        this.fileCategories.forEach((fileCategory) => {
          this.allCategories.forEach((category) => {
            if(Number(category.categoryId) === Number(fileCategory.categoryId)) {
              fileCategory.subcategory = category.subcategories
              fileCategory.selected = -1;
              if(category.subcategories.length > 1) {
                fileCategory.subcategory.unshift({
                  subCategoryId: -1,
                  subCategoryName: "All"
                });
              }
            }
          })
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.filesLoading = false
      }
    },
    goToFileDetail(uuid, temp) {
      if(temp) return;
      this.$router.push(`/file/detail/${uuid}`)
    },
    async goToExam(exam) {
      try {
        const { userId } = this.$store.state.user;
        const { data } = await userCanPerformExam(exam.fileId, userId);
        const { allowed, message, approved } = data;
        if (!allowed) {
          if (message) {
            // notify the user that no more remainings are available
            this.$store.dispatch('dispatchNotification', {
              title: 'Notification',
              message: message,
              type: approved ? 'success' : 'error',
            })
            this.redirect(`/view/exam/${exam.fileId}`)
            // return;
          }
          return;
        }
        this.redirect(`/apply/exam/${exam.fileId}`)
      } catch (error) {
        console.log(error);
      }
    },
    redirect(route) {
      router.push(route);
    }
  },
}
</script>
<style lang="scss" scoped>
  .accordion-custom {
    padding: 0 1rem 0;
    .accordion-button-custom {
      background: #ffffff;
      color: #000000;
      padding: 0.8rem 0;
    }
    .accordion-button-custom::after {
      display: none;
    } 
    .accordion-button-custom::before {
      margin: -0.2rem 0.5rem 0;
      flex-shrink: 0;
      width: var(--bs-accordion-btn-icon-width);
      height: var(--bs-accordion-btn-icon-width);
      content: "";
      background-image: var(--bs-accordion-btn-icon);
      background-repeat: no-repeat;
      background-size: var(--bs-accordion-btn-icon-width);
      transition: var(--bs-accordion-btn-icon-transition);
      transform: rotate(0.75turn);
    }
    .accordion-button-custom:not(.collapsed)::before {
      transform: rotate(0turn);
    }
  }
  .custom-sub {
    display: flex;
    gap: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .selected {
    color: #5e2dcc !important;
  }
  .not-selected {
    color: #787878;
  }
  .chips {
    display: flex;
    justify-content: center;
    min-width: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 1.5rem;
    background-color: #dcd3f4;
    border-radius: 1rem;
  }
  .custom-list-image {
    width: 1rem; 
    filter: opacity(0.4) drop-shadow(0 0 0 #4e23c9); 
    margin-right: 0.3rem;
    margin-left: 0.2rem;
  }
</style>