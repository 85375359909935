<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Settings (nav)')" />
      <div class="container background-form my-3 p-3">
        <PageTitle :title="$t('Assignment Management')" :description="$t('Assignments module')"
          :parentBread="$t('System Administration')" :childBread="$t('Assignment Management')" />
        <div class="container mt-3 py-3">
          <div class="row g-4">

            <div class="col-12 col-lg-4"
              v-if="permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.EXECUTE_ASSIGNMENTS) || user.isAdmin">
              <rd-admin-card :name="$t('Execute Assignments')" :description="$t('Execute new assignments massively')"
                @route="showConfirmationModal" />
            </div>
            <!-- <div class="col-12 col-lg-4" v-if="permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.EXECUTE_ASSIGNMENTS) || user.isAdmin">
              <rd-admin-card
                :name="$t('Execute Assignments v2')"
                :description="$t('Execute new assignments massively v2')"
                @route="showConfirmationModalv2"
              />
            </div> -->

            <div v-if="
              permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.VISUALIZATION)
              || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.EDIT_ASSIGNMENTS)
              || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.ACTIVE_ASSIGNMENTS)
              || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.ENABLE_ASSIGNMENTS)
              || user.isAdmin
            " class="col-12 col-lg-4">
              <rd-admin-card :name="$t('Active Assignments')" :description="$t('Go to Active assignments')"
                @route="goTo('/admin/active-assignments')" />
            </div>

            <div class="col-12 col-lg-4"
              v-if="permissions.includes(APP_PERMISSIONS.FIELD_WORK_ASSIGNMENTS.WORKER) && !user.isAdmin">
              <rd-admin-card :name="$t('Current Assignments')" :description="$t('Go to Current assignments')"
                @route="handleGoToAssignment" />
            </div>

            <div class="col-12 col-lg-4"
              v-if="permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.GET_REPORT) || user.isAdmin">
              <rd-loading v-if="downloadingReportsLoading" class="position-absolute top-50 start-50 translate-middle"
                style="z-index: var(--top-layer-z-index)" />
              <rd-admin-card :name="$t('Get a full report')"
                :description="$t('Click here to download a .xlsx file report')" @route="downloadReports" />
            </div>
            <div class="col-12 col-lg-4" v-if="
              permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.DELETE_ASSIGNMENTS)
              || user.isAdmin
            ">
              <rd-admin-card :name="$t('Delete Assignments')"
                :description="$t('Delete assignments massively by date, vc code or both')"
                @route="handleShowDeleteAssignments" />
            </div>

            <div class="col-12 col-lg-4" v-if="user.isAdmin || permissions.includes(APP_PERMISSIONS.ASSIGNMENTS.MAPS)">
              <rd-admin-card :name="$t('Maps')" :description="$t('Go to maps')" @route="handleGoToMaps" />
            </div>

          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>

    <div class="modal fade" id="confirmExecuteAssigmentModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header pb-0 d-flex flex-column flex-md-row justify-content-between">
            <h5 class="modal-title">
              {{ $t('Execute Assignments Modal') }} {{ useV1 ? 'V1' : 'V2' }}
            </h5>
            <span class="align-middle">
              <rd-calendar @getCalendar="getCalendar" />
            </span>
          </div>
          <div class="modal-body text-uppercase">
            <p class="text-left">
              {{ $t('warning') }}
              <br>
              {{ $t('This action will truncate the assignments table from database.') }}
              <span>
                {{ $t('Are you sure to continue?') }}
              </span>
              <br>
              <rd-input class="mt-2" :label="$t('Enter the max radius for this assignment')" :placeholder="$t('Radius')"
                type="number" :value="radius" :initialValue="radius" @clearIcon="(e) => this.radius = e"
                @input="(e) => this.radius = e.target.value" />
            </p>
          </div>
          <div class="modal-footer">
            <button-component type="button" ref="hideModal" class="btn btn-outline-primary"
              @handleClick="hideConfirmationModal" data-bs-dismiss="modal">
              {{ $t('Dismiss') }}
            </button-component>
            <button-component v-if="useV1" class="position-relative" @handleClick="handleExecuteAssigments"
              :disabled="executingAssignment" primary>
              {{ $t('Execute') }}
            </button-component>
            <button-component v-if="!useV1" @handleClick="preProcessV2" :disabled="executingAssignment" primary>
              {{ $t('Pre Process') }}
            </button-component>
            <button-component v-if="!useV1" class="position-relative" @handleClick="handleExecuteAssigmentsV2"
              :disabled="executingAssignment" primary>
              {{ $t('Execute') }}
            </button-component>
            <button-component @handleClick="showBanUsersModal" primary>
              {{ $t('Ban Users') }} <i class="bi bi-person-x-fill"></i>
            </button-component>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="confirmExecuteAssigmentModalv2">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header pb-0 d-flex flex-column flex-md-row justify-content-between">
            <h5 class="modal-title">
              {{ $t('Execute Assignments Modal') }}
            </h5>
            <span class="align-middle">
              <rd-calendar @getCalendar="getCalendar" />
            </span>
          </div>
          <div class="modal-body text-uppercase">
            <p class="text-left">
              {{ $t('warning') }}
              <br>
              {{ $t('This action will truncate the assignments table from database.') }}
              <span>
                {{ $t('Are you sure to continue?') }}
              </span>
              <br>
              <rd-input class="mt-2" :label="$t('Enter the max radius for this assignment')" :placeholder="$t('Radius')"
                type="number" :value="radius" :initialValue="radius" @clearIcon="(e) => this.radius = e"
                @input="(e) => this.radius = e.target.value" />
            </p>
          </div>
          <div class="modal-footer">
            <button-component type="button" ref="hideModal" class="btn btn-outline-primary"
              @handleClick="hideConfirmationModal" data-bs-dismiss="modal">
              {{ $t('Dismiss') }}
            </button-component>
            <button-component @handleClick="handleExecuteAssigmentsV2" :disabled="executingAssignment" primary
              class="position-relative">
              {{ $t('Execute') }}
            </button-component>
            <button-component @handleClick="preProcessV2" :disabled="executingAssignment" primary>
              {{ $t('Pre Process') }}
            </button-component>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Show Ban Users -->
    <div class="modal fade" id="showBanUsersModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t('Users to be banned') }}
            </h5>
          </div>
          <div class="modal-body">
            <rd-multiselect ref="multiselect" :initialValue="userListedToBan"
              :placeholderToTag="$t('Add users to be banned')" :placeholder="$t('Users available')" name="name"
              :label="$t('Add users to be banned')" :optionsToSelect="usersNotBanned" @handleAddTagging="addUsers" />
          </div>
          <div class="modal-footer">
            <button-component type="button" ref="hideModalBan" class="btn btn-outline-primary"
              @handleClick="hideBanModal();" data-bs-dismiss="modal">
              {{ $t('Dismiss') }}
            </button-component>
            <button-component @handleClick="acceptBanModal();" type="button" class="btn btn-primary"
              ref="acceptModalBan" data-bs-dismiss="modal">
              {{ $t('Accept') }}
            </button-component>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="modalLoading">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="background: transparent; border: none;">
          <div class="modal-header pb-0 d-flex flex-column flex-md-row justify-content-between"
            style="background: transparent; border: none;">
            <rd-loading v-if="executingAssignment" class="position-absolute top-50 start-50 translate-middle"
              style="z-index: var(--top-layer-z-index)" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="deleteAssigmentModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header pb-0 d-flex flex-column flex-md-row justify-content-between">
            <h5 class="modal-title">
              {{ $t('Delete Assignments') }}
            </h5>
            <span class="align-middle">
              <rd-calendar @getCalendar="getDeleteDateCalendar" previousDateAvailable />
            </span>
          </div>
          <div class="modal-body text-uppercase">
            <p>
              {{ $t('This action will delete assignments table from database.') }}
              <span>
                {{ $t('Are you sure to continue?') }}
              </span>
              <rd-input class="mt-2" :label="$t('Enter the vc code for assignment(s) deletion')"
                :placeholder="$t('VC CODE')" clearIcon type="number" :value="vcCodeToDelete"
                :initialValue="vcCodeToDelete" @clearIcon="(e) => this.vcCodeToDelete = e"
                @input="(e) => this.vcCodeToDelete = e.target.value" />
            </p>
          </div>
          <div class="modal-footer">
            <button-component @handleClick="hideDeleteModal();" :disabled="loadingDeletion" ref="hideModal"
              type="button" data-bs-dismiss="modal" class="btn btn-outline-primary">
              {{ $t('Dismiss') }}
            </button-component>
            <div class="position-relative">
              <rd-loading v-if="loadingDeletion" class="position-absolute top-50 start-50 translate-middle"
                style="z-index: var(--top-layer-z-index)" />
              <button-component :disabled="loadingDeletion" @handleClick="handleDeleteAssigments();" type="button"
                class="btn btn-primary w-100">
                {{ $t('Delete') }}
              </button-component>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import RdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdAdminCard from '@/components/rd-components/rd-admin-card.vue'
import router from '@/router'
import { mapState } from 'vuex'
import { APP_PERMISSIONS } from '@/utils/constants.js'
import { getReport } from '@/services/reports/reports.api'
import { downloadFileReport, base64ToArrayBuffer } from '@/utils/handleFile.js';
import RdCalendar from '../../components/rd-components/rd-calendar.vue'
import RdInput from '../../components/rd-components/rd-input.vue'
import { generateAssignment, preprocessAssignment, generateAssignmentV2, deleteAssignments } from '@/services/assignments/assignment.api'
import { getNotBannedUsers } from '@/services/user/user.api'
import { Modal } from 'bootstrap'
import PageTitle from '../../components/rd-components/page-title.vue'
import ButtonComponent from '../../components/button-component.vue'
import { getAssignmentSettings } from '../../services/settings/settings.api'
import RdLoading from '../../components/rd-components/rd-loading.vue'
import RdMultiselect from "../../components/rd-components/rd-multiselect.vue";
export default {
  components: {
    RdFooter,
    RdNavbar,
    RdAdminCard,
    RdCalendar,
    RdInput,
    PageTitle,
    ButtonComponent,
    RdLoading,
    RdMultiselect
  },
  data() {
    return {
      APP_PERMISSIONS,
      userListedToBan: [],
      usersNotBanned: [],
      calendar: {
        date: '',
        formattedDate: ''
      },
      radius: 15,
      executingAssignment: false,
      downloadingReportsLoading: false,
      userConfirmed: [],
      useV1: true,
      siteSizeSelected: null,
      calendarToDelete: null,
      vcCodeToDelete: null,
      loadingDeletion: false,
    }
  },
  created() {
    if (!this.user.isAdmin) {
      const permissionsFiltered = this.permissions.filter(permission => [
        APP_PERMISSIONS.ASSIGNMENTS.VISUALIZATION,
        APP_PERMISSIONS.ASSIGNMENTS.EDIT_ASSIGNMENTS,
        APP_PERMISSIONS.ASSIGNMENTS.ACTIVE_ASSIGNMENTS,
        APP_PERMISSIONS.ASSIGNMENTS.ENABLE_ASSIGNMENTS,
        APP_PERMISSIONS.FIELD_WORK_ASSIGNMENTS.WORKER
      ].includes(permission));
      if(permissionsFiltered.includes(APP_PERMISSIONS.FIELD_WORK_ASSIGNMENTS.WORKER)) {
        router.replace('/admin/current-assignments');
        return;
      }
      if (permissionsFiltered.length == 1) {
        switch (permissionsFiltered[0]) {
          case APP_PERMISSIONS.ASSIGNMENTS.VISUALIZATION:
            router.replace('/admin/active-assignments');
            return;
          case APP_PERMISSIONS.ASSIGNMENTS.EDIT_ASSIGNMENTS:
            router.replace('/admin/active-assignments');
            return;
          case APP_PERMISSIONS.ASSIGNMENTS.ACTIVE_ASSIGNMENTS:
            router.replace('/admin/active-assignments');
            return;
          case APP_PERMISSIONS.ASSIGNMENTS.ENABLE_ASSIGNMENTS:
            router.replace('/admin/active-assignments');
            return;
          case APP_PERMISSIONS.FIELD_WORK_ASSIGNMENTS.WORKER:
            router.replace('/admin/current-assignments');
            return;
          default:
            return;
        }
      }
    }
  },
  async mounted() {
    const { data } = await getAssignmentSettings();
    const { use_v1 } = data;
    this.useV1 = use_v1;
  },
  computed: {
    ...mapState(['user', 'permissions'])
  },
  methods: {
    async handleDeleteAssigments() {
      try {
        this.loadingDeletion = true;
        const { date } = this.calendarToDelete;
        const { data: { deletion } } = await deleteAssignments({ date, code: this.vcCodeToDelete });
        const { deleted, message } = deletion;
        if (!deleted) {
          this.$toast.open({
            message: message,
            type: 'error',
            duration: 4000
          });
          return
        }
        this.$toast.open({
          message: 'Assignments deleted successfully',
          type: 'success',
          duration: 4000
        });
        this.calendarToDelete = null;
        this.vcCodeToDelete = null;
        this.hideDeleteModal();
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingDeletion = false;
      }
    },
    hideDeleteModal() {
      this.$refs.hideModal.$refs.button.click()
    },
    getDeleteDateCalendar(calendar) {
      this.calendarToDelete = calendar;
    },
    handleShowDeleteAssignments() {
      let myModal = new Modal(document.getElementById('deleteAssigmentModal'))
      myModal.show(myModal)
    },
    async preProcessV2() {
      const usuarios = this.userConfirmed.map(user => `${user.code}`)
      this.executingAssignment = true
      let modalElement = document.getElementById('modalLoading')
      let myModal = new Modal(modalElement, {
        backdrop: 'static'
      });
      myModal.show(myModal)
      try {
        await preprocessAssignment({ fecha: this.calendar.date, maxDistance: this.radius, usuarios })
        this.$toast.open({
          message: 'Assignment pre-processed correctly',
          type: 'success'
        })

      } catch (error) {
        // console.log(error.response.data.message)
        this.$toast.open({
          message: error.response.data.message[1],
          type: 'error'
        })
      } finally {
        this.executingAssignment = false
        myModal.hide();
      }
    },
    async handleExecuteAssigmentsV2() {
      this.executingAssignment = true
      let modalElement = document.getElementById('modalLoading')
      let myModal = new Modal(modalElement, {
        backdrop: 'static'
      });
      myModal.show(myModal)
      try {
        await generateAssignmentV2({ fecha: this.calendar.date })

        this.$toast.open({
          message: 'Assignments Created Successfully',
          type: 'success'
        })


      } catch (error) {
        // console.log(error.response.data.message)
        this.$toast.open({
          message: error.response.data.message[1],
          type: 'error'
        })
      } finally {
        this.executingAssignment = false
        myModal.hide();
      }
    },
    async handleExecuteAssigments() {
      this.executingAssignment = true
      let modalElement = document.getElementById('modalLoading')
      let myModal = new Modal(modalElement, {
        backdrop: 'static'
      });
      myModal.show(myModal)
      const usuarios = this.userConfirmed.map(user => `${user.code}`)
      try {
        const { data } = await generateAssignment({ fecha: this.calendar.date, usuarios, maxDistance: this.radius })

        this.$toast.open({
          message: 'Assignments Created Successfully',
          type: 'success'
        })

        data.forEach((element) => {
          this.$toast.open({
            message: element,
            type: 'error',
            duration: 10000
          })
        })

        this.hideConfirmationModal()
        this.userListedToBan = [];
        this.userConfirmed = [];

      } catch (error) {
        // console.log(error.response.data.message)
        this.$toast.open({
          message: error.response.data.message[1],
          type: 'error'
        })
      } finally {
        this.executingAssignment = false
        myModal.hide();
      }
    },
    addUsers(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.userListedToBan.push(tag);
      this.usersNotBanned.push(tag);
    },
    hideConfirmationModal() {
      this.$refs.hideModal.$refs.button.click()
    },
    async showBanUsersModal() {
      await getNotBannedUsers().then((response) => {
        const availableUsers = response.data.map(user => ({ code: user.user_id, name: `${user.user_name} - (${user.role_name})` }))
        this.usersNotBanned = availableUsers;
        let myModal = new Modal(document.getElementById('showBanUsersModal'))
        myModal.show(myModal)
      })
    },
    acceptBanModal() {
      const users = JSON.parse(JSON.stringify(this.$refs['multiselect'].value));

      this.userConfirmed = users;
      this.$refs.acceptModalBan.$refs.button.click()
    },
    hideBanModal() {
      this.userListedToBan = JSON.parse(JSON.stringify(this.userConfirmed));
      this.$refs.hideModalBan.$refs.button.click()
    },
    getCalendar(calendar) {
      this.calendar = calendar
    },
    showConfirmationModal() {
      let myModal = new Modal(document.getElementById('confirmExecuteAssigmentModal'))
      myModal.show(myModal)
    },
    showConfirmationModalv2() {
      let myModal = new Modal(document.getElementById('confirmExecuteAssigmentModalv2'))
      myModal.show(myModal)
    },
    goTo(route) {
      router.push(route)
    },
    async downloadReports() {
      if (!this.$store.isMobile) {
        this.downloadingReportsLoading = true
        try {
          const { data } = await getReport()
          const buffer = data
          const arrayBuffer = base64ToArrayBuffer(buffer)
          downloadFileReport(arrayBuffer)
          this.$toast.open({
            message: 'Report downloaded successfully',
            type: 'success'
          })
        } catch (error) {
          console.log(error)
          this.$toast.open({
            message: 'An error occurred while downloading report',
            type: 'error'
          })
        } finally {
          this.downloadingReportsLoading = false
        }
      } else {
        const { data } = await getReport()
        const buffer = data
        const arrayBuffer = base64ToArrayBuffer(buffer)
        const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = `${'ReportAssignments'}.xlsx`;
        const reader = new FileReader();
        reader.onloadend = function () {
          const base64data = reader.result.split(',')[1];

          window.flutter_inappwebview.callHandler('comunicationname', JSON.stringify({
            fileName: fileName,
            fileData: base64data
          }));
        };
        reader.readAsDataURL(blob);
        // window.flutter_inappwebview.callHandler('comunicationname', JSON.stringify({
        //   token: store.token,
        //   id: file.fileId,
        //   name: file.fileName,
        //   route: `${process.env.VUE_APP_API_URL}/api/files/download/${file.fileId}`
        // }));
      }

    },
    handleGoToAssignment() {
      this.goTo('/admin/current-assignments')
    },
    handleGoToMaps() {
      this.goTo('/admin/assignments/maps')
    },
  },
}
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.09);
  /* Semi-transparent background */
  color: white;
  z-index: var(--top-layer-z-index);
  border-radius: .5rem;
}

.overflow-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--top-layer-z-index);
}
</style>