<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Exam (nav)')" />
      <div class="container background-form rounded-3 my-3 p-3">
        <PageTitle
          :title="$t('Exam Creation')"
          :description="$t('Exam Creation')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Exam Creation')"
        />
        <div class="row p-3 justify-content-between">
          <!-- Search bar -->
          <div class="col-12 col-lg-3">
            <rd-input
              class="w-100"
              :placeholder="$t('Search exam by name')" 
              type="text"
              @input="(e) => this.search = e.target.value"
            />
          </div>

          <!-- buttons -->
          <div class="col-12 col-lg-6" v-if="permissions.includes(APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD) || user.isAdmin">
            <div class="row justify-content-end gap-1">
              
              <div class="col-12 text-end">
                <button-component
                  primaryOutline
                  @handleClick="goToExamStats"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Go to General Stats" type="button" class="bi bi-arrow-down-circle-fill"></i> {{ $t('General Stats') }}
                </button-component>
                <button-component
                  primary
                  @handleClick="goToAddExam"
                >
                  <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Exam" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('New Exam') }}
                </button-component>
              </div>
              
            </div>
          </div>
        </div>

        <div v-if="examsLoading">
          <rd-loading /> 
        </div>

        <div v-else>
          <div
            v-if="filteredExams.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No exams Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <div v-else class="row p-3 d-flex justify-content-start">

            <data-table-component
              :columns="[
                { label: $t('Name'), field: 'examName', sortable: true, type: 'string', },
                { label: $t('Description'), field: 'examDescription', sortable: true, type: 'string', },
                { label: $t('Actions'), field: 'actions', headerHAlign: 'center', hAlign: 'center' },
              ]"
              :rows="filteredExams"
              resizeable
              paginator
            >
              <template #actions="{ row }">
                <options-menu-component
                  :contextTitle="$t('Exams options')"
                  :items="[
                    {
                      show: (
                        permissions.includes(APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD) || user.isAdmin
                      ),
                      label: $t('Edit Exam'),
                      icon: 'bi-pencil-square',
                      callback: () => goToEditExam({ examId: row.examId }),
                    },
                    {
                      show: (
                        permissions.includes(APP_PERMISSIONS.EXAM_MANAGEMENT.CRUD) || user.isAdmin
                      ),
                      label: $t('Delete Exam'),
                      icon: 'bi-trash-fill',
                      callback: () => showModal({ row })
                    },
                    {
                      show: true,
                      label: $t('See Exam'),
                      icon: 'bi-eye-fill',
                      callback: () => goToExam({ examId: row.examId })
                    },
                    {
                      show: true,
                      label: $t('See Stats'),
                      icon: 'bi-body-text',
                      callback: () => goExamStats(`/admin/exam/stats/${row.examId}`)
                    }
                  ]"
                />
              </template>
            </data-table-component>

          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
    <rd-confirmation-modal
      v-if="showModalProp"
      modalTitle="Delete exam permanently?"
      confirmButton="Delete"
      dismissButton="Dismiss"
      @confirmAction="deleteFile(examToDelete)"
      @dismissAction="dismissAction"
    />
  </div>
</template>
<script>
import rdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import router from '@/router'
import { getAllExams, deleteExamById } from '@/services/exams/exam.api.js'
import { downloadCSV } from '@/utils/handleFile.js';
import { convertDateFormat, convertToCSV } from '@/utils/convertFormat.js';
import RdConfirmationModal from '@/components/rd-components/rd-confirmation-modal.vue'
import RdLoading from '@/components/rd-components/rd-loading.vue'
import RdInput from '@/components/rd-components/rd-input.vue'
import { getGeneralStats } from '@/services/exams/exam.api';
import {APP_PERMISSIONS} from '@/utils/constants.js'
import PageTitle from '../../components/rd-components/page-title.vue';
import OptionsMenuComponent from '../../components/options-menu-component.vue'
import ButtonComponent from '../../components/button-component.vue'
import DataTableComponent from '../../components/tables/data-table-component.vue'

export default {
  data() {
    return {
      exams: [],
      APP_PERMISSIONS,
      interactionTypes: {},
      convertDateFormat,
      examsLoading: true,
      showModalProp: false,
      examToDelete: {},
      search: '',
      hasSpinner: true,
      dataTemp: [],
      columns: [
        { label: this.$t('Name'), field: 'examName', sortable: true, type: 'string', },
        { label: this.$t('Description'), field: 'examDescription', sortable: true, type: 'string', },
        { label: this.$t('Actions'), field: 'actions', headerHAlign: 'center', hAlign: 'center' },
      ]
    }
  },
  components: { 
    rdFooter, 
    RdNavbar,
    RdConfirmationModal,
    RdLoading,
    RdInput,
    PageTitle,
    OptionsMenuComponent,
    ButtonComponent,
    DataTableComponent
  },
  computed: {
    filteredExams() {
      return this.exams.filter(
          exam => exam.examName.toLowerCase().includes(this.search.trim().toLowerCase())
        )
    },
    user(){
      return this.$store.getters.getUser;
    },
    permissions(){
      return this.$store.state.permissions;
    }
  },
  async mounted() {
    if(this.$store.state.permissions.length == 1 && this.$store.state.permissions[0] == 11) {
      this.$store.commit('setShowBackButton', false)
    }
    try {
      await this.setExams();
    } catch (error) {
      console.log(error);
    }
  },
  beforeUnmount(){
    window.removeEventListener('click', this.callback);
    window.removeEventListener('resize', this.closeDisplay);
  },
  methods: {
    handleScroll() {
      this.display = 'none'
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    showModal({ exam }){
      this.showModalProp = true
      this.examToDelete = exam
    },
    dismissAction() {
      this.examToDelete = {}
      this.showModalProp = false
    },
    async deleteFile({ examId }) {
      try {
        await deleteExamById(examId)
        await this.setExams()
      } catch (error) {
        console.log(error)
      } finally {
        this.showModalProp = false
        this.examToDelete = {}
      }
    },
    isExternal(path) {
      return path === null || path === ''
    },
    async setExams() {
      try {
        this.examsLoading = true
        const { data } = await getAllExams();
        this.exams = data
      } catch (error) {
        console.log(error)
      } finally {
        this.examsLoading = false
      }
    },
    goToAddExam() {
      router.push(`/admin/exam/add/`)
    },
    goToExamStats() {
      router.push(`/admin/exam/stats/`)
    },
    async downloadGeneralStats() {
      try {
        const { data } = await getGeneralStats();
        const csv = await convertToCSV({ data });
        downloadCSV({ data: csv });
      } catch (error) {
        console.log(error);
      }
    },
    goToEditExam({ examId }){
      router.push(`/admin/exam/edit/${examId}`)
    },
    goToExam({ examId }){
      router.push(`/apply/exam/${examId}`)
    },
    goExamStats(route){
      router.push(route)
    },
  },
  
}
</script>
<style lang="scss" scoped>
.hoverable:hover {
  cursor: pointer;
}
.hoverable:hover {
  cursor: pointer;
}
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>