<template>
    <div class="row">
        <!-- <div class="breadcrumb text-start"> -->
        <div class="col-12">
            <nav aria-label="breadcrumb" class="ms-4 text-start">
                <ol class="breadcrumb text-star m-0">
                    <li class="breadcrumb-item text-start pointer "><a class="activeBreadcrumb" @click="goBack(parentBread)">{{ parentBread }} </a></li>
                    <li class="active text-start pointer "><a class="activeBreadcrumb" @click="goBack(childBread)"> {{ childBread }}</a></li>
                    <li v-if="grandChildBread.length" class="active text-start pointer "><a class="activeBreadcrumb" @click="goBack(grandChildBread)"> {{ grandChildBread }}</a></li>
                    <li v-if="grandGrandChildBread.length" class="active text-start pointer "><a class="activeBreadcrumb"> {{ grandGrandChildBread }}</a></li>
                </ol>
            </nav>
        </div>
        <div class="col-12">
            <h2 class="ms-4 text-start page-title mb-0">{{ title }}</h2>
            <p class="ms-4 text-start description">
                {{ description }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        parentBread: {
            type: String,
            default: ''
        },
        childBread: {
            type: String,
            default: ''
        },
        grandChildBread: {
            type: String,
            default: ''
        },
        grandGrandChildBread: {
            type: String,
            default: ''
        },
    },
    methods: {
        goBack(parent) {
            if(parent === this.parentBread && !this.grandChildBread.length) this.$router.back();
            else if(parent === this.parentBread && this.grandChildBread.length && !this.grandGrandChildBread.length) 
                window.history.go(-2);
            else if(parent === this.parentBread && this.grandChildBread.length && this.grandGrandChildBread.length) 
                window.history.go(-3);
            else if(parent === this.childBread && this.grandChildBread.length && this.grandGrandChildBread.length) 
                window.history.go(-2);
            else if(parent ===  this.childBread && !this.grandChildBread.length) return
            else if(parent ===  this.grandChildBread && !this.grandGrandChildBread.length) return
            else this.$router.back();
        },
    }
}

</script>

<style lang="css" scoped>
    .page-title {
        font-size: calc(24px + .25vw);
        font-weight: 700;
        color: #3d3d3d;
    }

    .description {
        font-size: calc(14px + .25vw);
        font-weight: 300;
        color: #3d3d3d;
    }

    .breadcrumb {
        font-size: calc(14px + .25vw);
        font-weight: 300;
        color: #3d3d3d;
    }
    .breadcrumb li:not(:last-child)::after {
        display: inline-block;
        margin: 0 .5rem;
        content: ">";
        color: #6c757d;
        font-size: calc(8px + .25vw);
        font-weight: 600;
    }

    .activeBreadcrumb {
        text-decoration: none !important;
        
        font-size: calc(8px + .25vw) !important;
        font-weight: 600 !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.33 !important;
        letter-spacing: .5px !important;
        color: #3d3d3d !important;
        text-transform: uppercase !important;
    }

    .pointer {
        cursor: pointer;
    }
</style>