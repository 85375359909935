<template>
  <p>
    The current file has no valid preview
  </p>
</template>
<script>
export default {
  name: 'RdNotValidViewer',
}
</script>
<style scoped>
  p {
    margin: 0;
  }
</style>
