import axios from 'axios';
import router from '@/router';
import store from '@/store';

let httpClient = axios.create({
  headers: { 
    'Accept': 'application/json', 
    'Content-Type': 'application/json',
  }
});

httpClient.interceptors.request.use(
  async config => {
    config.headers.common['Authorization'] = `Bearer ${store.getters['getToken']}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401 || error.response.status === 403) {
      if(error.response.data.error.message.search('Access denied') !== -1) {
        // TODO: handle main route
        router.push('/admin');
      }
      else if (error.response.data.error.message.search('Error verifying token') !== -1) {
        if (error.response.data.error.message.search('jwt expired') !== -1) { 
          store.commit('setSessionExpired', true); 
        }
        if (store.getters['getStatusLogin'] && router.history.current.path !== '/login') { 
          store.dispatch('logout');
          router.push('/');
        }
      }

      else if (error.response.data.error.message.search('Error verifying credentials') !== -1) {
        if (error.response.data.error.message.search('Invalid session') !== -1) { 
          store.commit('setInvalidSession', true); 
          router.push('/');
        }
      }
    }
    return Promise.reject(error);
  }
);

export default httpClient;
