import httpClient from "../httpClient";

const END_POINT = '/api/team'

const getAllTeams = async () => await httpClient.get(END_POINT)
const getTeamById = async (teamId, assignementId) => await httpClient.get(`${END_POINT}/${teamId}/${assignementId}`)
const addUsersToTeam = async (team) => await httpClient.post(`${END_POINT}/addUsers`, team)
const updateTeam = async (team) => await httpClient.put(`${END_POINT}/${team.teamId}`, team.body)
const massiveLoadForTeams = async (csvTeam) => await httpClient.post(`${END_POINT}/import`, csvTeam)

export {
  getAllTeams,
  getTeamById,
  addUsersToTeam,
  updateTeam,
  massiveLoadForTeams
}