<template>
  <nav v-if="!isMobile" class="navbar navbar-light bg-white m-0 p-0">
    <div class="container-fluid border-bottom m-0 py-2">
      <div
        :style="{'cursor': 'pointer'}"
        class="d-flex align-items-center flex-wrap"
        @click="goToMain"
      >
        <img 
          v-if="!calcLogo" 
          src="@/assets/statics/smartmatic-logo.svg" 
          title="Smartfiles logo" 
          alt="Smartfiles logo"
          class="d-inline-block align-text-center navbar-logo"
        >
        <img 
          v-else 
          :src="calcLogo" 
          title="Smartfiles logo" 
          alt="Smartfiles logo" 
          class="d-inline-block align-text-center navbar-logo"
        >
  
        <span
          class="project-name-custom"
        >
          {{ (settings ? settings.title : null) ||  $t('Smart Files') }}
        </span>
      </div>
      <div 
        v-if="userLoggedIn" 
        class="d-flex ms-auto custom-responsive"
      >
        <div  class="dropdown">
          <a 
            class="btn btn-link dropdown-toggle" 
            role="button" 
            id="userMenu" 
            data-bs-toggle="dropdown" 
            aria-expanded="false"
            style="color:#3d3d3d;"
          >
            <p style="color:#3d3d3d;" class="btn btn-link text-decoration-none p-0 m-0 fw-bold arrow-down-icon-after">
              {{ $t('Navigation')}}
            </p>
          </a>

          <ul class="dropdown-menu dropdown-menu-end custom-dropdown custom-dropdown-responsive" aria-labelledby="userMenu">

            <li v-if="permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.FILE_UPLOADER) || permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES) || user.isAdmin">
              <button
                class="btn btn-head text-decoration-none dropdown-item text-uppercase"
              >
              {{ $t('Administration Files')}}
              </button>
            </li>
            <li v-if="permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.FILE_UPLOADER) || user.isAdmin">
              <button
                @click="goTo('/admin/files')"
                class="btn btn-link text-decoration-none dropdown-item"
              >
              {{ $t('Files')}}
              </button>
            </li>            
            <li v-if="permissions.includes(APP_PERMISSIONS.FILES_MANAGEMENT.CATEGORIES) || user.isAdmin">
              <button
                @click="goTo('/admin/categories')"
                class="btn btn-link text-decoration-none dropdown-item"
              >
              {{ $t('Categories')}}
              </button>
            </li>            
            <li v-if="permissions.includes(APP_PERMISSIONS.USER_MANAGEMENT.CRUD) || permissions.includes(APP_PERMISSIONS.USER_MANAGEMENT.EDIT_USER) || user.isAdmin">
              <button
                class="btn btn-head text-decoration-none dropdown-item text-uppercase"
              >
              {{ $t('Administration User')}}
              </button>
            </li>
            <li v-if="permissions.includes(APP_PERMISSIONS.USER_MANAGEMENT.CRUD) || permissions.includes(APP_PERMISSIONS.USER_MANAGEMENT.EDIT_USER) || user.isAdmin">
              <button
                @click="goTo('/admin/user')"
                class="btn btn-link text-decoration-none dropdown-item"
              >
              {{ $t('Users')}}
              </button>
            </li>            
            <li v-if="permissions.includes(APP_PERMISSIONS.PROFILES.CRUD) || user.isAdmin">
              <button
                @click="goTo('/admin/profiles')"
                class="btn btn-link text-decoration-none dropdown-item"
              >
              {{ $t('Profiles')}}
              </button>
            </li>            
            <li>
              <button
                class="btn btn-head text-decoration-none dropdown-item text-uppercase"
              >
              {{ $t('Main Site')}}
              </button>
            </li>
            <li>
              <button
                @click="goToMain"
                class="btn btn-link text-decoration-none dropdown-item"
              >
              {{ $t('Main Site')}}
              </button>
            </li>                       
            <li v-if="(permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.LANGUAGES) && permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.BACKGROUND)) || user.isAdmin">
              <button
                class="btn btn-head text-decoration-none dropdown-item text-uppercase"
              >
              {{ $t('Configuration')}}
              </button>
            </li>
            <li>
              <button
                v-if="(permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.LANGUAGES) && permissions.includes(APP_PERMISSIONS.SYSTEM_MANAGEMENT.BACKGROUND)) || user.isAdmin"
                @click="goTo('/admin/settings')"
                class="btn btn-link text-decoration-none dropdown-item"
              >
              {{ $t('Settings')}}
              </button>
            </li>                      
          </ul>
        </div>
        <rd-language-switcher/>
        <div @click="goToMain" class="" style="cursor:pointer;">
          <a 
            class="btn btn-link" 
            role="button" 
            title="Configuration"
            style="color:black;"
          >
            <Tooltip :text="$t('Main Site')" >
              <i class="bi bi-gear-fill"></i>
            </Tooltip>
          </a>
        </div>
        <div class="dropdown" style="cursor:pointer;">
          <a 
            class="btn btn-link dropdown-toggle custom-dropdown-toogle" 
            role="button"
            title="About"
            style="color:black;"
            data-bs-toggle="modal"
            data-bs-target="#infoModal"
          >
            <Tooltip :text="$t('About')" >
              <i class="bi bi-info-circle"></i>
            </Tooltip>
          </a>
        </div>
        <div class="dropdown">
          <a 
            class="btn btn-link dropdown-toggle" 
            role="button" 
            id="userMenu"
            data-bs-toggle="dropdown" 
            aria-expanded="false"
            style="color:#000;"
          >
            <div class="arrow-down-icon-after">
              <Tooltip v-if="(windowWidth < 768)" :text="user.userName">
                <i class="bi bi-person-fill"></i>
              </Tooltip>
              <p style="color:black;" v-if="!(windowWidth < 768)" class="btn btn-link text-decoration-none p-0 m-0 ms-1">
                {{ user.userName }}
              </p>
              <span class="role_styles">
                {{ user.roleName || ''}}
              </span>
            </div>
          </a>

          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userMenu">

            <li >

              <button 
                class="btn btn-link text-decoration-none dropdown-item"
                @click="goToMain"
              >
                <!-- <i data-bs-toggle="tooltip" data-bs-placement="right" title="Go to Main" type="button" class="bi bi-house-fill"></i> -->
                {{ $t('Main')}}
              </button>

            </li>
            
            <li>
              
              <button
                class="btn btn-link text-decoration-none dropdown-item"
                @click="handleLogout"
              >
                <!-- <i data-bs-toggle="tooltip" data-bs-placement="right" title="Logout" type="button" class="bi bi-box-arrow-in-right"></i> -->
                {{ $t('Logout') }}
              </button>
            
            </li>
          </ul>
        </div>

      </div>
    </div>
    <div 
      class="container-fluid custom-back-row bg-light" 
      v-if="showBackButton && (this.$store.state.showBackButton || user.isAdmin)"
    >
      <button-component
        class="btn btn-link py-0 my-0 text-decoration-none go-back-btn-custom" 
        @click="goBack"
        :style="{ 'font-weight': '700' }"
      >
        <svg viewBox="0 0 24 24" title="Back" width="24px" height="24px" fill="#522eb3" stroke="none" style="vertical-align: middle;"><path d="M4.29 11.29l7-7a1 1 0 1 1 1.42 1.41L7.41 11H19a1 1 0 0 1 0 2H7.41l5.3 5.29a1 1 0 0 1-1.42 1.41l-7-7a1 1 0 0 1 0-1.41z"></path></svg> {{ $t('Go back') }}
      </button-component>
    </div>
    
    <rd-info-modal />

  </nav>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import rdLanguageSwitcher from './rd-language-switcher.vue';
import rdInfoModal from './rd-info-modal.vue';
import {APP_PERMISSIONS} from '@/utils/constants.js'
import Tooltip from '../tooltip.vue';
import ButtonComponent from '../button-component.vue';

export default {
  components: {
    rdLanguageSwitcher,
    rdInfoModal,
    Tooltip,
    ButtonComponent
  },
  data() {
    return {
      APP_PERMISSIONS
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    showBackButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    calcLogo() {
      return (
        !!this.$store.state.settings?.logo && 
        `/api/settings/${this.$store.state.settings.logo}`
      );
    },
    user() {
      return this.$store.state.user;
    },

    userLoggedIn() {
      return Object.keys(this.$store.state.user).length > 0
    },

    userRoute() {
      // TODO: Check all the permissions so if user has only one permission push to that route as main
      return '/admin';
    },
    isMobile() {
      return this.windowWidth <= 768;
    },
    ...mapState(['windowWidth', 'settings', 'permissions', 'isMobile'])
  },
  methods: {
    ...mapActions([
      'logout'
    ]),
    goToMain() {
      this.$router.push(this.userRoute)
    },
    async handleLogout() {
      if (window.Intercom) window.Intercom('shutdown');
      await this.logout();
    },
    goBack() {
      this.$router.back()
    },
    goTo(route) {
      this.$router.push(route)
    }
  },
  
}
</script>

<style scoped>
.custom-dropdown {
  max-height: 200px;
  overflow-y: scroll  ;
}
.custom-dropdown .btn-head {
  color: #9baec4;
  cursor: auto;
}
.custom-dropdown .btn-head:hover {
  background: unset;
}
.custom-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #efefef;
}

.custom-dropdown::-webkit-scrollbar {
  width: 10px;
  background-color: #efefef;
}

.custom-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  max-height: 5px;
  background-color: #6c6d75;
}

.dropdown-toggle::after {
  display: none;
}
.custom-dropdown-toogle::after {
  display: none;
}
@media (max-width: 768px) {
  /* .custom-responsive {
    margin-left: 0 !important;
    flex-flow: column;
    align-items: baseline;
  } */
  .custom-dropdown-responsive {
    right: auto !important;
  }
}

.arrow-down-icon-after::after {
  display: inline-block;
  font-size: inherit;
  height: 1.357em;
  overflow: visible;
  vertical-align: -.3em;
  width: 1.357em;
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%233d3d3d' d='M6.006 9.5a1 1 0 011.709-.71l4.288 4.296L16.3 8.79a1 1 0 111.41 1.419l-4.998 4.995a1 1 0 01-.71.29 1 1 0 01-.71-.29L6.296 10.21a1 1 0 01-.29-.71z'/%3E%3C/svg%3E");
}

.role_styles {
  font-size: .5rem;
  text-decoration: none;
  position: absolute;
  top: 2rem;
  right: 1rem;
  width: max-content;
}

.role_styles_logo {
  font-size: .5rem;
  text-decoration: none;
  position: absolute;
  top: 1rem;
  right: .5rem;
}

.border-bottom {
  z-index: var(--high-layer-z-index);
  box-shadow: 0 0 .1rem rgba(0,0,0,.9);
}

.project-name-custom {
  font-size: 1.125rem;
  font-weight: 900;
  margin-left: 24px;
  height: 24px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  display: table;
  color: #3d3d3d;
  white-space: normal;
}

.go-back-btn-custom {
  font-size: calc(10px + .25vw);
  font-weight: 900 !important;
}

.go-back-btn-custom:hover {
  background-color: #eee9fb !important;
}

.custom-back-row {
  z-index: var(--ground-layer-z-index);
}

.navbar-logo {
  width: 100%;
  max-width: 350px;
  min-width: 150px;
  width: 218px;
  height: 24px;
  padding: 0;
  margin: 0 0 0 24px;
  object-fit: cover;
}

.custom-modal .modal-content {
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
}

.custom-modal .modal-header {
  justify-content: space-between;
}

.custom-modal .modal-title {
  font-size: 24px;
}

.custom-modal .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-modal-logo {
  width: 100px;
  margin: 20px 0;
}
</style>