<template>
  <div class="btn-group dropdown" ref="dropdown">
    <span
      class="btn btn-link dropdown-toggle button-select-class"
      type="button"
      :id="`dropdownMenu-${id}`"
      style="color:black;"
      aria-expanded="false"
      @click="toggleDropdown"
    >
      <p :id="`dropdownMenu-${id}`" style="color:black;"  class="btn btn-link text-decoration-none p-0 m-0 ms-1 button-select-class">
        {{ selectedItem?.label ?? $t('Select an option')}}
      </p>
    </span>
    <teleport to="body">
      <ul 
        class="dropdown-menu dropdown-menu-end custom-dropdown"
        :class="{ show: isDropdownVisible }"
        :aria-labelledby="`dropdownMenu-${id}`"
        :style="{ width: 'max-content', position: 'absolute', top: top + 'px', right: right + 'px'  }"
      >
        <li
          v-for="({
            label,
            value,
            callback
          }, index) in items" :key="index"
          :class="{
            'bg-light': selectedItem ? +(selectedItem.value) === +(value) : false
          }"
        >
          <button
            @click="handleCallback({ callback, value, label })"
            class="btn btn-link text-decoration-none dropdown-item"
          >
            {{ label }}
          </button>
        </li>
      </ul>
    </teleport>
  </div>
</template>
<script>
import EventBus from '@/events';
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String || Number,
      default: null
    },
  },
  data() {
    const id = uuidv4();
    return {
      selectedItem: null,
      isDropdownVisible: false,
      id: id,
      top: 0,
      right: 0
    }
  },
  mounted() {
    this.selectedItem = this.items.find(item => +(item.value) === +(this.selected)) ?? null
    EventBus.on('dropdown-open', this.closeDropdown);
    document.addEventListener('click', this.handleClickOutside);
  },
  methods: {
    toggleDropdown(event) {
      this.isDropdownVisible = true;
      EventBus.emit('dropdown-open', this.id);
      const element = event?.target;
      const rect = element.getBoundingClientRect();
      const { top: topRect, bottom: bottomRect, right: rightRect } = rect;
      const { scrollY, scrollX, innerWidth } = window;

      const dropdownHeight = 30 * this.items.length; // Estimación de la altura del dropdown
      const absoluteTop = topRect + scrollY;
      const absoluteRight = innerWidth - (rightRect + scrollX);

      // Calcula la posición inicial (hacia abajo)
      const top = absoluteTop + 30;
      const right = absoluteRight + 30;
      const fullHeight = document.documentElement.scrollHeight;

      // Ajusta la posición si el dropdown se sale de la ventana
      if ((top + bottomRect + dropdownHeight) > fullHeight) {
        this.top = top - dropdownHeight;
        this.right = right;
      } else {
        this.top = top;
        this.right = right;
      }
    },
    handleCallback({ callback, value }) {
      if (callback) callback()
      this.selectedItem = this.items.find(item =>  +(item.value) === +(value))
      this.$emit('change', value)
      this.isDropdownVisible = false;
    },
    closeDropdown(id) {
      if (id !== this.id) {
        this.isDropdownVisible = false;
      }
    },
    handleClickOutside(event) {
      if (this.isDropdownVisible && !this.$refs.dropdown.contains(event.target)) {
        this.isDropdownVisible = false;
      }
    },
  },
  beforeUnmount() {
    EventBus.off('dropdown-open', this.closeDropdown);
    document.removeEventListener('click', this.handleClickOutside);
  },
}
</script>
<style lang="scss" scoped>
.custom-dropdown {
  max-height: 200px;
  overflow-y: scroll  ;
}
.custom-dropdown .btn-head {
  color: #9baec4;
  cursor: auto;
}
.custom-dropdown .btn-head:hover {
  background: unset;
}
.custom-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #efefef;
}

.custom-dropdown::-webkit-scrollbar {
  width: 10px;
  background-color: #efefef;
}

.custom-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  max-height: 5px;
  background-color: #6c6d75;
}
.dropdown-toggle::after {
  position: relative;
  top: 2px;
}
.custom-dropdown-toogle::after {
  display: none;
}
</style>