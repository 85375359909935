import httpClient from "../httpClient";

const END_POINT = '/api/languages'
const createLanguage = (language) => httpClient.post(END_POINT, language)
const getAllLanguage = () => httpClient.get(`${END_POINT}`)
const getLanguageById = (languageId) => httpClient.get(`${END_POINT}/${languageId}`)
const editLanguageById = (languageId,language) => httpClient.patch(`${END_POINT}/${languageId}?where={"languageId":${languageId}}`, language)
const deleteLanguageById = (languageId) => httpClient.delete(`${END_POINT}/${languageId}`)
const validateLanguageAbbreviation = (languageAbbreviation) => httpClient.get(`${END_POINT}/validate/abbreviation/${languageAbbreviation}`)

export {
  createLanguage,
  getAllLanguage,
  getLanguageById,
  editLanguageById,
  deleteLanguageById,
  validateLanguageAbbreviation
}
