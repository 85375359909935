<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar title="Video (nav)" />
      <div class="container background-form my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ video.fileName }}</h2>
          </div>
        </div>

        <div 
          class="
            d-flex
            justify-content-center
            align-items-center
            flex-column
          "
        >
          <video
            width="500"
            controls
          >
            <source 
              :src="`/files/streaming/${fileUUID}?authToken=${authToken}`" 
              type="video/mp4"
            >
          </video>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue'
import rdNavbar from '../../components/rd-components/rd-navbar.vue'

export default {
  components: { rdNavbar, RdFooter },
  data() {
    return {
      video: {}
    }
  },
  mounted() {
    this.video = this.$store.getters.getPlayingVideo
  },
  unmounted() {
    this.$store.commit('clearPlayingVideo')
  },
  computed: {
    fileUUID() {
      return this.$route.params.uuid
    },
    authToken() {
      return this.$store.getters.getToken
    }
  },
}
</script>
<style scoped>
video {
  width: 100%;
  height: auto;
}
</style>