<template>
  <div
    class="d-flex flex-column justify-content-between vh-100"
  >
    <div>
      <rd-navbar />
      <div class="container background-form my-3 p-3 pt-0">
        <div class="row">
          <div class="col-12">
            <p 
              class="p-2 pb-0 text-start"
            >
              {{ $t('Trainer Resources') }} - {{ file.categoryName }}
            </p>
          </div>
          <div class="col-12">
            <div class="accordion accordion-custom my-3" id="accordionParent">
              <div class="accordion-item p-3 text-start">
                <div class="accordion-heading">
                  {{ file.fileName }}
                </div>
                <hr>
                <div class="accordion-body p-3 pt-0">
                  <p class="mb-0">
                    <span class="fw-bold">
                      {{ $t('Language') }}:
                    </span> 
                    {{ file.fileLanguage }}
                  </p>
                  <p class="mb-0">
                    <span class="fw-bold">
                      {{ $t('Category') }}:
                    </span> 
                    {{ file.categoryName }}
                  </p>
                  <p class="mb-0">
                    <span class="fw-bold">
                      {{ $t('Description') }}:
                    </span> 
                    {{ file.fileDescription }}
                  </p>
                  <p class="mb-0">
                    <span class="fw-bold">
                      {{ $t('File Type') }}:
                    </span> 
                    {{ file.fileType }}
                  </p>
                  <p class="mb-0">
                    <span class="fw-bold">
                      {{ $t('Sub category') }}: 
                    </span> 
                    {{ file.subcategoryName }}
                  </p>
                  <p class="mb-0">
                    <span class="fw-bold">
                      {{ $t('Last update') }}: 
                    </span> 
                    {{ convertDateFormat(file.fileLastUpdate) }}
                  </p>
                  <div class="d-flex justify-content-between mt-2">
                    <button-component
                      class="text-capitalize rounded border-2"
                      primaryOutline
                      @handleClick="!file.loadingView ? handleFileView($event, file) : {}"
                    >
                      {{ $t('View') }}
                    </button-component>
                    <button-component
                      v-if="showDownloadBtn"
                      class="text-capitalize rounded border-2"
                      primaryOutline
                      @handleClick="handleFileDownload($event, file)"
                      :disabled="isExternal(file.filePath)"
                    >
                      {{ $t('Download') }}
                    </button-component>
                  </div>
                </div>

                <!-- loading  -->
                <div
                  v-if="file.loadingView || file.downloading"
                  class="
                    col 
                    p-0 
                    m-0 
                    d-flex 
                    justify-content-center 
                    align-items-center
                    position-absolute
                    top-50
                    start-50
                    translate-middle
                  "
                >
                  <div
                    class="
                      d-flex 
                      justify-content-center 
                      align-items-center
                      position-absolute
                      top-50
                      start-50
                      translate-middle
                    "
                  >
                    <rd-loading
                      :width="4"
                      :height="4"
                    />
                  </div>
                </div>
              </div>
            
            </div>

          </div>
        </div>
      </div>

    </div>
    <div>
      <rd-footer />
    </div>
  </div>
</template>
<script>
import RdFooter from '../../components/rd-components/rd-footer.vue';
import rdNavbar from '../../components/rd-components/rd-navbar.vue';
import { getFileById } from '../../services/files/file.api' 
import { convertDateFormat } from '@/utils/convertFormat.js';
import { externalLinkRedirect, fileDownload, fileView } from '../../utils/handleFile';
import { createInteraction } from '@/services/interactions/interactions.api.js';
import { mapState } from 'vuex';
import RdLoading from '../../components/rd-components/rd-loading.vue';
import { interactionTypes as getInteractionTypes } from '@/utils/constants.js';
import ButtonComponent from '../../components/button-component.vue';

export default {
  data() {
    return {
      file: {},
      convertDateFormat,
      hasSpinner: true,
      interactionTypes: {},
      onlyDownload: false,
      fileReady: false,
    }
  },
  components: { rdNavbar, RdFooter, RdLoading, ButtonComponent },
  async mounted() {
    this.interactionTypes = await getInteractionTypes();
    await this.getThisFile()
  },
  computed: {
    fileUUID() {
      return this.$route.params.uuid;
    },
    isIos() {
      const userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);
      return ios && !safari;
    },
    isAndroid() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android/.test(userAgent);
    },
    showDownloadBtn() {
      return this.fileReady && !this.onlyDownload
    },
    ...mapState(['user'])
  },
  methods: {
    async getThisFile() {
      try {
        const {data} = await getFileById(this.fileUUID)
        this.file = { ...data, downloading: false, loadingView: false }
        if(!this.file.fileExternalSource && this.file.filePath.substring(this.file.filePath.lastIndexOf('.') + 1).toLowerCase() == 'pdf') {
          this.onlyDownload = true;
        }
        this.fileReady = true;
        if(this.file.isTemp) this.$router.push(`/files`)
      } catch(error) {
        console.log(error)
      }
    },

    async handleFileDownload(e, file) {
      if(file.isTemp) return;
      this.hasSpinner = true;
      setTimeout(() => {
          if(this.hasSpinner) {
            this.$store.commit('setStateLoad', true)
          }
        }, 200);
      const store = this.$store.state;

      this.file.downloading = true

      try {
        if(!store.isMobile) {
          await fileDownload(file.fileId, file.fileName);
        } else {
          window.flutter_inappwebview.callHandler('comunicationname', JSON.stringify({
            token: store.token,
            id: file.fileId,
            name: file.fileName,
            route: `${process.env.VUE_APP_API_URL}/api/files/download/${file.fileId}`
          }));
        }
        this.hasSpinner = false;
        await createInteraction({
          userId: parseInt(this.user.userId),
          fileId: parseInt(file.fileId),
          interactionTypeId: this.interactionTypes['DOWNLOAD']
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit('setStateLoad', false)
        this.file.downloading = false
      }
    },

    async handleFileView(e, file) {
      if(file.isTemp) return;
      this.hasSpinner = true;
      setTimeout(() => {
          if(this.hasSpinner) {
            this.$store.commit('setStateLoad', true)
          }
        }, 200);
      
      // if (file.filePath && file.filePath.endsWith('.mp4')) {
      //   this.$store.commit('setPlayingVideo', file)
      //   this.hasSpinner = false;
      //   return this.$router.push(`/files/${file.uuid}`)
      // }

      this.file.loadingView = true

      try {
        if(this.isExternal(file.filePath)) {
          externalLinkRedirect(file.fileExternalSource);
        } else {
          if (this.isIos || this.onlyDownload) {
            await fileDownload(file.fileId, file.fileName);
          } else {
            const data = await fileView(file.fileId);
            this.$store.dispatch('dispatchFileView', {data, file})
          }
        }
        this.hasSpinner = false;
        if(this.isExternal(file.filePath) || (this.$store.state.modalType !== 'rd-not-valid-viewer' && !(this.$store.state.modalType === 'rd-pdf-viewer' && this.$store.state.windowWidth <= 768))) {
          await createInteraction({
            userId: this.user.userId,
            fileId: file.fileId,
            interactionTypeId: this.interactionTypes['VISUALIZE']
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit('setStateLoad', false)
        this.file.loadingView = false
      }
    },

    isExternal(path) {
      return path === null || path === ''
    },
  },
}
</script>