<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar />
      <div class="container background-form rounded-3 my-3 p-3">
        <PageTitle
          :title="$t('SmartHire')"
          :description="$t('SmartHire Module')"
          :parentBread="$t('System Administration')"
          :childBread="$t('SmartHire')"
        />

        <div class="row p-3 mt-1">
          <div class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              :value="this.search"
              type="text"
              :placeholder="$t('Search by username or email')"
              @input="(e) => this.search = e.target.value"
            />
          </div>

          <div class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              ref="select-status"
              type="select"
              :placeholder="$t('Select status')"
              :placeholderToTag="$t('Add this')" 
              :items="statusAvailable.map(({ name, code }) => ({ name, value: code }))"
              @handleAddTagging="calcStatusSelected($event)"
            />
          </div>
          <div class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              ref="select-availability"
              type="select"
              :placeholder="$t('Select availibility')"
              :placeholderToTag="$t('Add this')" 
              :items="[
                { name: $t('Enabled'), value: true },
                { name: $t('Disabled'), value: false }
              ]"
              @handleAddTagging="calcAvailability($event)"
            />
          </div>
          <div class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              ref="select-exam"
              type="select"
              :placeholder="$t('Select exam')"
              :placeholderToTag="$t('Add this')" 
              :items="examsAvailable.map(({ name, id }) => ({ name, value: id }))"
              @handleAddTagging="calcExam($event)"
            />
          </div>
          <div class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              ref="select-session"
              type="select"
              :placeholder="$t('Select session')"
              :placeholderToTag="$t('Add this')" 
              :items="sessionsAvailable.map(({ name, id }) => ({ name: name, value: id }))"
              @handleAddTagging="calcSession($event)"
            />
          </div>
          <div v-if="examSelected" class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              ref="select-operator-logic"
              type="select"
              :placeholder="$t('Select the logical operator')"
              :placeholderToTag="$t('Add this')" 
              :items="[
                { name: $t('Equal'), value: '=' },
                { name: $t('Smaller than'), value: '<' },
                { name: $t('Less than equal'), value: '<=' },
                { name: $t('Greater than'), value: '>' },
                { name: $t('Greater than or equal'), value: '>=' },
              ]"
              @handleAddTagging="calcOperator($event)"
            />
          </div>
          <div v-if="examSelected" class="col-12 col-lg-4 col-md-4 mt-2">
            <rd-input
              type="number"
              :placeholder="$t('Search by percentage')"
              @input="(e) => this.percentage = e.target.value"
            />
          </div>
          <div class="col-12 col-lg-2 col-md-3 mt-2 pt-2 text-end text-md-start text-lg-start">
            <button-component
              primaryOutline
              @handleClick="clearFilters"
            >
              {{ $t('Clear filters') }}
            </button-component>
          </div>
        </div>

        <div v-if="screenLoading">
          <rd-loading /> 
        </div>
        <div v-else>
          <div
            v-if="filteredEmployees.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No Employees Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <template v-else>
            <div class="row p-3 d-flex justify-content-start">

              <data-table-component
                :columns="[
                  { label: $t('Username (table)'), field: 'userName', sortable: true, type: 'string', },
                  { label: $t('First Name (table)'), field: 'userFirstname', sortable: true, type: 'string', },
                  { label: $t('Last Name (table)'), field: 'userLastname', sortable: true, type: 'string',},
                  { label: $t('Email (table)'), field: 'userEmail', sortable: true, type: 'string', },
                  { label: $t('Preferred Role (table)'), field: 'extrainfo1', sortable: true, type: 'string'},
                  { label: $t('Rating (table)'), field: 'feedback', sortable: true, type: 'number'},
                  { label: $t('Score (table)'), field: 'exam', sortable: true, type: 'number'},
                  { label: $t('Status (table)'), field: 'status' },
                  { label: $t('Function (table)'), field: 'function' },
                ]"
                :rows="filteredEmployees"
                paginator
                resizeable
                clickable-row
                @rowClick="({ row }) => showUserDetails({ userId: row.userId })"
              >
                <template #status="{ row }">
                  <select
                    class="form-select"
                    aria-label="select availability"
                    v-model="row.status.code"
                    :disabled="(!row.isAvailable && !isAvailableNextAssignment && !row.userFunction.function)"
                    @click="(e) => e.stopPropagation()"
                    @change="handleUserStatusChange($event, row.userId)"
                  >
                    <option v-for="(item, index) in statusAvailable" :key="index" :value="item.code">{{ item.name }}</option>
                  </select>
                </template>

                <!-- :disabled="!row.isAvailable && !isAvailableNextAssignment && !row.userFunction.function" -->
                <template #function="{ row }">
                  <select
                    class="form-select"
                    aria-label="select availability"
                    v-model="row.userFunction.function"
                    :disabled="(!row.isAvailable && !row.isAvailableNextAssignment && !row.userFunction.function) || row.status.code === 2"
                    @click="(e) => e.stopPropagation()"
                    @change="handleUserFunctionChange($event, row.userId)"
                  >
                    <option v-for="(item, index) in functions" :key="index" :value="item.code ?? false">{{ item.name }}</option>
                  </select>
                </template>
              </data-table-component>

            </div>

            <div class="row justify-content-end">

              <div class="col-12 col-lg-6 col-md-6 text-end">
                <button-component
                  primary
                  @handleClick="reportSmartfieldDownloadCSV"
                >
                  {{ $t('SmartField CSV') }}
                </button-component>
                <button-component
                  primary
                  @handleClick="reportSmarthireDownloadCSV"
                >
                  {{ $t('SmartHire CSV') }}
                </button-component>
                <button-component
                  primary
                  @handleClick="handleUploadCSV"
                >
                  {{ $t('Upload CSV') }}
                </button-component>
                <button-component
                  primary
                  @handleClick="handleDownloadCSV"
                >
                  {{ $t('Download CSV') }}
                </button-component>
              </div>

            </div>
          </template>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>

    <!-- bulk load modal -->
    <div
      v-if="showBulkLoadModal"
      class="position-fixed top-0 start-0 backScreen"
    >
      <div 
        class="
          card 
          position-fixed 
          top-50
          start-50
          translate-middle
          modalPosition
          my-2
          w-75 p-4
        "
      >
        <div class="container h-100">
          <div class="row">
            <h4 class="col-12 text-capitalize">
              {{ $t('Bulk smarthire updates')}}
            </h4>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <rd-input
                type="file"
                ref="file"
                :placeholder="$t('File')"
                :label="$t('Upload a file to bulk update')"
                @input="handleFileSelect"
              />
            </div>
          </div>
          
          <!-- actions buttons -->
          <div class="row justify-content-end mt-4">
            <div class="col-12 col-md-6 col-lg-6 text-end">
              <button-component
                @handleClick="showBulkLoadModal = false"
                primaryOutline
              >
                {{ $t('Dismiss') }}
              </button-component>
              <button-component
                @handleClick="handleUploadFile"
                primary
              >
                {{ $t('Upload') }}
              </button-component>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- user details modal -->
    <button
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRight"
      aria-controls="offcanvasRight"
      type="button"
      class="d-none"
      ref="userDetailsBtn"
    ></button>
    <user-details-component
      id="offcanvasRight"
      :userId="userSelectedId"
      @hide="userSelectedId = null"
      @user-updated="reloadTableData"
    />
  </div>
</template>
<script>
import RdFooter from '@/components/rd-components/rd-footer.vue'
import RdLoading from '@/components/rd-components/rd-loading.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import {
  getEmployees,
  updateEmployee,
  getSHAvailableStatus,
  bulkUpdateEmployees
} from '@/services/smarthire/smarthire.api'
import { convertToCSV } from '@/utils/convertFormat'
import { downloadCSV } from '@/utils/handleFile'
import RdInput from '../../components/rd-components/rd-input.vue';
import { getAllExams } from '../../services/exams/exam.api'
import { getSessions } from '../../services/sessions/sessions.api'
import UserDetailsComponent from '../../components/smarthire/user-details-component.vue'
import PageTitle from '../../components/rd-components/page-title.vue'
import ButtonComponent from '../../components/button-component.vue'
import { smartHireReport, smartFieldReport } from '../../services/user/user.api'
import DataTableComponent from '../../components/tables/data-table-component.vue'

export default {
  components: {
    RdFooter,
    RdNavbar,
    RdLoading,
    RdInput,
    UserDetailsComponent,
    PageTitle,
    ButtonComponent,
    DataTableComponent
  },
  data() {
    return {
      screenLoading: false,
      employees: [],
      employeesDataTemp: [],
      statusAvailable: [],
      functions: [
        {
          code: null,
          name: ''
        },
        {
          code: "1",
          name: 'Lead'
        },
        {
          code: "2",
          name: 'Operator'
        },
      ],
      availibilitySelected: null,
      statusSelected: null,
      showBulkLoadModal: false,
      file: null,
      search: '',
      examsAvailable: [],
      sessionsAvailable: [],
      percentage: null,
      examSelected: null,
      sessionSelected: null,
      operator: null,
      examScoreOptions: [
        { name: '0-10', value: [0, 10] },
        { name: '11-20', value: [11, 20] },
        { name: '21-30', value: [21, 30] },
        { name: '31-40', value: [31, 40] },
        { name: '41-50', value: [41, 50] },
        { name: '51-60', value: [51, 60] },
        { name: '61-70', value: [61, 70] },
        { name: '71-80', value: [71, 80] },
        { name: '81-90', value: [81, 90] },
        { name: '91-100', value: [91, 100] },
      ],
      forceRender: true,
      userSelectedId: null
    }
  },
  async mounted() {
    if(this.$store.state.permissions.length == 1) {
      this.$store.commit('setShowBackButton', false)
    }
    await this.getEmployees()
    await this.getStatus()
    await this.getExams()
    await this.getSessions()
  },
  computed: {
    filteredEmployees() {
      let tempEmployees = JSON.parse(JSON.stringify(this.employees))
      if (this.statusSelected !== null) {
        tempEmployees = tempEmployees.filter(({ status }) => {
          return status.code === this.statusSelected
        })
      }
      if (this.availibilitySelected !== null) {
        tempEmployees = tempEmployees.filter(({ isAvailable }) => {
          return isAvailable === this.availibilitySelected
        })
      }

      if (this.examSelected !== null) {
        tempEmployees = tempEmployees.filter(employeeData => {
          const { exams } = employeeData;  // Destructure exams from employeeData

          // Check if any exam matches the selected exam id
          const employeesFound = exams.some(({ id }) => id === +(this.examSelected));

          // If no matching exam is found, filter out this employee
          if (!employeesFound) return false;

          // If matching exams are found, process them
          if (exams.length) {
            employeeData.exam = this.examAvg(exams.filter(exam => exam.id === +(this.examSelected)));
          }

          // Return true to keep the employee in the filtered array
          return true;
        });
      }
      if (this.sessionSelected !== null) {
        console.log('tempEmployees',tempEmployees)
        tempEmployees = tempEmployees.filter(employeeData => {
          const { sessions } = employeeData;  // Destructure exams from employeeData
          // Check if any exam matches the selected exam id
          const employeesFound = sessions.some(({ session_id }) => session_id == +(this.sessionSelected));
          if (!employeesFound) return false;
          return true;
        });
      }

      if (
        this.examSelected !== null
        && this.operator!== null
        && this.percentage !== null
        && this.percentage !== ''
      ) {
        tempEmployees = tempEmployees.filter(({ exams }) => {
          const exam = exams.filter(({ id }) => id == this.examSelected )
          if(!(exam.length)) {
            return false;
          }
          const {score} = exam[0]
          if (this.operator == '=') {
            return score == this.percentage
          }
          if (this.operator == '<') {
            return score < this.percentage
          }
          if (this.operator == '<=') {
            return score <= this.percentage
          }
          if (this.operator == '>') {
            return score > this.percentage
          }
          if (this.operator == '>=') {
            return score >= this.percentage
          }
          return (
            false
          )
        })
      }

      if (this.search !== '') {
        tempEmployees = tempEmployees.filter(({ userEmail, userName }) => {
          return (
            userName && userName.toLowerCase().includes(this.search.toLowerCase())
            || userEmail && userEmail.toLowerCase().includes(this.search.toLowerCase())
          )
        })
      }

      return tempEmployees
    },
  },
  methods: {
    showUserDetails({ userId }) {
      this.userSelectedId = userId
      this.$refs.userDetailsBtn.click();
    },
    calcOperator(e) {
      if(!e) {
        this.operator = null;
        return;
      }
      this.operator = e.value;
    },
    calcStatusSelected(e) { 
      if(!e) {
        this.statusSelected = null;
        return;
      }
      this.statusSelected = e.value;
    },
    calcAvailability(e) { 
      if(!e) {
        this.availibilitySelected = null;
        return;
      }
      this.availibilitySelected = e.value;
    },
    calcExam(e) { 
      if(!e) {
        this.examSelected = null;
        return;
      }
      this.examSelected = e.value;
    },   
    calcSession(e) { 
      if(!e) {
        this.sessionSelected = null;
        return;
      }
      this.sessionSelected = e.value;
    },   
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '16.66%'
      })
    },
    handleFileSelect(e) {
      this.file = e.target.files[0];
    },
    async handleUploadFile() {
      try {
        const fileData = new FormData();
        fileData.append('file', this.file);
        
        await bulkUpdateEmployees({ file: fileData });

        // re-fetch employees
        await this.getEmployees();

        this.$toast.open({
          message: this.$t('Bulk Load was sucessful.'),
          type: 'success',
        })
      } catch (error) {
        this.$toast.open({
          message: this.$t('Bulk Load was not created.'),
          type: 'error',
        })
      } finally {
        this.showBulkLoadModal = false
      }
    },
    handleUploadCSV() {
      this.showBulkLoadModal = true
    },
    async handleDownloadCSV() {
      try {
        const tempRaw = JSON.parse(JSON.stringify(this.filteredEmployees));
        const propertiesToExclude = [
          'exams',
          'userFunction',
          'roleId',
          'isAvailable',
          'accessToken',
          'refreshToken',
          'keycloackId',
          'feedback',
          'isAvailableNextAssignment',
          'address',
          'status',
          'role',
          'sessions'
        ];

        let finalData = [];

        tempRaw.forEach(item => {
          const {userId,userName,userEmail,userFirstname,userLastname, ...resto} = item;
          item = {
            userId,
            userName,
            userFirstname,
            userLastname,
            userEmail,
            ...resto
          }
          const { role, status, ...rest } = item;

          rest.rating = rest.feedback ?? ''

          if (Array.isArray(item.exams) && item.exams.length > 0) {
            // Case when user has multiple exams
            let examRowsAdded = false; // Track if any valid exam rows have been added

            item.exams.forEach(examItem => {
              // Add row only if session, trainer, or location is not all null, or if there's only one exam
              if (item.exams.length === 1 || examItem.session || examItem.trainer || examItem.location) {
                let newItem = { ...rest };

                // Include user-related fields in every row
                newItem.roleName = role?.roleName;
                newItem.enable = item.isAvailable ? 1 : 0;
                newItem.smartHireStatus = status?.name;

                // Add session data
                newItem.session = examItem.session;
                newItem.trainer = examItem.trainer;
                newItem.location = examItem.location;

                // Remove unwanted properties
                propertiesToExclude.forEach(prop => delete newItem[prop]);

                // Add the new item (row) to the final data array
                finalData.push(newItem);
                examRowsAdded = true; // Track that a row has been added
              }
            });

            // If no valid exam rows were added but there are exams, add one row with null session data
            if (!examRowsAdded) {
              const newItem = { ...rest };
              newItem.roleName = role?.roleName;
              newItem.enable = item.isAvailable ? 1 : 0;
              newItem.smartHireStatus = status?.name;
              newItem.session = null;
              newItem.trainer = null;
              newItem.location = null;

              // Remove unwanted properties
              propertiesToExclude.forEach(prop => delete newItem[prop]);

              finalData.push(newItem);
            }
          } else {
            // If no exams, include the user data with session, trainer, and location as null
            const newItem = { ...rest };
            newItem.roleName = role?.roleName;
            newItem.enable = item.isAvailable ? 1 : 0;
            newItem.smartHireStatus = status?.name;
            newItem.session = null;
            newItem.trainer = null;
            newItem.location = null;

            // Remove unwanted properties
            propertiesToExclude.forEach(prop => delete newItem[prop]);

            finalData.push(newItem);
          }
        });

        // Convert to CSV and download
        const csv = await convertToCSV({ data: finalData, separator: ',' });
        downloadCSV({ data: csv, filename: 'smarthire_status' });
      } catch (error) {
        console.error(error);
      }
    },
    async reportSmarthireDownloadCSV() {
      try {
        const {data} = await smartHireReport();
        const csv = await convertToCSV({ data: data, separator: ',' })
        downloadCSV({ data: csv, filename: 'smarthire_report' })
      } catch (error) {
        console.log(error)
      }
    },
    async reportSmartfieldDownloadCSV() {
      try {
        const {data} = await smartFieldReport();
        const csv = await convertToCSV({ data: data, separator: ',' })
        downloadCSV({ data: csv, filename: 'smartfield_report' })
      } catch (error) {
        console.log(error)
      }
    },
    clearFilters() {
      this.statusSelected = null
      this.availibilitySelected = null
      this.examSelected = null
      this.sessionSelected = null
      this.search = ''
      this.operator = null
      this.percentage = null
      this.$refs['select-exam'].selectValue = null
      this.$refs['select-session'].selectValue = null
      this.$refs['select-status'].selectValue = null
      this.$refs['select-availability'].selectValue = null
      if(this.$refs['select-operator-logic']) {
        this.$refs['select-operator-logic'].selectValue = null
      }
    },
    async getStatus() {
      try {
        const { data } = await getSHAvailableStatus();
        this.statusAvailable = data.map(({
          statusName, id
        }) => ({
          name: statusName, code: id
        }))
      } catch (error) {
        console.log(error)
      }
    },
    async getExams() {
      try {
        const { data } = await getAllExams();
        const exams = data.map(({
          examName, examId
        }) => ({
          id: examId, name: examName
        }));
        this.examsAvailable = exams
      } catch (error) {
        console.log(error)
      }
    },
    async getSessions() {
      try {
        const { data } = await getSessions();
        const sessions = data.map(({
          code, id
        }) => ({
          id: id, name: code
        }));
        this.sessionsAvailable = sessions
      } catch (error) {
        console.log(error)
      }
    },
    examAvg(exams = []) {
      if(!exams.length) return null;
      const sum = exams.reduce((accumulator, currentValue) => accumulator + currentValue.score, 0);
      const average = sum / exams.length;
      
      return average.toFixed(2);
    },
    async reloadTableData() {
      await this.getEmployees();
    },
    async getEmployees() {
      try {
        this.screenLoading = true
        const { data } = await getEmployees();
        this.employees = data.map(({ status, ...employee}) => ({
          ...employee,
          status: { name: status.statusName, code: status.id },
          userFunction: {
            function: employee.function
          },
          exam: this.examAvg(employee.exams),
        }))
      } catch (error) {
        console.log(error)
      } finally {
        this.screenLoading = false
        await this.$nextTick(() => {
          const elements = document.querySelectorAll("table th");
          elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index
            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });
            el.appendChild(grip);
          })
          document.addEventListener("mousemove", (e) => {
            if (this.thElm) {
              this.lastWidth  = this.thElm.offsetWidth;
              this.thElm.style.width = this.startOffset + e.pageX + "px";
              document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                if((internalIndex % 6) == Number(this.thElm.id)) {
                  td.style.width = this.startOffset + e.pageX + "px";
                } else {
                  td.style.width = td.offsetWidth + "px";
                }
              })
              this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
            }
          });

          document.addEventListener("mouseup", () => {
            this.thElm = undefined;
          });
        });
      }
    },
    handlePageChange(data) {
      this.employeesDataTemp = data;
    },
    async saveEmployeeChanges(e, id) {
      e.stopPropagation();
      const userToChange = this.employees.find(employee => employee.userId === id);
      const { isAvailable, status: { code } } = userToChange
      try {
        await updateEmployee({
          id: id,
          payload: {
            isAvailable: isAvailable,
            smartHireStatusId: code,
            function: userToChange.function
          }
        })
        this.$toast.open({
          message: this.$t('User updated.'),
          type: 'success',
        })
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('User could not be updated.'),
          type: 'error',
        })
      }
    },
    async handleUserStatusChange(e, id) {
      console.log('e.target.value: ', e.target.value);
      this.forceRender = false;
      const status = this.statusAvailable.find(({ code }) => code == e.target.value)
      this.employees.forEach(employ => {
        if (+employ.userId === +id) {
          employ.status = { name: status.name, code: status.code }
        }
      });
      await this.$nextTick();
      this.forceRender = true;
      await this.saveEmployeeChanges(e, id);
    },
    async handleUserFunctionChange(e, id) {
      this.forceRender = false;
      const functionUser = e.target.value == 'false' || e.target.value == false? null : e.target.value
      this.employees.forEach(employ => {
        if (+employ.userId === +id) {
          employ.function = functionUser
          employ.userFunction = {
            'function': functionUser
          }
        }
      });
      await this.$nextTick();
      this.forceRender = true;
      await this.saveEmployeeChanges(e, id);
    },
    async handleUserAvailabilityChange(e, id) {
      this.forceRender = false;
      const { checked } = e.target
      const emp = this.employees.map(employee => {
        if (+employee.userId === +id) {
          employee.isAvailable = checked
        }
        return {...employee}
      })
      this.employees = emp;
      await this.$nextTick();
      this.forceRender = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.fill-ods {
  background-color: #f5f5f5;
}
.backScreen {
  background: rgb(0, 0, 0, 0.7);
  min-height: 100vh;
  min-width: 100vw;
  z-index: var(--top-layer-z-index);
}

.modalPosition {
  max-height: 100%;
  overflow-y: scroll;
}

.hoverable:hover {
  cursor: pointer;
}
.hoverable:hover {
  cursor: pointer;
}

thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.cursor-pointer {
  cursor: pointer;
}
</style>