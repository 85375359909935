<template>
  <div
    :style="{
      position: 'relative',
      overflowX: overflowX ? 'auto' : 'visible',
      width: '100%',
      maxWidth: '100%',
    }"
    :ref="`table-${id}`"
  >
    <div class="w-100">
      <table class="table text-start table-hover table-custom-general" ref="table_resize">
        <thead
          class="table-primary"
          @contextmenu.prevent="handleTheadContextMenu({ row })"
          @dblclick="handleTheadDblClick({ row })"
        >
          <tr :style="{ borderBottom: '1px solid #000' }">
            <th
              v-for="(column, index) in columns"
              scope="col"
              :key="column.field"
              :class="{ 'pointer': column.sortable }"
              :style="thStyles(column.field, index)"
              :ref="`th-${column.field}`"
              @click="handleTheadClick($event, { column })"
            >
              <slot :name="`${column.field}Header`" :column="column"></slot>
              {{ column.label}}
              <span v-if="column.sortable">
                <span v-if="isAsc && sortField === column.field" :style="{ fontSize: '.8rem' }">
                  <i class="bi bi-arrow-up-short"></i>
                </span>
                <span v-if="!isAsc && sortField === column.field" :style="{ fontSize: '.8rem' }">
                  <i class="bi bi-arrow-down-short"></i>
                </span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="text-start">
          <tr
            v-for="(row, index) in tempRows"
            :key="index"
            :class="{ 'bg-light': index % 2 !== 0, 'pointer': clickableRow }"
            @dblclick="handleClickRowEmitter($event, { row })"
          >
            <td
              v-for="column in columns"
              :key="column.field"
              :style="tdStyles(column.field)"
              :ref="`td-${column.field}-${index}`"
            >
              <div
                class="p-0 m-0"
                ref="cell"
                @click.prevent="handleClickCell($event, { cell: row[column.field], column })"
              >
                <slot :name="column.field" :row="row" :column="column">
                  {{ row[column.field] }}
                </slot>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <rd-paginator
        v-if="paginator"
        :forceRender="forceRenderPaginator"
        :items="filteredRows"
        @page-change="handlePageChange($event)"
      />
    </div>
  </div>
</template>

<script>
import rdPaginator from '../rd-components/rd-paginator.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: { rdPaginator },
  name: 'DataTableComponent',
  props: {
    columns: { type: Array, required: true },
    rows: { type: Array, required: true },
    clickableRow: { type: Boolean, default: false },
    paginator: { type: Boolean, default: false },
    resizeable: { type: Boolean, default: false },
    forceRenderPaginator: { type: Boolean, default: false },
  },
  data() {
    const id = uuidv4();
    return {
      id,
      tempRows: [],
      thElm: null,
      startOffset: 0,
      lastWidth: 0,
      sortField: '',
      isAsc: true,
      clickedCell: false,
    }
  },
  async mounted() {
    /**
     * Event to start listening for outside click the cells
     */
    //document.addEventListener("click", this.handleClickOutside);
    if (this.resizeable) await this.setResize();
  },
  beforeUnmount() {
    /**
     * Remove Event to listen for outside click the cells
     */
    // document.removeEventListener("click", this.handleClickOutside);
  },
  computed: {
    overflowX() {
      return this.resizeable;
    },
    tableRef() {
      return this.$refs.table_resize;
    },
    filteredRows() {
      return this.rows;
    },
    colsNumber() {
      return this.columns.length;
    }
  },
  methods: {
    /* Pagination */
    handlePageChange(data) {
      this.tempRows = data;
      // order new rows by sortField
      this.sortedRows();
    },
    /* Styles */
    tdStyles(field) {
      const column = this.columns.find(column => column.field === field);
      const { vAlign: verticalAlign, hAlign: textAlign, bgColor } = column;
      const styles = {
        wordBreak: 'keep-all',
        verticalAlign: verticalAlign || 'middle',
        textAlign: textAlign || 'start',
        backgroundColor: bgColor,
      };
      return styles;
    },
    thStyles(field) {
      const column = this.columns.find(column => column.field === field);
      const { headerVAlign, headerHAlign } = column;
      const styles = {
        wordBreak: 'keep-all',
        verticalAlign: headerVAlign || 'middle',
        textAlign: headerHAlign || 'start',
      };
      return styles;
    },
    makeColumnWidthEqual() {
      const defaultWidth = Math.floor(100 / this.columns.length);
      return `${defaultWidth}%`;
    },
    /* Click events */
    handleClickCell(event, { column }) {
      event.stopPropagation();
      const { type } = column;
      if (type === 'dropdown') {
        /**
         * Useful if you want to know if a cell was a dropdown and clicked
         */
        // this.clickedCell = true;
      }
    },
    handleClickOutside(event) {
      // useful if you want to know if a cell was clicked first and then clicked outside
      const found = this.$refs.cell && this.$refs.cell.some(element => element.contains(event.target))
      if (!found) this.clickedCell = false;
    },
    handleClickRowEmitter(event, { row }) {
      event.stopPropagation();
      if (this.clickableRow) this.$emit('row-click', { row });
    },
    handleTheadContextMenu({ row }) {
      this.$emit('thead-context-menu', { row });
      if (this.resizeable) this.resizeTable();
    },
    handleTheadDblClick({ row }) {
      this.$emit('thead-dblclick', { row });
      if (this.resizeable) this.resizeTable();
    },
    handleTheadClick(event, { column }) {
      event.stopPropagation();
      // sorting if column is sortable
      const { sortable } = column;
      if (sortable) this.sortColumn({ column });
    },
    /* Sorting Table funtions */
    sortColumn({ column }) {
      const { sortable, field } = column;
      if (!sortable) return;
      if (this.sortField === field) {
        this.isAsc = !this.isAsc;
      } else {
        this.sortField = field;
        this.isAsc = true;
      }
      this.sortedRows();
    },
    sortedRows() {
      if (!this.sortField) return this.tempRows;
      this.tempRows.sort((a, b) => {
        let result;

        /**
         * Column type could be number, string or date
         */

        const columnType = this.columns.find(col => col.field === this.sortField)?.type;

        // if column type is not found, return 0
        if (!columnType) return 0;

        // number sorting
        if (columnType === 'number') {
          result = parseInt(a[this.sortField] || 0) - parseInt(b[this.sortField] || 0);
        } 

        // string sorting
        if (columnType === 'string') {
          result = (a[this.sortField] || '').localeCompare(b[this.sortField] || '');
        }

        // date sorting
        if (columnType === 'date') {
          const dateA = new Date(a[this.sortField] || 0);
          const dateB = new Date(b[this.sortField] || 0);
          result = dateA - dateB;
        }
        return this.isAsc ? result : -result;
      });
    },
    /* Resize Table funtions */
    async setResize() {
      await this.$nextTick(() => {
        const elements = this.$refs.table_resize.querySelectorAll("th");
        elements.forEach((el, index) => {
          el.style.position = "relative";
          el.id = index;
          let grip = document.createElement("div");
          grip.style.borderRight = 'black solid 1px';
          grip.innerHTML = "&nbsp;";
          grip.style.top = 0;
          grip.style.right = 0;
          grip.style.bottom = 0;
          grip.style.width = "5px";
          grip.style.position = "absolute";
          grip.style.cursor = "col-resize";
          grip.addEventListener("mousedown", (e) => {
            this.thElm = el;
            this.startOffset = el.offsetWidth - e.pageX;
            this.lastWidth = 0;
            document.addEventListener("mousemove", this.handleMouseMove);
            document.addEventListener("mouseup", this.handleMouseUp);
          });
          el.appendChild(grip);
        });
      });
    },
    handleMouseMove(e) {
      if (this.thElm) {
        this.lastWidth = this.thElm.offsetWidth;
        this.thElm.style.width = this.startOffset + e.pageX + "px";
        this.$refs.table_resize.querySelectorAll("tbody tr td").forEach((td, internalIndex) => {
          if ((internalIndex % this.colsNumber) === Number(this.thElm.id)) {
            td.style.width = this.startOffset + e.pageX + "px";
          } else {
            td.style.width = td.offsetWidth + "px";
          }
        });
        this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + (this.thElm.offsetWidth - this.lastWidth) + "px";
      }
    },
    handleMouseUp() {
      this.thElm = undefined;
      document.removeEventListener("mousemove", this.handleMouseMove);
      document.removeEventListener("mouseup", this.handleMouseUp);
    },
    resizeTable() {
      this.$refs.table_resize.style.width = '100%';
      const elements = this.$refs.table_resize.querySelectorAll("th");
      elements.forEach((el) => {
        el.style.width = 'auto';
      });
      this.$refs.table_resize.querySelectorAll("tbody tr td").forEach((td) => {
        // td.style.width = this.makeColumnWidthEqual();
        td.style.width = 'max-content';
      });
    },
  },
}
</script>

<style scoped lang="scss">
.table-custom-general {
  border: #e0e0e0 solid 1px !important;
}
.table-custom-general thead {
  font-size: 14px !important;
}
.table-custom-general thead tr th div {
  border-right: #e0e0e0 solid 1px !important;
}
.table-custom-general thead tr  {
  -webkit-box-shadow: inset 0 -1px #e0e0e0, inset 1px 0 #e0e0e0, inset -1px 0 #e0e0e0;
  box-shadow: inset 0 -1px #e0e0e0, inset 1px 0 #e0e0e0, inset -1px 0 #e0e0e0;
}
.table-custom-general thead tr th  {
  border: none;
}
.table-custom-general tbody tr td{
  position: relative;
}
.table-custom-general tbody tr td .custom-table-text {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  right: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-custom-general tbody tr td .custom-table-text * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pointer {
  cursor: pointer;
}
</style>