<template>
  <!-- <embed 
    v-if="windowWidth > 768" 
    :src="`${data}#navpanes=0&scrollbar=0&view=FitH`"
    type="application/pdf"
    title="Custom PDF Title"
  >  -->
  <embed 
    v-if="pdfDataUrl && windowWidth > 768"
    :src="pdfDataUrl"
    type="application/pdf"
  >
  <rd-not-valid-viewer v-else />
</template>
<script>
import { PDFDocument } from 'pdf-lib';
import RdNotValidViewer from './rd-not-valid-viewer.vue'
import { mapState } from 'vuex';
export default {
  name: 'RdPdfViewer',
  props: {
    data: {
      type: String,
      required: true
    },
    title: {
      type: String,
    }
  },
  components: {
    RdNotValidViewer,
  },
  data() {
    return {
      pdfDataUrl: null
    };
  },
  async mounted() {
    await this.loadAndModifyPdf(this.data, this.title);
  },
  computed: {
    ...mapState(['windowWidth'])
  },
  methods: {
    async loadAndModifyPdf(url, newTitle) {
      try {
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDoc.setTitle(newTitle);
        const pdfBytes = await pdfDoc.save();
        this.pdfDataUrl = URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));
      } catch (error) {
        console.error('Error modifying PDF metadata:', error);
      }
    }
  },
}
</script>
<style scoped>
</style>
