import httpClient from '../httpClient';

const END_POINT = '/api/files';
const createFile = (file) => httpClient.post(END_POINT, file, { headers: {
  'Content-Type': 'multipart/form-data'
}})
const getFileById = async (uuid) => await httpClient.get(`${END_POINT}/${uuid}`)
const getAllFiles = async ({ fileVisibility = '', exams = false }) => await httpClient.get(`${END_POINT}?fileVisibility=${fileVisibility}&exams=${exams}`);
const getFilesByUserId = async ({ userId, fileVisibility = true, exams = false }) => await httpClient.get(`${END_POINT}/users/${userId}?fileVisibility=${fileVisibility}&exams=${exams}`);
const editFileById = async (fileId, file) => await httpClient.patch(`${END_POINT}/${fileId}`, file, { headers: {
  'Content-Type': 'multipart/form-data'
}})
const acceptFile = async (fileId) => await httpClient.patch(`${END_POINT}/temp/${fileId}`)
const deleteFileById = async (fileId) => await httpClient.delete(`${END_POINT}/${fileId}`)
const filesBySubcategory = async (subcategoryId) => await httpClient.get(`${END_POINT}/bySubcategory/${subcategoryId}`)
const filesByCategory = async (categoryId) => await httpClient.get(`${END_POINT}/byCategory/${categoryId}`)
const downloadFile = async (fileId) => await httpClient.get(`${END_POINT}/download/${fileId}`, {responseType: 'blob'});
const downloadFileQuestion = async (questionId) => await httpClient.get(`${END_POINT}/download/question/${questionId}`, {responseType: 'blob'});

export {
  createFile,
  getFileById,
  getAllFiles,
  editFileById,
  deleteFileById,
  downloadFile,
  getFilesByUserId,
  filesBySubcategory,
  filesByCategory,
  acceptFile,
  downloadFileQuestion
}