<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Files (nav)')" />
      <div class="container background-form my-3 p-3">
        <PageTitle
          :title="$t('Files')"
          :description="$t('Manage all related to files')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Files Management')"
          :grandChildBread="$t('Files')"
        />
        <div class="row px-3 justify-content-between">
          <!-- Search bar -->
          <div
            class="col-12 col-lg-4"
          >
            <rd-input
              class="w-100"
              :placeholder="$t('Search file by file name')" 
              type="text"
              @input="(e) => this.search = e.target.value"
            />
          </div>

          <div class="col-12 col-lg-4 text-end">
            <button-component
              primary
              @handleClick="goToAddFile"
            >
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add File" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add File (btn)') }}
            </button-component>
          </div>
        </div>

        <div v-if="filesLoading">
          <rd-loading /> 
        </div>

        <div v-else>
          <div
            v-if="filteredFiles.length === 0" 
            class="row p-3"
          >
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No files Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>

          <div v-else class="row p-3 d-flex justify-content-start">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-start table-hover table-custom-general" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col">{{ $t('File (table)') }}</th>
                      <th scope="col">{{ $t('Sub Category (table)') }}</th>
                      <th scope="col">{{ $t('Language (table)') }}</th>
                      <th scope="col">{{ $t('File Type (table)') }}</th>
                      <th scope="col" class="text-center">{{ $t('Actions (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(file, index) in dataTemp" :key="index"
                      class="text-start hoverable"
                      @click="!file.loadingView ? handleTimer($event, file) : {}"
                      :class="{ 'fill-ods': index % 2 !== 0 }"
                    >
                      <td style="width: 20%; word-break: break-all;" scope="row" :header="$t('File (table)')" class="word-wrap" ><div class="custom-table-text">{{ file.fileName }}</div></td>
                      <td style="width: 20%; word-break: break-all;" scope="row" :header="$t('Sub Category (table)')" >
                        <div class="custom-table-text">{{ file.subcategory ? file.subcategory.subCategoryName : '-' }}</div>
                      </td>
                      <td style="width: 20%; word-break: break-all;" scope="row" :header="$t('Language (table)')" >
                        <div class="custom-table-text">{{ file.fileLanguage }}</div>
                      </td>
                      <td style="width: 20%; word-break: break-all;" scope="row" :header="$t('File Type (table)')" ><div class="custom-table-text">{{ file.fileType }}</div></td>
                      <td style="width: 20%; word-break: break-all;" :header="$t('Actions (table)')">
                        <div class="container">
                          <div class="row">
                            
                            <div class="col p-0 m-0">
                              <button-component
                                class="btn btn-label"
                                @handleClick="goToEditFile($event, file.uuid)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit File" type="button" class="bi bi-pencil-square"></i>
                              </button-component>
                            </div>

                            <div class="col p-0 m-0">
                              <button-component
                                class="
                                  btn btn-label d-flex justify-content-center align-items-center position-relative
                                "
                                v-if="!isExternal(file.filePath)"
                                @handleClick="!file.downloading ? handleFileDownload($event, file) : {}"
                              >
                                <rd-loading
                                  v-if="file.downloading"
                                  class="position-absolute"
                                  :width="1.8"
                                  :height="1.8"
                                />
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Download File" type="button" class="bi bi-download"></i>
                              </button-component>
                            </div>

                            <div class="col p-0 m-0">
                              <button-component
                                class="btn btn-label" 
                                @handleClick="showModal($event, file)"
                              >
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Delete File" type="button" class='bi bi-trash-fill'></i>
                              </button-component>
                            </div>

                            <div v-if="file.loadingView" class="col p-0 m-0 d-flex justify-content-center align-items-center">
                              <div 
                                class="
                                  d-flex justify-content-center align-items-center position-relativems-1
                                "
                              >
                                <rd-loading
                                  class="position-absolute"
                                  :width="1.8"
                                  :height="1.8"
                                />
                                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Loading File" type="button" class="bi bi-eye-fill"></i>
                              </div>
                            </div>

                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            <rd-paginator :items="filteredFiles" @page-change="handlePageChange" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
    <rd-confirmation-modal
      v-if="showModalProp"
      modalTitle="Delete file permanently?"
      confirmButton="Delete"
      dismissButton="Dismiss"
      @confirmAction="deleteFile(fileToDelete)"
      @dismissAction="dismissAction"
    />
  </div>
</template>
<script>
import rdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import router from '@/router'
import { getAllFiles, getFilesByUserId, deleteFileById } from '@/services/files/file.api.js'
import { fileView, fileDownload, externalLinkRedirect } from '@/utils/handleFile.js';
import { interactionTypes as getInteractionTypes } from '@/utils/constants.js';
import { createInteraction } from '@/services/interactions/interactions.api.js';
import { convertDateFormat } from '@/utils/convertFormat.js';
import RdConfirmationModal from '../../components/rd-components/rd-confirmation-modal.vue'
import RdLoading from '../../components/rd-components/rd-loading.vue'
import { convertDateFormatFromString } from '../../utils/convertFormat'
import RdInput from '../../components/rd-components/rd-input.vue'
import RdPaginator from '@/components/rd-components/rd-paginator.vue'
import PageTitle from '../../components/rd-components/page-title.vue';
import ButtonComponent from '../../components/button-component.vue'

export default {
  data() {
    return {
      files: [],
      interactionTypes: {},
      convertDateFormat,
      filesLoading: true,
      showModalProp: false,
      fileToDelete: {},
      search: '',
      hasSpinner: true,
      dataTemp: [],
      counter: 0,
      thElm: null,
      startOffset: null,
      lastWidth: 0,
    }
  },
  components: { 
    rdFooter, 
    RdNavbar,
    RdConfirmationModal,
    RdLoading,
    RdInput ,
    RdPaginator,
    PageTitle,
    ButtonComponent
  },
  computed: {
    filteredFiles() {
      return this.files.filter(
          file => file.fileName.toLowerCase().includes(this.search.trim().toLowerCase())
        )
    },
    user(){
      return this.$store.getters.getUser;
    },
  },
  async mounted() {
    if(this.$store.state.permissions.length == 1) {
      this.$store.commit('setShowBackButton', false)
    }
    try {
      await this.setFiles();
      this.interactionTypes = await getInteractionTypes();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    resize() {
      this.$refs.table_resize.style.width = '100%'
      const elements = document.querySelectorAll("table th");
      elements.forEach((el) => {
        el.style.width = 'auto'
      })
      document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '20%'
      })
    },
    async handlePageChange(data) {
      this.dataTemp = data;
    },
    showModal(e, file){
      this.showModalProp = true
      this.fileToDelete = file
    },
    dismissAction() {
      this.fileToDelete = {}
      this.showModalProp = false
    },
    async deleteFile({fileId}) {
      try {
        await deleteFileById(fileId)
        await this.setFiles()
      } catch (error) {
        console.log(error)
      } finally {
        this.showModalProp = false
      }
    },
    isExternal(path) {
      return path === null || path === ''
    },
    async setFiles() {
      try {
        const {userId, isAdmin} = this.user

        this.filesLoading = true
        const { data } = isAdmin
          ? await getAllFiles({ fileVisibility: 'show_hidden', exams: false })
          : await getFilesByUserId({ userId, fileVisibility: false })

        this.files = data
          // se agrega estas propiedades para controlar los spinners de carga cuando se va a mostrar o descargar
          .map(file => ({ ...file, downloading: false, loadingView: false }))
          // los siguientes sort se encargan de ordenar los files en un orden de prioridad de menor a mayor, primero por fecha, nombre, subcateory y category
          .sort((a, b) => convertDateFormatFromString(b['fileLastUpdate']) - convertDateFormatFromString(a['fileLastUpdate']))
          .sort((a, b) => a['fileName'].localeCompare(b['fileName']))
          .sort((a, b) => a.subcategory.subCategoryName.localeCompare(b.subcategory.subCategoryName))
          .sort((a, b) => a.category.categoryName.localeCompare(b.category.categoryName))
      } catch (error) {
        console.log(error)
      } finally {
        this.filesLoading = false
        await this.$nextTick(() => {
           const elements = document.querySelectorAll("table th");
           elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
           })
           document.addEventListener("mousemove", (e) => {
             if (this.thElm) {
               this.lastWidth  = this.thElm.offsetWidth;
               this.thElm.style.width = this.startOffset + e.pageX + "px";
                document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                  if((internalIndex % 5) == Number(this.thElm.id)) {
                    td.style.width = this.startOffset + e.pageX + "px";
                  } else {
                    td.style.width = td.offsetWidth + "px";
                  }
                })
                this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
             }
           });

            document.addEventListener("mouseup", () => {
              this.thElm = undefined;
            });
        });
      }
    },
    goToAddFile(){
      router.push(`/admin/file/add/`)
    },
    goToEditFile(e, uuid){
      router.push(`/admin/file/edit/${uuid}`)
    },
    async handleTimer(e, file) {
      e.stopPropagation();
      this.counter++;
      
      if(this.counter == 1) {
         this.timer = setTimeout(async () => {
            this.counter = 0;
            await this.handleFileView(e,file);
         }, 300);

         return;
      }
      clearTimeout(this.timer); 
      this.counter = 0;
    },
    async handleFileView(e, file){
      e.stopPropagation();
      if(file.isTemp) return;
      this.hasSpinner = true;
      setTimeout(() => {
          if(this.hasSpinner) {
            this.$store.commit('setStateLoad', true)
          }
        }, 200);
      // if (file.filePath && file.filePath.endsWith('.mp4')) {
      //   this.$store.commit('setPlayingVideo', file)
      //   this.hasSpinner = false;
      //   return router.push(`/files/${file.uuid}`)
      // }
      this.files.forEach((f) => {
        if (f.fileId === file.fileId) {
          f.loadingView = true
        }
      })
      // this.files = this.files.map(elem => {
      //   if (elem.fileId === file.fileId) {
      //     file.loadingView = true
      //   }
      //   return elem
      // })
      try {
        if(this.isExternal(file.filePath)) {
          externalLinkRedirect(file.fileExternalSource);
        } else {
          const shouldStreamDirectly = file.filePath.substring(file.filePath.lastIndexOf('.') + 1).toLowerCase() == 'mp4';
          if(shouldStreamDirectly) {
            this.$store.dispatch('dispatchFileView', {file, shouldStreamDirectly})
          } else {
            const data = await fileView(file.fileId);
            this.$store.dispatch('dispatchFileView', {data, file})
          }
        }
        this.hasSpinner = false;
        if(this.isExternal(file.filePath) || (this.$store.state.modalType !== 'rd-not-valid-viewer' && !(this.$store.state.modalType === 'rd-pdf-viewer' && this.$store.state.windowWidth <= 768))) {
          await createInteraction({
            userId: this.user.userId,
            fileId: file.fileId,
            interactionTypeId: this.interactionTypes['VISUALIZE']
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.hasSpinner = false;
        this.$store.commit('setStateLoad', false)
        this.files.forEach((f) => {
          if (f.fileId === file.fileId) {
            f.loadingView = false
          }
        })
      }
    },
    async handleFileDownload(e, file) {
      if(file.isTemp) return;
      this.hasSpinner = true;
      setTimeout(() => {
          if(this.hasSpinner) {
            this.$store.commit('setStateLoad', true)
          }
        }, 200);
      const store = this.$store.state;
      this.files = this.files.map(elem => {
        if (elem.fileId === file.fileId) {
          file.downloading = true
        }
        return elem
      })

      try {
        if(!store.isMobile) {
          await fileDownload(file.fileId, file.fileName);
        } else {
          window.flutter_inappwebview.callHandler('comunicationname', JSON.stringify({
            token: store.token,
            id: file.fileId,
            name: file.fileName,
            route: `${process.env.VUE_APP_API_URL}/api/files/download/${file.fileId}`
          }));
        }
        this.hasSpinner = false;
        await createInteraction({
          userId: this.user.userId,
          fileId: file.fileId,
          interactionTypeId: this.interactionTypes['DOWNLOAD']
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.hasSpinner = false;
        this.$store.commit('setStateLoad', false)
        this.files = this.files.map(elem => {
          if (elem.fileId === file.fileId) {
            file.downloading = false
          }
          return elem
        })
      }
    }
  },
  
}
</script>
<style lang="scss" scoped>
.hoverable:hover {
  cursor: pointer;
}
.hoverable:hover {
  cursor: pointer;
}
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>