<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Settings (nav)')" />
      <div class="container background-form my-3 p-3">
        <PageTitle
          :title="$t('Sites Management')"
          :description="$t('Teams module')"
          :parentBread="$t('System Administration')"
          :childBread="$t('Sites Management')"
        />
        <div class="container mt-3 py-3">
          <div class="row g-4">

            <div
              v-if="user.isAdmin"
              class="col-12 col-lg-4" 
            >
              <rd-admin-card
                :name="$t('Teams')"
                :description="$t('')"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>

<script>
import RdFooter from '@/components/rd-components/rd-footer.vue'
import RdNavbar from '@/components/rd-components/rd-navbar.vue'
import RdAdminCard from '@/components/rd-components/rd-admin-card.vue'
import router from '@/router'
import { mapState } from 'vuex'
import {APP_PERMISSIONS} from '@/utils/constants.js'
import PageTitle from '../../components/rd-components/page-title.vue'

export default {
  components: { 
    RdFooter, 
    RdNavbar, 
    RdAdminCard,
    PageTitle
  },
  data() {
    return {
      APP_PERMISSIONS
    }
  },
  computed: {
    ...mapState(['user', 'permissions'])
  },
  methods: {
    goTo(route) {
      router.push(route)
    }
  },
}
</script>