import httpClient from '../httpClient';

const END_POINT = '/api/profiles';

const createProfile = (profile) => httpClient.post(END_POINT, profile);
const getProfileById = (profileId) => httpClient.get(`${END_POINT}/${profileId}`);
const getAllProfiles = () => httpClient.get(END_POINT);
const editProfileById = (profileId, profile) => httpClient.patch(`${END_POINT}/${profileId}`, profile);
const deleteProfileById = (profileId) => httpClient.delete(`${END_POINT}/${profileId}`);

export {
  createProfile,
  getProfileById,
  getAllProfiles,
  editProfileById,
  deleteProfileById
}