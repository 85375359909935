<template>
  <div class="d-flex justify-content-center">
    <div 
      class="spinner-border my-1 text-primary" 
      :style="`
        width: ${width}rem; 
        height: ${height}rem;
      `"
      role="status"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '6'
    },
    height: {
      type: String,
      default: '6'
    },
  }
}
</script>