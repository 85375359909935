<template>
  <div class="modal fade custom-modal" id="infoModal" tabindex="-1" aria-labelledby="infoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" :style="{'max-width': isMobile ? '100vw' : '325px'}">
      <div class="modal-content">
        <div class="modal-header" style="height:25px;">
          <h5 class="modal-title" style="font-size: 15px; font-weight: bold; color: rgb(61, 61, 61);">
            {{ $t('About') }}
          </h5>
        </div>
        <div class="modal-body pb-1 align-items-start">
          <p style="margin-bottom: 0; color: rgb(61, 61, 61);">
            Smart Files - e360 stack
          </p>
          <p style="color: rgb(61, 61, 61);">
            Version 3.1.9.12
          </p>
          <div class="d-flex flex-column align-items-center w-100" >
            <img src="/svg/smartmatic-logo-lightblue.svg" width="200" height="24">
            <p style="    font-size: calc(8px + .25vw); margin-top:1rem;">
              Copyright © Smartmatic 2023 
            </p>
          </div>
        </div>
        <div class="modal-footer pt-0" style="border-top: none;">
          <button
            type="button" 
            class="btn-outline-primary ghost medium button-dismiss"
            style="font-size: calc(10px + .25vw); border: none; font-weight: bold; background-color: white; color: #522eb3;"
            data-bs-dismiss="modal"
          >
            {{ $t('Close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'rdInfoModal',
  computed: {
    ...mapState(['windowWidth']),
    isMobile() {
      return this.windowWidth <= 768;
    }
  },
}
</script>
<style lang="scss" scoped>
.custom-modal .modal-content {
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
}

.custom-modal .modal-header {
  justify-content: space-between;
}

.custom-modal .modal-title {
  font-size: 24px;
}

.custom-modal .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-modal-logo {
  width: 100px;
  margin: 20px 0;
}
</style>
