import { encodeToBase64 } from '../../utils/utils';
import httpClient from '../httpClient';

const END_POINT = '/api/exams';

const createExam = async (exam) => await httpClient.post(END_POINT, exam, { headers: {
  'Content-Type': 'multipart/form-data'
}});
const getAllExams = async () => await httpClient.get(END_POINT);
const getExamById = async (id) => await httpClient.get(`${END_POINT}/${id}`);
const updateExamById = async (id, payload) => await httpClient.patch(`${END_POINT}/${id}`, payload, { headers: {
  'Content-Type': 'multipart/form-data'
}});
const deleteExamById = async (id) => await httpClient.delete(`${END_POINT}/${id}`);
const getScore = async (payload) => await httpClient.post(`${END_POINT}/score`, payload, {
  headers: {
    'message': encodeToBase64(`End of exam: ${payload.examId}`)
  }
});
const logStartExam = async ({ exam }) => await httpClient.post(`${END_POINT}/start`, exam, {
  headers: {
    'message': encodeToBase64(`Start of exam: ${exam.examId}`)
  }
});
const userCanPerformExam = async (examId, userId) => await httpClient.get(`${END_POINT}/allowed/${examId}/${userId}`);
const getStats = async (examId) => await httpClient.get(`${END_POINT}/stats/${examId}`);
const getGeneralStats = async () => await httpClient.get(`${END_POINT}/general/stats/`);
const getAttempt = async(examId, userId) => await httpClient.get(`${END_POINT}/attemp/${examId}/${userId}`);
const getAttempts = async(examId, userId) => await httpClient.get(`${END_POINT}/attemps/${examId}/${userId}`);

export {
  createExam,
  getAllExams,
  getExamById,
  updateExamById,
  deleteExamById,
  getScore,
  userCanPerformExam,
  getStats,
  getGeneralStats,
  getAttempt,
  getAttempts,
  logStartExam,
}