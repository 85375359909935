<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Files (nav)')" />
      <div class="container background-form my-3 p-3">
        <div class="row">
          <div class="col-12">
            <h2 class="ms-4 text-start">{{ $t('Add File') }}</h2>
          </div>
        </div>
        <div class="container">
        

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                :placeholder="$t('file name')" 
                type="text"
                :label="$t('Enter file name')"
                required
                @input="(e) => fileName = e.target.value"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :placeholder="$t('Choose a category')"
                :placeholderToTag="$t('Add this category')" 
                :label="$t('Enter file category')"
                :items="categoriesOfDB"
                required
                @handleAddTagging="handleCategorySelect"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="select"
                :placeholder="$t('Choose a subcategory')" 
                :placeholderToTag="$t('Add this subcategory')" 
                :label="$t('Enter the file subcategory')"
                :items="subCategoriesOfDB"
                required
                @handleAddTagging="(e) => this.selectedSubcategoryId = e.value"
              />
            </div>
          </div>
          
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Choose a language')"
                :label="$t('Enter the file language')"
                required
                @input="(e) => this.selectedLanguage = e.target.value"
              />
            </div>
          </div>  
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <rd-input
                type="text"
                :placeholder="$t('Description')"
                :label="$t('Enter the file description')"
                @input="(e) => this.description = e.target.value"
              />
            </div>
          </div>  
         
          <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
              <table width="100%">
                <tr>
                  <td>
                    <label class="labelText">
                      {{ $t('Type of file') }}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <rd-input
                      ref="file-input"
                      :disable="externalSource"
                      type="file"
                      :label="$t('Choose a file')"
                      required
                      :showError="showFileError"
                      :errorMsg="fileErrorMsg"
                      @input="handleFileSelect"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-check d-flex">
                      <input class="form-check-input me-2" type="checkbox" value="youtube" v-model="externalSource" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ $t(`It's an external source?`) }}
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <rd-input
                      v-if="externalSource"
                      type="text"
                      :placeholder="$t('Link to the external source')"
                      :label="$t('Enter link to the external source')"
                      required
                      @input="handleLinkSelect"
                    />
                  </td>
                </tr>
              </table>
              
            </div>
          </div>   

          <div class="row mb-4">
            <div class="col-2">
              <table>
                <tr>
                  <td>
                    <div class="row no-gutters pb-4 labelText h3">
                      {{ $t('File visibility') }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <rd-input
                      refs="file-visibility"
                      type="radio"
                      name="file-visibility"
                      :items="[
                        {label: $t('Visible (opt)'), value:'1'},
                        {label: $t('Hidden (opt)'),value:'0'}
                      ]"
                      :checked="isVisible ? 1 : 0"
                      @input="(e) => this.isVisible = e.target.value == 1"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
          
          <div class="my-3 row justify-content-end">
            <div class="col-12 col-md-6 col-lg-3 text-end">
              <button-component primaryOutline @handleClick="goBack">
                {{ $t('Dismiss') }}
              </button-component>
              <button-component
                :disabled="disableSaveButton"
                primary
                @handleClick="handleCreateFile"
              >
                <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add File" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add File (btn)') }}
              </button-component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer/>
    </div>
  </div>
</template>
<script>
import router from '@/router'
import RdFooter from '../../components/rd-components/rd-footer.vue';
import RdNavbar from '../../components/rd-components/rd-navbar.vue';
import RdInput from '@/components/rd-components/rd-input.vue'
import { createFile, acceptFile, deleteFileById } from '@/services/files/file.api.js'
import { getAllCategory, getCategoriesByUploader } from '@/services/categories/categories.api.js'
import { notAllowedFileExtensions } from '../../utils/constants';
import ButtonComponent from '../../components/button-component.vue';

export default {
  data() {
    return {
      externalSource: false,
      categoriesOfDB: [],
      subCategoriesOfDB: [],
      fileName: '',
      selectedCategoryId: '',
      selectedSubcategoryId: '',
      selectedLanguage: '',
      description: '',
      externalSourceLink: '',
      isPublic: false,
      isVisible: true,
      file: '',
      disableSaveButton: true,
      showFileError: false,
      fileErrorMsg: '',
      idTempFile: null,
      hasSpinner: true,
    }
  },
  async mounted() {
    await this.setCategories()

    this.$watch(
      () => {
        return [this.fileName, this.selectedCategoryId, this.selectedSubcategoryId, this.selectedLanguage, this.file, this.externalSourceLink, this.showFileError]
      },
      ([fileName, selectedCategory, selectedSubcategoryId, selectedLanguage, file, externalSourceLink, showFileError]) => {        
        if (fileName === '' || selectedCategory === '' || selectedSubcategoryId === '' || selectedLanguage === '' || (file === '' && externalSourceLink === '') || showFileError) {
          this.disableSaveButton = true
        } else { 
          this.disableSaveButton = false
        }

      },
    )
  },
  computed: {
    user(){
      return this.$store.getters.getUser;
    },
  },
  components: {
    RdFooter,
    RdNavbar,
    RdInput,
    ButtonComponent,
  },
  methods: {
    async setCategories() {
      try {
        let {isAdmin, userId} = this.user

        let {data} = isAdmin 
          ? await getAllCategory()
          : await getCategoriesByUploader(userId)
        
        this.categoriesOfDB = data.map(category => (
          { 
            value: category.categoryId, 
            name: category.categoryName, 
            subcategories: category.subcategories 
          }
        ));
      } catch (error) {
        console.log(error)
      }
    },
    async handleCreateFile() {
      this.hasSpinner = true;
      setTimeout(() => {
          if(this.hasSpinner) {
            this.$store.commit('setStateLoad', true)
          }
        }, 200);
      try {
        let fileData = new FormData();
        if (this.externalSourceLink != ''){
          fileData.append('fileExternalSource', this.externalSourceLink);
          fileData.append('fileOriginalName', '');
        } else {
          fileData.append('file', this.file);
          fileData.append('fileOriginalName', this.file.name);
        }
        fileData.append('fileName', this.fileName);
        fileData.append('categoryId', this.selectedCategoryId);
        fileData.append('subCategoryId', this.selectedSubcategoryId);
        fileData.append('fileLanguage', this.selectedLanguage);
        fileData.append('fileDescription', this.description);
        fileData.append('filePublic', this.isPublic);
        fileData.append('fileVisibility', this.isVisible);
        const {data} = await createFile(fileData);
        this.hasSpinner = false;
        if(!data.isTemp) {

          this.$store.dispatch('dispatchNotification', {
            title: 'Success!',
            message: 'File added.',
            type: 'success',
          });
          router.back()
          return;
        }
        const {fileId} = data;
        this.$swal.fire({
          title: "Would you like to save this file? The file extension you provided does not match the file's format",
          showDenyButton: true,
          confirmButtonText: 'Save',
          denyButtonText: `Don't save`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false
        }).then(async (result) => {
          if (result.isConfirmed) {
            await acceptFile(fileId);
            this.$swal.fire('Saved!', '', 'success')
          } else if (result.isDenied) {
            await deleteFileById(fileId);
            this.$swal.fire('The file was not saved', '', 'info')
          }
          setTimeout(() => {
            router.back()
          }, 1000);
        })
      } catch (error) {
        this.$store.dispatch('dispatchNotification', {
          title: 'Error!',
          message: 'File was not created.',
          type: 'error',
        });
        console.log(error);
      } finally {
        this.$store.commit('setStateLoad', false)
      }
    },
    goBack() {
      router.back()
    },
    handleCategorySelect(category) {
      this.selectedCategoryId = category.value
      const {subcategories} = this.categoriesOfDB.find(cat => parseInt(cat.value) === parseInt(this.selectedCategoryId))
      this.subCategoriesOfDB = subcategories.map(subcategory => (
        { value: subcategory.subCategoryId, name: subcategory.subCategoryName }
      ));
    },
    validateExt(fileName) {
      let result = false
      for (const extention of notAllowedFileExtensions) {
        if (fileName.endsWith(extention)) {
          result = true
          break
        }
      }
      return result
    },
    handleFileSelect(event) {
      this.showFileError = false
      if (this.validateExt(event.target.files[0].name)) {
        this.showFileError = true
        this.fileErrorMsg = 'File type not supported'
        return
      }
      this.externalSourceLink = ""
      this.file = event.target.files[0]
    },
    handleLinkSelect(event) {
      this.file = ""
      this.externalSourceLink = event.target.value
    }
  },
}
</script>
<style scoped>
.labelText {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: grey;
  float: left;
  border-radius: 4px;
}
</style>