<template>
  <div class="d-flex flex-column justify-content-between vh-100">
    <div>
      <rd-navbar :title="$t('Categories (nav)')" />
      <div class="container background-form my-3 p-3">
        <div class="row px-3 justify-content-between">
          <div class="col-12 col-lg-4">
            <h2 class="ms-4 text-start">{{category}}</h2>
          </div>
          <div class="col-12 col-lg-6 text-end">
            <button-component
              primary
              @handleClick="goToAddNewSubCategory"
            >
              <i data-bs-toggle="tooltip" data-bs-placement="right" title="Add Subcategory" type="button" class="bi bi-plus-circle-fill"></i> {{ $t('Add Subcategory (btn)') }}
            </button-component>
          </div>
        </div>

        <div v-if="subcategoriesLoading">
          <rd-loading />
        </div>
        <div v-else>
          <div v-if="subcategories.length == 0" class="row p-3">
            <h2>
              {{ $t('No data found') }} <i data-bs-toggle="tooltip" data-bs-placement="right" title="No subcategories Found" type="button" class="bi bi-exclamation-triangle"></i>
            </h2>
          </div>
          <div v-if="subcategories.length > 0" class="row p-3 d-flex justify-content-center">
            <div style="overflow-x: auto; width: 100%; max-width: 100%;">
              <div class="w-100">
                <table class="table text-center table-hover table-custom-general" ref="table_resize">
                  <thead class="table-primary" @contextmenu.prevent="resize">
                    <tr>
                      <th scope="col" class="text-start">{{ $t('Sub Category (table)') }}</th>
                      <th scope="col" class="text-start">{{ $t('Category (table)') }}</th>
                      <th scope="col">{{ $t('Actions (table)') }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-start">
                    <tr
                      v-for="(subcategory, index) in dataTemp"
                      :key="index"
                      class="hoverable"
                      :class="{ 'fill-ods': index % 2 !== 0}"
                    >
                      <td style="width: 33%; word-break: break-all;" :header="$t('Sub Category (table)')" scope="row"><div class="custom-table-text">{{ subcategory.subCategoryName }}</div></td>
                      <td style="width: 33%; word-break: break-all;" :header="$t('Category (table)')" scope="row"><div class="custom-table-text">{{ subcategory.categoryName }}</div></td>
                      <td style="width: 33%; word-break: break-all;" :header="$t('Actions (table)')" class="centered">
                        <button-component class="btn btn-label" @handleClick="goToEditSubCategory(subcategory.subCategoryId)">
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="Edit Subcategory" type="button" class="bi bi-pencil-square"></i>
                        </button-component>
                        <button-component
                          class="btn btn-label" 
                          @handleClick="showModal(subcategory)"
                        >
                          <i data-bs-toggle="tooltip" data-bs-placement="right" title="Delete Subcategory" type="button" class='bi bi-trash-fill'></i>
                        </button-component>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <rd-paginator :items="subcategories" @page-change="handlePageChange" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <rd-footer />
    </div>
    <rd-confirmation-modal
      v-if="showModalProp"
      modalTitle="Delete subcategory permanently?"
      confirmButton="Delete"
      dismissButton="Dismiss"
      :showBody="filesRelated.length > 0"
      bodyTitleText="Files related"
      confirmCheckText="Delete anyway"
      :items="filesRelated"
      @confirmAction="deleteSubCategory(subcategoryToDelete)"
      @dismissAction="dismissAction"
    />
  </div>
</template>
<script>
  import RdFooter from '../../components/rd-components/rd-footer.vue'
  import RdNavbar from '../../components/rd-components/rd-navbar.vue'
  import {
    getCategoryById
  } from '@/services/categories/categories.api.js'
  import {
    getSubCategoriesByCategoryId,
    deleteSubcategoryById
  } from '@/services/subcategories/subcategories.api.js'
  import { 
    filesBySubcategory 
  } from '@/services/files/file.api.js'
  import router from '@/router'
  import RdConfirmationModal from '../../components/rd-components/rd-confirmation-modal.vue'
  import RdLoading from '../../components/rd-components/rd-loading.vue'
  import RdPaginator from '@/components/rd-components/rd-paginator.vue'
import ButtonComponent from '../../components/button-component.vue'

  export default {
    data() {
      return {
        category: '',
        subcategories: [],
        subcategoriesLoading: true,
        showModalProp: false,
        subcategoryToDelete: {},
        filesRelated: [],
        dataTemp: [],
        thElm: null,
        startOffset: null,
        lastWidth: 0,
      }
    },
    async mounted() {
      await this.setThisCategory()
      await this.setSubcategories()
    },
    components: {
      RdFooter,
      RdNavbar,
      RdConfirmationModal,
      RdLoading,
      RdPaginator,
        ButtonComponent
    },
    methods: {
      resize() {
        this.$refs.table_resize.style.width = '100%'
        const elements = document.querySelectorAll("table th");
        elements.forEach((el) => {
          el.style.width = 'auto'
        })
        document.querySelectorAll("table tbody tr td").forEach((td) => {
        td.style.width = '33%'
      })
      },
      async handlePageChange(data) {
        this.dataTemp = data;
      },
      async setThisCategory() {
        const categoryId = this.$route.params.id
        try {
          const {
            data
          } = await getCategoryById(categoryId)
          this.category = data.category.categoryName
        } catch (error) {
          console.log(error)
        }
      },
      async setSubcategories() {
        this.subcategories = []
        const categoryId = this.$route.params.id
        try {
          this.subcategoriesLoading = true
          const {
            data
          } = await getSubCategoriesByCategoryId(categoryId)
          data.forEach(element => element.categoryName = this.category);
          this.subcategories = data
        } catch (error) {
          console.log(error)
        } finally {
          this.subcategoriesLoading = false
          await this.$nextTick(() => {
           const elements = document.querySelectorAll("table th");
           elements.forEach((el, index) => {
            el.style.position = "relative";
            el.id = index

            var grip = document.createElement("div");
            grip.innerHTML = "&nbsp;";
            grip.style.borderRight = 'black solid 1px'
            grip.style.top = 0;
            grip.style.right = 0;
            grip.style.bottom = 0;
            grip.style.width = "5px";
            grip.style.position = "absolute";
            grip.style.cursor = "col-resize";
            grip.addEventListener("mousedown", (e) => {
              this.thElm = el;
              this.startOffset = el.offsetWidth - e.pageX;
              this.lastWidth = 0
            });

            el.appendChild(grip);
           })
           document.addEventListener("mousemove", (e) => {
             if (this.thElm) {
               this.lastWidth  = this.thElm.offsetWidth;
               this.thElm.style.width = this.startOffset + e.pageX + "px";
                document.querySelectorAll("table tbody tr td").forEach((td, internalIndex) => {
                  if((internalIndex % 3) == Number(this.thElm.id)) {
                    td.style.width = this.startOffset + e.pageX + "px";
                  } else {
                    td.style.width = td.offsetWidth + "px";
                  }
                })
                this.$refs.table_resize.style.width = this.$refs.table_resize.offsetWidth + ((this.thElm.offsetWidth - this.lastWidth)) + "px"
             }
           });

            document.addEventListener("mouseup", () => {
              this.thElm = undefined;
            });
        });
        }
      },
      goToEditSubCategory(id) {
        router.push(`/admin/subcategories/edit/${id}`)
      },
      async deleteSubCategory({subCategoryId}) {
        try {
          await deleteSubcategoryById(subCategoryId)
          await this.setSubcategories()
        } catch (error) {
          console.log(error)
        } finally {
          this.showModalProp = false
        }
      },
      goToAddNewSubCategory(){
        router.push(`/admin/subcategories/add`)
      },
      async showModal(subcategory) {
        try {
          const {data} = await filesBySubcategory(subcategory.subCategoryId)
          this.filesRelated = data.map(file => ({name: file.fileName}))
        } catch (error) {
          console.log(error)
        }
        this.showModalProp = true
        this.subcategoryToDelete = subcategory
      },
      dismissAction() {
        this.subcategoryToDelete = {}
        this.showModalProp = false
      },
    },
  }
</script>
<style lang="scss" scoped>
.hoverable:hover {
  cursor: pointer;
}
.fill-ods {
  background-color: #f5f5f5;
}
thead {
  border-top: #e0e0e0 solid 1px;
  border-right: #e0e0e0 solid 1px;
  border-left: #e0e0e0 solid 1px;
}
tr {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
tbody {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
td {
  min-height: 50px;
}
th {
  min-height: 50px;
  height: 50px;
}
.table-primary {
  tr {
    th {
      vertical-align: middle;
    }
  }
}
.centered {
  text-align: center;
  vertical-align: middle;
}
.hoverable {
  td {
    vertical-align: middle;
  }
}
th {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
td {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>